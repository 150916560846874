const React = require('react');
const {campaign,sortDisplayName} = require('../lib/campaign.js');
const {displayMessage} = require('./notification.jsx');
const {getMergedCustomLevels,fixupFeature,getCharacterFromId} = require('../lib/character.js');
const Parser = require("../lib/dutils.js").Parser;
const {EntityEditor,Renderentry} = require('./entityeditor.jsx');
const {SpellPicker,getSchoolsOfMagic,getAllSpellSources} = require('./renderspell.jsx');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
const {LinkHref,AddChatEntry}=require('./renderhref.jsx');
const {ItemPicker,ItemDialog,PickProperties,getItemPropertyList} = require('./items.jsx');
const {MaxNumberAdjust, NumberAdjust, SelectTextVal, SelectMultiVal, SelectVal, TextVal, PickVal, CheckVal, TextPlusEdit, DeleteWithConfirm, NumberAdjustPlusMinus, TextBasicEdit,escapeRegExp,alwaysArray} = require('./stdedit.jsx');
const stdvalues = require('../lib/stdvalues.js');
const {signedNum, pluralString, joinCommaAnd,gamesystemAnyOptions} = stdvalues;
const {FeatureComplete} = require('./searchfs.jsx');
import {htmlFromEntry} from "../lib/entryconversion.js";


class FeatureListEdit extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }

    onChangeFeature(i, feature) {
        const features = (this.props.features||[]).concat([]);
        features[i] = feature;
        this.props.onChange(features);
    }

    onDeleteFeature(i) {
        const features = (this.props.features||[]).concat([]);
        features.splice(i,1);
        this.props.onChange(features);
    }

    onAddFeature(i) {
        const features = (this.props.features||[]).concat([]);
        features.push({auto:true, usage:{type:"A"}, id:campaign.newUid()});
        this.props.onChange(features);
    }

    onReorderFeature(i, direction) {
        const features = (this.props.features||[]).concat([]);
        i = Number(i);
        const cur = features[i];
        features[i]= features[i+direction];
        features[i+direction] = cur;
        
        this.props.onChange(features);
    }

    render() {
        const {displayType, type, subtype, level, emptyName, name, translate} = this.props;
        const features = this.props.features||[];
        const baseFeatures = this.props.baseFeatures||[];
        const list = [];
        let num=0;

        for (let i in baseFeatures) {
            list.push(<FeatureNoEdit key={this.props.idBase?(this.props.idBase+num):num} feature={baseFeatures[i]} id={this.props.idBase?(this.props.idBase+num):null} translate={translate}/>);
            num++;
        }

        let prevFeature = null;

        for (let i in features) {
            list.push(<FeatureEdit 
                key={this.props.idBase?(this.props.idBase+num):num} 
                feature={features[i]}
                displayType={displayType} type={type} subtype={subtype} level={level} prevFeature={prevFeature} emptyName={emptyName} name={name}
                id={this.props.idBase?(this.props.idBase+num):null} 
                editable 
                noOptions={this.props.noOptions} 
                noFeats={this.props.noFeats} 
                onChange={this.onChangeFeature.bind(this,i)} 
                onDelete={this.onDeleteFeature.bind(this,i)}
                moveUp={i>0?this.onReorderFeature.bind(this,i,-1):null}
                moveDn={i<(features.length-1)?this.onReorderFeature.bind(this,i,1):null}
                equipment={this.props.equipment}
                onChangeEquipment={this.props.onChangeEquipment}
                compact={this.props.compact}
                translate={translate}
            />);
            prevFeature=features[i].name;
            num++;
        }

        return <div>
            {list}
            <div id={"addbutton"+this.props.idBase}>
                <Button className="ml2 minw2" color="secondary" variant="outlined" size="small" onClick={this.onAddFeature.bind(this)}>{this.props.addLabel||"+ Feature"}</Button>
            </div>
        </div>;
    }
}

class FeatureEdit extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    onChangePick(field, val) {
        const feature = Object.assign({}, this.props.feature);
        if (val == "none") {
            val = null;
        }
        feature[field] = val;
        if (field=="entries") {
            delete feature.auto;
        }
        this.props.onChange(feature)
    }

    onChangeName(event) {
        this.onChangePick("name",event.target.value)
    }

    onChangeEntries(entry) {
        this.onChangePick("entries",entry?[entry]:null);
    }

    render() {
        const {feature, equipment, onChangeEquipment, displayType, type, subtype, level, prevFeature, emptyName,noDescription, name, editable, translate} = this.props;

        fixupFeature(feature);
        return <div className="mv1 stdcontent" id={this.props.id}>
            <div className="flex mb2">
                <div className="flex-auto">
                    <Input className="f3 b small-caps titletext titlecolor" value={feature.name||""} variant="standard" fullWidth onChange={this.onChangeName.bind(this)} placeholder="feature name"/>
                </div>
                <div>
                    {(editable && !this.props.noButtons)?<FeatureComplete onChange={this.props.onChange} onChangeEquipment={onChangeEquipment} feature={feature} displayType={displayType} type={type} subtype={subtype} level={level} prevFeature={prevFeature} emptyName={emptyName} name={name}/>:null}
                    {(editable && !this.props.noButtons)?<Button className="mr1 minw2" onClick={this.showEditFeatureOptions.bind(this)} size="small" color="secondary" variant={featureHasOptions(feature)?"contained":null}>
                        Edit
                    </Button>:null}
                    {this.props.onDelete?<DeleteWithConfirm className="pa1" name={feature.name} onClick={this.props.onDelete}/>:null}
                    {(editable && !this.props.noButtons)?<span className={(this.props.moveUp?"hoverhighlight":"gray-80")+" fas fa-arrow-up pa1 ml--2"} onClick={this.props.moveUp}/>:null}
                    {(editable && !this.props.noButtons)?<span className={(this.props.moveDn?"hoverhighlight":"gray-80")+" fas fa-arrow-down pa1 ml--2"} onClick={this.props.moveDn}/>:null}
                </div>
            </div>
            <EntityEditor onChange={this.onChangeEntries.bind(this)} entry={(feature.entries&&(feature.entries.length==1)&&(feature.entries[0].type=="html"))?feature.entries[0]:feature.entries?{entries:feature.entries}:null}/>
            <ExtraSpells extraSpells={feature.extraSpells} translate={translate} />
            {feature.descriptionOptions&&!noDescription?<div className="mb1"><DescriptionOptionsListEdit descriptions={feature.descriptionOptions} noBackground/></div>:null}
            {feature.options?
                <div className="ma1 pl1 bl titlebordercolor">
                    <FeatureListEdit editable noOptions features={feature.options} onChange={this.onChangePick.bind(this,"options")} addLabel="Add Feature to Option List" displayType={displayType} type={type} subtype={subtype} level={level} name={name}/>
                </div>
            :  null
            }
            <EditFeatureOptions 
                open={this.state.showEditFeatureOptions} 
                feature={feature} 
                onClose={this.closeEditFeatureOptions.bind(this)}
                noOptions={this.props.noOptions}
                noFeats={this.props.noFeats}
                compact={this.props.compact}
            />
            {(onChangeEquipment && feature.name == "Equipment")?<EquipmentList editable noGoldAlt noBackground equipment={equipment} onChange={onChangeEquipment}/>:null}
        </div>;
    }

    showEditFeatureOptions() {
        this.setState({showEditFeatureOptions:true});
    }

    closeEditFeatureOptions(feature) {
        this.setState({showEditFeatureOptions:false});
        if (feature) {
            this.props.onChange(feature);
        }
    }
}

const usageNameInfoText = "Setting a usage name allows other features to share usage values.  Usage counts will show as the maximum of any value across features. Note: Changing the name will break usage tracking on existing character sheets.";
class EditFeatureOptions extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({feature:this.props.feature,showDescription:false});
        }
    }

    handleClose(savechanges) {
        if (savechanges) {
            this.props.onClose(this.state.feature);
        } else {
            this.props.onClose();
        }
    };

    deleteFeature() {
        this.props.onClose({});
    }

    onChangePick(field, val) {
        const feature = Object.assign({}, this.state.feature);
        if (val == "none") {
            val = null;
        }
        if (field == "recoveryType"){
            delete feature.ritualOnly;
        }
        if (field == "grantFeat") {
            delete feature.pickFeat;
        }
        if (field == "pickFeat") {
            delete feature.grantFeat;
        }

        feature[field] = val;
        if (feature.auto) {
            feature.entries = [getFeatureSummaryText(feature)]
        }
        this.setState({feature});
    }

    onChangeFeature(feature) {
        if (feature.auto) {
            feature.entries = [getFeatureSummaryText(feature)]
        }
        this.setState({feature});
    }

    onChangeDualPick(f1,f2,v1,v2) {
        const feature = Object.assign({}, this.state.feature);
        feature[f1] = v1;
        feature[f2] = v2;
        if (feature.auto) {
            feature.entries = [getFeatureSummaryText(feature)]
        }
        this.setState({feature});
    }

    onChangeMediumArmorMax(medium) {
        this.onChangePick("mediumArmorMax", medium?3:0);
    }

    showPicker(property) {
        const feature = this.state.feature;
        const spells = feature[property];
        const selectedSpells = {};
        for (let i in spells) {
            selectedSpells[spells[i].toLowerCase()] = true;
        }

        this.setState({showPicker:true, showPickerProperty:property, selectedSpells});
    }

    showPickFeat() {
        this.setState({showPickFeat:true});
    }

    pickFeat(feat) {
        if (feat) {
            this.onChangePick("grantFeat", feat);
        }
        this.setState({showPickFeat:false});
    }

    closePicker(spells) {
        this.setState({showPicker:false});
        if (spells) {
            const ne = [];
            for (let i in spells) {
                if (spells[i]) {
                    ne.push(i);
                }
            }
            this.onChangePick(this.state.showPickerProperty, ne.length?ne:null);
        }
    }

    changeMode(mode) {
        let feature = Object.assign({}, this.state.feature);
        switch (mode) {
            case "hide":
                if (feature.usage) {
                    feature.usage.type="hide";
                }
                delete feature.options;
                break;
            case "show":
                if (!feature.usage) {
                    feature.usage={type:"A"};
                } else {
                    feature.usage.type="A";
                }
                delete feature.options;
                break;
            case "options":
                if (!feature.options) {
                    feature.options=[{usage:{type:"A"}}];
                }
                delete feature.pickFeat;
                delete feature.usage;
                break;
            case "asi":
                feature = {
                    "name": "Ability Score Improvement",
                    "entries": [
                      {
                        "type": "html",
                        "html": "You can increase one ability score of your choice by 2, or you can increase two ability scores of your choice by 1. As normal, you can't increase an ability score above 20 using this feature.<p>If your DM allows the use of feats, you may instead take a feat.</p>"
                      }
                    ],
                    "options": [
                      {
                        "name": "Increase Ability Scores",
                        "ability": {"choose": 2, "from": ["str","dex","con","int","wis","cha"]},
                        "entries": [],auto:true
                      },
                      {
                        "name": "Pick Feat",
                        "pickFeat": true,
                        "entries": [], auto:true
                      }
                    ]
                }
                break;
        }
        this.setState({feature});
    }

    onChangeUsageVal(prop, val) {
        let usage = Object.assign({}, this.state.feature.usage||{});

        if (val=="none") {
            val = null;
        }

        usage[prop] = val;

        this.onChangePick("usage", usage);
    }

    render() {
        if (!this.props.open || !this.state.feature) {
            return null;
        }

        const {PickFeat} = require('./renderfeats.jsx');
        const feature = this.state.feature;
        const textDescription = feature.auto?"":getFeatureSummaryText(feature);
        const selectedOptions = [];

        const usageOptions = [];
        const proficiencyOptions = [];
        const spellOptions = [];
        const combatOptions=[];
        const bonusOptions = [];
        const movesenseOptions=[];
        const userSelections=[];
        const otherOptions = [];

        const usage = feature.usage||{};
        let mode="hide";
        fixupFeature(feature);

        let featureOptions = Object.assign({},selectFeatureOptionsType);
        if (this.props.noFeats || this.props.noOptions) {
            featureOptions = Object.assign(featureOptions);
            if (this.props.noFeats) {
                delete featureOptions.feat;
                delete featureOptions.asi;
            }
            if (this.props.noOptions) {
                delete featureOptions.options;
            }
        }
        if (this.props.noShow) {
            delete featureOptions.show;
        }

        if (feature.options) {
            mode="options";
        } else if (feature.usage && (usage.type!="hide")) {
            mode="show";
        }

        if (mode!="options") {
            const hasUses = usage.baseCount||usage.levelsCount||usage.ability||usage.proficiency;
            
            addFeatureOption(hasUses,usageOptions,
                <div key="usage" className="mb1">
                    <PickUsage usage={usage} onChange={this.onChangePick.bind(this,"usage")}/>
                </div>
            );
            addFeatureOption(usage.displayLevels,usageOptions,
                <div key="displayLevels" className="mb1">
                    <PickCountsByLevel noDiv levelsCount={usage.displayLevels} label="Display Values by Level" onChange={this.onChangeUsageVal.bind(this,"displayLevels")}/>
                    {usage.displayLevels?<span> <TextPlusEdit noDiv useButton label="Display Prefix" text={usage.displayPrefix} editable size="small" variant="outlined" color="primary" onChange={this.onChangeUsageVal.bind(this, "displayPrefix")}/> </span>:null}
                    {usage.displayLevels?<TextPlusEdit noDiv useButton label="Display Suffix" text={usage.displaySuffix} editable size="small" variant="outlined" color="primary" onChange={this.onChangeUsageVal.bind(this, "displaySuffix")}>&nbsp;</TextPlusEdit>:null}
                    {usage.displayLevels?<TextPlusEdit noDiv useButton label="Value Name" text={usage.valueName} editable size="small" variant="outlined" color="primary" onChange={this.onChangeUsageVal.bind(this, "valueName")}>&nbsp;</TextPlusEdit>:null}
                </div>
            );
            if (usage?.usageName || usage?.valueName) {
                addFeatureOption(usage.showAsAttribute,usageOptions,
                    <div key="showprof" className="mb1">
                        <PickVal values={yesNoValues} isNum onClick={this.onChangeUsageVal.bind(this, "showAsAttribute")} label="Show as Attribute" size="small" variant="outlined" color="primary"/>
                        {usage.showAsAttribute?<span> Show as attribute. <TextPlusEdit noDiv useButton label="Attribute Extra" text={usage.extraValue} editable size="small" variant="outlined" color="primary" onChange={this.onChangeUsageVal.bind(this, "extraValue")}>&nbsp;</TextPlusEdit></span>:null}
                    </div>
                );
            }
        }
        
        if (mode == "show") {
            addFeatureOption(usage.saveDC,usageOptions,
                <div key="saveDC"><SimpleCheckPick onChange={this.onChangeUsageVal.bind(this, "saveDC")} value={usage.saveDC} values={stdvalues.abilityNames}  label="Save DC" prefix="Show save DC" editable useButton/></div>
            );

            addFeatureOption(feature.showProficiencies,usageOptions,
                <div key="showprof" className="mb1">
                    <PickVal values={yesNoValues} isNum onClick={this.onChangePick.bind(this, "showProficiencies")} label="Show Proficiencies" size="small" variant="outlined" color="primary"/>
                    {feature.showProficiencies?" - Display selected weapons and armor proficiencies on character sheet.":null}
                </div>
            );
        }

        if (mode !="options") {
            addFeatureOption(usage.usageName,usageOptions,
                <div key="usageName" className="mb1">
                    <TextPlusEdit useButton label="Usage Name" text={usage.usageName||""} info={usageNameInfoText} editable size="small" variant="outlined" color="primary" onChange={this.onChangeUsageVal.bind(this, "usageName")}/>
                </div>
            );

            addFeatureOption(feature.addUses,usageOptions,
                <div key="au" className="mb1">
                    <AddUses adduses={feature.addUses} numOnly onChange={this.onChangePick.bind(this,"addUses")}/>
                </div>
            );

            addFeatureOption(feature.armor,proficiencyOptions,
                <div key="armor"><CheckPick useButton label="Armor" value={feature.armor||{}} options={stdvalues.armorTypes} editable onChange={this.onChangePick.bind(this, "armor")}/></div>
            );

            const {weaponProficiencies,toolProficiencies} = campaign.getItemExtensions();

            addFeatureOption(feature.weapons,proficiencyOptions,
                <div key="weapons"><CheckPick useButton label="Weapons" value={feature.weapons||{}} options={weaponProficiencies} editable onChange={this.onChangePick.bind(this, "weapons")}/></div>
            );

            addFeatureOption(feature.tools,proficiencyOptions,
                <div key="tools"><CheckPick useButton label="Tools" showProficiency showAllProficient value={feature.tools||{}} options={toolProficiencies} editable onChange={this.onChangePick.bind(this, "tools")}/></div>
            );

            addFeatureOption(feature.skills,proficiencyOptions,
                <div key="skills"><CheckPick useButton label="Skills" showProficiency value={feature.skills||{}} options={campaign.getAllSkills()} editable onChange={this.onChangePick.bind(this, "skills")}/></div>
            );

            addFeatureOption(feature.checks,proficiencyOptions,
                <div key="checks"><CheckPick useButton noChooseOption label="Ability Checks" showProficiency value={feature.checks||{}} options={stdvalues.abilities} editable onChange={this.onChangePick.bind(this, "checks")}/></div>
            );

            addFeatureOption(feature.languages,proficiencyOptions,
                <div key="languages"><CheckPick useButton allowExtension label="Languages" value={feature.languages||{}} options={campaign.getAllLanguages()} editable onChange={this.onChangePick.bind(this, "languages")}/></div>
            );

            addFeatureOption(feature.abilitySaves,proficiencyOptions,
                <div key="abilitySaves"><CheckPick useButton label="Saves" value={feature.abilitySaves||{}} options={stdvalues.abilities} editable onChange={this.onChangePick.bind(this, "abilitySaves")}/></div>
            );

            addFeatureOption(feature.speed,movesenseOptions,
                <div key="speed" className="mb1"><Movement speed={feature.speed||{}} onChange={this.onChangePick.bind(this, "speed")}/></div>
            );

            addFeatureOption(feature.senses,movesenseOptions,
                <div key="senses" className="mb1"><Senses senses={feature.senses||{}} onChange={this.onChangePick.bind(this, "senses")}/></div>
            );

            addFeatureOption(feature.ability,bonusOptions,
                <div key="ability" className="mb1"><AbilityOptions editable ability={feature.ability} onChange={this.onChangePick.bind(this, "ability")}/></div>
            );

            addFeatureOption(feature.hpLevelMod,combatOptions,
                <div key="hpLevelMod" className="mb1">
                    <PickVal values={bonusValues} isNum onClick={this.onChangePick.bind(this, "hpLevelMod")} label="Hit Point Bonus" size="small" variant="outlined" color="primary"/>
                    {feature.hpLevelMod?(" "+signedNum(feature.hpLevelMod)+" Per level bonus to hit point maximum."):null}
                </div>
            );

            addFeatureOption(feature.maxHP,combatOptions,
                <div key="maxHP" className="mb1">
                    <TextPlusEdit noDiv useButton size="small" variant="outlined" label="Max Hit Points" text={feature.maxHP} editable onChange={this.onChangePick.bind(this, "maxHP")}/>
                    {feature.maxHP?<span> to maximum hit points</span>:null}
                </div>
            );
    
            addFeatureOption(feature.acBonus||feature.acAbilitiesBonus,combatOptions,
                <div key="acBonus" className="mb1">
                    <ACBonus feature={feature} onChange={this.onChangeFeature.bind(this)}/>
                </div>
            );

            addFeatureOption(feature.baseAC,combatOptions,
                <div key="baseAC" className="mb1"><BaseAC baseAC={feature.baseAC} onChange={this.onChangePick.bind(this, "baseAC")}/></div>
            );

            addFeatureOption(feature.unarmedAttack,combatOptions,
                <div key="unarmedAttack" className="mb1"><UnarmedAttack noDiv label="Unarmed Attack" unarmedAttack={feature.unarmedAttack} onChange={this.onChangePick.bind(this, "unarmedAttack")}/></div>
            );

            addFeatureOption(feature.extraAttack,combatOptions,
                <div key="extraAttack" className="mb1">
                    <PickVal values={[0,1,2,3,4]} isNum onClick={this.onChangePick.bind(this, "extraAttack")} label="Extra Attack" size="small" variant="outlined" color="primary"/>
                    {feature.extraAttack?" - Gain an extra attack.":null}
                </div>
            );

            addFeatureOption(feature.resist,combatOptions,
                <div key="resist"><CheckPick useButton allowExtension label="Resistance" value={feature.resist||{}} options={stdvalues.damageTypesList} editable onChange={this.onChangePick.bind(this, "resist")}/></div>
            );

            addFeatureOption(feature.immune,combatOptions,
                <div key="immune"><CheckPick useButton allowExtension label="Immunity" value={feature.immune||{}} options={stdvalues.damageTypesList} editable onChange={this.onChangePick.bind(this, "immune")}/></div>
            );

            addFeatureOption(feature.vulnerable,combatOptions,
                <div key="vulnerablility"><CheckPick useButton allowExtension label="Vulnerability" value={feature.vulnerable||{}} options={stdvalues.damageTypesList} editable onChange={this.onChangePick.bind(this, "vulnerable")}/></div>
            );

            addFeatureOption(feature.conditionImmune,combatOptions,
                <div key="conditionImmune"><CheckPick useButton allowExtension label="Condition Immunity" value={feature.conditionImmune||{}} options={stdvalues.conditionList} editable onChange={this.onChangePick.bind(this, "conditionImmune")}/></div>
            );

            addFeatureOption(feature.dueling,combatOptions,
                <div key="dueling" className="mb1">
                    <PickVal values={bonusValues} isNum onClick={this.onChangePick.bind(this, "dueling")} label="Dueling Bonus" size="small" variant="outlined" color="primary"/>
                    {feature.dueling?(" "+signedNum(feature.dueling)+" Bonus to damage when using single weapon with one hand."):null}
                </div>
            );

            addFeatureOption(feature.dualACBonus,combatOptions,
                <div key="dualACBonus" className="mb1">
                    <PickVal values={bonusValues} isNum onClick={this.onChangePick.bind(this, "dualACBonus")} label="Dual Weapon Bonus" size="small" variant="outlined" color="primary"/>
                    {feature.dualACBonus?(" "+signedNum(feature.dualACBonus)+" Bonus to AC when using a weapon in each hand."):null}
                </div>
            );

            addFeatureOption(feature.mediumArmorMax==3,combatOptions,
                <div key="mediumArmorMax" className="mb1">
                    <PickVal values={yesNoValues} isNum onClick={this.onChangeMediumArmorMax.bind(this)} label="Medium Armor Master" size="small" variant="outlined" color="primary"/>
                    {feature.mediumArmorMax==3?" - When wearing medium armor, add 3 rather than 2 with dexterity 16 or higher.":null}
                </div>
            );

            addFeatureOption(feature.itemmod,combatOptions,
                <ItemsAdjust key="itemmod" itemmod={feature.itemmod} onChange={this.onChangePick.bind(this, "itemmod")}/>
            );

            addFeatureOption(feature.initiativeBonus,bonusOptions,
                <div key="initiativeBonus" className="mb1">
                    <PickVal values={bonusValuesWithAbilitiesProf} isPossibleNum onClick={this.onChangePick.bind(this, "initiativeBonus")} label="Initiative" size="small" variant="outlined" color="primary"/>
                    {feature.initiativeBonus?(" "+(bonusValuesWithAbilitiesProf[feature.initiativeBonus]||signedNum(feature.initiativeBonus))+" Initiative Bonus"):""}
                </div>
            );

            addFeatureOption(feature.perceptionBonus,movesenseOptions,
                <div key="perceptionBonus" className="mb1">
                    <PickVal values={bonusValues} isNum onClick={this.onChangePick.bind(this, "perceptionBonus")} label="Passive Perception Bonus" size="small" variant="outlined" color="primary"/>
                    {feature.perceptionBonus?(" "+signedNum(feature.perceptionBonus)+" Passive Perception Bonus"):""}
                </div>
            );

            addFeatureOption(feature.proficiencyBonus,bonusOptions,
                <div key="proficiencyBonus" className="mb1">
                    <PickVal values={bonusValues} isNum onClick={this.onChangePick.bind(this, "proficiencyBonus")} label="Proficiency" size="small" variant="outlined" color="primary"/>
                    {feature.proficiencyBonus?(" "+signedNum(feature.proficiencyBonus)+" Proficiency Bonus"):""}
                </div>
            );

            addFeatureOption(feature.spellDCBonus,spellOptions,
                <div key="spellDCBonus" className="mb1">
                    <PickVal values={bonusValues} isNum onClick={this.onChangePick.bind(this, "spellDCBonus")} label="DC Bonus" size="small" variant="outlined" color="primary"/>
                    {feature.spellDCBonus?(" "+signedNum(feature.spellDCBonus)+" Spell DC Bonus"):""}
                </div>
            );

            addFeatureOption(feature.spellAttackBonus,spellOptions,
                <div key="spellAttackBonus" className="mb1">
                    <PickVal values={bonusValues} isNum onClick={this.onChangePick.bind(this, "spellAttackBonus")} label="Attack Bonus" size="small" variant="outlined" color="primary"/>
                    {feature.spellAttackBonus?(" "+signedNum(feature.spellAttackBonus)+" Spell Attack Bonus"):""}
                </div>
            );

            addFeatureOption(feature.savingThrowBonus,bonusOptions,
                <div key="savingThrowBonus">
                    <BonusCheckPick value={feature.savingThrowBonusAbilities} values={stdvalues.abilityNames} bonus={feature.savingThrowBonus} onChange={this.onChangeDualPick.bind(this, "savingThrowBonus", "savingThrowBonusAbilities")} label="Saving Throw" prefix="Saving Throw Bonus=" editable useButton />
                </div>
            );

            addFeatureOption(feature.skillCheckBonus,bonusOptions,
                <div key="skillCheckBonus">
                    <BonusCheckPick value={feature.skillCheckBonusAbilities} values={campaign.getAllSkills()} bonus={feature.skillCheckBonus} onChange={this.onChangeDualPick.bind(this, "skillCheckBonus", "skillCheckBonusAbilities")} label="Skill Checks" prefix="Skill Checks Bonus=" editable useButton />
                </div>
            );

            addFeatureOption(feature.d20Bonus,bonusOptions,
                <div key="d20">
                    <D20Modifier modifier={feature.d20Bonus} onChange={this.onChangePick.bind(this, "d20Bonus")}/>
                </div>
            );

            addFeatureOption(feature.speedAdjustment,movesenseOptions,
                <div key="speedAdjustment" className="mb1">
                    <PickVal values={speedDeltaList} isNum onClick={this.onChangePick.bind(this, "speedAdjustment")} label="Speed Adjustment" size="small" variant="outlined" color="primary"/>
                    {feature.speedAdjustment?(" "+signedNum(feature.speedAdjustment)+"ft. Increase in speed"):null}
                    {feature.speedAdjustment?<PickVal values={movementAvailability} onClick={this.onChangePick.bind(this, "speedAvailability")}>
                        <span className="hover-bg-contrast underline"> with {movementAvailability[feature.speedAvailability]||"or without armor"}</span>
                    </PickVal>:null}
                </div>
            );

            addFeatureOption(feature.extraSpells,spellOptions,
                <div key="extraSpells" className="mb1">
                    <Button size="small" variant="outlined" color="primary" onClick={this.showPicker.bind(this,"extraSpells")}>
                        Always Available
                    </Button> {spellNamesFromList(feature.extraSpells)}
                </div>
            );

            addFeatureOption(feature.selectableSpells,spellOptions,
                <div key="selectableSpells" className="mb1">
                    <Button size="small" variant="outlined" color="primary" onClick={this.showPicker.bind(this, "selectableSpells")}>
                        Selectable
                    </Button> {spellNamesFromList(feature.selectableSpells)}
                </div>
            );

            addFeatureOption(feature.castableSpells,spellOptions,
                <div key="castableSpells" className="mb1">
                    <CastableSpells feature={feature} onChange={this.onChangeFeature.bind(this)}/>
                </div>
            );

            addFeatureOption(feature.spellPick,spellOptions,
                <div key="spellPick" className="mb1">
                    <PickChooseSpells noDiv label="User Pick" spellPick={feature.spellPick} onChange={this.onChangePick.bind(this, "spellPick")}/>  {feature.spellPick?<SimpleCheckPick onChange={this.onChangePick.bind(this, "spellAbilityDC")} value={feature.spellAbilityDC} values={stdvalues.abilityNames}  includeNum numLabel="Save DC" label="Spell Save DC" prefix="Spell save DC" editable useButton noDiv/>:null}
                </div>
            );

            addFeatureOption(feature.ritualCaster,spellOptions,
                <div key="ritualCaster" className="mb1">
                    <PickVal values={yesNoValues} isNum onClick={this.onChangePick.bind(this, "ritualCaster")} label="Ritual Caster" size="small" variant="outlined" color="primary"/>
                    {feature.ritualCaster?" - Character can cast spells as rituals":null}
                </div>
            );

            const spellAdvanceClasses = ["none", "any"].concat(campaign.getClassesListByName().map(function (c) {return c.displayName}));
            addFeatureOption(feature.spellLevelAdvance,spellOptions,
                <div key="spellLevelAdvance" className="mb1">
                    <PickVal values={spellAdvanceClasses} onClick={this.onChangePick.bind(this, "spellLevelAdvance")} label="Advance Spell Casting Level" size="small" variant="outlined" color="primary"/>
                    {feature.spellLevelAdvance?(" advance a spell casting level in "+feature.spellLevelAdvance+" class"):null}
                </div>
            );

            addFeatureOption(feature.addSpellSlots,spellOptions,
                <div key="addSpellSlots" className="mb1">
                    <PickAddSpellSlots noDiv label="Add Spell Slots" addSpellSlots={feature.addSpellSlots} onChange={this.onChangePick.bind(this, "addSpellSlots")}/>
                </div>
            );

            addFeatureOption(feature.spellmod,spellOptions,
                <SpellAdjust key="spellmod" mod={feature.spellmod} onChange={this.onChangePick.bind(this, "spellmod")}/>
            );

            addFeatureOption(feature.customPick,userSelections,
                <div key="customPick" className="mb1"><PickCustomTable noDiv label="User Pick Custom" customPick={feature.customPick} onChange={this.onChangePick.bind(this, "customPick")}/></div>
            );

            addFeatureOption(feature.customPickMod,userSelections,
                <div key="customPickm" className="mb1"><PickCustomMod noDiv customPick={feature.customPickMod} onChange={this.onChangePick.bind(this, "customPickMod")}/></div>
            );
            
            addFeatureOption(feature.customAttribute,userSelections,
                <div key="customAttribute" className="mb1">
                    <TextPlusEdit noDiv useButton size="small" variant="outlined" label="Text Attribute" text={feature.customAttribute} extraTextLabel="Options (comma separated)" extraText={feature.customAttributeOptions} editable onChange={this.onChangeDualPick.bind(this, "customAttribute", "customAttributeOptions")}/>
                    {feature.customAttribute?" - Show text field for user to enter text, for example: favored enemy.":null}
                </div>
            );

            if (feature.altcreateitem) {
                addFeatureOption(feature.altcreateitem,userSelections,
                    <div key="altcreateitem" className="mb1"><CreateItemOptions prefix="Alt " editable createitem={feature.altcreateitem} onChange={this.onChangePick.bind(this, "altcreateitem")} showButtonName/></div>
                );
            }

            if ((feature.altcreateitem) || feature.alt2createitem) {
                addFeatureOption(feature.alt2createitem,userSelections,
                    <div key="alt2createitem" className="mb1"><CreateItemOptions prefix="Alt2 " editable createitem={feature.alt2createitem} onChange={this.onChangePick.bind(this, "alt2createitem")} showButtonName/></div>
                );
            }

            addFeatureOption(feature.pickFeat,userSelections,
                <div key="pickFeat" className="mb1">
                    <PickVal values={yesNoValues} isNum onClick={this.onChangePick.bind(this, "pickFeat")} label="Pick Feat" size="small" variant="outlined" color="primary"/>
                    {feature.pickFeat?" - Select a feat.":null}
                </div>
            );

            addFeatureOption(feature.grantFeat,userSelections,
                <div key="castableSpells" className="mb1">
                    <Button size="small" variant="outlined" color="primary" onClick={this.showPickFeat.bind(this)}>
                        Grant Feat
                    </Button> {(campaign.getFeatInfo(feature.grantFeat)||{}).displayName||""} {feature.grantFeat?<span className="hoverhighlight pa1 fas fa-trash" onClick={this.onChangePick.bind(this, "grantFeat", null)}/>:null}
                </div>
            );


            addFeatureOption(feature.attunement,bonusOptions,
                <div key="attune" className="mb1">
                    <Attunement attunement={feature.attunement} onChange={this.onChangePick.bind(this, "attunement")} useButton label="Attunement" editable/>
                </div>
            );

            addFeatureOption(feature.action||feature.createitem,otherOptions,
                <div key="atv" className="mb1">
                    <Button size="small" variant="outlined" color="primary" onClick={this.showActionConfig.bind(this)}>
                        User Action
                    </Button> {(feature.action||feature.createitem)?"Action configured":null}
                    <EditActionConfig open={this.state.showActionConfig} action={feature.action} createitem={feature.createitem} onClose={this.onSetAction.bind(this)}/>
                </div>
            );
            
            addFeatureOption(feature.effects,otherOptions,
                <div key="ate" className="mb1">
                    <Button size="small" variant="outlined" color="primary" onClick={this.showEffectConfig.bind(this)}>
                        Set Toggle Effect
                    </Button> {feature.effects?"Toggle effect configured":null}
                    <EditFeatureOptions noShow noFeats enableDelete open={this.state.showEffectConfig} feature={feature.effects||{id:campaign.newUid()}} onClose={this.onSetEffect.bind(this)}/>
                </div>
            );
            
            addFeatureOption(feature.subfeatures,otherOptions,
                <div key="sub" className="mb1">
                    <EditFeatureList features={feature.subfeatures} onChange={this.onChangePick.bind(this, "subfeatures")} label="Subfeatures"/>
                </div>
            );

            addFeatureOption(feature.sizeAdjust,otherOptions,
                <div key="sizeAdjust" className="mb1">
                    <PickVal values={sizeAdjustValues} isNum onClick={this.onChangePick.bind(this, "sizeAdjust")} label="Size Adjustment" size="small" variant="outlined" color="primary"/>
                    {feature.sizeAdjust?(" "+sizeAdjustValues[feature.sizeAdjust]):null}
                </div>
            );

            if (!feature.descriptionOptions&&!this.state.showDescription) {
                otherOptions.push(<div key="desc" className="mb1 mr1">
                    <Button size="small" variant="outlined" color="primary" onClick={this.showDescription.bind(this)}>
                        Description Options
                    </Button>
                </div>);
            }
        }

        addFeatureOption(feature.restriction,otherOptions,
            <div key="fr" className="mb1">
                <FeatureRestriction restriction={feature.restriction} onChange={this.onChangePick.bind(this, "restriction")}/>
            </div>
        );

        return <Dialog
            classes={{paper:"minvh-80"}}
            open            
            maxWidth="md"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                Feature Options
            </DialogTitle>
            <DialogContent>
                <div className="mb2">
                    <SelectVal value={mode} values={featureOptions} fullWidth onClick={this.changeMode.bind(this)}/>
                </div>
                {this.props.compact?<div className="mb2">
                    <CheckVal value={feature.noDiv||false} onChange={this.onChangePick.bind(this,"noDiv",!feature.noDiv)} label="Display compact version of feature"/>
                </div>:null}
                <FeatureEdit feature={feature} onChange={this.onChangeFeature.bind(this)} editable noDescription noButtons/>
                {(feature.descriptionOptions||this.state.showDescription)?<div className="mb2"><DescriptionOptionsListEdit onChange={this.onChangePick.bind(this, "descriptionOptions")} editable descriptions={feature.descriptionOptions} noBackground/></div>:null}
                {selectedOptions}
                <div className="mt2 ba br2 titleborder pa1">
                    <div className="flex flex-wrap">
                        {usageOptions}
                    </div>

                    <div className="f3 bb titleborder titletext titlecolor mb--2">Proficiencies</div>
                    <div className="flex flex-wrap">
                        {proficiencyOptions}
                    </div>

                    <div className="f3 bb titleborder titletext titlecolor mb--2">Bonuses</div>
                    <div className="flex flex-wrap">
                        {bonusOptions}
                    </div>

                    <div className="f3 bb titleborder titletext titlecolor mb--2">Spells</div>
                    <div className="flex flex-wrap">
                        {spellOptions}
                    </div>

                    <div className="f3 bb titleborder titletext titlecolor mb--2">Combat</div>
                    <div className="flex flex-wrap">
                        {combatOptions}
                    </div>

                    <div className="f3 bb titleborder titletext titlecolor mb--2">Movement and Senses</div>
                    <div className="flex flex-wrap">
                        {movesenseOptions}
                    </div>

                    <div className="f3 bb titleborder titletext titlecolor mb--2">User Selections</div>
                    <div className="flex flex-wrap">
                        {userSelections}
                    </div>

                    <div className="f3 bb titleborder titletext titlecolor mb--2">Other</div>
                    <div className="flex flex-wrap">
                        {otherOptions}
                    </div>
                </div>
                {textDescription.length?<div className="tc mt2 f4 hk-well">
                    <div className="mb2">
                        <Button className="minw2" color="secondary" variant="outlined" size="small" onClick={this.onChangePick.bind(this, "auto", true)}>Autoset Description</Button>
                    </div>
                    <div className="tl">{textDescription}</div>
                </div>:null}
                <SpellPicker 
                    open={this.state.showPicker} 
                    maxSpellLevel={20}
                    selectedSpells={this.state.selectedSpells}
                    hideCounts 
                    onClose={this.closePicker.bind(this)}
                />
                <PickFeat open={this.state.showPickFeat} onClose={this.pickFeat.bind(this)}/>

            </DialogContent>
            <DialogActions>
                {globalCopyFeature?<Button onClick={this.pasteFeature.bind(this)} color="primary">
                    Paste
                </Button>:null}
                <Button onClick={this.copyFeature.bind(this)} color="primary">
                    Copy
                </Button>
                {this.props.enableDelete?<Button onClick={this.deleteFeature.bind(this,)} color="primary">
                    Delete
                </Button>:null}
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;

        function addFeatureOption(selected, unselList, val) {
            if (selected) {
                selectedOptions.push(val);
            } else {
                unselList.push(<div className="mr--2" key={unselList.length}>{val}</div>);
            }
        }
    }

    showDescription() {
        this.setState({showDescription:true});
    }

    copyFeature() {
        globalCopyFeature = Object.assign({}, this.state.feature);
        displayMessage("Feature copied");
    }

    pasteFeature() {
        const feature = Object.assign({},globalCopyFeature);
        if (this.state.feature?.id) {
            feature.id = this.state.feature.id;
        }
        displayMessage("Feature pasted");
        this.setState({feature});
    }

    showActionConfig() {
        this.setState({showActionConfig:true});
    }

    onSetAction(action,createitem) {
        if (action) {
            this.onChangeDualPick("action", "createitem", Object.keys(action).length?action:null, createitem||null);
        }

        this.setState({showActionConfig:false});
    }

    showEffectConfig() {
        this.setState({showEffectConfig:true});
    }

    onSetEffect(val) {
        if (val) {
            this.onChangePick("effects", (val && Object.keys(val).length)?val:null);
        }
        this.setState({showEffectConfig:false});
    }
}
let globalCopyFeature=null;

class EditItemFeatureOptions extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {};
    }

    onChangePick(field, val) {
        const feature = Object.assign({}, this.props.feature);
        if (val == "none") {
            val = null;
        }
        feature[field] = val;

        this.onChangeFeature(feature);
    }

    onChangeDualPick(f1,f2,v1,v2) {
        const feature = Object.assign({}, this.props.feature);
        feature[f1] = v1;
        feature[f2] = v2;
        this.onChangeFeature(feature);
    }

    onChangeFeature(feature) {
        this.props.onChange(feature);
    }

    onChangeUsageVal(prop, val) {
        let usage = Object.assign({}, this.props.feature.usage||{});

        if (val=="none") {
            val = null;
        }

        usage[prop] = val;

        this.onChangePick("usage", usage);
    }

    render() {
        const feature = this.props.feature||{};
        const {allowAlt} = this.props;
        const selectedOptions = [];

        const usageOptions = [];
        const proficiencyOptions = [];
        const spellOptions = [];
        const combatOptions=[];
        const bonusOptions = [];
        const movesenseOptions=[];

        const usage = feature.usage||{};
        const hasUses = usage.baseCount||usage.levelsCount||usage.ability;

        addFeatureOption(hasUses,usageOptions,
            <div key="usage" className="mb1">
                <PickItemUsage usage={usage} onChange={this.onChangePick.bind(this,"usage")}/>
            </div>
        );

        addFeatureOption(feature.extraDamage,usageOptions,
            <div key="xdamageBonus" className="mb1">
                <ExtraDamage damage={feature.extraDamage} onChange={this.onChangePick.bind(this,"extraDamage")}/>
            </div>
        );

        if (allowAlt) {
            addFeatureOption(feature.altEffect,usageOptions,
                <div key="altEffect" className="mb1"><AltAttack noDiv label="Effect" altAttack={feature.altEffect} onChange={this.onChangePick.bind(this, "altEffect")}/></div>
            );
        }

        selectedOptions.push(<div key="extraNotes" className="mb1">
            <div className="b">Extra Notes</div>
            <EntityEditor onChange={this.onChangePick.bind(this,"extraNotes")} entry={feature.extraNotes}/>
        </div>);

        addFeatureOption(feature.attackBonus,combatOptions,
            <div key="attackBonus" className="mb1">
                <PickVal values={bonusValuesWithAbilities} isPossibleNum onClick={this.onChangePick.bind(this, "attackBonus")} label="To Hit Bonus" size="small" variant="outlined" color="primary"/>
                {feature.attackBonus?(" "+signedMod(feature.attackBonus)+" To Hit Bonus"):""}
            </div>
        );

        addFeatureOption(feature.damageBonus,combatOptions,
            <div key="damageBonus" className="mb1">
                <PickVal values={bonusValues} isNum onClick={this.onChangePick.bind(this, "damageBonus")} label="Damage Bonus" size="small" variant="outlined" color="primary"/>
                {feature.damageBonus?(" "+signedNum(feature.damageBonus)+" Damage Bonus"):""}
            </div>
        );

        addFeatureOption(feature.attackAbility,combatOptions,
            <div key="attackAbility" className="mb1">
                <PickVal values={attackAbilities} onClick={this.onChangePick.bind(this, "attackAbility")} label="Attack Ability" size="small" variant="outlined" color="primary"/>
                {feature.attackAbility?(" You can use your "+attackAbilities[feature.attackAbility]+" modifier for attack and damage rolls instead of Strength or Dexterity."):null}
            </div>
        );

        addFeatureOption(feature.armor,proficiencyOptions,
            <div key="armor"><CheckPick noChooseOption useButton label="Armor" value={feature.armor||{}} options={stdvalues.armorTypes} editable onChange={this.onChangePick.bind(this, "armor")}/></div>
        );

        const {weaponProficiencies,toolProficiencies} = campaign.getItemExtensions();

        addFeatureOption(feature.weapons,proficiencyOptions,
            <div key="weapons"><CheckPick noChooseOption useButton label="Weapons" value={feature.weapons||{}} options={weaponProficiencies} editable onChange={this.onChangePick.bind(this, "weapons")}/></div>
        );

        addFeatureOption(feature.tools,proficiencyOptions,
            <div key="tools"><CheckPick noChooseOption useButton label="Tools" showProficiency value={feature.tools||{}} options={toolProficiencies} editable onChange={this.onChangePick.bind(this, "tools")}/></div>
        );

        addFeatureOption(feature.skills,proficiencyOptions,
            <div key="skills"><CheckPick noChooseOption useButton label="Skills" showProficiency value={feature.skills||{}} options={campaign.getAllSkills()} editable onChange={this.onChangePick.bind(this, "skills")}/></div>
        );

        addFeatureOption(feature.languages,proficiencyOptions,
            <div key="languages"><CheckPick noChooseOption allowExtension useButton label="Languages" value={feature.languages||{}} options={campaign.getAllLanguages()} editable onChange={this.onChangePick.bind(this, "languages")}/></div>
        );

        addFeatureOption(feature.abilitySaves,proficiencyOptions,
            <div key="abilitySaves"><CheckPick noChooseOption useButton label="Ability Saves" value={feature.abilitySaves||{}} options={stdvalues.abilities} editable onChange={this.onChangePick.bind(this, "abilitySaves")}/></div>
        );

        addFeatureOption(feature.alwaysProficient,proficiencyOptions,
            <div key="alwaysProficient" className="mb1">
                <PickVal values={yesNoValues} isNum onClick={this.onChangePick.bind(this, "alwaysProficient")} label="Always Proficient" size="small" variant="outlined" color="primary"/>
                {feature.alwaysProficient?" - always proficient in the use of item.":null}
            </div>
        );


        addFeatureOption(feature.speed,movesenseOptions,
            <div key="speed" className="mb1"><Movement speed={feature.speed||{}} onChange={this.onChangePick.bind(this, "speed")}/></div>
        );

        addFeatureOption(feature.senses,movesenseOptions,
            <div key="senses" className="mb1"><Senses senses={feature.senses||{}} onChange={this.onChangePick.bind(this, "senses")}/></div>
        );

        addFeatureOption(feature.ability,bonusOptions,
            <div key="ability" className="mb1"><AbilityOptions editable ability={feature.ability} onChange={this.onChangePick.bind(this, "ability")}/></div>
        );

        addFeatureOption(feature.baseAC,combatOptions,
            <div key="baseAC" className="mb1"><BaseAC baseAC={feature.baseAC} onChange={this.onChangePick.bind(this, "baseAC")}/></div>
        );

        addFeatureOption(feature.hpLevelMod,combatOptions,
            <div key="hpLevelMod" className="mb1">
                <PickVal values={bonusValues} isNum onClick={this.onChangePick.bind(this, "hpLevelMod")} label="Hit Point Bonus" size="small" variant="outlined" color="primary"/>
                {feature.hpLevelMod?(" "+signedNum(feature.hpLevelMod)+" Per level bonus to hit point maximum."):null}
            </div>
        );

        addFeatureOption(feature.maxHP,combatOptions,
            <div key="maxHP" className="mb1">
                <NumberAdjust value={feature.maxHP||0} altText={<span className="nowrap">
                    <Button size="small" color="primary" variant="outlined">Max Hit Points</Button>
                    {feature.maxHP?<span> {signedNum(feature.maxHP)} to maximum hit points</span>:null}
                </span>} onChange={this.onChangePick.bind(this, "maxHP")}/>
            </div>
        );

        addFeatureOption(feature.acBonus||feature.acAbilitiesBonus,combatOptions,
            <div key="acBonus" className="mb1">
                <ACBonus feature={feature} onChange={this.onChangeFeature.bind(this)}/>
            </div>
        );

        addFeatureOption(feature.itemmod,combatOptions,
            <ItemsAdjust key="itemmod" itemmod={feature.itemmod} onChange={this.onChangePick.bind(this, "itemmod")}/>
        );

        addFeatureOption(feature.initiativeBonus,bonusOptions,
            <div key="initiativeBonus" className="mb1">
                <PickVal values={bonusValuesWithAbilitiesProf} isPossibleNum onClick={this.onChangePick.bind(this, "initiativeBonus")} label="Initiative" size="small" variant="outlined" color="primary"/>
                {feature.initiativeBonus?(" "+(bonusValuesWithAbilitiesProf[feature.initiativeBonus]||signedNum(feature.initiativeBonus))+" Initiative Bonus"):""}
            </div>
        );

        addFeatureOption(feature.perceptionBonus,movesenseOptions,
            <div key="perceptionBonus" className="mb1">
                <PickVal values={bonusValues} isNum onClick={this.onChangePick.bind(this, "perceptionBonus")} label="Passive Perception Bonus" size="small" variant="outlined" color="primary"/>
                {feature.perceptionBonus?(" "+signedNum(feature.perceptionBonus)+" Passive Perception Bonus"):""}
            </div>
        );

        addFeatureOption(feature.proficiencyBonus,bonusOptions,
            <div key="proficiencyBonus" className="mb1">
                <PickVal values={bonusValues} isNum onClick={this.onChangePick.bind(this, "proficiencyBonus")} label="Proficiency" size="small" variant="outlined" color="primary"/>
                {feature.proficiencyBonus?(" "+signedNum(feature.proficiencyBonus)+" Proficiency Bonus"):""}
            </div>
        );

        addFeatureOption(feature.spellDCBonus,spellOptions,
            <div key="spellDCBonus" className="mb1">
                <PickVal values={bonusValues} isNum onClick={this.onChangePick.bind(this, "spellDCBonus")} label="DC Bonus" size="small" variant="outlined" color="primary"/>
                {feature.spellDCBonus?(" "+signedNum(feature.spellDCBonus)+" Spell DC Bonus"):""}
            </div>
        );

        addFeatureOption(feature.spellAttackBonus,spellOptions,
            <div key="spellAttackBonus" className="mb1">
                <PickVal values={bonusValues} isNum onClick={this.onChangePick.bind(this, "spellAttackBonus")} label="Attack Bonus" size="small" variant="outlined" color="primary"/>
                {feature.spellAttackBonus?(" "+signedNum(feature.spellAttackBonus)+" Spell Attack Bonus"):""}
            </div>
        );

        const spellRecoveryType = (feature.recoveryType||"day");
        addFeatureOption(feature.castableSpells,spellOptions,
            <div key="castableSpells" className="mb1">
                <Button size="small" variant="outlined" color="primary" onClick={this.showPicker.bind(this, "castableSpells")}>
                    Spell List
                </Button> {spellNamesFromList(Object.keys(feature.castableSpells||{}))} {feature.castableSpells?<SelectVal className="w5" onClick={this.onChangePick.bind(this, "spellCastDC")} isPossibleNum value={feature.spellCastDC||15} values={stdvalues.spellDCAttack} helperText="Spell Save DC"/>:null} {feature.castableSpells?<SelectVal className="minw35" value={spellRecoveryType} values={itemSpellRecoveryOptions} helperText="Recovery" onClick={this.onChangePick.bind(this,"recoveryType")}/>:null}
            </div>
        );

        const spellAdvanceClasses = ["none"].concat(campaign.getClassesListByName().map(function (c) {return c.displayName}));
        addFeatureOption(feature.spellLevelAdvance,spellOptions,
            <div key="spellLevelAdvance" className="mb1">
                <PickVal values={spellAdvanceClasses} onClick={this.onChangePick.bind(this, "spellLevelAdvance")} label="Advance Spell Casting Level" size="small" variant="outlined" color="primary"/>
                {feature.spellLevelAdvance?(" advance a spell casting level in "+feature.spellLevelAdvance+" class"):null}
            </div>
        );


        addFeatureOption(feature.savingThrowBonus,bonusOptions,
            <div key="savingThrowBonus">
                <BonusCheckPick value={feature.savingThrowBonusAbilities} values={stdvalues.abilityNames} bonus={feature.savingThrowBonus} onChange={this.onChangeDualPick.bind(this, "savingThrowBonus", "savingThrowBonusAbilities")} label="Saving Throw" prefix="Saving Throw Bonus=" editable useButton />
            </div>
        );

        addFeatureOption(feature.skillCheckBonus,bonusOptions,
            <div key="skillCheckBonus">
                <BonusCheckPick value={feature.skillCheckBonusAbilities} values={campaign.getAllSkills()} bonus={feature.skillCheckBonus} onChange={this.onChangeDualPick.bind(this, "skillCheckBonus", "skillCheckBonusAbilities")} label="Skill Checks" prefix="Skill Checks Bonus=" editable useButton />
            </div>
        );

        addFeatureOption(feature.speedAdjustment,movesenseOptions,
            <div key="speedAdjustment" className="mb1">
                <PickVal values={speedDeltaList} isNum onClick={this.onChangePick.bind(this, "speedAdjustment")} label="Speed Adjustment" size="small" variant="outlined" color="primary"/>
                {feature.speedAdjustment?(" "+signedNum(feature.speedAdjustment)+"ft. Increase in speed"):null}
                {feature.speedAdjustment?<PickVal values={movementAvailability} onClick={this.onChangePick.bind(this, "speedAvailability")}>
                    <span className="hover-bg-contrast underline"> with {movementAvailability[feature.speedAvailability]||"or without armor"}</span>
                </PickVal>:null}
            </div>
        );

        addFeatureOption(feature.unarmedAttack,combatOptions,
            <div key="unarmedAttack" className="mb1"><UnarmedAttack noDiv label="Unarmed Attack" unarmedAttack={feature.unarmedAttack} onChange={this.onChangePick.bind(this, "unarmedAttack")}/></div>
        );

        addFeatureOption(feature.resist,combatOptions,
            <div key="resist"><CheckPick noChooseOption allowExtension useButton label="Resistance" value={feature.resist||{}} options={stdvalues.damageTypesList} editable onChange={this.onChangePick.bind(this, "resist")}/></div>
        );

        addFeatureOption(feature.immune,combatOptions,
            <div key="immune"><CheckPick noChooseOption allowExtension useButton label="Immunity" value={feature.immune||{}} options={stdvalues.damageTypesList} editable onChange={this.onChangePick.bind(this, "immune")}/></div>
        );

        addFeatureOption(feature.vulnerable,combatOptions,
            <div key="vulnerablility"><CheckPick noChooseOption allowExtension useButton label="Vulnerability" value={feature.vulnerable||{}} options={stdvalues.damageTypesList} editable onChange={this.onChangePick.bind(this, "vulnerable")}/></div>
        );

        addFeatureOption(feature.conditionImmune,combatOptions,
            <div key="conditionImmune"><CheckPick noChooseOption allowExtension useButton label="Condition Immunity" value={feature.conditionImmune||{}} options={stdvalues.conditionList} editable onChange={this.onChangePick.bind(this, "conditionImmune")}/></div>
        );

        addFeatureOption(feature.extraAttack,combatOptions,
            <div key="extraAttack" className="mb1">
                <PickVal values={yesNoValues} isNum onClick={this.onChangePick.bind(this, "extraAttack")} label="Extra Attack" size="small" variant="outlined" color="primary"/>
                {feature.extraAttack?" - Gain an extra attack.":null}
            </div>
        );

        return <div>
            {this.getItemUsage()}
            {this.getSpellList()}
            {selectedOptions}
            <div className="mt2 ba br2 titleborder pa1">
                <div className="flex flex-wrap">
                    {usageOptions}
                </div>

                <div className="f3 bb titleborder titletext titlecolor mb--2">Proficiencies</div>
                <div className="flex flex-wrap">
                    {proficiencyOptions}
                </div>

                <div className="f3 bb titleborder titletext titlecolor mb--2">Bonuses</div>
                <div className="flex flex-wrap">
                    {bonusOptions}
                </div>

                <div className="f3 bb titleborder titletext titlecolor mb--2">Spells</div>
                <div className="flex flex-wrap">
                    {spellOptions}
                </div>

                <div className="f3 bb titleborder titletext titlecolor mb--2">Combat</div>
                <div className="flex flex-wrap">
                    {combatOptions}
                </div>

                <div className="f3 bb titleborder titletext titlecolor mb--2">Movement and Senses</div>
                <div className="flex flex-wrap">
                    {movesenseOptions}
                </div>
            </div>
            <SpellPicker 
                open={this.state.showPicker} 
                maxSpellLevel={20}
                selectedSpells={this.state.selectedSpells}
                hideCounts 
                onClose={this.closePicker.bind(this)}
            />
        </div>;
        
        function addFeatureOption(selected, unselList, val) {
            if (selected) {
                selectedOptions.push(val);
            } else {
                unselList.push(<div className="mr--2" key={unselList.length}>{val}</div>);
            }
        }
    }

    getItemUsage() {
        const feature = this.props.feature||{};
        const usage = feature.usage;
        if (!usage || !usage.baseCount) {
            return null;
        }
        const max = usage.baseCount;
        const uses = (feature.uses=== undefined)?max:feature.uses;
        
        return <div className="mb1">
            Item Uses <MaxNumberAdjust max={max} value={uses} onAdjustValue={this.onChangePick.bind(this, "uses")}/>
        </div>;
    }

    getSpellList() {
        const feature = this.props.feature||{};
        const castable = feature.castableSpells;
        if (!castable) {
            return null;
        }
        const spellRecoveryType = (feature.recoveryType||"day");

        const spells = Object.keys(castable).map(function (s){return (campaign.getSpell(s)||{})});
        const rows = [];
        let lastLevel = -1;

        spells.sort(sortSpellList);

        for (let i in spells) {
            const s = spells[i];
            if (s) {
                const si = castable[(s.name||"").toLowerCase()];
                if (lastLevel != s.level) {
                    lastLevel = s.level;
                    rows.push(<tr key={"L"+lastLevel}>
                        <td colSpan="4" className="b">
                            {(lastLevel!="0")?(Parser.levelToFull(lastLevel)+"-level"):"Cantrips"}
                        </td>
                    </tr>);
                }

                let uses = ((feature.itemUsage||{})[s.name]);
                if (uses === undefined) {
                    uses=1;
                }

                rows.push(<tr key={i}>
                    <td>{s.displayName}</td>
                    <td className="minw35">{(s.level > 0)?<SelectVal isNum fullWidth includeVal="lowest" value={si.castLevel||"lowest"} values={getCastLevels(s.level)} onClick={this.changeCastableVal.bind(this, "castLevel",s.name)}/>:null}</td>
                    {(spellRecoveryType=="charges")?<td><SelectVal isPossibleNum fullWidth value={si.chargeCount||"auto"} values={["auto","none"].concat(getNumberArray(9))} onClick={this.changeCastableVal.bind(this, "chargeCount",s.name)}/></td>:
                    (["day","once"].includes(spellRecoveryType))?<td><MaxNumberAdjust max={1} value={uses} onAdjustValue={this.setItemSpellUsage.bind(this, s.name)}/></td>:null}
                </tr>);
            }
        }

        if (!rows.length) {
            return null;
        }

        return <div className="mb2 stdcontent">
            <table className="w-100">
                <tbody>
                    <tr className="b">
                        <td>Spell</td>
                        <td>Cast Level</td>
                        {(spellRecoveryType=="charges")?<td>Charges</td>:
                        (["day","once"].includes(spellRecoveryType))?<td>Uses</td>:null}
                    </tr>
                    {rows}
                </tbody>
            </table>
        </div>;
    }

    setItemSpellUsage(name, val){
        const feature = this.props.feature||{};
        const itemUsage = Object.assign({}, feature.itemUsage);

        itemUsage[name] = val;
        this.onChangePick("itemUsage", itemUsage);
    }

    changeCastableVal(prop,name, val) {
        const feature = this.props.feature||{};
        const castableSpells = Object.assign({}, feature.castableSpells);
        const lowerName = name.toLowerCase();

        if (["auto", "lowest"].includes(val)) {
            val=null;
        }

        if (castableSpells[lowerName]) {
            castableSpells[lowerName][prop] = val;
        }
        this.onChangePick("castableSpells", castableSpells);
    }

    showPicker(property) {
        const feature = this.props.feature||{};
        const spells = feature[property];
        const selectedSpells = spells||{};

        this.setState({showPicker:true, showPickerProperty:property, selectedSpells});
    }

    closePicker(spells) {
        this.setState({showPicker:false});
        if (spells) {
            this.onChangePick(this.state.showPickerProperty, (Object.keys(spells).length)?spells:null);
        }
    }
}

function sortSpellList(a,b){
    const sd = ((a&&a.level) || 0)-((b&&b.level) || 0);
    if (sd != 0) {
        return sd;
    }
    return (a.displayName||"").toLowerCase().localeCompare((b.displayName||"").toLowerCase());
}


const attackAbilities = {
    "none":"none",
    "str":"Strength",
    "dex":"Dexterity",
    "con":"Constitution",
    "int":"Intelligence",
    "wis":"Wisdom",
    "cha":"Charisma"
};



const countOneToNineMap = ["", "one ", "two ", "three ", "four ", "five ", "six ", "seven ", "eight ", "nine "];


function getFeatureSummaryText(feature) {
    const usage = feature.usage||{};
    const ret = [];
    fixupFeature(feature);

    if (feature.options && feature.options.length) {
        return "You get to choose 1 from the following options.";
    } 
    
    if (feature.pickFeat) {
        ret.push("You get to pick a feat.");
    }

    const longRest = usage.longRest||0;
    let restStr;
    if (!longRest) {
        restStr = "recover after long or short rest";
    } else if (longRest < 0){
        restStr = "discover a method to recharge";
    } else {
        restStr = "recover after long rest";
    }

    if (usage.baseCount) {
        if (usage.baseCount==1) {
            ret.push("After you use this feature, you cannot use it again until you "+restStr);
        } else {
            ret.push("You can use this feature "+usage.baseCount+" times.  You regain expended uses when you "+restStr);
        }
    } else if (usage.ability) {
        ret.push("You can use this feature a number of times equal to your "+Parser.ATB_ABV_TO_FULL[usage.ability]+" modifier (minimum of once).  You regain all expended uses when you "+restStr);
    } else if (usage.levelsCount) {
        const levels = [];
        let last = 0;
        for (let i in usage.levelsCount) {
            const c = usage.levelsCount[i];
            if (c != last) {
                levels.push({level:Number(i)+1, count:c});
                last=c;
            }
        }
        if (levels.length) {
            const ll = [];
            const start = "Starting at level "+levels[0].level+" you gain "+levels[0].count+" uses of the feature";

            if (levels.length>1) {
                for (let i=1; i<levels.length; i++) {
                    ll.push(levels[i].count+" at level "+levels[i].level)
                }

                ret.push(start+", going to "+joinCommaAnd(ll)+".  You regain all expended uses when you "+restStr);
            } else {
                ret.push(start+".  You regain all expended uses when you "+restStr);
            }
        }
    } else if (usage.proficiency) {
        ret.push("You can use this feature a number of times equal to your proficiency bonus modifier (minimum of once).  You regain all expended uses when you "+restStr);
    }

    if (usage.saveDC) {
        if (Array.isArray(usage.saveDC)) {
            ret.push("The DC for this saving throw equals 8 + your choice of "+usage.saveDC.map(function (a) {return Parser.ATB_ABV_TO_FULL[a]}).join(", ")+" modifiers + your proficiency bonus.");
        } else {
            ret.push("The DC for this saving throw equals 8 + your "+Parser.ATB_ABV_TO_FULL[usage.saveDC]+" modifier + your proficiency bonus.");
        }
    }

    if (feature.extraSpells) {
        ret.push("You gain access to the following spells as if they were granted by your class and always have them prepared. They don't count against the number of spells you can prepare each day.");
    }

    if (feature.selectableSpells) {
        ret.push("You choose from an expanded list of spells when you learn spells. The following spells are added to your spell list: "+spellNamesFromList(feature.selectableSpells)+".");
    }

    if (feature.castableSpells) {
        const cantrips =[];
        const levels =[];
        const castableSpells=feature.castableSpells;
        for (let i in castableSpells) {
            const spell = castableSpells[i];
            const spellInfo = campaign.getSpell(spell.name);
            if (spellInfo) {
                if (!spell.level) {
                    cantrips.push(spellInfo.displayName);
                } else {
                    const l = spell.grantLevel ||0;
                    if (!levels[l]) {
                        levels[l]=[];
                    }
                    levels[l].push(spellInfo.displayName);
                }
            }
        }
        if (cantrips.length) {
            ret.push("You know the "+joinCommaAnd(cantrips)+((cantrips.length == 1)?" cantrip.":" cantrips."));
        }
        for (let i=0; i<levels.length; i++) {
            const l = levels[i];
            if (l) {
                ret.push(
                    (i>2?("When you reach level "+i+", you "):"You ")+
                    "know and can cast the "+
                    joinCommaAnd(l)+((l.length == 1)?" spell":" spells")+
                    " once per day; you must finish a long rest in order to cast the spell again using this trait."
                );
            }
        }
    }

    if (feature.spellPick) {
        const spellPick = feature.spellPick;
        const tail = (spellPick.spellList||spellPick.sources)?(" from the "+(getArrayVal(spellPick.spellList).concat(getArrayVal(spellPick.sources)).join(", "))+" spells."):".";

        if (spellPick.cantrips && spellPick.spells) {
            ret.push("Choose "+pluralString("cantrip", spellPick.cantrips,countOneToNineMap)+" and "+pluralString("spell", spellPick.spells,countOneToNineMap)+" that you can cast once a day"+tail);
        } else if (spellPick.cantrips) {
            ret.push("Choose "+pluralString("cantrip", spellPick.cantrips,countOneToNineMap)+tail);
        } else if (spellPick.spells) {
            ret.push("Choose "+pluralString("spell", spellPick.spells,countOneToNineMap)+" that you can cast once a day"+tail);
        }
    }

    if (feature.customPick) {
        const customPick = feature.customPick;
        if (customPick.customOptions && (customPick.customOptions.length==1)) {
            const custom = campaign.getCustom(customPick.customTable, customPick.customOptions[0]);
            if (custom) {
                ret.push("You gain "+custom.displayName+".");
            }

        } else {
            ret.push("Pick "+pluralString(customPick.customTable, customPick.count, countOneToNineMap)+".");
        }
    }

    if (feature.unarmedAttack) {
        const unarmedAttack = feature.unarmedAttack;

        if (unarmedAttack.name) {
            ret.push("You have "+unarmedAttack.name+" as a natural weapon, which you can use to make unarmed strikes. If you hit, you deal "+(unarmedAttack.damage||"1")+" damage of type "+(unarmedAttack.damageType||"bludgeoning")+".");
        } else {
            ret.push("Your unarmed strike does "+(unarmedAttack.damage||"1")+" damage of type "+(unarmedAttack.damageType||"bludgeoning")+".");
        }
    }

    if (feature.armor) {
        ret.push(getProficiencySummaryText(feature.armor, "armor", "armor"));
    }
    if (feature.weapons) {
        ret.push(getProficiencySummaryText(feature.weapons, "weapon", "weapons"));
    }

    if (feature.tools) {
        ret.push(getProficiencySummaryText(feature.tools, "", ""));
    }

    if (feature.attunement) {
        if (feature.attunement.extra) {
            ret.push("You can attune an extra "+feature.attunement.extra+" items.");
        }
        if (feature.attunement.saveBonus) {
            ret.push("You gain a +1 bonus to all saving throws per magic item you are currently attuned to.");
        }
    }

    if (feature.abilitySaves) {
        ret.push(getProficiencySummaryText(feature.abilitySaves, "", ""));
    }

    if (feature.skills) {
        ret.push(getProficiencySummaryText(feature.skills, "skill", "skills"));
    }

    if (feature.checks) {
        ret.push(getProficiencySummaryText(feature.checks, "ability check", "ability checks"));
    }

    if (feature.resist) {
        ret.push(getOtherSummaryText(feature.resist, "You have resistance to ", "You have resistance to your choice of ", " damage type to be resistant.", " damage"));
    }

    if (feature.immune) {
        ret.push(getOtherSummaryText(feature.immune, "You have immunity to ", "You have immunity to your choice of ", " damage type to be immune.", " damage"));
    }

    if (feature.vulnerable) {
        ret.push(getOtherSummaryText(feature.vulnerable, "You have vulnerability to ", "You have vulnerability to your choice of ", " damage type to be vulnerable.", " damage"));
    }

    if (feature.conditionImmune) {
        ret.push(getOtherSummaryText(feature.conditionImmune, "You have immunity to being ", "You have immunity to your choice of ", " condition.", null, "You can choose immunity to "));
    }

    if (feature.languages) {
        ret.push(getOtherSummaryText(feature.languages, "You learn to speak, read and write ", "You learn to speak, read and write ", " languages to learn to speak, read and write."));
    }

    if (feature.speed) {
        const speed = feature.speed;

        if (speed.walk&&speed.walk.number) {
            ret.push("You have a walking speed of "+speed.walk.number+" feet.");
        }
        if (speed.burrow&&speed.burrow.number) {
            ret.push("You have a burrowing speed of "+speed.burrow.number+" feet.");
        } else if (speed.burrow&&speed.burrow.walking) {
            ret.push("You have a burrowing speed equal to your walking speed.");
        }
        if (speed.climb&&speed.climb.number) {
            ret.push("You have a climbing speed of "+speed.climb.number+" feet.");
        } else if (speed.climb&&speed.climb.walking) {
            ret.push("You have a climbing speed equal to your walking speed.");
        }
        if (speed.swim&&speed.swim.number) {
            ret.push("You have a swimming speed of "+speed.swim.number+" feet.");
        } else if (speed.swim&&speed.swim.walking) {
            ret.push("You have a swimming speed equal to your walking speed.");
        }
    }

    if (feature.senses) {
        const senses = feature.senses;

        if (senses.blindsight&&senses.blindsight.number) ret.push("You have blindsight to "+senses.blindsight.number+" feet.");
        if (senses.darkvision&&senses.darkvision.number) {
            ret.push("You have superior vision in dark and dim conditions. You can see in dim light within "+
                senses.darkvision.number+
                " feet of you as if it were bright light, and in darkness as if it were dim light. You can't discern color in darkness, only shades of gray.");
        }
        if (senses.tremorsense&&senses.tremorsense.number) ret.push("You have tremorsense to "+senses.tremorsense.number+" feet.");
        if (senses.truesight&&senses.truesight.number) ret.push("You have truesight to "+senses.truesight.number+" feet.");
        if (senses["keen smell"]&&senses["keen smell"].number) ret.push("You have keen smell to "+senses["keen smell"].number+" feet.");
    }

    if (feature.ability) {
        const ability=feature.ability;
        if (ability.str==1 && ability.dex==1 && ability.con==1 && ability.int==1 && ability.wis==1 && ability.cha==1) {
            ret.push("Your ability scores each increase by 1.");
        } else {
            if (ability.str) ret.push("Your Strength score increases by "+ability.str+".");
            if (ability.dex) ret.push("Your Dexterity score increases by "+ability.dex+".");
            if (ability.con) ret.push("Your Constitution score increases by "+ability.con+".");
            if (ability.int) ret.push("Your Intelligence score increases by "+ability.int+".");
            if (ability.wis) ret.push("Your Wisdom score increases by "+ability.wis+".");
            if (ability.cha) ret.push("Your Charisma score increases by "+ability.cha+".");
        }
        if (ability.minstr) ret.push("Your Strength score is at least "+ability.minstr+".");
        if (ability.mindex) ret.push("Your Dexterity score is at least "+ability.mindex+".");
        if (ability.mincon) ret.push("Your Constitution score is at least "+ability.mincon+".");
        if (ability.minint) ret.push("Your Intelligence score is at least "+ability.minint+".");
        if (ability.minwis) ret.push("Your Wisdom score is at least "+ability.minwis+".");
        if (ability.mincha) ret.push("Your Charisma score is at least "+ability.mincha+".");

        if (ability.maxstr) ret.push("Your Strength score can be up to "+ability.maxstr+".");
        if (ability.maxdex) ret.push("Your Dexterity score can be up to "+ability.maxdex+".");
        if (ability.maxcon) ret.push("Your Constitution score can be up to "+ability.maxcon+".");
        if (ability.maxint) ret.push("Your Intelligence score can be up to "+ability.maxint+".");
        if (ability.maxwis) ret.push("Your Wisdom score can be up to "+ability.maxwis+".");
        if (ability.maxcha) ret.push("Your Charisma score can be up to "+ability.maxcha+".");

        if (ability.choose && (ability.from||[]).length) {
            if (ability.choose == 1) {
                ret.push("Choose one ability score from "+joinCommaAnd(ability.from.map(function (a) {return Parser.ATB_ABV_TO_FULL[a]}))+" to increase by "+(ability.amount||1)+".")
            } else {
                ret.push("Choose "+ability.choose+" ability scores from "+joinCommaAnd(ability.from.map(function (a) {return Parser.ATB_ABV_TO_FULL[a]}))+" to increase by "+(ability.amount||1)+".")
            }
        }
    }

    if (feature.dueling) {
        ret.push("When you are wielding a melee weapon in one hand and no other weapons you gain a "+
            signedNum(feature.dueling)+" bonus to damage rolls with that weapon")
    }

    if (feature.dualACBonus) {
        ret.push("You gain a "+signedNum(feature.dualACBonus)+" bonus to AC while you are wielding a separate melee weapon in each hand.")
    }

    if (feature.itemmod) {
        ret.push(getItemmodDescription(feature.itemmod)+".");
    }

    if (feature.spellmod) {
        ret.push(getSpellmodDescription(feature.spellmod)+".");
    }

    if (feature.sizeAdjust) {
        ret.push(feature.sizeAdjust>0?"Your size is enlarged by one size.":"Your size is reduced by one size.");
    }

    if (feature.baseAC) {
        const baseAC = feature.baseAC;
        ret.push("While you are wearing no armor"+(baseAC.allowShield?", your AC equals ":" and not wearing a shield, your AC equals ")+
            (baseAC.ac||10)+
            (baseAC.ability||[]).map(function (a) {return " + your "+Parser.ATB_ABV_TO_FULL[a]+" modifier"}).join("")+
            (baseAC.halfAbility||[]).map(function (a) {return " + 1/2 your "+Parser.ATB_ABV_TO_FULL[a]+" modifier"}).join("")+".");
    }

    if (feature.hpLevelMod) {
        ret.push("Your hit point maximum increases by an additional "+feature.hpLevelMod+" hit points per level.");
    }

    if (feature.maxHP) {
        ret.push("Your hit point maximum changes by "+feature.maxHP+" hit points.");
    }

    if (feature.acBonus) {
        ret.push("Your AC increases by "+signedNum(feature.acBonus)+ " with "+ (acBonusTypes[feature.acBonusType]||"or without armor")+".");
    }

    if (feature.initiativeBonus) {
        const ability = stdvalues.abilityNames[feature.initiativeBonus];
        if (ability) {
            ret.push("You can give yourself a bonus to your initiative rolls equal to your "+ability+" modifier.");
        } else {
            ret.push("You have a "+(bonusValuesWithAbilitiesProf[feature.initiativeBonus]||signedNum(feature.initiativeBonus))+" bonus to initiative rolls.");
        }
    }

    if (feature.perceptionBonus) {
        ret.push("You have a "+signedNum(feature.perceptionBonus)+" bonus to passive perception.");
    }

    if (feature.proficiencyBonus) {
        ret.push("You have a "+signedNum(feature.proficiencyBonus)+" proficiency bonus.");
    }

    if (feature.spellDCBonus) {
        ret.push("You have a "+signedNum(feature.spellDCBonus)+" bonus to your Spell DC.");
    }

    if (feature.spellAttackBonus) {
        ret.push("You have a "+signedNum(feature.spellAttackBonus)+" bonus to spell attack rolls.");
    }

    if (feature.savingThrowBonus) {
        let extra="";

        if (feature.savingThrowBonusAbilities) {
            extra = " "+joinCommaAnd(feature.savingThrowBonusAbilities.map(function(a) {return stdvalues.abilityNames[a];}));
        }
        if (isNaN(feature.savingThrowBonus)) {
            ret.push("You gain a bonus based on your "+stdvalues.abilityNames[feature.savingThrowBonus]+" bonus to"+extra+" saving throws.");
        } else {
            ret.push("You have a "+signedNum(feature.savingThrowBonus)+" bonus to"+extra+" saving throws.");

        }
    }

    if (feature.skillCheckBonus) {
        let extra="";

        if (feature.skillCheckBonusAbilities) {
            extra = " "+joinCommaAnd(feature.skillCheckBonusAbilities);
        }
        if (isNaN(feature.skillCheckBonus)) {
            ret.push("You gain a bonus based on your "+stdvalues.abilityNames[feature.skillCheckBonus]+" bonus to"+extra+" skill checks.");
        } else {
            ret.push("You have a "+signedNum(feature.skillCheckBonus)+" bonus to"+extra+" saving throws.");

        }
    }

    if (feature.speedAdjustment) {
        ret.push("Your speed increases by "+feature.speedAdjustment+" feet with "+
            (movementAvailability[feature.speedAvailability]||"or without armor")+".");
    }

    if (feature.extraAttack) {
        ret.push("You can attack an additional time when you take your attack action.");
    }

    if (feature.mediumArmorMax==3) {
        ret.push("When you wear medium armor, you can add 3, rather than 2, to your AC if you have a Dexterity of 16 or higher.");
    }

    return ret.join("  ");
}

function featureHasOptions(feature) {
    const {usage}=feature;
    return ((usage && ((usage.type!="hide")|| usage.baseCount||usage.calcCount||usage.levelsCount||usage.ability||usage.proficiency||usage.displayLevels||usage.usageName)) || 
        feature.extraSpells || feature.selectableSpells || feature.castableSpells || feature.armor ||
        feature.weapons || feature.tools || feature.skills || feature.checks || feature.resist || feature.immune || feature.vulnerable ||
        feature.languages || feature.speed || feature.senses || feature.ability || feature.dueling ||
        feature.dualACBonus || feature.baseAC || feature.hpLevelMod || feature.maxHP || feature.spellPick || feature.ritualCaster || feature.spellLevelAdvance ||
        feature.acBonus || feature.speedAdjustment || feature.extraAttack || feature.unarmedAttack || feature.addSpellSlots ||
        feature.initiativeBonus || feature.perceptionBonus || feature.spellDCBonus || feature.spellAttackBonus || feature.sizeAdjust ||
        feature.savingThrowBonus || feature.skillCheckBonus || feature.proficiencyBonus || feature.createitem || feature.altcreateitem || feature.alt2createitem || feature.itemmod || feature.spellmod ||
        (feature.mediumArmorMax==3) || feature.customAttribute || feature.abilitySaves || feature.customPick || feature.pickFeat || feature.grantFeat || feature.attunement || feature.action || 
        feature.effects || feature.subfeatures || feature.d20Bonus);

}

const selectFeatureOptionsType = {
    "hide":"Do not display feature on character sheet",
    "show":"Display feature on character sheet",
    "options":"Display feature with options list for user to select",
    "asi":"Ability Score Improvement"
}

const movementAvailability = {
    "noarmornoshield":"no armor or shield",
    "noarmor":"shield, no armor",
    "light":"no medium or heavy armor",
    "medium":"no heavy armor",
    "none":"any armor"
}

const enabledTypes = {
    0:"disabled",
    1:"enabled"
}

const mediumArmorTypes = {
    0:"Max dex bonus 2 with medium armor",
    3:"Max dex bonus 3 with medium armor"
}

const bonusValues = ["-5", "-4", "-3", "-2", "-1","0", "+1", "+2", "+3", "+4", "+5", "+6", "+7", "+8", "+9", "+10"];
const bonusValuesWithAbilities = {
    "-5":"-5", "-4":"-4", "-3":"-3", "-2":"-2", "-1":"-1",
    "+0":"+0", 
    "+1":"+1", "+2":"+2", "+3":"+3", "+4":"+4", "+5":"+5", "+6":"+6", "+7":"+7", "+8":"+8", "+9":"+9", "+10":"+10",
    "str":"strength",
    "dex":"dexterity",
    "con":"constitution",
    "int":"intelligence",
    "wis":"wisdom",
    "cha":"charisma"
};

const bonusValuesWithAbilitiesAndProficiency = {
    "-5":"-5", "-4":"-4", "-3":"-3", "-2":"-2", "-1":"-1",
    "+0":"+0", 
    "+1":"+1", "+2":"+2", "+3":"+3", "+4":"+4", "+5":"+5", "+6":"+6", "+7":"+7", "+8":"+8", "+9":"+9", "+10":"+10",
    "str":"strength",
    "dex":"dexterity",
    "con":"constitution",
    "int":"intelligence",
    "wis":"wisdom",
    "cha":"charisma",
    "proficiency":"proficiency"
};

const posBonusValuesWithAbilities = {
    "+0":"+0", 
    "+1":"+1", "+2":"+2", "+3":"+3", "+4":"+4", "+5":"+5", "+6":"+6", "+7":"+7", "+8":"+8", "+9":"+9", "+10":"+10",
    "+11":"+11", "+12":"+12", "+13":"+13", "+14":"+14", "+15":"+15", "+16":"+16", "+17":"+17", "+18":"+18", "+19":"+19", "+20":"+20",
    "str1":"strength",
    "dex1":"dexterity",
    "con1":"constitution",
    "int1":"intelligence",
    "wis1":"wisdom",
    "cha1":"charisma"
};
const bonusValuesWithAbilitiesProf = {
    "-5":"-5", "-4":"-4", "-3":"-3", "-2":"-2", "-1":"-1",
    "+0":"+0", 
    "+1":"+1", "+2":"+2", "+3":"+3", "+4":"+4", "+5":"+5", "+6":"+6", "+7":"+7", "+8":"+8", "+9":"+9", "+10":"+10",
    "str":"strength",
    "dex":"dexterity",
    "con":"constitution",
    "int":"intelligence",
    "wis":"wisdom",
    "cha":"charisma",
    "prof":"proficiency",
    "prof2":"double proficiency",
};

const acBonusTypes = {
    "noarmornoshield":"no armor or shield",
    "noarmor":"shield, no armor",
    "uptolight":"no armor or light armor",
    "light":"light armor",
    "medium":"medium armor",
    "heavy":"heavy armor",
    "any":"any armor",
    "none":"with or without armor"
}


class FeatureNoEdit extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    render() {
        const {feature, translate} = this.props;
        fixupFeature(feature);
        let options=[];
        for (let i in feature.options) {
            options.push(<FeatureNoEdit key={i} feature={feature.options[i]} isOption translate={translate}/>);
        }
        return <div className="stdcontent bg-gray-50" id={this.props.id}>
            {this.props.isOption?<div className="b">{feature.name}</div>:<h2>{feature.name}</h2>}
            <Renderentry entry={{entries:feature.entries}} depth={2}/>
            {feature.extraSpells?<ExtraSpells extraSpells={feature.extraSpells} translate={translate}/>:null}
            {options}
        </div>;
    }
}

class CustomLevelsListEdit extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }

    onChangeCustomAttributes(i, customAttributes) {
        const customLevels = (this.props.customLevels||[]).concat([]);
        customLevels[i] = customAttributes;
        this.props.onChange(customLevels);
    }

    onDeleteCustomAttributes(i) {
        const customLevels = (this.props.customLevels||[]).concat([]);
        customLevels.splice(i,1);
        this.props.onChange(customLevels);
    }

    onAddCustomAttributes() {
        const customLevels = (this.props.customLevels||[]).concat([]);
        customLevels.push({attributeType:"points", longRest:true, levelsCount:[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]});
        this.props.onChange(customLevels);
    }

    render() {
        const customLevels = this.props.customLevels||[];
        const list = [];

        for (let i in customLevels) {
            list.push(<CustomLevelsEdit key={i} customAttributes={customLevels[i]} editable noOptions={this.props.noOptions} onChange={this.onChangeCustomAttributes.bind(this,i)} onDelete={this.onDeleteCustomAttributes.bind(this,i)}/>);
        }

        return <div>
            {list}
            <div>
                <Button className="ml2 minw2" color="secondary" variant="outlined" size="small" onClick={this.onAddCustomAttributes.bind(this)}>{this.props.addLabel||"+ Custom Levels Attribute"}</Button>
            </div>
        </div>;
    }
}

class CustomLevelsEdit extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    onChangePick(field, val) {
        const customAttributes = Object.assign({}, this.props.customAttributes);
        customAttributes[field] = val;
        this.props.onChange(customAttributes)
    }

    onChangeName(event) {
        this.onChangePick("name",event.target.value)
    }

    render() {
        const customAttributes = this.props.customAttributes;
        const {CustomListDialog} = require('./customtable.jsx');
        let extra = null;

        switch (customAttributes.attributeType) {
            case "display":
                extra = <div className="mt1">
                    <TextPlusEdit label="Display Prefix:" text={customAttributes.prefix} editable onChange={this.onChangePick.bind(this, "prefix")}/>
                    <TextPlusEdit label="Display Suffix:" text={customAttributes.suffix} editable onChange={this.onChangePick.bind(this, "suffix")}/>
                </div>;
                break;
            case "points":
                extra = <PickVal values={recoverAfterList} isNum onClick={this.onChangePick.bind(this, "longRest")}><span className="hover-bg-contrast mr2"><b>Recover After:</b> {customAttributes.longRest?"Long Rest":"Short Rest"}</span></PickVal>;
                break;
            case "select":
                if (customAttributes.name) {
                    extra = <span>
                        <Button className="minw2" color="secondary" variant="outlined" size="small" onClick={this.showCustomDialog.bind(this)}>Edit Options</Button>
                        <CustomListDialog 
                            open={this.state.showCustomDialog}
                            type={customAttributes.name} 
                            onClose={this.onCloseCustomDialog.bind(this)}
                            editable
                        />
                    </span>;
                }
                break;
        }

        return <div className="mt1 mb2 stdcontent">
            <div className="mb1">
                <Input className="f3 titletext titlecolor" value={customAttributes.name||""} variant="standard" onChange={this.onChangeName.bind(this)} placeholder="attribute name"/>
                {this.props.onDelete?<DeleteWithConfirm className="pa1" name={customAttributes.name} onClick={this.props.onDelete}/>:null}
            </div>
            <div className="mb1">
                <PickVal values={customAttributeTypes} onClick={this.onChangePick.bind(this, "attributeType")}><span className="hover-bg-contrast"><b>Attribute Type:</b> {customAttributeTypes[customAttributes.attributeType]} </span></PickVal>
                {extra}
            </div>
            <PickCountsByLevel levelsCount={customAttributes.levelsCount} label="Values by level" onChange={this.onChangePick.bind(this,"levelsCount")}/>
        </div>;
    }

    showCustomDialog() {
        this.setState({showCustomDialog:true});
    }

    onCloseCustomDialog() {
        this.setState({showCustomDialog:false});
    }
}

const customAttributeTypes = {
    "display":"Display Value",
    "points":"Show as Points",
    "select":"Pick Options from List"
}

const recoverAfterList = {
    0:"Short Rest",
    1:"Long Rest",
}

class DescriptionOptionsListEdit extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }

    render() {
        const descriptions = this.props.descriptions||[];
        const list = [];

        for (let i in descriptions) {
            list.push(<DescriptionOptionsEdit description={descriptions[i]} key={i} editable={this.props.editable} onChange={this.onChangeDescriptionOptions.bind(this,i)} onDelete={this.onDeleteDescriptionOptions.bind(this,i)}/>);
        }

        return <div className={(this.props.editable || this.props.noBackground)?"":"bg-gray-50"}>
            {list}
            {this.props.editable?<div>
                <Button className="ml2 minw2" color="secondary" variant="outlined" size="small" onClick={this.onAddDescriptionOptions.bind(this)}>+ Description Options</Button>
            </div>:null}
        </div>;
    }

    onAddDescriptionOptions() {
        const descriptions = (this.props.descriptions||[]).concat([{name:"", values:[]}]);
        this.props.onChange(descriptions);
    }

    onChangeDescriptionOptions(i, value) {
        const descriptions = (this.props.descriptions||[]).concat([]);
        descriptions[i] = value;
        this.props.onChange(descriptions);
    }

    onDeleteDescriptionOptions(i) {
        const descriptions = (this.props.descriptions||[]).concat([]);
        descriptions.splice(i,1);
        this.props.onChange(descriptions.length?descriptions:null);
    }
}

class DescriptionOptionsEdit extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    onChangeName(event) {
        const description = Object.assign({}, this.props.description);
        description.name = event.target.value || "";
        this.props.onChange(description);
    }

    render() {
        const description = this.props.description;
        const values = description.values||[];
        const list = [];
        const d100 = !([4,6,8,10,12,20].includes(values.length));
        const die = d100?"d100":("d"+values.length);

        for (let i in values) {
            let n = Number(i);
            let num =n+1;

            if (d100) {
                num = (twoDigitNum(Math.trunc(n*100/values.length)+1)) + "-" + twoDigitNum(Math.trunc((n+1)*100/values.length));
            }
            list.push(<tr key={i} onClick={this.props.editable?this.editList.bind(this):null}>
                <td className="tc nowrap">{num}</td>
                <td className={this.props.editable?"hoverhighlight":null}>{values[i]}</td>
            </tr>);
        }


        return <div className="mt1 mb2 stdcontent flex items-center">
            <table className="flex-auto">
                <tbody>
                    <tr>
                        <td className="tc b mh1">{die}</td>
                        <td className="w-100 b">
                            {this.props.editable?
                                <Input className="b" value={description.name||""} fullWidth variant="standard" onChange={this.onChangeName.bind(this)} placeholder="description name"/>
                            :
                                <div className="b">{description.name||""}</div>
                            }
                        </td>
                    </tr>
                    {list}
                    {!list.length?<tr onClick={this.props.editable?this.editList.bind(this):null}><td></td><td className={this.props.editable?"hoverhighlight":null}>click to add values...</td></tr>:null}
                </tbody>
            </table>
            <div className="ml1">
                {(this.props.editable&&this.props.onDelete)?<DeleteWithConfirm className="pa1" name={description.name} onClick={this.props.onDelete}/>:null}
            </div>
            <EditDescriptionList open={this.state.showEditDescriptions} values={description.values} onClose={this.doneEditingList.bind(this)}/>
        </div>;
    }

    editList() {
        this.setState({showEditDescriptions:true});
    }

    doneEditingList(values) {
        this.setState({showEditDescriptions:false});
        if (values) {
            const description = Object.assign({}, this.props.description);
            description.values = values;
            this.props.onChange(description);
        }
    }
} 

function twoDigitNum(num) {
    if (num < 10) {
        return "0"+num;
    }
    if (num == 100) {
        return "00"
    }
    return num;
}

class EditDescriptionList extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({text:(this.props.values||[]).join('\n')});
        }
    }

    handleClose(savechanges) {
        if (savechanges) {
            const values = this.state.text.split('\n');

            for (let i=values.length-1; i>=0; i--) {
                if (values[i]=="") {
                    values.splice(i,1);
                }
            }

            this.props.onClose(values);
        } else {
            this.props.onClose();
        }
    };

    onChange(event) {
        const val = event.target.value;
        this.setState({text:val});
    }

    render() {
        if (!this.props.open)
            return null;
        
        return <Dialog
            open
            maxWidth="md"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>Description Options</DialogTitle>
            <DialogContent>
                <div className="mb2 titletext titlecolor f2">Each line will be a separate description option.</div>
                <TextField
                    multiline
                    className="minvh-60"
                    value={this.state.text||""}
                    onChange={this.onChange.bind(this)}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

class EditItemTextList extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({text:(this.props.values||[]).join('\n')});
        }
    }

    handleClose(savechanges) {
        if (savechanges) {
            const values = this.state.text.split('\n');

            for (let i=values.length-1; i>=0; i--) {
                if (values[i]=="") {
                    values.splice(i,1);
                }
            }

            this.props.onClose(values);
        } else {
            this.props.onClose();
        }
    };

    onChange(event) {
        const val = event.target.value;
        this.setState({text:val});
    }

    render() {
        if (!this.props.open)
            return null;
        
        return <Dialog
            open
            maxWidth="md"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>Equipment List</DialogTitle>
            <DialogContent>
                <div className="mb2 titletext titlecolor f2">Each line will be a separate piece of equipment.</div>
                <TextField
                    multiline
                    className="minvh-60"
                    value={this.state.text||""}
                    onChange={this.onChange.bind(this)}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

class EquipmentList extends React.Component {
    constructor(props) {
        super(props);
	    this.state= { };
    }

    render() {
        const editable = this.props.editable;
        const equipment = this.props.equipment||{};
        const options = equipment.options||[];
        const elist = [];
        let alt;

        for (let i in options) {
            elist.push(<div key={i}><EquipmentOptions key="e" editable={editable} options={options[i]} onChange={this.onChangeEquipment.bind(this,i)}/></div>);
        }
        if (editable) {
            elist.push(<div key={elist.length}><EquipmentOptions key="e" editable={editable} options={[]} onChange={this.onChangeEquipment.bind(this,elist.length)}/></div>);
        }

        if (!this.props.noGoldAlt && (editable || equipment.goldAlt)) {
            let goldAlt;
            if (equipment.goldAlt) {
                goldAlt = equipment.goldAlt.count+"d4"+(equipment.goldAlt.multiple?(" x "+equipment.goldAlt.multiple):"");
            }
    
            if (goldAlt) {
                alt = <PickVal values={goldAltList} noEdit={!editable} onClick={this.onChangeGoldAlt.bind(this)}>
                    <div className={editable?"hover-bg-contrast":""}>Alternatively, you may start with <b>{goldAlt}</b> gp to buy your own equipment.</div>
                </PickVal>;
            } else {
                alt = <PickVal values={goldAltList} noEdit={!editable} onClick={this.onChangeGoldAlt.bind(this)}>
                    <span className={editable?"hover-bg-contrast":""}>No gold alternative.</span>
                </PickVal>;
            }
        }
        return <div>
            {!this.props.noBackground?<div>You start with the following equipment, in addition to the equipment granted by your background:</div>:null}
            <ul>
                {elist}
            </ul>
            <div>{alt}</div>
        </div>;
    }

    onChangeEquipment(i, option) {
        const options = ((this.props.equipment && this.props.equipment.options)||[]).concat([]);
        if (!option || !option.length) {
            options.splice(i,1);
        } else {
            options[i] = option;
        }
        this.onChangeField("options", options);
    }

    onChangeField(prop, val) {
        const equipment = Object.assign({}, this.props.equipment||{});
        equipment[prop]=val;
        this.props.onChange(equipment);
    }

    onChangeGoldAlt(val) {
        this.onChangeField("goldAlt", goldAltValList[val]||null);
    }
}

const goldAltList = {
    "none":"no alternative",
    "1d4":"1d4 gp",
    "2d4":"2d4 gp",
    "3d4":"3d4 gp",
    "4d4":"4d4 gp",
    "5d4":"5d4 gp",
    "1d4t":"1d4 x 10 gp",
    "2d4t":"2d4 x 10 gp",
    "3d4t":"3d4 x 10 gp",
    "4d4t":"4d4 x 10 gp",
    "5d4t":"5d4 x 10 gp",
}

const goldAltValList = {
    "1d4":{count:1},
    "2d4":{count:2},
    "3d4":{count:3},
    "4d4":{count:4},
    "5d4":{count:5},
    "1d4t":{count:1, multiple:10},
    "2d4t":{count:2, multiple:10},
    "3d4t":{count:3, multiple:10},
    "4d4t":{count:4, multiple:10},
    "5d4t":{count:5, multiple:10},
}


const letterPrefixes = ["(a) ", "(b) ", "(c) ", "(d) "];
class EquipmentOptions extends React.Component {
    constructor(props) {
        super(props);
	    this.state= { };
    }

    render() {
        const options = this.props.options||[];
        const olist = [];

        for (let i in options) {
            let prefix = null;

            if (olist.length) {
                olist.push(<span key={"o"+i}>{(i < (options.length-1))?", ":" or "}</span>);
            }
            if (options.length > 1) {
                prefix = letterPrefixes[i];
            }
            olist.push(<span key={i}>{prefix}<EquipmentOption key="edit" editable={this.props.editable} option={options[i]} onChange={this.onChangeOption.bind(this,i)}/></span>);
        }
        if (this.props.editable) {
            if ((olist.length<6)) {
                olist.push(<span key={options.length}><EquipmentOption key="edit"editable={this.props.editable} option={{}} onChange={this.onChangeOption.bind(this,options.length)}/></span>);
            }
        }
        return <div>
            {olist}
        </div>;
    }

    onChangeOption(i, option) {
        const options = (this.props.options||[]).concat([]);

        if (!option) {
            options.splice(i,1);
        } else {
            options[i] = option;
        }
        this.props.onChange(options);
    }
}

const countMap = ["", " ", "two ", "three ", "four ", "five ", "six ", "seven ", "eight ", "nine "];
const countAnyMap = ["", "any ", "two ", "three ", "four ", "five ", "six ", "seven ", "eight ", "nine "];

class EquipmentOption extends React.Component {
    constructor(props) {
        super(props);
	    this.state= { };
    }

    render() {
        const option = this.props.option||{};
        const editable =this.props.editable;
        let typeDescription;
        const items = {};

        if (option.pickCount) {
            typeDescription = <span>{pluralString(option.type,Number(option.pickCount), countAnyMap)}</span>;
        } else if (option.items) {
            const itemList = [];
            const len = Object.keys(option.items).length;
            let cnt=1;

            for (let i in option.items) {
                const count  = Number(option.items[i]);
                const it = campaign.getItem(i);
                if (it) {
                    if (editable) {
                        itemList.push(<span key={i}>{(cnt>1)?(len == cnt?" and ":", "):""}{pluralString(it.displayName, count, countMap)}</span>);
                    } else {
                        itemList.push(<span key={i}>{(cnt>1)?(len == cnt?" and ":", "):""}{pluralString("", count, countMap)}<LinkHref href={"#item?id="+encodeURIComponent(it.name)}>{pluralString(it.displayName, count)}</LinkHref></span>);
                    }
                    cnt++;
                }
            }
            if (!itemList.length) {
                typeDescription = <span>(choose items)</span>;
            } else {
                typeDescription = <span>{itemList}</span>;
            }
        } else if (option.gp) {
            typeDescription = <span>{option.gp} gp</span>;
        } else if (option.itemList) {
            typeDescription = <span>{joinCommaAnd(option.itemList)||"(empty)"}</span>;
        } else {
            typeDescription = <span> (add equipment)</span>;
        }

        if (this.state.showItemPicker) {
            for (let i in option.items) {
                items[i.toLowerCase()] = option.items[i];
            }
        }
        return <span>
            <PickVal values={equipmentTypes} noEdit={!editable} onClick={this.onChangeType.bind(this)}>
                <span className={editable?"hover-bg-contrast":""}>{typeDescription}</span>
            </PickVal>
            <ItemPicker open={this.state.showItemPicker} createNew onClose={this.closeItemPicker.bind(this)} equipment={items}/>
            <TextBasicEdit show={this.state.showGPPicker} isNum label="Gold Pieces" text={option.gp} onChange={this.onChangeGP.bind(this)}/>
            <EditItemTextList open={this.state.showItemTextEdit} values={option.itemList||[]} onClose={this.onChangeItemList.bind(this)}/>
        </span>;
    }

    onShowPickItems() {
        this.setState({showItemPicker:true});
    }

    closeItemPicker(items) {
        this.setState({showItemPicker:false});
        if (items) {
            const newOption = Object.assign({}, this.props.option||{});
            const setitems = {};

            for (let i in items) {
                const it=items[i];
                setitems[it.name] = it.count||1;
            }
            newOption.items = setitems;
            this.props.onChange(newOption);
        }
    }

    onChangeGP(gp) {
        if (gp) {
            let newOption = Object.assign({}, this.props.option||{});
            newOption.gp = gp;
            this.props.onChange(newOption);
        }
        this.setState({showGPPicker:false});
    }

    onChangeItemList(list) {
        if (list) {
            let newOption = Object.assign({}, this.props.option||{});
            newOption.itemList = list;
            this.props.onChange(newOption);
        }
        this.setState({showItemTextEdit:false});
    }

    onChangeType(type) {
        let count =1;
        let newOption = Object.assign({}, this.props.option||{});
        let items = newOption.items;
        let itemList = newOption.itemList;
        let gp = newOption.gp;
        let showItemPicker = false;
        let showGPPicker = false;
        let showItemTextEdit = false;
        delete newOption.items;
        delete newOption.gp;
        delete newOption.itemList;

        switch(type) {
            case "none":
                newOption = null;
                break;

            case "items":
                delete newOption.pickCount;
                newOption.items = items||{};
                showItemPicker = true;
                break;

            case "itemtext":
                delete newOption.pickCount;
                newOption.itemList = itemList||[];
                showItemTextEdit = true;
                break;

            case "gp":
                delete newOption.pickCount;
                newOption.gp = gp || 5;
                showGPPicker = true;
                break;

            case "twosimple":
                count=2;
            case "simple":
                newOption.pickCount = count;
                newOption.type = "simple weapon";
                delete newOption.items;
                break;

            case "twomartial":
                count=2;
            case "martial":
                newOption.pickCount = count;
                newOption.type = "martial weapon";
                break;

            case "martialshield":
                newOption.pickCount = count;
                newOption.type = "martial weapon and shield";
                break;

            case "twosimplemelee":
                count=2;
            case "simplemelee":
                newOption.pickCount = count;
                newOption.type = "simple melee weapon";
                break;
    
            case "twomartialmelee":
                count=2;
            case "martialmelee":
                newOption.pickCount = count;
                newOption.type = "martial melee weapon";
                break;
    
            case "gamingset":
                newOption.pickCount = count;
                newOption.type = "gaming set";
                break;

            case "tools":
                newOption.pickCount = count;
                newOption.type = "artisan's tools";
                break;
    
            case "instrument":
                newOption.pickCount = count;
                newOption.type = "musical instrument";
                break;
        }
        this.props.onChange(newOption);
        if (showItemPicker || showGPPicker||showItemTextEdit) {
            this.setState({showItemPicker, showGPPicker, showItemTextEdit});
        }
    }
}

const equipmentTypes = {
    "none":"none",
    "items":"pick items",
    "itemtext":"enter equipment text",
    "simple":"simple weapon",
    "twosimple":"two simple weapons",
    "martial":"martial weapon",
    "martialshield":"martial weapon and shield",
    "twomartial":"two martial weapons",
    "simplemelee":"simple melee weapon",
    "twosimplemelee":"two simple melee weapons",
    "martialmelee":"martial melee weapon",
    "twomartialmelee":"two martial melee weapons",
    "gamingset":"gaming set",
    "tools":"artisan's tools",
    "instrument":"musical instrument",
    "gp":"gold pieces"
}

class Movement extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    handleClose(savechanges) {
        if (savechanges){
            const speed = this.state.speed||{};
            if (!speed.walk && !speed.burrow && !speed.climb && !speed.fly && !speed.swim) {
                this.props.onChange(null);
            } else {
                this.props.onChange(this.state.speed);
            }
        }

        this.setState({open:false})
    };

    onChange(speed) {
        this.setState({speed});
    }

    showDialog() {
        this.setState({open:true,speed:this.props.speed||{}});
    }

    render() {
        const speed = this.props.speed||{};

        return <span>
            <Button size="small" variant="outlined" color="primary" onClick={this.showDialog.bind(this)}>
                Movement
            </Button> 
            {(speed.walk&&speed.walk.number)?(" walk "+speed.walk.number+"ft."):null}
            {(speed.burrow&&speed.burrow.number)?(" burrow "+speed.burrow.number+"ft."):null}
            {(speed.climb&&speed.climb.number)?(" climb "+speed.climb.number+"ft."):null}
            {(speed.fly&&speed.fly.number)?(" fly "+speed.fly.number+"ft."):null}
            {(speed.swim&&speed.swim.number)?(" swim "+speed.swim.number+"ft."):null}
            {this.state.open?<Dialog
                open
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Movement Speeds</DialogTitle>
                <DialogContent>
                    <EditNN vals={this.state.speed} onChange={this.onChange.bind(this)} nntype='walk'/>
                    <EditNN vals={this.state.speed} onChange={this.onChange.bind(this)} nntype='burrow' walking/>
                    <EditNN vals={this.state.speed} onChange={this.onChange.bind(this)} nntype='climb' walking/>
                    <EditNN vals={this.state.speed} onChange={this.onChange.bind(this)} nntype='fly' walking/>
                    <EditNN vals={this.state.speed} onChange={this.onChange.bind(this)} nntype='swim' walking/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;
    }
}

class CastableSpells extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    handleClose(savechanges) {
        if (savechanges){
            this.props.onChange(this.state.feature);
        }

        this.setState({open:false})
    };

    onChangePick(field, val) {
        const feature = Object.assign({}, this.state.feature);
        if (["none","all"].includes(val)) {
            val = null;
        }
        if (field == "recoveryType"){
            delete feature.ritualOnly;
        }

        feature[field] = val;
        this.setState({feature});
    }

    changeCastableVal(prop, name, val) {
        const selectedSpells = Object.assign({}, this.state.feature.castableSpells);
        const lowerName = name.toLowerCase();

        if (["all", "lowest"].includes(val)) {
            val=null;
        }
        if (selectedSpells[lowerName]) {
            selectedSpells[lowerName][prop] = val;
        }
        this.onChangePick("castableSpells", selectedSpells);
    }

    showDialog() {
        this.setState({open:true,feature:this.props.feature});
    }

    render() {
        const curFeature = this.props.feature||{};
        const {feature,showPicker,selectedSpells,open} = this.state;
        let spellRecoveryType;
        const rows=[];

        if (open) {
            spellRecoveryType = feature.ritualOnly?"ritual":(feature.recoveryType||"long");
            const castable = feature.castableSpells;
            if (castable) {
                const spells = Object.keys(castable).map(function (s){return (campaign.getSpell(s)||{})});
                let lastLevel = -1;
        
                spells.sort(sortSpellList);
        
                for (let i in spells) {
                    const s = spells[i];
                    if (s) {
                        const si = castable[s.name.toLowerCase()];
                        if (lastLevel != s.level) {
                            lastLevel = s.level;
                            rows.push(<tr key={"L"+lastLevel}>
                                <td colSpan="4" className="b">
                                    {(lastLevel!="0")?(Parser.levelToFull(lastLevel)+"-level"):"Cantrips"}
                                </td>
                            </tr>);
                        }
        
                        rows.push(<tr key={i}>
                            <td>{s.displayName}</td>
                            <td className="minw35">{(s.level > 0)?<SelectVal isNum fullWidth includeVal="all" value={si.grantLevel||"all"} values={stdvalues.oneTo20} onClick={this.changeCastableVal.bind(this, "grantLevel",s.name)}/>:null}</td>
                            <td className="minw35">{(s.level > 0)?<SelectVal isNum fullWidth includeVal="lowest" value={si.castLevel||"lowest"} values={getCastLevels(s.level)} onClick={this.changeCastableVal.bind(this, "castLevel",s.name)}/>:null}</td>
                            {(spellRecoveryType=="uses")?<td><SelectVal isPossibleNum fullWidth value={si.useCount||1} values={getNumberArray(9)} onClick={this.changeCastableVal.bind(this, "useCount",s.name)}/></td>:null}
                        </tr>);
                    }
                }
            }    
        }

        return <span>
            <Button size="small" variant="outlined" color="primary" onClick={this.showDialog.bind(this)}>
                Innate
            </Button> {spellNamesFromList(Object.keys(curFeature.castableSpells||{}))} 
            {open?<Dialog
                open
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Innate Spells</DialogTitle>
                <DialogContent>
                    <div className="mb1">
                        <SimpleCheckPick onChange={this.onChangePick.bind(this, "spellAbilityDC")} value={feature.spellAbilityDC} values={stdvalues.abilityNames} includeNum numLabel="Save DC" label="Spell Save DC" prefix="Spell save DC" editable useButton noDiv/>
                    </div>
                    <div className="mb1">
                        <SelectVal className="minw35" value={spellRecoveryType} values={spellRecoveryOptions} helperText="Recovery" onClick={this.onChangePick.bind(this,"recoveryType")}/>
                    </div>
                    <div className="mb1">
                        <Button size="small" variant="outlined" color="primary" onClick={this.showPicker.bind(this, "castableSpells")}>
                            Pick Spells
                        </Button>
                    </div>
                    {rows.length?<div className="mt2 stdcontent">
                        <table className="w-100">
                            <tbody>
                                <tr className="b">
                                    <td>Spell</td>
                                    <td>Grant Level</td>
                                    <td>Cast Level</td>
                                    {(spellRecoveryType=="uses")?<td>Uses</td>:null}
                                </tr>
                                {rows}
                            </tbody>
                        </table>
                    </div>:null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
                <SpellPicker 
                    open={showPicker} 
                    maxSpellLevel={20}
                    selectedSpells={selectedSpells}
                    hideCounts 
                    onClose={this.closePicker.bind(this)}
                />
            </Dialog>:null}
        </span>;
    }

    showPicker(property) {
        const feature = this.state.feature;
        const spells = feature[property];
        const selectedSpells = spells||{};

        this.setState({showPicker:true, showPickerProperty:property, selectedSpells});
    }
    
    closePicker(spells) {
        this.setState({showPicker:false});
        if (spells) {
            this.onChangePick(this.state.showPickerProperty, (Object.keys(spells).length)?spells:null);
        }
    }

}

const restrictionTypes = {
    none:"None",
    custom:"Named Value",
    level:"Character Level",
    item:"Equipment"
}
class FeatureRestriction extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    handleClose(savechanges) {
        if (savechanges){
            const {restriction}=this.state;
            if (!restriction.extension && !restriction.activateName) {
                this.props.onChange(null);
            } else {
                this.props.onChange(restriction);
            }
        }

        this.setState({open:false})
    };

    onChangeVal(prop, val) {
        const restriction = Object.assign({}, this.state.restriction);
        if (prop=="extension" && val=="none") {
            val = null;
        }
        restriction[prop]=val;
        this.setState({restriction});
    }

    showDialog() {
        this.setState({open:true,restriction:this.props.restriction||{}});
    }

    render() {
        const restrictionProp = this.props.restriction||{};
        const restriction = this.state.restriction||{};
        const {PickClasses} = require('./renderspell.jsx');
        let restrictType,inside;

        if (this.state.open) {
            restrictType=restrictionTypes[restriction.extension]?restriction.extension:restriction.extension?"custom":"none";

            switch (restrictType) {
                case "custom":{
                    inside = <div>
                        <div className="hk-well mb2">A restriction will only enable the feature if a named value (extension, custom column, display value by level) is between two values.  If there is no upper bound then set the max to 0 (only if min &gt; 0).</div>
                        <div>
                            <TextVal fullWidth text={restriction.characterOption||""} onChange={this.onChangeVal.bind(this,"characterOption")} helperText="Value Name"/>
                        </div>
                        <div className="mt1">
                            Min <MaxNumberAdjust max={1000} value={restriction.min||0} onAdjustValue={this.onChangeVal.bind(this, "min")}/> Max <MaxNumberAdjust max={1000} value={restriction.max||0} onAdjustValue={this.onChangeVal.bind(this, "max")}/>
                        </div>      
                    </div>
                    break;
                }
                case "level":{
                    inside = <div>
                        <div className="hk-well mb2">A restriction will only enable the feature if a character level is between two values.  If there is no upper bound then set the max to 0 (only if min &gt; 0).</div>
                        <div className="mt1">
                            Min <MaxNumberAdjust max={1000} value={restriction.min||0} onAdjustValue={this.onChangeVal.bind(this, "min")}/> Max <MaxNumberAdjust max={1000} value={restriction.max||0} onAdjustValue={this.onChangeVal.bind(this, "max")}/>
                        </div>
                        <div className="mt1">
                            <Button className="minw2" size="small" variant="outlined" color="primary" onClick={this.showPickClasses.bind(this)}>
                                Restrict by Class Level
                            </Button> {restriction.classes?("Classes "+restriction.classes.join(", ")):"Character level"}
                            <PickClasses open={this.state.showPickClasses} classList={restriction.classes} onClose={this.closePickClasses.bind(this)}/>
                        </div>
                    </div>;
                    break;
                }
                case "item":{
                    inside = <div>
                        <div className="hk-well mb2">A restriction will only enable the feature if a character an item/weapon equiped.</div>
                        <div className="mt1">
                            <ItemFilter filter={restriction.itemFilter||{}} onChange={this.onChangeVal.bind(this, "itemFilter")}/>
                        </div>
                    </div>;
                    break;
                }
            }
        }


        return <span>
            <Button size="small" variant="outlined" color="primary" onClick={this.showDialog.bind(this)}>
                Restrict
            </Button> 
            {restrictionProp.extension||restrictionProp.activateName?<span className="ml2">
                Restrict by {restrictionProp.extension=="level"?(restrictionProp.classes?"class level":"character level"):restrictionProp.extension?(restrictionProp.characterOption || restrictionTypes[restrictionProp.extension] || "unknown"):null} {restrictionProp.activateName?(restrictionProp.activateName+" is active"):null}
            </span>:null}
            {this.state.open?<Dialog
                open
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Restrict Feature</DialogTitle>
                <DialogContent>
                    <SelectVal fullWidth value={restrictType} helperText="Value Restrict Type" onClick={this.onChangeVal.bind(this,"extension")} values={restrictionTypes}/>
                    {inside}
                    <div className="hk-well mv2">An activate restriction will only enable the feature if a named action is active.</div>
                    <div>
                        <TextVal fullWidth text={restriction.activateName||""} onChange={this.onChangeVal.bind(this,"activateName")} helperText="Activate Name"/>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;
    }

    showPickClasses() {
        this.setState({showPickClasses:true});
    }

    closePickClasses(classList) {
        if (classList) {
            this.onChangeVal("classes", classList.length?classList:null);
        }
        this.setState({showPickClasses:false});
    }
}

class Senses extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    handleClose(savechanges) {
        if (savechanges){
            const senses = this.state.senses||{};
            if (!senses.blindsight && !senses.darkvision && !senses.tremorsense && !senses.truesight && !senses["keen smell"]) {
                this.props.onChange(null);
            } else {
                this.props.onChange(this.state.senses);
            }
        }

        this.setState({open:false})
    };

    onChange(senses) {
        this.setState({senses});
    }

    showDialog() {
        this.setState({open:true,senses:this.props.senses||{}});
    }

    render() {
        const senses = this.props.senses||{};

        return <span>
            <Button size="small" variant="outlined" color="primary" onClick={this.showDialog.bind(this)}>
                Senses
            </Button> 
            {(senses.blindsight&&senses.blindsight.number)?(" blindsight "+senses.blindsight.number+"ft."):null}
            {(senses.darkvision&&senses.darkvision.number)?(" darkvision "+senses.darkvision.number+"ft."):null}
            {(senses.tremorsense&&senses.tremorsense.number)?(" tremorsense "+senses.tremorsense.number+"ft."):null}
            {(senses.truesight&&senses.truesight.number)?(" truesight "+senses.truesight.number+"ft."):null}
            {(senses["keen smell"]&&senses["keen smell"].number)?(" keen smell "+senses["keen smell"].number+"ft."):null}
            {this.state.open?<Dialog
                open
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Senses</DialogTitle>
                <DialogContent>
                    <EditNN vals={this.state.senses} onChange={this.onChange.bind(this)} nntype='blindsight'/>
                    <EditNN vals={this.state.senses} onChange={this.onChange.bind(this)} nntype='darkvision'/>
                    <EditNN vals={this.state.senses} onChange={this.onChange.bind(this)} nntype='tremorsense'/>
                    <EditNN vals={this.state.senses} onChange={this.onChange.bind(this)} nntype='truesight'/>
                    <EditNN vals={this.state.senses} onChange={this.onChange.bind(this)} nntype='keen smell'/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;
    }
}



class EditNN extends React.Component {
    onChange(event) {
        let nn = this.getNNVals();
        let val = event.target.value;
        let newNN;

        if (!val) {
            nn.number = null;
        } else if (/[0-9]*/.exec(val) == val) {
            nn.number = Number(val);
        } 
        newNN = Object.assign({}, this.props.vals);

        if (!nn.number||nn.number=="0"){
            delete newNN[this.props.nntype];
        } else {
            newNN[this.props.nntype] = nn;
        }

        if (this.props.onChange)
            this.props.onChange(newNN);
    }

    toggleWalking() {
        let nn = this.getNNVals();
        const walking = !nn.walking;
        const newNN = Object.assign({}, this.props.vals);
        if (walking) {
            newNN[this.props.nntype]={walking:true};
        } else {
            delete newNN[this.props.nntype];
        }
        this.props.onChange(newNN);
    }

    getNNVals() {
        let number;
        let nn;

        nn = this.props.vals[this.props.nntype]||{};
        number = nn.number || "0";

        return {number:number, walking:nn.walking||false};
    }

    render() {
        let val = this.getNNVals()
        const number=val.number;

        return <div className="mv1 flex items-end">
        <div className="ttc pa2 w5">{this.props.nntype}</div>
        <TextField
          label="(ft.)"
          value={number}
          onChange={this.onChange.bind(this)}
          disabled={val.walking}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          style={{marginLeft:10,marginRight:10, width:64}}
        />
        {this.props.walking?<CheckVal value={val.walking} onChange={this.toggleWalking.bind(this)} label="walking speed"/>:null}
        </div>;
    }
}

const usageValuesList = [
    1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,999
];

class PickUsage extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    onChangeVal(prop, val) {
        let usage = Object.assign({}, this.state.usage);

        if (val=="none") {
            val = null;
        }

        if (val == "unlimited") {
            val = 10000;
        }

        switch (prop) {
            case "type":
            {
                switch (val) {
                    case "nousage":
                        delete usage.proficiency;
                        delete usage.calcCount;
                        delete usage.baseCount;
                        delete usage.ability;
                        delete usage.levelsCount;
                        break;
                    case "baseusage":
                        if (!usage.baseCount) {
                            usage.baseCount=1;
                        }
                        delete usage.proficiency;
                        delete usage.ability;
                        delete usage.levelsCount;
                        delete usage.calcCount;
                        break;
                    case "abilityusage":
                        if (!usage.ability) {
                            usage.ability="str";
                        }
                        delete usage.proficiency;
                        delete usage.baseCount;
                        delete usage.levelsCount;
                        delete usage.calcCount;
                        break;
                    case "proficiency":
                        usage.proficiency=true;
                        delete usage.ability;
                        delete usage.baseCount;
                        delete usage.levelsCount;
                        delete usage.calcCount;
                        break;
                    case "levelusage":
                        if (!usage.levelsCount) {
                            usage.levelsCount = [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1];
                        }
                        delete usage.proficiency;
                        delete usage.baseCount;
                        delete usage.ability;
                        delete usage.calcCount;
                        break;
                    case "calc":
                        if (!usage.calcCount) {
                            usage.calcCount="";
                        }
                        delete usage.proficiency;
                        delete usage.baseCount;
                        delete usage.ability;
                        delete usage.levelsCount;
                        break;
                }
                break;
            }
            case "calcCount":{
                usage.calcCount=val||"";
                break;
            }
            default:
                usage[prop] = val;
                break;
        }
        this.setState({usage});
    }

    onChangeRecover(value) {
        let usage = Object.assign({}, this.state.usage);
        delete usage.recover;
        usage.longRest=value;
        this.setState({usage});
    }

    render() {
        const usage = this.props.usage||{};
        const longRest = usage.longRest||0;
        let inside = null;
        let recover;

        if (usage.calcCount != null) {
            inside  = <span>{usage.calcCount} uses</span>;
        } else if (usage.baseCount) {
            inside  = <span>{usage.baseCount} uses</span>;
        } else if (usage.levelsCount) {
            inside  = <span>{usage.levelsCount.join(", ")} uses</span>;
        } else if (usage.ability) {
            inside  = <span>
                {stdvalues.abilityNames[usage.ability]} modifier {usage.abilityBonus?("+"+usage.abilityBonus):null}
            </span>;
        } else if (usage.proficiency) {
            inside  = <span>
                proficiency bonus {usage.abilityBonus?("+"+usage.abilityBonus):null}
            </span>;
        }
        if (!longRest) {
            recover = "recover after long or short rest";
        } else if (longRest < 0){
            recover = "does not recover";
        } else {
            recover = "recover after long rest";
        }

        return <span>
            <Button size="small" variant="outlined" color="primary" onClick={this.showPick.bind(this)}>Uses</Button>
            {inside?<span> {inside} {recover}</span>:null}
            {this.getEditDialog()}
        </span>;
    }

    showPick() {
        this.setState({open:true, usage:this.props.usage||{}});
    }

    handleClose(save) {
        if (save) {
            this.props.onChange(this.state.usage);
        }
        this.setState({open:false});
    }

    getEditDialog() {
        if (!this.state.open) {
            return null;
        }
        const usage = this.state.usage||{};
        let inside=null;
        let usageType = "nousage";

        if (usage.calcCount != null) {
            usageType = "calc";
            inside  = <TextVal fullWidth className="mv1" text={usage.calcCount} onChange={this.onChangeVal.bind(this,"calcCount")} helperText="Uses calculation"/>;
        } if (usage.baseCount) {
            usageType = "baseusage";
            inside  = <NumberAdjustPlusMinus value={usage.baseCount||0} onChange={this.onChangeVal.bind(this,"baseCount")}> uses</NumberAdjustPlusMinus>;
//            <SelectVal value={usage.baseCount} onClick={this.onChangeVal.bind(this,"baseCount")} values={usageValuesList} suffix=" uses"/>;
        } else if (usage.levelsCount) {
            usageType = "levelusage";
            inside = <PickCountsByLevel noDiv levelsCount={usage.levelsCount} label="Usage by Level" onChange={this.onChangeVal.bind(this,"levelsCount")}/>;
        } else if (usage.ability) {
            usageType = "abilityusage";
            inside  = <span>
                <SelectVal value={usage.ability} helperText="ability" onClick={this.onChangeVal.bind(this,"ability")} values={stdvalues.abilityNames} suffix=" modifier"/>&nbsp;
                <SelectVal isNum value={usage.abilityBonus||0} helperText="additional bonus" onClick={this.onChangeVal.bind(this,"abilityBonus")} values={[0,1,2,3,4]}/>
            </span>;
        } else if (usage.proficiency) {
            usageType = "proficiency";
            inside  = <span>
                <SelectVal isNum value={usage.abilityBonus||0} helperText="additional bonus" onClick={this.onChangeVal.bind(this,"abilityBonus")} values={[0,1,2,3,4]}/>
            </span>;
        }
        return <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>Pick Uses</DialogTitle>
            <DialogContent>
                <SelectVal fullWidth values={selectUsageList} value={usageType} onClick={this.onChangeVal.bind(this, "type")} helperText="Uses"/>
                <div>
                    {inside} {inside?<SelectVal isNum noteText value={usage.longRest||0} onClick={this.onChangeRecover.bind(this)} values={restValues}/>:null}
                </div>
                {inside?<div>
                    <CheckVal value={usage.resetToZero||false} onChange={this.onChangeVal.bind(this, "resetToZero")} label="Set to zero on rest"/>
                </div>:null}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

class PickItemUsage extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    onChangeVal(prop, val) {
        let usage = Object.assign({}, this.props.usage);

        if ((prop == "restore") && (val=="random")) {
            usage.random = usage.baseCount;
        }

        if ((val=="none")&&(prop != "restore")) {
            val = null;
        }

        switch (prop) {
            case "type":
            {
                switch (val) {
                    case "nousage":
                        usage = {type:"F"};
                        break;
                    case "baseusage":
                        if (!usage.baseCount) {
                            usage = {type:"F", baseCount:1};
                        }
                        break;
                }
                break;
            }
            case "baseCount":
                if (val < 1) {
                    val=1;
                }
                // fall through
            default:
                usage[prop] = val;
                break;
        }

        if ((usage.restore == "random") && ((usage.random+(usage.randomBonus||0)) > usage.baseCount)) {
            if (usage.random>usage.baseCount) {
                usage.random = usage.baseCount;
            }
            usage.randomBonus=null;
        }

        this.props.onChange(usage);
    }

    render() {
        const usage = this.props.usage||{};
        let inside = null;
        let randomBonus = usage.randomBonus;

        if (usage.baseCount) {
            inside  = <NumberAdjustPlusMinus value={usage.baseCount||0} onChange={this.onChangeVal.bind(this,"baseCount")}/>;

            if (randomBonus == null) {
                randomBonus = usage.baseCount-usage.random;
            }
        }

        return <span>
            <PickVal values={selectUsageListBase} onClick={this.onChangeVal.bind(this, "type")} label="Uses" size="small" variant="outlined" color="primary"/>
            {inside?<span> {inside}&nbsp;
                <SelectVal noteText value={usage.restore||"long"} onClick={this.onChangeVal.bind(this,"restore")} values={restValuesFull}/>
                {(usage.restore=="random")?<span>
                    <SelectVal className="mh1" value={usage.random} onClick={this.onChangeVal.bind(this,"random")} prefix="1d" values={getNumberArray(usage.baseCount)}/>
                    {(usage.random<usage.baseCount)?<SelectVal value={randomBonus} onClick={this.onChangeVal.bind(this,"randomBonus")} prefix="+" values={getNumberArray(usage.baseCount-usage.random,true)}/>:null}
                </span>:null}
            </span>:null}
        </span>;
    }
}

class AbilityProficiency extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
            anchorEl:null, 
        };
    }

    showMenu(event){
        this.setState({ showmenu:true, anchorEl:event.currentTarget, proficiency:this.props.proficiency });
    }

    handleClose(savechanges, event) {
        if (savechanges){
            this.props.onChange(this.state.proficiency);
        }
        this.setState({showmenu:false});
    };

    onChange(event) {
        const proficiency = this.state.proficiency.concat([]);
        const val = event.target.value;
        const pos = proficiency.indexOf(val);

        if (pos >=0) {
            proficiency.splice(pos,1);
        } else {
            proficiency.push(val);
        }
        this.setState({proficiency});
    }

    render() {
        const proficiency=this.state.proficiency||[];

        return <div className="mv1">
            <span className={this.props.editable?"hover-bg-contrast":""} onClick={this.props.editable?this.showMenu.bind(this):null}><b>Saving Throws:</b> {this.props.proficiency.join(", ")}</span>
            <Dialog
                open={this.state.showmenu}
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Saving Throw Proficiencies</DialogTitle>
                <DialogContent>
                <FormControlLabel
                    control={<Checkbox checked={proficiency.includes("str")} onChange={this.onChange.bind(this)} value="str" />}
                    label="STR"
                />
                <FormControlLabel
                    control={<Checkbox checked={proficiency.includes("dex")} onChange={this.onChange.bind(this)} value="dex" />}
                    label="DEX"
                />
                <FormControlLabel
                    control={<Checkbox checked={proficiency.includes("con")} onChange={this.onChange.bind(this)} value="con" />}
                    label="CON"
                />
                <FormControlLabel
                    control={<Checkbox checked={proficiency.includes("int")} onChange={this.onChange.bind(this)} value="int" />}
                    label="INT"
                />
                <FormControlLabel
                    control={<Checkbox checked={proficiency.includes("wis")} onChange={this.onChange.bind(this)} value="wis" />}
                    label="WIS"
                />
                <FormControlLabel
                    control={<Checkbox checked={proficiency.includes("cha")} onChange={this.onChange.bind(this)} value="cha" />}
                    label="CHA"
                />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>;
    }
}

class CreateItemOptions extends React.Component {
    constructor(props) {
        super(props);

        this.state= {
            showmenu:false, 
        };
    }

    showMenu(event){
        if (this.props.editable) {
            this.setState({showmenu:true, createitem:this.props.createitem});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            if (!this.state.createitem || ((this.state.createitem.type||"none") == "none")) {
                this.props.onChange(null);
            } else {
                if ((this.state.createitem.type == "fixed") && !this.state.createitem.item) {
                    displayMessage("Need to select an item to create");
                    return;
                } 
                this.props.onChange(this.state.createitem);
            }
        }
        if (event) {
            event.stopPropagation();
        }
        this.setState({showmenu:false});
    };

    onChangeCreateItem(createitem) {
        this.setState({createitem});
    }

    render() {
        let r = <span>
            <span>
                <Button className="minw2" size="small" variant="outlined" color="primary" onClick={this.props.editable?this.showMenu.bind(this):null}>
                    {this.props.prefix}Create Item
                </Button> {this.props.createitem?"Create a special item from scratch or by modifying an item.":null}
            </span>
            {this.state.showmenu?<Dialog
                open
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Create Item</DialogTitle>
                <DialogContent>
                    <CreateItemEmbed createitem={this.state.createitem} includeNone onChange={this.onChangeCreateItem.bind(this)}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;
        
        if (this.props.noDiv) {
            return r;
        } else {
            return <div>{r}</div>
        }
    }
}

class CreateItemEmbed extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    setProperty(prop,val) {
        const createitem=Object.assign({}, this.props.createitem||{});
        if (val=="none"){
            val=null;
        }
        createitem[prop]=val;
        this.onChangeCreateItem(createitem);
    }

    onChangeCreateItem(createitem) {
        if ((createitem?.type||"none") == "none") {
            createitem=null;
        }
        this.props.onChange(createitem);
    }

    render() {
        const createitem = this.props.createitem||{};
        let inside=null;

        switch (createitem.type) {
            case "fixed":{
                inside = <div>
                    <Button onClick={this.showItemPicker.bind(this)} color="primary" variant="outlined">
                        Pick Item
                    </Button>&nbsp;
                    {createitem.item?<a onClick={this.showItem.bind(this)}>{createitem.item.displayName}</a>:null}
                </div>
                break;
            }

            case "pick":{
                inside = <div>
                    Player will be able to select item to add abilities either from inventory or from item list.
                    <h2>Item Select Filter</h2>
                    <ItemFilter filter={createitem} onChange={this.onChangeCreateItem.bind(this)}/>
                </div>
                break;
            }

            case "none":
            default:
                break;
        }
        
        return <div className="stdcontent">
            <div className="hk-well mb2">The create item feature ability allows creation of a special item.</div>
            <div className="mb2">
                <SelectVal fullWidth helperText="Create Item Type" values={this.props.includeNone?createitemOptionsWithNone:createitemOptions} value={createitem.type||"none"} onClick={this.setProperty.bind(this,"type")}/>
            </div>
            {(createitem.type && createitem.type != "none")?<div>
                <div className="flex mb1">
                    <SelectVal fullWidth includeVal="none" selectClass=" " helperText="Activation" values={activationTypes} value={createitem.usageType||"none"} onClick={this.setProperty.bind(this, "usageType")}/>
                    <SelectTextVal selectClass=" " className="ml1" fullWidth text={createitem.duration||""} onChange={this.setProperty.bind(this, "duration")} values={stdvalues.durationSuggestions} helperText="Duration"/>
                </div>
                <div className="flex mb1">
                    <SelectVal fullWidth isNum selectClass=" " helperText="Consume count" values={consumeUsageVals} value={createitem.consumeUsage||0} onClick={this.setProperty.bind(this, "consumeUsage")}/>
                    <SelectVal fullWidth className="ml1" selectClass=" " helperText="Consume on action" values={consumeUsageTypes} value={createitem.consumeType||"uses"} onClick={this.setProperty.bind(this, "consumeType")}/>
                </div>
                {this.props.allowNotes?<div className="mv1">
                    <div className="titlecolor titletext mt1 bb titleborder">Action Notes</div>
                    <EntityEditor onChange={this.setProperty.bind(this,"extraNotes")} entry={createitem.extraNotes}/>
                </div>:null}
            </div>:null}
            {(createitem.type && createitem.type != "none")?<div>
                {this.props.showButtonName?<TextVal fullWidth className="mv1" text={createitem.buttonName||""} onChange={this.setProperty.bind(this,"buttonName")} helperText="Create Button Name"/>:null}
                <CheckVal value={createitem.reqAttune||false} onChange={this.setProperty.bind(this, "reqAttune")} label="Require Attunement"/>
            </div>:null}
            {inside}
            {(createitem.type && createitem.type != "none")?<div>
                <h2>Item Magical Abilities</h2>
                <EditItemFeatureOptions feature={createitem.itemfeature} onChange={this.setProperty.bind(this,"itemfeature")}/>
                <h2>Level Up Magical Abilities</h2>
                <SelectVal fullWidth helperText="Level" values={stdvalues.zeroTo20} value={createitem.levelup||0} onClick={this.setProperty.bind(this,"levelup")}/>
                {createitem.levelup?<EditItemFeatureOptions feature={createitem.itemlevelfeature} onChange={this.setProperty.bind(this,"itemlevelfeature")}/>:null}
            </div>:null}
            <ItemPicker single saveLabel="Select" open={this.state.showItemPicker} onClose={this.closeItemPicker.bind(this)}/>
            <ItemDialog editable item={createitem.item} open={this.state.showItem} onClose={this.closeItem.bind(this)}/>
        </div>;
    }

    showItemPicker() {
        this.setState({showItemPicker:true});
    }

    closeItemPicker(items) {
        if (items) {
            let item;
            for (let i in items) {
                item = items[i];
            }
            if (item) {
                this.setProperty("item", item);
            }
        }
        this.setState({showItemPicker:false});
    }

    showItem() {
        this.setState({showItem:true});
    }

    closeItem(item) {
        if (item) {
            this.setProperty("item", item);
        }
        this.setState({showItem:false});
    }
}

const createitemOptions = [
    {value:"pick", name:"Pick an item to transform"},
    {value:"fixed", name:"Create a specific item"}
];

const createitemOptionsWithNone = [
    {value:"none", name:"No ability to create an item"},
].concat(createitemOptions);

    

class AbilityOptions extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
            anchorEl:null, 
        };
    }

    showMenu(event){
        this.setState({ showmenu:true, anchorEl:event.currentTarget, ability:this.props.ability||{} });
    }

    handleClose(savechanges, event) {
        if (savechanges){
            const ability = this.state.ability;
            if (!ability.choose || !ability.from || !ability.from.length) {
                delete ability.choose;
                delete ability.from;
                delete ability.amount;
            }
            if (!ability.str && !ability.dex && !ability.con && !ability.int && !ability.wis && !ability.cha && 
                !ability.minstr && !ability.mindex && !ability.mincon && !ability.minint && !ability.minwis && !ability.mincha && 
                !ability.maxstr && !ability.maxdex && !ability.maxcon && !ability.maxint && !ability.maxwis && !ability.maxcha && 
                !ability.choose) {
                this.props.onChange(null);
            } else {
                this.props.onChange(ability);
            }
        }
        this.setState({showmenu:false});
    };

    onChange(prop, value) {
        const ability = Object.assign({}, this.state.ability);
        
        if (value) {
            ability[prop] = value;
        } else {
            delete ability[prop];
        }
        this.setState({ability});
    }

    onSetOption(abilityOption) {
        const from = (this.state.ability.from||[]).concat([]);

        const pos = from.indexOf(abilityOption);
        if (pos>=0) {
            from.splice(pos,1);
        } else {
            from.push(abilityOption);
        }

        if (from.length) {
            this.onChange("from", from);
        } else {
            this.onChange("from", null);
        }
    }

    render() {
        const ability=this.state.ability||{};
        const abilityProp = this.props.ability||{};
        const from = ability.from||[];
        const textList = [];
        let text="";

        if (abilityProp.str) {
            textList.push(((abilityProp.str<0)?"":"+")+abilityProp.str+" str");
        }
        if (abilityProp.dex) {
            textList.push(((abilityProp.dex<0)?"":"+")+abilityProp.dex+" dex");
        }
        if (abilityProp.con) {
            textList.push(((abilityProp.con<0)?"":"+")+abilityProp.con+" con");
        }
        if (abilityProp.int) {
            textList.push(((abilityProp.int<0)?"":"+")+abilityProp.int+" int");
        }
        if (abilityProp.wis) {
            textList.push(((abilityProp.wis<0)?"":"+")+abilityProp.wis+" wis");
        }
        if (abilityProp.cha) {
            textList.push(((abilityProp.cha<0)?"":"+")+abilityProp.cha+" cha");
        }

        if (abilityProp.minstr) {
            textList.push("minimum str of "+abilityProp.minstr);
        }
        if (abilityProp.mindex) {
            textList.push("minimum dex of "+abilityProp.mindex);
        }
        if (abilityProp.mincon) {
            textList.push("minimum con of "+abilityProp.mincon);
        }
        if (abilityProp.minint) {
            textList.push("minimum int of "+abilityProp.minint);
        }
        if (abilityProp.minwis) {
            textList.push("minimum wis of "+abilityProp.minwis);
        }
        if (abilityProp.mincha) {
            textList.push("minimum cha of "+abilityProp.mincha);
        }

        if (abilityProp.maxstr) {
            textList.push("maximum str of "+abilityProp.maxstr);
        }
        if (abilityProp.maxdex) {
            textList.push("maximum dex of "+abilityProp.maxdex);
        }
        if (abilityProp.maxcon) {
            textList.push("maximum con of "+abilityProp.maxcon);
        }
        if (abilityProp.maxint) {
            textList.push("maximum int of "+abilityProp.maxint);
        }
        if (abilityProp.maxwis) {
            textList.push("maximum wis of "+abilityProp.maxwis);
        }
        if (abilityProp.maxcha) {
            textList.push("maximum cha of "+abilityProp.maxcha);
        }

        if (textList.length) {
            text = textList.join(", ");
        }
        if (abilityProp.choose && (abilityProp.from||[]).length) {
            if (textList.length) {
                text = text+" and ";
            }
            text = text+"choose "+abilityProp.choose+" from "+abilityProp.from.join(", ")+" to increase by "+(ability.amount||1)+".";
        }

        return <span>
            <Button size="small" variant="outlined" color="primary" onClick={this.showMenu.bind(this)}>
                Ability Scores
            </Button> {text}
            <Dialog
                open={this.state.showmenu}
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Ability Modifiers</DialogTitle>
                <DialogContent>
                <table className="w-100 mb2">
                    <tbody>
                        <tr>
                            <td className="pa1"><SelectVal isNum noteText label="STR" fullWidth values={abilityValues} value={ability.str||0} onClick={this.onChange.bind(this,"str")}/></td>
                            <td className="pa1"><SelectVal isNum noteText label="DEX" fullWidth values={abilityValues} value={ability.dex||0} onClick={this.onChange.bind(this,"dex")}/></td>
                            <td className="pa1"><SelectVal isNum noteText label="CON" fullWidth values={abilityValues} value={ability.con||0} onClick={this.onChange.bind(this,"con")}/></td>
                            <td className="pa1"><SelectVal isNum noteText label="INT" fullWidth values={abilityValues} value={ability.int||0} onClick={this.onChange.bind(this,"int")}/></td>
                            <td className="pa1"><SelectVal isNum noteText label="WIS" fullWidth values={abilityValues} value={ability.wis||0} onClick={this.onChange.bind(this,"wis")}/></td>
                            <td className="pa1"><SelectVal isNum noteText label="CHA" fullWidth values={abilityValues} value={ability.cha||0} onClick={this.onChange.bind(this,"cha")}/></td>
                        </tr>
                        <tr>
                            <td className="pa1"><SelectVal isNum noteText helperText="min" fullWidth values={stdvalues.abilitiesListWithZero} value={ability.minstr||0} onClick={this.onChange.bind(this,"minstr")}/></td>
                            <td className="pa1"><SelectVal isNum noteText helperText="min" fullWidth values={stdvalues.abilitiesListWithZero} value={ability.mindex||0} onClick={this.onChange.bind(this,"mindex")}/></td>
                            <td className="pa1"><SelectVal isNum noteText helperText="min" fullWidth values={stdvalues.abilitiesListWithZero} value={ability.mincon||0} onClick={this.onChange.bind(this,"mincon")}/></td>
                            <td className="pa1"><SelectVal isNum noteText helperText="min" fullWidth values={stdvalues.abilitiesListWithZero} value={ability.minint||0} onClick={this.onChange.bind(this,"minint")}/></td>
                            <td className="pa1"><SelectVal isNum noteText helperText="min" fullWidth values={stdvalues.abilitiesListWithZero} value={ability.minwis||0} onClick={this.onChange.bind(this,"minwis")}/></td>
                            <td className="pa1"><SelectVal isNum noteText helperText="min" fullWidth values={stdvalues.abilitiesListWithZero} value={ability.mincha||0} onClick={this.onChange.bind(this,"mincha")}/></td>
                        </tr>
                        <tr>
                            <td className="pa1"><SelectVal isNum noteText helperText="max" fullWidth values={maxVals} value={ability.maxstr||0} onClick={this.onChange.bind(this,"maxstr")}/></td>
                            <td className="pa1"><SelectVal isNum noteText helperText="max" fullWidth values={maxVals} value={ability.maxdex||0} onClick={this.onChange.bind(this,"maxdex")}/></td>
                            <td className="pa1"><SelectVal isNum noteText helperText="max" fullWidth values={maxVals} value={ability.maxcon||0} onClick={this.onChange.bind(this,"maxcon")}/></td>
                            <td className="pa1"><SelectVal isNum noteText helperText="max" fullWidth values={maxVals} value={ability.maxint||0} onClick={this.onChange.bind(this,"maxint")}/></td>
                            <td className="pa1"><SelectVal isNum noteText helperText="max" fullWidth values={maxVals} value={ability.maxwis||0} onClick={this.onChange.bind(this,"maxwis")}/></td>
                            <td className="pa1"><SelectVal isNum noteText helperText="max" fullWidth values={maxVals} value={ability.maxcha||0} onClick={this.onChange.bind(this,"maxcha")}/></td>
                        </tr>
                    </tbody>
                </table>
                <div className="mb1"><SelectVal isNum fullWidth noteText label="Choose Abilities" values={[0,1,2,3,4]} value={ability.choose||0} onClick={this.onChange.bind(this,"choose")}/></div>
                {ability.choose?<SelectVal isNum fullWidth noteText label="Ability Adjustment" values={abilityAdjustValues} value={ability.amount||1} onClick={this.onChange.bind(this,"amount")}/>:null}
                {ability.choose?<table className="w-100">
                    <tbody>
                        <tr>
                            <td><FormControlLabel
                                control={<Checkbox checked={from.includes("str")} onChange={this.onSetOption.bind(this, "str")}/>}
                                label="STR" labelPlacement="bottom" classes={{labelPlacementBottom:"mh2"}}
                            /></td>
                            <td><FormControlLabel
                                control={<Checkbox checked={from.includes("dex")} onChange={this.onSetOption.bind(this,"dex")}/>}
                                label="DEX" labelPlacement="bottom" classes={{labelPlacementBottom:"mh2"}}
                            /></td>
                            <td><FormControlLabel
                                control={<Checkbox checked={from.includes("con")} onChange={this.onSetOption.bind(this, "con")}/>}
                                label="CON" labelPlacement="bottom" classes={{labelPlacementBottom:"mh2"}}
                            /></td>
                            <td><FormControlLabel
                                control={<Checkbox checked={from.includes("int")} onChange={this.onSetOption.bind(this, "int")}/>}
                                label="INT" labelPlacement="bottom" classes={{labelPlacementBottom:"mh2"}}
                            /></td>
                            <td><FormControlLabel
                                control={<Checkbox checked={from.includes("wis")} onChange={this.onSetOption.bind(this, "wis")}/>}
                                label="WIS" labelPlacement="bottom" classes={{labelPlacementBottom:"mh2"}}
                            /></td>
                            <td><FormControlLabel
                                control={<Checkbox checked={from.includes("cha")} onChange={this.onSetOption.bind(this, "cha")}/>}
                                label="CHA" labelPlacement="bottom" classes={{labelPlacementBottom:"mh2"}}
                            /></td>
                        </tr>
                    </tbody>
                </table>:null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </span>;
    }
}

const maxVals = [0,30,29,28,27,26,25,24,23,22,21]
const abilityValues=["-4", "-3", "-2", "-1", "+0", "+1", "+2", "+3", "+4"];
const abilityAdjustValues=["-4", "-3", "-2", "-1", "+1", "+2", "+3", "+4"];

class AbilityRequirements extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
            anchorEl:null, 
        };
    }

    showMenu(event){
        this.setState({ showmenu:true, anchorEl:event.currentTarget, requirements:this.props.requirements });
    }

    handleClose(savechanges, event) {
        if (savechanges){
            this.props.onChange(this.state.requirements);
        }
        this.setState({showmenu:false});
    };

    onChange(event) {
        const {isOr, abilities} = this.getRequirementInfo(this.state.requirements);
        const newA = Object.assign({}, abilities);
        const val = event.target.value;
        if (newA[val]) {
            delete newA[val];
        } else {
            newA[val] = 13;
        }

        if (isOr) {
            this.setState({requirements:{or:[newA]}});
        } else {
            this.setState({requirements:newA});
        }
    }

    onChangeOr() {
        const {isOr, abilities} = this.getRequirementInfo(this.state.requirements);
        const newA = Object.assign({}, abilities);

        if (!isOr) {
            this.setState({requirements:{or:[newA]}});
        } else {
            this.setState({requirements:newA});
        }
    }

    getRequirementInfo(requirements) {
        if (!requirements) {
            return {isOr:false, abilities:{}};
        }
        if (requirements.or && requirements.or[0]) {
            return {isOr:true, abilities:requirements.or[0]};
        }
        return {isOr:false, abilities:requirements}
    }

    render() {
        const {isOr, abilities} = this.getRequirementInfo(this.state.requirements);
        let abilityStr;

        {
            const {isOr, abilities} = this.getRequirementInfo(this.props.requirements);
            const list = [];
            for (let i in abilities) {
                list.push(i);
            }
            if (list.length) {
                abilityStr = "at least 13 in "+list.join(isOr?" or ":" and ");
            } else {
                abilityStr = "(none)";
            }
        }

        return <div className="mv1">
            <span className={this.props.editable?"hover-bg-contrast":""} onClick={this.props.editable?this.showMenu.bind(this):null}><b>Requirements:</b> {abilityStr}</span>
            <Dialog
                open={this.state.showmenu}
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Requirements (min 13)</DialogTitle>
                <DialogContent>
                <SelectVal fullWidth label="Union Type" values={["or", "and"]} value={isOr?"or":"and"} onClick={this.onChangeOr.bind(this)}/>
                <FormControlLabel
                    control={<Checkbox checked={!!abilities["str"]} onChange={this.onChange.bind(this)} value="str" />                    }
                    label="STR"
                />
                <FormControlLabel
                    control={<Checkbox checked={!!abilities["dex"]} onChange={this.onChange.bind(this)} value="dex" />}
                    label="DEX"
                />
                <FormControlLabel
                    control={<Checkbox checked={!!abilities["con"]} onChange={this.onChange.bind(this)} value="con" />                    }
                    label="CON"
                />
                <FormControlLabel
                    control={<Checkbox checked={!!abilities["int"]} onChange={this.onChange.bind(this)} value="int" />}
                    label="INT"
                />
                <FormControlLabel
                    control={<Checkbox checked={!!abilities["wis"]} onChange={this.onChange.bind(this)} value="wis" />}
                    label="WIS"
                />
                <FormControlLabel
                    control={<Checkbox checked={!!abilities["cha"]} onChange={this.onChange.bind(this)} value="cha" />}
                    label="CHA"
                />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>;
    }
}

class ExtraSpells extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    render() {
        let inside = null;
        const {extraSpells, character} = this.props;
        const levels = [];
        const rows = [];
        const translate=this.props.translate||character||emptyTranslate;
        const isBF = (character?character.isBF:campaign.hasBFGamesystem);

        for (let i in extraSpells) {
            const si = campaign.getSpell(extraSpells[i]);
            if (si) {
                if (!levels[si.level]) {
                    levels[si.level]=[];
                }
                if (levels[si.level].length) {
                    levels[si.level].push(", ");
                }
                levels[si.level].push(<LinkHref key={i} href={"#spell?id="+encodeURIComponent(extraSpells[i])}>{si.displayName}</LinkHref>);
            }
        }

        for (let i in levels) {
            if (levels[i]) {
                rows.push(<tr key={i}><td className="col-3 tc">{(i!="0")?Parser.levelToFull(i):translate.t("Cantrip")}</td><td className="col-9">{levels[i]}</td></tr>);
            }
        }

        if (rows.length) {
            inside = <table className="w-100">
                <tbody>
                    <tr className="b">
                        <td className="tc">{translate.t("Spell")} {isBF?"Circle":"Level"}</td>
                        <td>{translate.t("Spells")}</td>
                    </tr>
                    {rows}
                </tbody>
            </table>
        }

        return <div className="mb1 stdcontent">
            {inside}
        </div>;
    }
}

const restValues= {
    1:"recover after a long rest",
    0:"recover after a short rest",
    "-1":"does not recover"
}

const restValuesFull= {
    "long":"recover after a long rest",
    "random":"recover random amount after a long rest",
    "short":"recover after a short rest",
    "none":"does not recover",
}


const selectUsageList = {
    "nousage":"None",
    "baseusage":"Fixed count",
    "abilityusage":"Based on ability score",
    "levelusage":"Based on level",
    "proficiency":"Based on proficiency bonus",
    "calc":"Calculate from metawords"
}

const selectUsageListBase = {
    "nousage":"None",
    "baseusage":"Fixed count",
}


class PickCountsByLevel extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
        };
    }

    showMenu(event){
        this.setState({ showmenu:true, levelsCount:(this.props.levelsCount||[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0])});
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const levelsCount = this.state.levelsCount;
            let allZero=true;
            for (let i=0; i<20; i++){
                if (levelsCount[i]){
                    allZero=false;
                }
            }    
            if (allZero) {
                this.props.onChange(null);
            } else {
                this.props.onChange(this.state.levelsCount);
            }
        }
        if (event) {
            event.stopPropagation();
        }
        this.setState({showmenu:false});
    };

    onChange(level, oldAdjust, newAdjust) {
        const levelsCount = this.state.levelsCount.concat([]);
        const ld = newAdjust-oldAdjust;
        for (let l=level; l< 20; l++) {
            levelsCount[l] = (levelsCount[l]||0) + ld;
        }
        this.setState({levelsCount});
    }

    render() {
        const vals = [];

        if (this.state.showmenu) {
            const levelsCount = this.state.levelsCount||[];
            let base = 0;
            for (let l=0; l < 20; l++) {
                const ld = (levelsCount[l]||0)-base;
                vals.push(<tr key={l}>
                    <td>{l+1}</td>
                    <td>{levelsCount[l]||0}</td>
                    <td>
                        <PickVal value={ld} onClick={this.onChange.bind(this,l, ld)} values={pointsDeltaList}>
                            <span className="hover-bg-contrast ph2">+{ld}</span>
                        </PickVal>
                    </td>
                </tr>);
                base = levelsCount[l]||0;
            }
        }
        
        let r = <span>
            <span>
                <Button size="small" variant="outlined" color="primary" onClick={this.showMenu.bind(this)}>
                    {this.props.label}
                </Button> 
                {this.props.levelsCount?<span> [{this.props.levelsCount.join(",")}]</span>:null}
            </span>
            {this.state.showmenu?<Dialog
                open
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>{this.props.label}</DialogTitle>
                <DialogContent>
                    <table>
                        <tbody className="tc stdcontent">
                            <tr><td>Level</td><td>Count</td><td>adjust</td></tr>
                            {vals}
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;

        if (this.props.noDiv) {
            return r;
        } else {
            return <div className="mb1">{r}</div>
        }
    }
}

class PickCountsByCR extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
        };
    }

    showMenu(event){
        this.setState({ showmenu:true, counts:(this.props.counts||[1,1,1,1,1, 1,1,1,1,1, 1,1,1,1,1, 1,1,1,1,1, 1,1,1,1,1, 1,1,1,1,1, 1,1,1,1,1])});
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const counts = this.state.counts;
            let allDefault=true;
            for (let i=0; i<Parser.CRS.length; i++){
                if (counts[i]>1){
                    allDefault=false;
                }
            }    
            if (allDefault) {
                this.props.onChange(null);
            } else {
                this.props.onChange(this.state.counts);
            }
        }
        if (event) {
            event.stopPropagation();
        }
        this.setState({showmenu:false});
    };

    onChange(level, oldVal, newVal) {
        const counts = this.state.counts.concat([]);
        const ld = newVal-oldVal;
        if (ld > 0) {
            for (let l=0; l<= level; l++) {
                counts[l] = Math.max((counts[l]||1), newVal);
            }
        } else {
            for (let l=level; l<= Parser.CRS.length; l++) {
                counts[l] = Math.min((counts[l]||1), newVal);
            }
        }
        this.setState({counts});
    }

    render() {
        const vals = [];

        if (this.state.showmenu) {
            const counts = this.state.counts||[];
            for (let l in Parser.CRS) {
                vals.push(<tr key={l}>
                    <td>{Parser.CRS[l]}</td>
                    <td>
                        <PickVal value={counts[l]||0} onClick={this.onChange.bind(this,l, counts[l]||1)} values={stdvalues.oneTo50}>
                            <span className="hover-bg-contrast ph2">+{counts[l]||1}</span>
                        </PickVal>
                    </td>
                </tr>);
            }
        }
        
        let r = <span>
            <span>
                <Button size="small" variant="outlined" color="primary" onClick={this.showMenu.bind(this)}>
                    {this.props.label}
                </Button> 
                {this.props.counts?<span> [{this.props.counts.join(",")}]</span>:null}
            </span>
            {this.state.showmenu?<Dialog
                open
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>{this.props.label}</DialogTitle>
                <DialogContent>
                    <table className="w-100">
                        <tbody className="tc stdcontent">
                            <tr><td>CR</td><td>Count</td></tr>
                            {vals}
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;

        if (this.props.noDiv) {
            return r;
        } else {
            return <div className="mb1">{r}</div>
        }
    }
}

class DurationByLevel extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
        };
    }

    showMenu(event){
        this.setState({ showmenu:true, durations:(this.props.durations||[])});
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const {durations} = this.state;
            let allDefault=true;
            for (let i=0; i<=9; i++){
                if (durations[i]){
                    allDefault=false;
                } else {
                    durations[i]=null;
                }
            }
            if (allDefault) {
                this.props.onChange(null);
            } else {
                this.props.onChange(durations);
            }
        }
        if (event) {
            event.stopPropagation();
        }
        this.setState({showmenu:false});
    };

    onChange(level, val) {
        const durations = this.state.durations.concat([]);
        durations[level]=val;
        this.setState({durations});
    }

    render() {
        const vals = [];

        if (this.state.showmenu) {
            const {durations} = this.state;
            for (let l = this.props.startLevel||1; l<=9; l++) {
                vals.push(<div key={l}>
                    <SelectTextVal fullWidth text={durations[l]||""} onChange={this.onChange.bind(this,l)} values={stdvalues.durationSuggestions} helperText={"Level "+l+" duration"}/>
                </div>);
            }
        }
        
        let r = <span>
            <span>
                <Button size="small" variant="outlined" color="primary" onClick={this.showMenu.bind(this)}>
                    {this.props.label}
                </Button> 
                {this.props.durations?<span> durations: [{this.props.durations.join(",")}]</span>:null}
            </span>
            {this.state.showmenu?<Dialog
                open
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>{this.props.label}</DialogTitle>
                <DialogContent>
                    {vals}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;

        if (this.props.noDiv) {
            return r;
        } else {
            return <div className="mb1">{r}</div>
        }
    }
}

const pointsDeltaList = [
    0, 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,900
];

const spellRecoveryOptions={
    long:"Recover after long rest",
    short:"Recover after short rest",
    ritual:"Ritual casting only",
    slot:"Cast using spell/pact slots",
    atwill:"Cast at will",
    uses:"Cast using feature uses",
    longslot:"Cast once per day, then cast using spell/pact slots",
    useslot:"Cast using feature uses, and cast using spell/pact slots",
}

const itemSpellRecoveryOptions={
    day:"Once per day",
    charges:"Cast using charges",
    once:"Once only, never recover",
    ritual:"Ritual casting only",
    atwill:"Cast at will"
}

class PickChooseSpells extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
        };
    }

    showMenu(event){
        this.setState({ showmenu:true, spellPick:(this.props.spellPick||{})});
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const spellPick = this.state.spellPick;

            if (!spellPick.cantrips && !spellPick.spells) {
                this.props.onChange(null);
            } else {
                this.props.onChange(spellPick);
            }
        }
        if (event) {
            event.stopPropagation();
        }
        this.setState({showmenu:false});
    };

    onChangeVal(field, value) {
        const spellPick = Object.assign({}, this.state.spellPick);
        if (value == "all") {
            value = null;
        }
        if (field=="recoveryType") {
            delete spellPick.ritualOnly;
        }
        spellPick[field]=value
        this.setState({spellPick});
    }

    render() {
        const spellPick = this.state.spellPick||{};
        const spellPickProp = this.props.spellPick||{};
        const spellPropRecoveryType = spellPickProp.ritualOnly?"ritual":(spellPickProp.recoveryType||"long");
        const spellRecoveryType = spellPick.ritualOnly?"ritual":(spellPick.recoveryType||"long");
        
        let r = <span>
            <span>
                <Button size="small" variant="outlined" color="primary" onClick={this.showMenu.bind(this)}>
                    {this.props.label}
                </Button> 
                {spellPickProp.cantrips?<span> {spellPickProp.cantrips} Cantrips</span>:null}
                {spellPickProp.spells?<span> {spellPickProp.spells} Level {spellPickProp.spellLevel||"1"} Spells</span>:null}
                {(spellPickProp.spellList||spellPickProp.sources)?<span> from the {getArrayVal(spellPickProp.spellList).concat(getArrayVal(spellPickProp.sources)).join(", ")} spell list</span>:null} {this.props.spellPick?spellRecoveryOptions[spellPropRecoveryType]:null}
            </span>
            {this.state.showmenu?<Dialog
                open
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>{this.props.label}</DialogTitle>
                <DialogContent>
                    <SelectMultiVal value={getArrayVal(spellPick.sources)} values={getAllSpellSources()} helperText="Sources" onChange={this.onChangeVal.bind(this,"sources")} placeholder={spellPick.sources?null:"all"}/>
                    <SelectMultiVal value={getArrayVal(spellPick.spellList)} values={getSpellClassOptions()} helperText="Spell List Class" onChange={this.onChangeVal.bind(this,"spellList")} placeholder={spellPick.spellList?null:"all"}/>
                    <SelectMultiVal value={getArrayVal(spellPick.school)} values={getSchoolsOfMagic()} helperText="School of Magic" onChange={this.onChangeVal.bind(this,"school")} placeholder={spellPick.school?null:"all"}/>
                    <SelectVal className="minw3 mr1" isNum value={spellPick.cantrips||0} values={[0,1,2,3,4]} helperText="Cantrips" onClick={this.onChangeVal.bind(this,"cantrips")}/>
                    <SelectVal className="minw35 mr1" isPossibleNum value={spellPick.spellLevel||1} includeVal="any" values={[1,2,3,4,5,6,7,8,9]} helperText="Spell Level" onClick={this.onChangeVal.bind(this,"spellLevel")}/>
                    <SelectVal className="minw35 mr1" isNum value={spellPick.spells||0} values={[0,1,2,3,4]} helperText="Spells" onClick={this.onChangeVal.bind(this,"spells")}/>
                    <div>
                        <SelectVal className="minw35 mr1" value={spellRecoveryType} values={spellRecoveryOptions} helperText="Recovery" onClick={this.onChangeVal.bind(this,"recoveryType")}/>
                        {(spellRecoveryType=="uses")?<SelectVal isNum helperText="Use Count" value={spellPick.useCount||1} values={getNumberArray(9)} onClick={this.onChangeVal.bind(this, "useCount")}/>:null}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;

        if (this.props.noDiv) {
            return r;
        } else {
            return <div className="mb1">{r}</div>
        }
    }

}

function getSpellClassOptions() {
    const spellList = campaign.getSpellListByName();
    const classes = [];

    for (let i in spellList){
        const s=spellList[i];
        for (let x in s.classes) {
            const className = s.classes[x];
            if (!classes.includes(className)) {
                classes.push(className);
            }
        }
    }

    classes.sort();
    return classes;
}

class PickAddSpellSlots extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
        };
    }

    showMenu(event){
        this.setState({ showmenu:true, addSpellSlots:(this.props.addSpellSlots||{})});
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const addSpellSlots = this.state.addSpellSlots;
            let someVal = addSpellSlots.pactSlots;

            for (let i=0; i<9; i++){
                someVal |= (addSpellSlots.slots||[])[i];
            }
            
            if (!someVal) {
                this.props.onChange(null);
            } else {
                this.props.onChange(addSpellSlots);
            }
        }
        if (event) {
            event.stopPropagation();
        }
        this.setState({showmenu:false});
    };

    onChangeVal(field, value) {
        const addSpellSlots = Object.assign({}, this.state.addSpellSlots);
        if (value == "all") {
            value = null;
        }
        addSpellSlots[field]=value
        this.setState({addSpellSlots});
    }

    onChangeIndexVal(index, value) {
        const slots = (this.state.addSpellSlots.slots||[0,0,0,0,0,0,0,0,0]).concat([]);
        slots[index]=value;
        this.onChangeVal("slots",slots);
    }

    render() {
        const addSpellSlots = this.state.addSpellSlots||{};
        const addSpellSlotsProp = this.props.addSpellSlots||{};
        const slotCounts = [0,1,2,3,4,5,6,7,8,9];
        const slots = addSpellSlots.slots||[];
        
        let r = <span>
            <span>
                <Button size="small" variant="outlined" color="primary" onClick={this.showMenu.bind(this)}>
                    {this.props.label}
                </Button> 
                {this.props.addSpellSlots?<span>
                    Spell slots {(addSpellSlotsProp.slots||[]).join(", ")} Pact slots {addSpellSlotsProp.pactSlots}
                </span>:null}
            </span>
            {this.state.showmenu?<Dialog
                open
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>{this.props.label}</DialogTitle>
                <DialogContent>
                    <div>
                        <div className="f3 b mb1">Spell Slots</div>
                        <SelectVal className="ml1" value={slots[0]||0} isNum values={slotCounts} helperText="1" onClick={this.onChangeIndexVal.bind(this,0)}/>
                        <SelectVal className="ml1" value={slots[1]||0} isNum values={slotCounts} helperText="2" onClick={this.onChangeIndexVal.bind(this,1)}/>
                        <SelectVal className="ml1" value={slots[2]||0} isNum values={slotCounts} helperText="3" onClick={this.onChangeIndexVal.bind(this,2)}/>
                        <SelectVal className="ml1" value={slots[3]||0} isNum values={slotCounts} helperText="4" onClick={this.onChangeIndexVal.bind(this,3)}/>
                        <SelectVal className="ml1" value={slots[4]||0} isNum values={slotCounts} helperText="5" onClick={this.onChangeIndexVal.bind(this,4)}/>
                        <SelectVal className="ml1" value={slots[5]||0} isNum values={slotCounts} helperText="6" onClick={this.onChangeIndexVal.bind(this,5)}/>
                        <SelectVal className="ml1" value={slots[6]||0} isNum values={slotCounts} helperText="7" onClick={this.onChangeIndexVal.bind(this,6)}/>
                        <SelectVal className="ml1" value={slots[7]||0} isNum values={slotCounts} helperText="8" onClick={this.onChangeIndexVal.bind(this,7)}/>
                        <SelectVal className="ml1" value={slots[8]||0} isNum values={slotCounts} helperText="9" onClick={this.onChangeIndexVal.bind(this,8)}/>
                    </div>
                    <div>
                        <SelectVal className="minw3 ml1" value={addSpellSlots.pactSlots||0} isNum values={slotCounts} helperText="Pact Slots" onClick={this.onChangeVal.bind(this,"pactSlots")}/>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;

        if (this.props.noDiv) {
            return r;
        } else {
            return <div className="mb1">{r}</div>
        }
    }
}

class PickCustomTable extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
        };
    }

    showMenu(event){
        this.setState({ showmenu:true, customPick:(this.props.customPick||{})});
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const customPick = this.state.customPick;

            if (!customPick.count || !customPick.customTable) {
                this.props.onChange(null);
            } else {
                this.props.onChange(customPick);
            }
        }
        if (event) {
            event.stopPropagation();
        }
        this.setState({showmenu:false});
    };

    onChangeVal(field, value) {
        const customPick = Object.assign({}, this.state.customPick);
        if ((field!="keywords") && ["all","any"].includes(value)) {
            value = null;
        }
        if (((field == "customTable")  && (value!=customPick.value)) || ((field == "count") && !value)) {
            delete customPick.customOptions;
        }
        customPick[field]=value
        if (customPick.customOptions) {
            customPick.count= Math.min(customPick.count||1, customPick.customOptions.length);
        }
        this.setState({customPick});
    }

    onCloseCustomPicker(selectedCustom) {
        if (selectedCustom) {
            if (Object.keys(selectedCustom).length==0) {
                this.onChangeVal("customOptions", null);
            } else {
                this.onChangeVal("customOptions", Object.keys(selectedCustom));
            }
        }
        this.setState({showCustomPicker:false});
    }

    showPickCustom() {
        this.setState({showCustomPicker:true});
    }

    render() {
        const customPick = this.state.customPick;
        const customPickProp = this.props.customPick||{};
        const {CustomPicker} = require('./customtable.jsx');
        let selected = {};
        if (customPick && customPick.customOptions) {
            for (let i in customPick.customOptions) {
                selected[customPick.customOptions[i]]=1;
            }
        }
        
        let r = <span>
            <span>
                <Button size="small" variant="outlined" color="primary" onClick={this.showMenu.bind(this)}>
                    {this.props.label}
                </Button> 
                {customPickProp.count?<span> Pick {customPickProp.count}</span>:null}
                {customPickProp.customTable?<span> from the {customPickProp.customTable} custom table</span>:null}
                {customPickProp.keywords?<span> matching keywords {customPickProp.keywords}</span>:null}
            </span>
            {this.state.showmenu?<Dialog
                open
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>{this.props.label}</DialogTitle>
                <DialogContent>
                    <SelectVal className="minw4" value={customPick.customTable||""} values={campaign.getCustomTablesList()} label="Custom Table" onClick={this.onChangeVal.bind(this,"customTable")}/>&nbsp;
                    <SelectVal className="minw3" isNum value={customPick.count||0} values={stdvalues.zeroTo20} label="Count" onClick={this.onChangeVal.bind(this,"count")}/>
                    <div>
                        <TextVal fullWidth multiline rowsMax={3} className="mv1" text={customPick.keywords||""} onChange={this.onChangeVal.bind(this,"keywords")} helperText="Match Keywords (comma separated)"/>
                    </div>
                    <div>
                        <Button disabled={!customPick.customTable} size="small" variant="outlined" color="primary" onClick={this.showPickCustom.bind(this)}>
                            Restrict Selection Options
                        </Button>
                    </div>
                    <div className="pt1">
                        <SelectVal value={customPick.gamesystemPref||"any"} values={gamesystemAnyOptions} onClick={this.onChangeVal.bind(this,"gamesystemPref")} helperText="Preferred Game System"/>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
                <CustomPicker 
                    open={this.state.showCustomPicker} 
                    type={customPick.customTable}
                    selected={selected} 
                    onClose={this.onCloseCustomPicker.bind(this)}
                />
            </Dialog>:null}
        </span>;

        if (this.props.noDiv) {
            return r;
        } else {
            return <div className="mb1">{r}</div>
        }
    }
}

class PickCustomMod extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
        };
    }

    showMenu(event){
        this.setState({ showmenu:true, customPick:(this.props.customPick||{})});
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const customPick = this.state.customPick;

            if (!customPick.keywords || !customPick.customTable) {
                this.props.onChange(null);
            } else {
                this.props.onChange(customPick);
            }
        }
        if (event) {
            event.stopPropagation();
        }
        this.setState({showmenu:false});
    };

    onChangeVal(field, value) {
        const customPick = Object.assign({}, this.state.customPick);
        customPick[field]=value
        this.setState({customPick});
    }

    render() {
        const customPick = this.state.customPick;
        const customPickProp = this.props.customPick||{};
        
        let r = <span>
            <span>
                <Button size="small" variant="outlined" color="primary" onClick={this.showMenu.bind(this)}>
                    Custom Pick Mod
                </Button> 
                {customPickProp.customTable?<span> from the {customPickProp.customTable} custom table</span>:null}
                {customPickProp.keywords?<span> additional keywords {customPickProp.keywords}</span>:null}
            </span>
            {this.state.showmenu?<Dialog
                open
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Custom Pick Modifier</DialogTitle>
                <DialogContent>
                    <SelectVal className="minw4" value={customPick.customTable||""} values={campaign.getCustomTablesList()} label="Custom Table" onClick={this.onChangeVal.bind(this,"customTable")}/>&nbsp;
                    <div>
                        <TextVal fullWidth multiline rowsMax={3} className="mv1" text={customPick.keywords||""} onChange={this.onChangeVal.bind(this,"keywords")} helperText="Match Keywords (comma separated)"/>
                    </div>
                    <div className="hk-well">
                        Custom pick options for the same table will add these keywords to the matching keywords.
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;

        if (this.props.noDiv) {
            return r;
        } else {
            return <div className="mb1">{r}</div>
        }
    }
}

class UnarmedAttack extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
        };
    }

    showMenu(event){
        this.setState({ showmenu:true, unarmedAttack:(this.props.unarmedAttack||{})});
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const unarmedAttack = this.state.unarmedAttack;

            if ((unarmedAttack.name||"")=="" && (unarmedAttack.damage||"")=="") {
                this.props.onChange(null);
            } else {
                this.props.onChange(unarmedAttack);
            }
        }
        if (event) {
            event.stopPropagation();
        }
        this.setState({showmenu:false});
    };

    onChangeVal(field, value) {
        const unarmedAttack = Object.assign({}, this.state.unarmedAttack);
        if (value=="" || value=="none") {
            value=null;
        }
        unarmedAttack[field]=value
        this.setState({unarmedAttack});
    }

    onChange(field, event) {
        const value = event.target.value;
        this.onChangeVal(field, value);
    }

    render() {
        const unarmedAttack = this.state.unarmedAttack;
        const unarmedAttackProp = this.props.unarmedAttack||{};
        
        let r = <span>
            <span>
                <Button size="small" variant="outlined" color="primary" onClick={this.showMenu.bind(this)}>
                    {this.props.label}
                </Button> 
                {unarmedAttackProp.name?<span> {unarmedAttackProp.name}</span>:null}
                {unarmedAttackProp.damage?<span> with damage <b>{unarmedAttackProp.damage}</b></span>:null}
                {unarmedAttackProp.damageType?<span> of type {unarmedAttackProp.damageType}</span>:null}
            </span>
            {this.state.showmenu?<Dialog
                open
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>{this.props.label}</DialogTitle>
                <DialogContent>
                    <div className="flex items-end">
                        <TextVal helperText="Unarmed Attack"
                            className="minw5 mv0"
                            text={unarmedAttack.name||""}
                            onChange={this.onChangeVal.bind(this, "name")}
                        />&nbsp;
                        <TextVal
                            helperText="Damage"
                            className="minw4 mv0"
                            text={unarmedAttack.damage||""}
                            onChange={this.onChangeVal.bind(this, "damage")}
                        />&nbsp;
                        <SelectVal className="minw4" value={unarmedAttack.damageType||"bludgeoning"} values={stdvalues.damageTypesList} helperText="Damage Type" onClick={this.onChangeVal.bind(this,"damageType")}/>
                    </div>
                    <div className="mt1">
                        <CheckVal value={unarmedAttack.finesse} onChange={this.onChangeVal.bind(this, "finesse")} label="Treat as Finesse"/>
                        <SimpleCheckPick onChange={this.onChangeVal.bind(this,"ability")} value={unarmedAttack.ability} values={stdvalues.abilityNames} label="Ability Bonus Override" editable useButton/>
                    </div>
                    <div>
                        <div className="titlecolor titletext mt1">Extra Notes</div>
                        <EntityEditor onChange={this.onChangeVal.bind(this,"extraNotes")} entry={unarmedAttack.extraNotes}/>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;

        if (this.props.noDiv) {
            return r;
        } else {
            return <div className="mb1">{r}</div>
        }
    }
}

class AltAttack extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
        };
    }

    showMenu(event){
        this.setState({ showmenu:true, altAttack:(this.props.altAttack||{})});
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const altAttack = this.state.altAttack;

            if (((altAttack.effect||"")=="") && !altAttack.extraDamage && !altAttack.save && !altAttack.conditions && !altAttack.temphp) {
                this.props.onChange(null);
            } else {
                this.props.onChange(altAttack);
            }
        }
        if (event) {
            event.stopPropagation();
        }
        this.setState({showmenu:false});
    };

    onChangeVal(field, value) {
        const altAttack = Object.assign({}, this.state.altAttack);
        if (value=="" || value=="none") {
            value=null;
        }
        altAttack[field]=value
        this.setState({altAttack});
    }

    onChange(field, event) {
        const value = event.target.value;
        this.onChangeVal(field, value);
    }

    render() {
        const altAttack = this.state.altAttack;
        const altAttackProp = this.props.altAttack||{};
        
        let r = <span>
            <span>
                <Button size="small" variant="outlined" color="primary" onClick={this.showMenu.bind(this)}>
                    {this.props.label}
                </Button> 
                {altAttackProp.effect?<span> with effect <b>{altAttackProp.effect}</b></span>:null}
                {altAttackProp.damageType?<span> of type {altAttackProp.damageType}</span>:null}
            </span>
            {this.state.showmenu?<Dialog
                open
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>{this.props.label}</DialogTitle>
                <DialogContent>
                    <div className="flex items-end">
                        <TextVal
                            helperText="Effect"
                            className="minw4 mv0"
                            text={altAttack.effect||""}
                            onChange={this.onChangeVal.bind(this, "effect")}
                        />&nbsp;
                        <SelectVal className="minw4" includeVal="none" value={altAttack.damageType||"none"} values={stdvalues.damageTypesList.concat(stdvalues.extraEffectsList)} helperText="Effect Type" onClick={this.onChangeVal.bind(this,"damageType")}/>
                    </div>
                    <div>
                        <CheckVal 
                            onChange={this.onChangeVal.bind(this,"showAttack")}
                            value={altAttack.showAttack}
                            label="Show attack roll"
                        />
                    </div>
                    <div>
                        <CheckVal 
                            onChange={this.onChangeVal.bind(this,"includeBaseDamage")}
                            value={altAttack.includeBaseDamage}
                            label="Include base damage"
                        />
                    </div>
                    <div>
                        <ExtraDamage damage={altAttack.extraDamage} onChange={this.onChangeVal.bind(this,"extraDamage")}/>
                    </div>
                    <div className="flex">
                        <SelectVal fullWidth selectClass=" " value={altAttack.save||"none"} includeVal="none" values={stdvalues.abilityNames} helperText="Save Type" onClick={this.onChangeVal.bind(this, "save")}/>
                        {altAttack.save?<SelectVal fullWidth className="ml1" selectClass=" " isNum value={altAttack.saveDC||1} values={stdvalues.oneTo20} helperText="Save DC" onClick={this.onChangeVal.bind(this, "saveDC")}/>:null}
                    </div>
                    <div className="mb1">
                        <ActionConditions conditions={altAttack.conditions} onChange={this.onChangeVal.bind(this,"conditions")}/>
                    </div>
                    <div className="mb1">
                        <TempHPDamage temphp={altAttack.temphp} onChange={this.onChangeVal.bind(this,"temphp")}/>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;

        if (this.props.noDiv) {
            return r;
        } else {
            return <div className="mb1">{r}</div>
        }
    }
}

class CheckPick extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
        };
    }

    showMenu(event){
        if (this.props.editable) {
            const state = normalizeValue(this.props.value);
            state.showmenu = true;
            this.setState(state);
        }
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            let ret = {};

            switch (this.state.type) {
                case "choose 1":
                case "choose 2":
                case "choose 3":
                case "choose 4": {
                    const choose = Number(this.state.type.substr(7));
                    if (choose && this.state.values.length) {
                        ret.choose = choose;
                        ret.from = this.state.values;
                        if (this.state.proficient != "proficient") {
                            ret.proficient = this.state.proficient;
                        }
                        if (this.state.defaults.length) {
                            ret.defaults = this.state.defaults;
                        }
                        break;
                    }
                    // fall through
                }
                case "defaults": {
                    if (!this.state.defaults.length || this.state.proficient == "proficient") {
                        if (this.state.defaults.length) {
                            ret = this.state.defaults;
                        } else {
                            ret = null;
                        }
                    } else {
                        ret.defaults = this.state.defaults;
                        ret.proficient = this.state.proficient;
                    }
                    break;
                }

                case "any 1":
                case "any 2":
                case "any 3":
                case "any 4": {
                    ret.choose = Number(this.state.type.substr(4));
                    if (this.state.proficient != "proficient") {
                        ret.proficient = this.state.proficient;
                    }
                    if (this.state.defaults.length) {
                        ret.defaults = this.state.defaults;
                    }
                    break;
                }
            }

            this.props.onChange(ret);
        }
        if (event) {
            event.stopPropagation();
        }
        this.setState({showmenu:false});
    };

    onChangeOptions(event) {
        const values = this.state.values.concat([]);
        const val = event.target.value;
        const pos = values.indexOf(val);

        if (pos >=0) {
            values.splice(pos, 1);
        } else {
            values.push(val);
        }
        this.setState({values});
    }

    onChangeDefaults(event) {
        const defaults = this.state.defaults.concat([]);
        const val = event.target.value;
        const pos = defaults.indexOf(val);

        if (pos >=0) {
            defaults.splice(pos, 1);
        } else {
            defaults.push(val);
        }
        this.setState({defaults});
    }

    onChangeType(type) {
        const defaults = this.state.defaults.concat([]);
        const pos = defaults.indexOf("All Proficient");
        if ((pos>=0)&&(type != "expert")) {
            defaults.splice(pos,1);
        }
        this.setState({type,defaults});
    }

    onChangeProficiency(proficient) {
        this.setState({proficient});
    }

    onClearAll() {
        this.setState({values:[]});
    }

    render() {
        const {values, defaults, type } = normalizeValue(this.props.value);
        const vals = [];
        const defaultVals = [];
        const extraOptionVals = this.state.extraOptionVals;
        let options = this.props.options;
        let text = (Array.isArray(options)?defaults:defaults.map(function(a){return options[a]||""})).join(", ");

        if (type != "defaults") {
            if (text.length) {
                text = text+" and "+type +" "+values.join(", ");
            } else {
                text = type +" "+values.join(", ");
            }
        }

        if (this.state.showmenu) {
            const values = this.state.values;
            const defaults = this.state.defaults;
            const showVals = this.state.type.startsWith("choose");
            if (extraOptionVals) {
                options = options.concat(extraOptionVals);
            }

            if ((this.state.proficient == "expert") && this.props.showAllProficient) {
                defaultVals.push(<div key="All Proficient" className="w-100">
                    <FormControlLabel 
                        control={<Checkbox checked={defaults.includes("All Proficient")} onChange={this.onChangeDefaults.bind(this)} value="All Proficient"/>}
                        label="All Proficient"
                    />
                </div>);
            }

            for (let i in options) {
                let v;
                let o = options[i];
                let bold = false;

                if (o.startsWith("*")) {
                    bold=true;
                    o=o.substr(1);
                }

                if (Array.isArray(options)) {
                    v=o;
                } else {
                    v=i;
                }

                if (showVals) {
                    vals.push(<div key={i} className={bold?"w-100":"w-25 minw5"}>
                        <FormControlLabel 
                            classes={{label:bold?"b":""}}
                            control={<Checkbox checked={values.includes(v)} onChange={this.onChangeOptions.bind(this)} value={v}/> }
                            label={o}
                        />
                    </div>);
                }
                defaultVals.push(<div key={i} className={bold?"w-100":"w-25 minw5"}>
                    <FormControlLabel 
                        classes={{label:bold?"b":""}}
                        control={<Checkbox checked={defaults.includes(v)} onChange={this.onChangeDefaults.bind(this)} value={v}/> }
                        label={o}
                    />
                </div>);
            }
            if (showVals) {
                for (let i in values) {
                    const v = values[i];
                    if (!options.includes(v) && !options.includes("*"+v)){
                        vals.push(<div key={"sv"+i} className="w-25 minw5">
                            <FormControlLabel 
                                classes={{label:""}}
                                control={<Checkbox checked onChange={this.onChangeOptions.bind(this)} value={v}/> }
                                label={v}
                            />
                        </div>);
                    }
                }
            }
            if (Array.isArray(options)) {
                for (let i in defaults) {
                    const v = defaults[i];

                    if (!options.includes(v) && !options.includes("*"+v) && (v!="All Proficient")) {
                        defaultVals.push(<div key={"dv"+i} className="w-25 minw5">
                            <FormControlLabel 
                                classes={{label:""}}
                                control={<Checkbox checked onChange={this.onChangeDefaults.bind(this)} value={v}/> }
                                label={v}
                            />
                        </div>);
                    }
                }
            }
        }
        
        let r = <span>
            {this.props.useButton?
                <span>
                    <Button className="minw2" size="small" variant="outlined" color="primary" onClick={this.props.editable?this.showMenu.bind(this):null}>
                        {this.props.label}
                    </Button> {text}
                </span>
            :
                <span className={this.props.editable?"hover-bg-contrast":""} onClick={this.props.editable?this.showMenu.bind(this):null}>
                    <b>{this.props.label} </b>
                    {text}
                </span>
            }
            {this.props.children}
            {this.state.showmenu?<Dialog
                open
                maxWidth="md"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>{this.props.label}</DialogTitle>
                <DialogContent>
                    {this.props.showProficiency?<div className="mb2"><SelectVal fullWidth label="Proficiency Type" values={proficiencyValues} value={this.state.proficient} onClick={this.onChangeProficiency.bind(this)}/></div>:null}
                    {!this.props.noChooseOption?<SelectVal fullWidth label="Select Type" values={checkOptions} value={this.state.type} onClick={this.onChangeType.bind(this)}/>:null}
                    {(vals.length && !this.props.noChooseOption)?<div>
                        <div className="f3 b mv1">Options</div>
                        <div className="flex flex-wrap">{vals}</div>
                        <div className="f3 b mv1">Default Values</div>
                    </div>:null}
                    <div className="flex flex-wrap">{defaultVals}</div>
                </DialogContent>
                <DialogActions>
                    {this.props.allowExtension?<Button onClick={this.showExtra.bind(this)} color="primary">
                        Extend
                    </Button>:null}
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
                <TextBasicEdit show={this.state.showExtra} label="Extra Values" text={(extraOptionVals||[]).join(", ")} onChange={this.onChangeExtra.bind(this)}/>
            </Dialog>:null}
        </span>;

        if (this.props.noDiv) {
            return r;
        } else {
            return <div className="mb1">{r}</div>
        }
    }

    showExtra() {
        this.setState({showExtra:true});
    }

    onChangeExtra(extra) {
        if (extra) {
            const extraOptionVals = extra.split(",").map(function (a){return a.trim()});
            this.setState({showExtra:false,extraOptionVals});
        } else {
            this.setState({showExtra:false});
        }
    }
}

class SimpleCheckPick extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
        };
    }

    showMenu(event){
        if (this.props.editable) {
            const value=this.props.value;
            this.setState({showmenu:true, value:value?Array.isArray(value)?value:[value]:[]});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const len = this.state.value.length;
            this.props.onChange(len?(((len > 1)||this.props.alwaysArray)?this.state.value:this.state.value[0]):null);
        }
        if (event) {
            event.stopPropagation();
        }
        this.setState({showmenu:false});
    };

    handleClick(set) {
        const value = this.state.value.concat([]);
        const pos=value.indexOf(set);
        if (pos >=0) {
            value.splice(pos,1);
        } else {
            value.push(set);
        }
        this.setState({value});
    }

    clickNum(num) {
        const value = this.state.value.concat([]);
        let pos = -1;
        for (let i in value) {
            const n = value[i];
            if (!isNaN(n)) {
                pos=i
            }
        }
        if (pos >=0) {
            value.splice(pos,1);
        }

        if (num) {
            value.push(num);
        }
        this.setState({value});
    }

    render() {
        const text = this.props.value?(Array.isArray(this.props.value)?this.props.value.join(", "):this.props.value):null;

        const r = <span>
            {this.props.useButton?
                <span>
                    <Button className="minw2" size="small" variant="outlined" color="primary" onClick={this.props.editable?this.showMenu.bind(this):null}>
                        {this.props.label}
                    </Button> {text?this.props.prefix:null} {text}
                </span>
            :
                <span className={this.props.editable?"hover-bg-contrast":""} onClick={this.props.editable?this.showMenu.bind(this):null}>
                    <b>{this.props.label} </b>
                    {text?this.props.prefix:null} {text}
                </span>
            }
            {this.props.children}
            {this.state.showmenu?<Dialog
                open
                maxWidth="md"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>{this.props.label}</DialogTitle>
                <DialogContent>
                    <div className="flex flex-wrap items-start">{this.getList()}</div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;

        if (this.props.noDiv) {
            return r;
        } else {
            return <div className="mb1">{r}</div>
        }
    }

    getList(){
        const {values, includeNum, numLabel}=this.props;
        const curValue = this.state.value;
        let i;
        let ret=[];
        const isArray = Array.isArray(values);
        const found={};
    
        for (i in values){
            const v= values[i];
            let name, value;

            if (typeof v === 'object') {
                name=v.name;
                value=v.value;
            } else {
                if (isArray) {
                    name=value=v;
                } else {
                    name = v;
                    value = i;
                }
            }
        
            if (!found[name]) {
                ret.push(<CheckVal 
                    className="mr2"
                    key={value}
                    onChange={this.handleClick.bind(this,value)}
                    value={curValue.includes(value)}
                    label={name}
                />);
                found[name]=true;
            }
        }
        if (includeNum) {
            let nv = 0;
            for (let n of curValue) {
                if (!isNaN(n)) {
                    nv = Number(n);
                }
            }
            ret.push(<div key=".n" className="mt--3"><SelectVal selectClass=" " value={nv} isNum values={stdvalues.zeroTo20} onClick={this.clickNum.bind(this)} helperText={numLabel||"Number"}/></div>);
        }

        return ret;
    }

}

class Attunement extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
        };
    }

    showMenu(event){
        if (this.props.editable) {
            this.setState({showmenu:true, attunement:this.props.attunement||{}});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const a = this.state.attunement;
            this.props.onChange((a.extra||a.saveBonus)?a:null);
        }
        if (event) {
            event.stopPropagation();
        }
        this.setState({showmenu:false});
    };

    setValue(prop,value) {
        const attunement = Object.assign({}, this.state.attunement);
        attunement[prop]=value;
        this.setState({attunement})
    }

    render() {
        const ap = this.props.attunement||{};
        const a = this.state.attunement;

        const r = <span>
            {this.props.useButton?
                <span>
                    <Button className="minw2" size="small" variant="outlined" color="primary" onClick={this.props.editable?this.showMenu.bind(this):null}>
                        {this.props.label}
                    </Button> {ap.extra?"Extra attune: "+ap.extra:null} {ap.saveBonus?"+1 bonus to saving throw for each item attuned":null}
                </span>
            :
                <span className={this.props.editable?"hover-bg-contrast":""} onClick={this.props.editable?this.showMenu.bind(this):null}>
                    <b>{this.props.label} </b>
                    {ap.extra?"Extra attune: "+ap.extra:null} {ap.saveBonus?"+1 bonus to saving throw for each item attuned":null}
                </span>
            }
            {this.props.children}
            {this.state.showmenu?<Dialog
                open
                maxWidth="md"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>{this.props.label}</DialogTitle>
                <DialogContent>
                    <div>
                        <SelectVal value={a.extra||0} isNum values={[0,1,2,3,4]} onClick={this.setValue.bind(this,"extra")} helperText="Additional attunement slots"/>
                    </div>
                    <div>
                        <CheckVal value={a.saveBonus||0} onChange={this.setValue.bind(this,"saveBonus")} label="+1 bonus to saving throw for each item attuned"/>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;

        if (this.props.noDiv) {
            return r;
        } else {
            return <div className="mb1">{r}</div>
        }
    }
}

class BonusCheckPick extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
        };
    }

    showMenu(event){
        if (this.props.editable) {
            const value=this.props.value;
            this.setState({showmenu:true, bonus:this.props.bonus, value:this.props.value||[]});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const len = this.state.value.length;
            const value = len?this.state.value:null;
            this.props.onChange(this.state.bonus, value);
        }
        if (event) {
            event.stopPropagation();
        }
        this.setState({showmenu:false});
    };

    handleClick(set) {
        const value = this.state.value.concat([]);
        const pos=value.indexOf(set);
        if (pos >=0) {
            value.splice(pos,1);
        } else {
            value.push(set);
        }
        this.setState({value});
    }

    onChangeBonus(bonus) {
        this.setState({bonus});
    }

    render() {
        const text = this.props.value?(Array.isArray(this.props.value)?this.props.value.join(", "):this.props.value):null;

        const r = <span>
            {this.props.useButton?
                <span>
                    <Button className="minw2" size="small" variant="outlined" color="primary" onClick={this.props.editable?this.showMenu.bind(this):null}>
                        {this.props.label}
                    </Button> {this.props.bonus?this.props.prefix:null} {this.props.bonus||null} {this.props.bonus&&text?("with "+text):null}
                </span>
            :
                <span className={this.props.editable?"hover-bg-contrast":""} onClick={this.props.editable?this.showMenu.bind(this):null}>
                    <b>{this.props.label} </b>
                    {this.props.bonus?this.props.prefix:null} {this.props.bonus||null} {this.props.bonus&&text?("with "+text):null}
                </span>
            }
            {this.props.children}
            {this.state.showmenu?<Dialog
                open
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>{this.props.label}</DialogTitle>
                <DialogContent>
                    <div>
                        <SelectVal fullWidth value={this.state.bonus||0} values={bonusValuesWithAbilities} isPossibleNum onClick={this.onChangeBonus.bind(this)} label="Bonus"/>
                    </div>
                    {!this.state.value.length?<div className="mv1">All values will receive the bonus.</div>:null}
                    <div className="flex flex-wrap">{this.getList()}</div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;

        if (this.props.noDiv) {
            return r;
        } else {
            return <div className="mb1">{r}</div>
        }
    }

    getList(){
        const values=this.props.values;
        const curValue = this.state.value;
        let i;
        let ret=[];
        const isArray = Array.isArray(values);
        const found={};
    
        for (i in values){
            const v= values[i];
            let name, value;

            if (typeof v === 'object') {
                name=v.name;
                value=v.value;
            } else {
                if (isArray) {
                    name=value=v;
                } else {
                    name = v;
                    value = i;
                }
            }
        
            if (!found[name]) {
                ret.push(<CheckVal 
                    className="ml2 w-50 minw4 flex-auto"
                    key={value}
                    onChange={this.handleClick.bind(this,value)}
                    value={curValue.includes(value)}
                    label={name}
                />);
                found[name]=true;
            }
        }
        return ret;
    }

}

class ItemsAdjust extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
        };
    }

    showMenu(event){
        this.setState({showmenu:true, itemmod:this.props.itemmod||{}});
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            this.props.onChange((savechanges!="remove")?this.state.itemmod:null);
        }
        if (event) {
            event.stopPropagation();
        }
        this.setState({showmenu:false});
    };

    onChangeProp(prop, val) {
        const itemmod = Object.assign({}, this.state.itemmod);
        if (val==="") {
            val=null;
        }
        itemmod[prop] = val;
        this.setState({itemmod});
    }

    onChangeItemmod(itemmod) {
        this.setState({itemmod});
    }

    render() {
        const itemmod = this.state.itemmod;

        let r = <span>
            <Button className="minw2" size="small" variant="outlined" color="primary" onClick={this.showMenu.bind(this)}>
                Item Modifiers
            </Button>&nbsp;
            {getItemmodDescription(this.props.itemmod)}
            {this.props.children}
            {this.state.showmenu?<Dialog
                open
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Item Modifiers</DialogTitle>
                <DialogContent>
                    <div className="stdcontent">
                        <h2>Item Filter</h2>
                        <div className="hk-well mb1">Item filter determines which items will have a modified behavior.</div>
                        <ItemFilter filter={itemmod} onChange={this.onChangeItemmod.bind(this)}/>
                        <h2>Item Modifiers</h2>
                        <div className="hk-well mb1">Item modifiers determine how the items will be modified when used.</div>
                        <div className="mb1">
                            <PickVal values={bonusValuesWithAbilitiesAndProficiency} isPossibleNum onClick={this.onChangeProp.bind(this, "attackBonus")} label="To Hit Bonus" size="small" variant="outlined" color="primary"/>
                            {itemmod.attackBonus?(" "+signedMod(itemmod.attackBonus)+" To Hit Bonus"):""}
                        </div>
                        <div className="mb1">
                            <PickVal values={bonusValues} isNum onClick={this.onChangeProp.bind(this, "damageBonus")} label="Damage Bonus" size="small" variant="outlined" color="primary"/>
                            {itemmod.damageBonus?(" "+signedNum(itemmod.damageBonus)+" Damage Bonus"):""}
                        </div>
                        <div className="mb1">
                            <ExtraDamage damage={itemmod.extraDamage} onChange={this.onChangeProp.bind(this,"extraDamage")}/>
                        </div>
                        <div className="mb1">
                            <PickVal values={bonusValues} isNum onClick={this.onChangeProp.bind(this, "acBonus")} label="AC Bonus" size="small" variant="outlined" color="primary"/>
                            {itemmod.acBonus?(" "+signedNum(itemmod.acBonus)+" AC Bonus"):""}
                        </div>
                        <div className="mb1">
                            <PickVal values={attackAbilities} onClick={this.onChangeProp.bind(this, "attackAbility")} label="Attack Ability" size="small" variant="outlined" color="primary"/>
                            {itemmod.attackAbility?(" You can use your "+attackAbilities[itemmod.attackAbility]+" modifier for attack and damage rolls instead of Strength or Dexterity."):null}
                        </div>
                        <div>
                            <CheckVal value={itemmod.secondWeaponBonus||false} onChange={this.onChangeProp.bind(this, "secondWeaponBonus")} label="Second Weapon Ability Bonus"/>
                            {itemmod.secondWeaponBonus?" - Add ability modifier to the damage of the off-hand attack.":null}
                        </div>
                        <div>
                            <CheckVal value={itemmod.finesse||false} onChange={this.onChangeProp.bind(this, "finesse")} label="Finesse Weapon"/>
                            {itemmod.finesse?" - has the finesse property.":null}
                        </div>
                        <div>
                            <div className="titlecolor mt1">Extra Notes</div>
                            <EntityEditor onChange={this.onChangeProp.bind(this,"extraNotes")} entry={itemmod.extraNotes}/>
                        </div>
                        <div>
                            <div className="titlecolor mt1 titleborder bb">Add Item Effect</div>
                            <div className="flex items-end">
                                <TextVal
                                    helperText="Effect"
                                    fullWidth
                                    className="minw4 mv0"
                                    text={itemmod.altEffect||""}
                                    onChange={this.onChangeProp.bind(this, "altEffect")}
                                />&nbsp;
                                <SelectVal className="minw4" includeVal="none" fullWidth value={itemmod.altDamageType||"none"} values={stdvalues.damageTypesList.concat(stdvalues.extraEffectsList)} helperText="Effect Type" onClick={this.onChangeProp.bind(this,"altDamageType")}/>
                            </div>
                            <div>
                                <CheckVal value={itemmod.altIncludeDamageBonus||false} onChange={this.onChangeProp.bind(this, "altIncludeDamageBonus")} label="Include weapon damage bonus"/>
                            </div>
                            <div>
                                <ExtraDamage damage={itemmod.altExtraDamage} onChange={this.onChangeProp.bind(this,"altExtraDamage")}/>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, "remove")} color="primary">
                        Remove
                    </Button>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;

        if (this.props.noDiv) {
            return r;
        } else {
            return <div className="mb1">{r}</div>
        }
    }
}

class SpellAdjust extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
        };
    }

    showMenu(event){
        this.setState({showmenu:true, mod:this.props.mod||{}});
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            this.props.onChange((savechanges!="remove")?this.state.mod:null);
        }
        if (event) {
            event.stopPropagation();
        }
        this.setState({showmenu:false});
    };

    onChangeVal(prop, val) {
        const mod = Object.assign({}, this.state.mod);
        if (val==="" || val =="none" || val=="all") {
            val=null;
        }
        mod[prop] = val;
        this.setState({mod});
    }

    render() {
        const mod = this.state.mod;

        let r = <span>
            <Button className="minw2" size="small" variant="outlined" color="primary" onClick={this.showMenu.bind(this)}>
                Spell Modifiers
            </Button>&nbsp;
            {getSpellmodDescription(this.props.mod)}
            {this.props.children}
            {this.state.showmenu?<Dialog
                open
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Spell Modifiers</DialogTitle>
                <DialogContent>
                    <div className="stdcontent">
                        <h2>Spell List</h2>
                        <div className="hk-well mb1">Only spells in the spell lists will have a modified behavior.</div>
                        <SelectVal fullWidth className="minw4 mr1" value={mod.spellList||"all"} includeAll values={getSpellClassOptions()} helperText="Spell List Class" onClick={this.onChangeVal.bind(this,"spellList")}/>
                        <SelectVal fullWidth className="minw4 mr1" value={mod.school||"all"} includeAll values={getSchoolsOfMagic()} helperText="School of Magic" onClick={this.onChangeVal.bind(this,"school")}/>
                        <SelectVal fullWidth className="minw4 mr1" isPossibleNum value={(mod.maxSpellLevel==null)?"all":mod.maxSpellLevel} includeAll values={spellLevelNames} helperText="Max Spell Level" onClick={this.onChangeVal.bind(this,"maxSpellLevel")}/>
                        <div className="mb1">
                            <Button size="small" variant="outlined" color="primary" onClick={this.showPicker.bind(this)}>
                                Spell List
                            </Button> {spellNamesFromList(Object.keys(mod.selectedSpells||{}))}
                        </div>
                        <h2>Spell Modifiers</h2>
                        <div className="hk-well mb1">Spell modifiers determine how the spells will be effected.</div>
                        <div className="mb1">
                            <TextVal    
                                text={mod.attackBonus||""}
                                fullWidth
                                onChange={this.onChangeVal.bind(this,"attackBonus")}
                                helperText="Attack Bonus"
                            />
                        </div>

                        <div className="mb1">
                            <TextVal fullWidth helperText="Effect" text={mod.effect||""} onChange={this.onChangeVal.bind(this, "effect")}/>
                        </div>
                        <div className="mb1">
                            <ExtraDamage damage={mod.extraDamage} onChange={this.onChangeVal.bind(this,"extraDamage")}/>
                        </div>
                        <div>
                            <div className="titlecolor mt1">Extra Notes</div>
                            <EntityEditor onChange={this.onChangeVal.bind(this,"extraNotes")} entry={mod.extraNotes}/>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, "remove")} color="primary">
                        Remove
                    </Button>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
                <SpellPicker 
                    open={this.state.showPicker} 
                    maxSpellLevel={20}
                    selectedSpells={mod.selectedSpells}
                    hideCounts 
                    onClose={this.closePicker.bind(this)}
                />
            </Dialog>:null}
        </span>;

        if (this.props.noDiv) {
            return r;
        } else {
            return <div className="mb1">{r}</div>
        }
    }

    showPicker() {
        this.setState({showPicker:true});
    }

    closePicker(spells) {
        this.setState({showPicker:false});
        if (spells) {
            this.onChangeVal("selectedSpells", (Object.keys(spells).length)?spells:null);
        }
    }
}

const spellLevelNames= {
    0:"Cantrips",
    1:"1st Level Spells",
    2:"2nd Level Spells",
    3:"3rd Level Spells",
    4:"4th Level Spells",
    5:"5th Level Spells",
    6:"6th Level Spells",
    7:"7th Level Spells",
    8:"8th Level Spells",
    9:"9th Level Spells",
}



const yesNoValues = {
    1:"Yes",
    0:"No"
}

function getItemmodDescription(itemmod) {
    if (!itemmod) {
        return null;
    }
    const filter = getItemFilterDescription(itemmod);
    const list = [];
    if (itemmod.attackBonus) {
        list.push(signedMod(itemmod.attackBonus)+ " to hit");
    }
    if (itemmod.damageBonus) {
        list.push(signedNum(itemmod.damageBonus)+ " to damage");
    }
    if (itemmod.extraDamage) {
        const ed = [];
        for (let i in itemmod.extraDamage) {
            ed.push(itemmod.extraDamage[i]+" "+Parser.DMGTYPE_JSON_TO_FULL[i]);
        }
        list.push("an extra "+joinCommaAnd(ed)+ " to damage rolls")   
    }
    if (itemmod.acBonus) {
        list.push(signedNum(itemmod.acBonus)+ " to AC");
    }
    if (itemmod.attackAbility) {
        list.push("the ability to use your "+attackAbilities[itemmod.attackAbility]+" modifier for attack and damage rolls instead of Strength or Dexterity");
    }
    if (itemmod.secondWeaponBonus) {
        list.push(" the ability to add your ability modifier to the damage of an off-hand attack")
    }
    if (itemmod.finesse) {
        list.push(" has the finesse property")
    }
    if (itemmod.extraNotes) {
        list.push(" extra item notes");
    }

    return "You gain "+joinCommaAnd(list).toLowerCase()+ " with "+filter;
}

function getSpellmodDescription(mod) {
    if (!mod) {
        return null;
    }
    const filter = spellNamesFromList(Object.keys(mod.selectedSpells||{}));
    const list = [];
    if (mod.attackBonus) {
        list.push(mod.attackBonus + " to hit");
    }
    if (mod.effect) {
        list.push("effect modifier:"+ mod.effect);
    }
    if (mod.extraDamage) {
        const ed = [];
        for (let i in mod.extraDamage) {
            ed.push(mod.extraDamage[i]+" "+Parser.DMGTYPE_JSON_TO_FULL[i]);
        }
        list.push("an extra "+joinCommaAnd(ed)+ " to damage rolls")   
    }

    return "You gain "+joinCommaAnd(list).toLowerCase()+ " with "+filter;
}

class ItemFilter extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    onChangeProp(prop, val) {
        const filter = Object.assign({}, this.props.filter);
        if (val==="") {
            val=null;
        }
        filter[prop] = val;
        this.props.onChange(filter);
    }

    render() {
        const filter = this.props.filter;
        const {weaponCategories,allItemTypeMap}  = campaign.getItemExtensions();
        return <div>
            <TextVal fullWidth className="mv1" text={filter.name||""} onChange={this.onChangeProp.bind(this,"name")} helperText="Name (comma separate multiple options)"/>
            <CheckPick useButton noChooseOption label="Rarity" value={filter.rarity||[]} options={["Magical"].concat(stdvalues.rarityLevels)} editable onChange={this.onChangeProp.bind(this, "rarity")}/>
            <CheckPick useButton noChooseOption label="Item Type" value={filter.itemType||[]} options={allItemTypeMap} editable onChange={this.onChangeProp.bind(this, "itemType")}/>
            <CheckPick useButton noChooseOption label="Weapon Category" value={filter.weaponCategory||[]} options={weaponCategories} editable onChange={this.onChangeProp.bind(this, "weaponCategory")}/>
            <PickProperties property={filter.properties} useButton onChange={this.onChangeProp.bind(this, "properties")} editable/>
            <PickProperties label="Exclude Properties" property={filter.excludeProperties} useButton onChange={this.onChangeProp.bind(this, "excludeProperties")} editable/>
            <CheckVal value={filter.matchSome||false} onChange={this.onChangeProp.bind(this, "matchSome")} label="Match if some match (don't require all to match)"/>
        </div>;
    }
}

function getItemFilterDescription(filter) {
    const list = [];
    let ret;

    if (filter.rarity) {
        if (filter.rarity.length==1) {
            const r = filter.rarity[0];
            switch (r) {
                case "Magical":
                    list.push("magical");
                    break;
                case "None":
                    list.push("non-magical");
                    break;
                default:
                    list.push(r);
                    break;
            }
        } else {
            list.push(joinCommaAnd(filter.rarity, " or "));
        }
    }

    if (filter.weaponCategory) {
        list.push(joinCommaAnd(filter.weaponCategory, " or "));
    }

    if (filter.properties) {
        list.push(getItemPropertyList(filter.properties, " or "));
    }

    if (filter.itemType) {
        list.push(joinCommaAnd(filter.itemType.map(function (a){return pluralString(Parser.ITEM_TYPE_JSON_TO_ABV[a]||a,2)}), " or "));
    }

    if (filter.name) {
        ret=list.join(", ") + " " + pluralString(filter.name,2);
    } else if ((filter.weaponCategory || filter.properties) && !filter.itemType) {
        ret=list.join(", ") + " weapons";
    } else if (filter.itemType) {
        ret=list.join(", ");
    } else {
        ret=list.join(", ") + " items";
    }
    return ret.toLowerCase();
}

function spellNamesFromList(spells) {
    const sl=[];
    for (let i in spells) {
        const s=campaign.getSpell(spells[i]);
        if (s) {
            sl.push(s.displayName);
        }
    }
    return joinCommaAnd(sl);
}

function normalizeValue(value) {
    let values = [];
    let defaults=[];
    let type;
    let proficient;

    if (Array.isArray(value)){
        defaults=value;
        type="defaults"
        proficient="proficient";
    } else if (value.choose && (!value.from || !value.from.length)) {
        values=[];
        defaults=value.defaults||[];
        type = "any "+value.choose;
        proficient=value.proficient||"proficient";
    } else {
        values = (value.from||[]);
        defaults=value.defaults||[];
        if (value.choose) {
            type = "choose "+value.choose;
        } else {
            type = "defaults";
        }
        proficient = value.proficient||"proficient";
    }
    return {values, defaults, type, proficient};
}

function getProficiencySummaryText(value, name, nameplural) {
    const {values, defaults, type, proficient} = normalizeValue(value);
    const ret = [];
    if (defaults && defaults.length) {
        if (defaults.length == 1) {
           ret.push("You have proficiency with "+defaults[0]+" "+name+".");
        } else {
            ret.push("You have proficiency with "+joinCommaAnd(defaults)+" "+nameplural+".");

        }
    }

    if (type.startsWith("choose")) {
        if(value.choose > 1) {
            ret.push("You gain any "+value.choose+" proficiencies from the following: "+joinCommaAnd(values)+".");
        } else {
            ret.push("You gain any one proficiency from the following: "+joinCommaAnd(values)+".");
        }
    } else if (type.startsWith("any")){
        if(value.choose > 1) {
            ret.push("You gain any "+value.choose+" "+name+" proficiencies of your choice.");
        } else {
            ret.push("You gain any one "+name+" proficiency of your choice.");
        }
    }
    if (proficient == "expert") {
        ret.push("Your proficiency bonus with these is doubled.")
    } else if (proficient == "proficientplus") {
        ret.push("If you are already proficient then your proficiency bonus with these is doubled.")
    }
    return ret.join("  ");
}

function getOtherSummaryText(value, textGet, textChooseList, textChooseAllEnd, textEnd, altYouCanChoose) {
    const {values, defaults, type} = normalizeValue(value);
    const ret = [];
    if (defaults && defaults.length) {
        ret.push(textGet+joinCommaAnd(defaults)+(textEnd||"")+".");
    }

    if (type.startsWith("choose")) {
        ret.push(textChooseList+value.choose+" from "+joinCommaAnd(values)+(textEnd||"")+".");
    } else if (type.startsWith("any")){
        ret.push((altYouCanChoose || "You can choose ")+value.choose+textChooseAllEnd);
    }
    return ret.join("  ");
}

const proficiencyValues = {
    "half":"half proficient",
    "proficient":"proficient",
    "proficientplus":"proficient (expert if already proficient)",
    "expert":"expert"
}

const checkOptions = [
    "defaults",
    "any 1",
    "any 2",
    "any 3",
    "any 4",
    "choose 1",
    "choose 2",
    "choose 3",
    "choose 4"
];

const speedDeltaList = {
    "-30":"-30ft.",
    "-25":"-25ft.",
    "-20":"-20ft.",
    "-15":"-15ft.",
    "-10":"-10ft.",
    "-5":"-5ft.",
    "0":"None",
    "5":"+5ft.",
    "10":"+10ft.",
    "15":"+15ft.",
    "20":"+20ft.",
    "25":"+25ft.",
    "30":"+30ft."
}

const spellInfoLookup = {
    "full":stdvalues.fullSpellInfo,
    "half":stdvalues.halfSpellInfo,
    "halfplus":stdvalues.halfPlusSpellInfo,
    "third":stdvalues.thirdSpellInfo,
    "pact":stdvalues.pactSpellInfo,
    "halfpact":stdvalues.halfPactSpellInfo,
    "thirdpact":stdvalues.thirdPactSpellInfo,
}

function getClassTable(classInfo, subclassInfo, ptranslate,level) {
    const translate = ptranslate || emptyTranslate;
    const spellInfo = classInfo.spellcaster?spellInfoLookup[classInfo.spellcaster]:spellInfoLookup[(subclassInfo && subclassInfo.spellcaster)];
    const hasSpellSlots = (spellInfo && spellInfo[19].spellSlots);
    const knownCantrips = classInfo.spellcaster?classInfo.knownCantrips:subclassInfo?.knownCantrips;
    const knownSpells = classInfo.spellcaster?classInfo.knownSpells:subclassInfo?.knownSpells;
    const knownRituals = classInfo.spellcaster?classInfo.knownRituals:subclassInfo?.knownRituals;
    const hasPactSlots = (spellInfo && spellInfo[19].pactSlots);
    const customLevels = getMergedCustomLevels(classInfo, subclassInfo);
    const classFeatures = classInfo.classFeatures||[];
    const subclassFeatures = (subclassInfo && subclassInfo.classFeatures)||[];
    const useSpellPoints = (classInfo.useSpellPoints || (subclassInfo && subclassInfo.useSpellPoints));
    let rows=[];
    let grow={};
    let hrow=[];

    let baseCols = 3 + customLevels.length + (knownCantrips?1:0) + (knownSpells?1:0) + (knownRituals?1:0) + (hasPactSlots?2:0);

    if (hasSpellSlots && !useSpellPoints) {
        let spellLabel = translate.t("Spell Slots per Spell Level");
        if (classInfo.gamesystem == "bf") {
            spellLabel = spellLabel.replace("per Spell Level","by Circle");
        }
        grow.baseCols = baseCols;
        grow.spellLabel = spellLabel;
        grow.spellLabelSpan = spellInfo[19].spellSlots.length;
    }
    hrow.push("Level");
    hrow.push("Proficiency Bonus");
    hrow.push("Features");

    for (let i in customLevels){
        hrow.push(customLevels[i].name+((customLevels[i].attributeType=="select")?" Known":""));
    }
    if (knownCantrips) {
        hrow.push(translate.t("Cantrips")+" Known");
    }
    if (knownSpells) {
        hrow.push(translate.t("Spells")+" Known");
    }
    if (knownRituals) {
        hrow.push("Rituals Known");
    }
    if (useSpellPoints && (hasPactSlots || hasSpellSlots)) {
        hrow.push(translate.t("Spell Points"));
        hrow.push(translate.t("Spell")+" Level");
    } else {
        if (hasPactSlots) {
            hrow.push(translate.t("Pact Slots"));
            hrow.push(translate.t("Slot Level"));
        }
        if (hasSpellSlots) {
            for (let i in spellInfo[19].spellSlots) {
                hrow.push(Parser.numberToTextTh(Number(i)+1));
            }
        }
    }

    for (let r=0; r<20; r++){
        if (!level || ((level-1)==r) || ((level-2)==r)) {
            let row=[];

            row.push(Parser.numberToTextTh(r+1));
            row.push("+"+(Math.trunc(r/4)+2).toString());

            const features = [];
            let num=0;
            for (let f in classFeatures[r]){
                const nfeat=classFeatures[r][f];
                features.push({r,num,name:nfeat.name});
                num++
            }
            for (let f in subclassFeatures[r]){
                const nfeat=subclassFeatures[r][f];
                features.push({r,num,name:nfeat.name});
                num++;
            }
            row.push(features);

            for (let i in customLevels){
                const attribute = customLevels[i];
                const levelsCount = attribute.levelsCount||[];
                row.push(levelsCount[r]?((attribute.prefix||"")+levelsCount[r]+(attribute.suffix||"")):"-");
            }
            if (knownCantrips) {
                row.push(knownCantrips[r] || "-");
            }
            if (knownSpells) {
                row.push(knownSpells[r] || "-");
            }
            if (knownRituals) {
                row.push(knownRituals[r] || "-");
            }
            if (useSpellPoints && (hasPactSlots || hasSpellSlots)) {
                let points = 0;
                let maxSlotLevel = null;
                if (hasPactSlots) {
                    maxSlotLevel = spellInfo[r].pactLevel;
                    points += ((stdvalues.spellPointsByLevel[spellInfo[r].pactLevel]||0)*spellInfo[r].pactSlots);
                }
                if (hasSpellSlots) {
                    for (let i in spellInfo[r].spellSlots) {
                        if (spellInfo[r].spellSlots[i]) {
                            maxSlotLevel = Number(i)+1;
                            points += ((stdvalues.spellPointsByLevel[Number(i)+1]||0)*spellInfo[r].spellSlots[i]);
                        }
                    }
                }
                row.push(points?points:null);
                row.push(maxSlotLevel?Parser.numberToTextTh(maxSlotLevel):null);
            } else {
                if (hasPactSlots) {
                    row.push(spellInfo[r].pactSlots);
                    row.push(spellInfo[r].pactLevel?Parser.numberToTextTh(spellInfo[r].pactLevel):null);
                }
                if (hasSpellSlots) {
                    for (let i in spellInfo[r].spellSlots) {
                        row.push(spellInfo[r].spellSlots[i]||"-");
                    }
                }
            }
                        
            rows.push(row);
        }
    }
    return {grow, hrow, rows};
}

const emptyTranslate={
    t:function(str){return str}
}


class BaseAC extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
            anchorEl:null, 
        };
    }

    showMenu(event){
        this.setState({ showmenu:true, anchorEl:event.currentTarget, baseAC:this.props.baseAC||{ac:10, ability:[],halfAbility:[]} });
    }

    handleClose(savechanges, event) {
        if (savechanges){
            const baseAC = this.state.baseAC;

            if ((!baseAC.ac || baseAC.ac==10) && (!baseAC.ability || !baseAC.ability.length) && (!baseAC.halfAbility || !baseAC.halfAbility.length)) {
                this.props.onChange(null);
            }else {
                this.props.onChange(baseAC);
            }
        }
        this.setState({showmenu:false});
    };

    onChange(event) {
        const baseAC = Object.assign({}, this.state.baseAC||{});
        const ability = (baseAC.ability||[]).concat([]);
        const halfAbility = (baseAC.halfAbility||[]).concat([]);
        const val = event.target.value;
        const pos = ability.indexOf(val);
        const hpos = halfAbility.indexOf(val);

        if (pos >=0) {
            ability.splice(pos,1);
            if (hpos<0) {
                halfAbility.push(val);
            }
        } else if (hpos >=0) {
            halfAbility.splice(hpos,1);
        } else {
            ability.push(val);
        }
        baseAC.ability=ability;
        baseAC.halfAbility=halfAbility;
        this.setState({baseAC});
    }

    onChangeAC(val) {
        const baseAC = Object.assign({}, this.state.baseAC||{});
        baseAC.ac = val;
        this.setState({baseAC});
    }

    onChangeAllowShield() {
        const baseAC = Object.assign({}, this.state.baseAC||{});
        baseAC.allowShield = !baseAC.allowShield;
        this.setState({baseAC});
    }

    render() {
        const propsBaseAC = this.props.baseAC;
        let ability=[];
        let halfAbility=[];
        let ac = 10;
        let allowShield = false;
        if (this.state.showmenu) {
            ability = this.state.baseAC.ability||[];
            halfAbility = this.state.baseAC.halfAbility||[];
            ac = this.state.baseAC.ac||10;
            allowShield = this.state.baseAC.allowShield||false;
        }

        return <div>
            <span>
                <Button size="small" variant="outlined" color="primary" onClick={this.showMenu.bind(this)}>
                    Unarmored AC
                </Button>
                {propsBaseAC?<span> {(propsBaseAC.ac||10)}
                    {(propsBaseAC.ability&&propsBaseAC.ability.length)?(" plus bonus from "+joinCommaAnd(propsBaseAC.ability)):null}
                    {(propsBaseAC.halfAbility&&propsBaseAC.halfAbility.length)?(" plus 1/2 bonus from "+joinCommaAnd(propsBaseAC.halfAbility)):null}
                    {propsBaseAC.allowShield?" with or without a shield":" without a shield"}
                    <span> - Armor class if not wearing armor.</span>
                </span>:null}
            </span>
            <Dialog
                open={this.state.showmenu}
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Unarmored Armor Class Base</DialogTitle>
                <DialogContent>
                    <SelectVal fullWidth values={baseACValues} value={ac} onClick={this.onChangeAC.bind(this)}/>
                    <div>
                        <FormControlLabel
                            control={<Checkbox checked={allowShield} onChange={this.onChangeAllowShield.bind(this)}/>}
                            label="Allow Shield"
                        />
                    </div>
                    <FormControlLabel
                        control={<Checkbox checked={ability.includes("str")} indeterminate={!ability.includes("str")&&halfAbility.includes("str")} onChange={this.onChange.bind(this)} value="str" />}
                        label="STR"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={ability.includes("dex")} indeterminate={!ability.includes("dex")&&halfAbility.includes("dex")} onChange={this.onChange.bind(this)} value="dex" />}
                        label="DEX"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={ability.includes("con")} indeterminate={!ability.includes("con")&&halfAbility.includes("con")} onChange={this.onChange.bind(this)} value="con" />}
                        label="CON"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={ability.includes("int")} indeterminate={!ability.includes("int")&&halfAbility.includes("int")} onChange={this.onChange.bind(this)} value="int" />}
                        label="INT"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={ability.includes("wis")} indeterminate={!ability.includes("wis")&&halfAbility.includes("wis")} onChange={this.onChange.bind(this)} value="wis" />}
                        label="WIS"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={ability.includes("cha")} indeterminate={!ability.includes("cha")&&halfAbility.includes("cha")} onChange={this.onChange.bind(this)} value="cha" />}
                        label="CHA"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>;
    }
}

class ACBonus extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
            anchorEl:null, 
        };
    }

    showMenu(event){
        this.setState({ showmenu:true, anchorEl:event.currentTarget, feature:this.props.feature});
    }

    handleClose(savechanges, reset) {
        if (savechanges){
            this.props.onChange(this.state.feature);
        }
        this.setState({showmenu:false});
    };

    onChangePick(field, val) {
        const feature = Object.assign({}, this.state.feature);
        if (val == "none") {
            val = null;
        }
        feature[field] = val;
        this.setState({feature});
    }

    toggleAbility(a) {
        const acAbilitiesBonus = (this.state.feature.acAbilitiesBonus||[]).concat([]);
        if (acAbilitiesBonus.includes(a)) {
            acAbilitiesBonus.splice(acAbilitiesBonus.indexOf(a),1);
        } else {
            acAbilitiesBonus.push(a);
        }
        this.onChangePick("acAbilitiesBonus", acAbilitiesBonus.length?acAbilitiesBonus:null);
    }

    render() {
        const feature=this.props.feature;
        const efeature=this.state.feature||{};
        const alist =[];

        if (this.state.showmenu) {
            const abilities = efeature.acAbilitiesBonus||[];
            const av = stdvalues.abilitiesValues.concat(["proficiency"]);
            for (let i in av) {
                const a = av[i];
                alist.push(<CheckVal className="mr2" key={a} value={abilities.includes(a)} onChange={this.toggleAbility.bind(this, a)} label={a}/> )
            }
        }

        return <div>
            <span>
                <Button size="small" variant="outlined" color="primary" onClick={this.showMenu.bind(this)}>
                    AC Bonus
                </Button>
                {feature.acBonus||feature.acAbilitiesBonus?(" "+ (feature.acBonus?signedNum(feature.acBonus):"") +" "+(feature.acAbilitiesBonus||[]).join(", ")+" Bonus to AC with "+ (acBonusTypes[feature.acBonusType]||"or without armor")):""}
            </span>
            <Dialog
                open={this.state.showmenu}
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Armor Class Bonus</DialogTitle>
                <DialogContent>
                    <div>
                        <SelectVal values={bonusValues} value={efeature.acBonus||0} isNum onClick={this.onChangePick.bind(this, "acBonus")} helperText="Bonus"/>&nbsp;&nbsp;
                        <SelectVal values={acBonusTypes} value={efeature.acBonusType||"none"} onClick={this.onChangePick.bind(this, "acBonusType")} helperText="Armor Type"/>
                    </div>
                    <div>{alist}</div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>;
    }
}

const baseACValues = [10, 11, 12, 13, 14, 15,16,17,18,19,20];

class RenderFeature extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    render() {
        const feature = this.props.feature;
        if (!feature) {
            return null;
        }
        fixupFeature(feature);

        let options=[];
        for (let i in feature.options) {
            const fo = feature.options[i];
            if ((fo.entries && fo.entries.length && fo.entries[0].html) || fo.extraSpells) {
                options.push(<div className="mb1" key={i}><RenderFeature showInstantRoll={this.props.showInstantRoll} doRoll={this.props.doRoll} doSubRoll={this.props.doSubRoll} addSpellToken={this.props.addSpellToken} getDiceRoller={this.props.getDiceRoller} feature={fo} isOption noDiv translate={this.props.translate} character={this.props.character}/></div>);
            }
        }
        if (this.props.noDiv) {
            return <span className="stdcontent">
                {(this.props.noTitle||(!feature.name&&!this.props.usage&&!this.props.emptyName))?null:<span>{feature.name||this.props.emptyName?<span className="b i">{featureNameWithPeriod(feature.name||this.props.emptyName)}&nbsp;</span>:null}{this.props.usage}</span>}
                <Renderentry entry={{entries:feature.entries}} depth={2} showInstantRoll={this.props.showInstantRoll} doRoll={this.props.doRoll} doSubRoll={this.props.doSubRoll} addSpellToken={this.props.addSpellToken} getDiceRoller={this.props.getDiceRoller} character={this.props.character} nodiv/>
                {feature.extraSpells?<ExtraSpells extraSpells={feature.extraSpells} translate={this.props.translate} character={this.props.character}/>:null}
                {feature.descriptionOptions?<div className="mb1"><DescriptionOptionsListEdit descriptions={feature.descriptionOptions} noBackground/></div>:null}
                {options}
            </span>;
        } else {
            return <div className="stdcontent" id={this.props.id}>
                {(this.props.noTitle||(!feature.name&&!this.props.usage&&!this.props.emptyName))?null:(this.props.isOption?<div><b>{feature.name||this.props.emptyName}</b>{this.props.usage}</div>:(this.props.h3?<h3>{feature.name}{this.props.usage}</h3>:<h2>{feature.name}{this.props.usage}</h2>))}
                <Renderentry entry={{entries:feature.entries}} depth={2} showInstantRoll={this.props.showInstantRoll} doRoll={this.props.doRoll} doSubRoll={this.props.doSubRoll} addSpellToken={this.props.addSpellToken} getDiceRoller={this.props.getDiceRoller} character={this.props.character}/>
                {feature.extraSpells?<ExtraSpells extraSpells={feature.extraSpells} translate={this.props.translate} character={this.props.character}/>:null}
                {feature.descriptionOptions?<div className="mb1"><DescriptionOptionsListEdit descriptions={feature.descriptionOptions} noBackground/></div>:null}
                {options}
            </div>;
        }
    }
}

function renderPrintFeatures(features,header, noDiv,translate) {
    if (!features) {
        return;
    }
    if (!Array.isArray(features)) {
        features=[features];
    }
    const list=[];
    for (let feature of features) {
        fixupFeature(feature);
        list.push("<div>");
        if (noDiv) {
            if (feature.name) {
                list.push(`<b><i>${featureNameWithPeriod(feature.name)||""}</i></b> `);
            }
        } else {
            if (feature.name) {
                list.push(header?`<h${header}>${feature.name||""}</h${header}>`:`<div class="itemHeader">${feature.name||""}</div>`);
            }
        }

        let html =htmlFromEntry({entries:feature.entries});
        if (noDiv && (html||"").startsWith("<p>")) {
            html = html.replace("<p>","");
            html = html.replace("</p>$", "");
        }
        list.push(`${noDiv?"":"<div>"}${html}${noDiv?"":"</div>"}`)

        list.push(printExtraSpells(feature.extraSpells,translate));
        list.push(printDescriptionOptions(feature.descriptionOptions,feature));

        for (let i in feature.options) {
            const fo = feature.options[i];
            if ((fo.entries && fo.entries.length && fo.entries[0].html) || fo.extraSpells) {
                list.push(renderPrintFeatures(fo, null, true));
            }
        }
        list.push("</div>");
    }
    return list.join("\n");
}

function printExtraSpells(extraSpells, translateFn) {
    const list=[];
    const levels = [];
    const rows = [];
    const translate=translateFn||emptyTranslate;
    const isBF = campaign.hasBFGamesystem;

    for (let i in extraSpells) {
        const si = campaign.getSpell(extraSpells[i]);
        if (si) {
            if (!levels[si.level]) {
                levels[si.level]=[];
            }
            levels[si.level].push(si.displayName);
        }
    }

    for (let i in levels) {
        if (levels[i]) {
            rows.push(`<tr><td>${(i!="0")?Parser.levelToFull(i):translate.t("Cantrip")}</td><td>${levels[i].join(", ")}</td></tr>`);
        }
    }

    if (!rows.length) {
        return "";
    }

    list.push(`<div><table width="100%"><tr><td><b>${translate.t("Spell")} ${isBF?"Circle":"Level"}</b></td><td><b>${translate.t("Spells")}</b></td></tr>`);
    list.push(rows.join("\n"));
    list.push("</table></div>");

    return list.join("\n");
}

function printDescriptionOptions(descriptions,feature) {
    const list=[];
    for (let di in descriptions) {
        const d = descriptions[di];
        const values = d.values||[];
        const options=[];

        const d100 = !([4,6,8,10,12,20].includes(values.length));
        const die = d100?"d100":("d"+values.length);

        for (let i in values) {
            let n = Number(i);
            let num =n+1;

            if (d100) {
                num = (twoDigitNum(Math.trunc(n*100/values.length)+1)) + "-" + twoDigitNum(Math.trunc((n+1)*100/values.length));
            }
            options.push(`<tr><td>${num}</td><td>${values[i]}</td></tr>`);
        }

        if (options.length) {
            list.push(`<div><table><tr><td><b>${die}</b></td><td width="100%"><b>${d.name||""}</b></td></tr>${options.join("\n")}</table></div>`);
        }
    }
    return list.join("\n");
}

function featureNameWithPeriod(fn) {
    if ((/[:\.]$/).test(fn||"")) {
        return fn;
    }
    return (fn||"")+".";
}


class UsageListEdit extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }

    onChangeFeature(i, feature) {
        const features = (this.props.features||[]).concat([]);
        features[i] = feature;
        this.props.onChange(features);
    }

    onDeleteFeature(i) {
        const features = (this.props.features||[]).concat([]);
        features.splice(i,1);
        this.props.onChange(features);
    }

    onAddFeature() {
        const features = (this.props.features||[]).concat([]);
        features.push({description:"", current:1, maximum:1, longrest:1});
        this.props.onChange(features);
    }

    onReorderFeature(i, direction) {
        const features = (this.props.features||[]).concat([]);
        i = Number(i);
        const cur = features[i];
        features[i]= features[i+direction];
        features[i+direction] = cur;
        
        this.props.onChange(features);
    }

    render() {
        const features = this.props.features||[];
        const list = [];

        for (let i in features) {
            if (!features[i].hidden) {
                list.push(<UsageEdit 
                    key={i} 
                    feature={features[i]} 
                    onChange={this.onChangeFeature.bind(this,i)} 
                    onDelete={this.onDeleteFeature.bind(this,i)}
                    moveUp={i>0?this.onReorderFeature.bind(this,i,-1):null}
                    moveDn={i<(features.length-1)?this.onReorderFeature.bind(this,i,1):null}
                />);
            }
        }

        return <div>
            {list}
            <div>
                <Button className="ml2 minw2" color="secondary" variant="outlined" size="small" onClick={this.onAddFeature.bind(this)}>{this.props.addLabel||"+ Usage"}</Button>
            </div>
        </div>;
    }
}

class UsageEdit extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    onChangeVal(field, val) {
        const feature = Object.assign({}, this.props.feature);
        if (field == "maximum") {
            if ((val < (field.current||1)) || (feature.current == feature.maximum)) {
                feature.current = val
            }
        }
        feature[field] = val;
        this.props.onChange(feature)
    }

    render() {
        const feature = this.props.feature;

        return <div className="">
            <div className="flex">
                <div className="flex-auto">
                    <TextVal text={feature.description||""} fullWidth onChange={this.onChangeVal.bind(this,"description")} helperText="Label"/>
                </div>
                <div className="pt1 notetext f4">
                    &nbsp;Cur&nbsp;<NumberAdjustPlusMinus value={feature.current||1} onChange={this.onChangeVal.bind(this,"current")}></NumberAdjustPlusMinus>
                    &nbsp;Max&nbsp;<NumberAdjustPlusMinus value={feature.maximum||1} onChange={this.onChangeVal.bind(this,"maximum")}></NumberAdjustPlusMinus>
                    <SelectVal className="ml1 minw4" isNum values={recoverAfterList} value={feature.longrest?1:0} onClick={this.onChangeVal.bind(this,"longrest")} helperText="Recover"/>
                </div>
                <div>
                    <DeleteWithConfirm className="pa1" name={feature.name} onClick={this.props.onDelete}/>
                    <span className={(this.props.moveUp?"hoverhighlight":"gray-80")+" fas fa-arrow-up pa1 ml--2"} onClick={this.props.moveUp}/>
                    <span className={(this.props.moveDn?"hoverhighlight":"gray-80")+" fas fa-arrow-down pa1 ml--2"} onClick={this.props.moveDn}/>
                </div>
            </div>
        </div>;
    }
}

function hasFeatureConfig(feature, allowAbilityOverride) {
    let hasConfig = (
        feature && 
        (
            feature.options || 
            (feature.abilitySaves && feature.abilitySaves.choose) ||
            (feature.languages && feature.languages.choose) ||
            (feature.skills && feature.skills.choose) ||
            (feature.tools && feature.tools.choose) || 
            (feature.weapons && feature.weapons.choose) || 
            (feature.armor && feature.armor.choose) ||
            (feature.resist && feature.resist.choose) || 
            (feature.immune && feature.immune.choose) ||
            (feature.vulnerable && feature.vulnerable.choose) ||
            (feature.conditionImmune && feature.conditionImmune.choose) ||
            feature.pickFeat ||
            feature.grantFeat || 
            feature.spellPick ||
            feature.customPick ||
            feature.restriction ||
            feature.customAttribute || 
            feature.spellLevelAdvance ||
            (feature.ability && (allowAbilityOverride||feature.ability.choose))
        )
    );
    if (hasConfig) {
        return true;
    }
    if (feature?.subfeatures) {
        for (let i in feature.subfeatures) {
            if (hasFeatureConfig(feature.subfeatures[i], allowAbilityOverride)) {
                return true;
            }
        }
    }
    return hasConfig;
}

class FeatureConfigure extends React.Component {
    constructor(props) {
        super(props);
	    this.state= { };
    }

    render() {
        let feature = this.props.feature;
        const character = this.props.character;
        const baseName = this.props.baseName;
        const options = this.props.options;
        const allowAbilityOverride= this.props.allowAbilityOverride;
        const opts = [];
        const {Feat,PickFeat} = require('./renderfeats.jsx');
        const {CustomPicker} = require('./customtable.jsx');

        if (!feature || !character.checkRestriction(feature.restriction)) {
            return null;
        }

        if (feature.options && feature.options.length) {
            const fid = baseName + ".selected";
            const v = options[fid];
            const values = ["none"];
            let selected;

            for (let i in feature.options) {
                const f = feature.options[i];
                if ((f.name||i) == v) {
                    selected = f;
                }
                values.push(f.name||i);
            }
            opts.push(<div key={fid} className="mb2 tl">
                <div className="mb2">
                    {!v?<ConfigureAlert/>:null}
                    <SelectVal helperText={"Select "+(feature.name||"")} fullWidth value={v||"none"} onClick={this.changeVal.bind(this, fid)} values={values}/>
                </div>
                {selected?<div className="stdcontent mt1"><RenderFeature noTitle isOption feature={selected}/></div>:null}
            </div>);
            if (!selected) {
                return <div key={baseName}>{opts}</div>;
            }
            feature = selected;
        }

        if (feature.pickFeat || feature.grantFeat) {
            const fid = baseName + ".selectedFeat";
            const featName = feature.grantFeat || options[fid];
            const feat = campaign.getFeatInfo(featName);
            const featOptions = [];

            if (feat) {
                for (let i in feat.features) {
                    const f = feat.features[i];
                    const featBaseName = baseName+".feat."+(f.id||f.name||"");

                    if (hasFeatureConfig(f)) {
                        featOptions.push(<FeatureConfigure
                            key={"feat"+i}
                            character={character}
                            feature={f}
                            baseName={featBaseName}
                            options={options}
                            onChange={this.props.onChange}
                        />);
                    }
                }
            }

            if (feature.pickFeat || featOptions.length>0) {
                opts.push(<div key={fid} className="mb2 tl">
                    {feature.pickFeat?<div className="mb2">
                        {!feat?<ConfigureAlert/>:null}
                        {(feat&&feat.displayName)||"Need to choose feat"}{" "}
                        <Button onClick={this.showPickFeat.bind(this)} color="primary" variant="outlined" size="small">
                            Select Feat
                        </Button>
                    </div>:null}
                    <PickFeat open={this.state.showPickFeat} feat={featName} onClose={this.pickFeat.bind(this, fid)} character={character}/>
                    {featOptions}
                    {feat?<div className="stdcontent mt1"><Feat feat={featName} noArt/></div>:null}
                </div>);
            }
        }

        if (feature.subfeatures) {
            for (let i in feature.subfeatures) {
                const f = feature.subfeatures[i];
                const subBaseName = baseName+".sub."+(f.id||f.name||"");

                if (hasFeatureConfig(f)) {
                    opts.push(<FeatureConfigure
                        key={"sub"+i}
                        character={character}
                        feature={f}
                        baseName={subBaseName}
                        options={options}
                        onChange={this.props.onChange}
                    />);
                }
            }
        }

        if (feature.ability) {
            const a = feature.ability;
            if (feature.ability.choose) {
                const choose = feature.ability.choose;
                const amount = feature.ability.amount ||1;

                for (let i=1; i<= choose; i++) {
                    opts.push (<div className="mb2" key={"ability"+i}>
                        {!options[baseName+".abilitymod."+i]?<ConfigureAlert className="pa2"/>:null}
                        <FormControl>
                            <Select
                                value={options[baseName+".abilitymod."+i]||""}
                                onChange={this.changeOption.bind(this,baseName+".abilitymod."+i)}
                                className="w6"
                            >
                                {getAbilitiesMenus(feature.ability.from)}
                            </Select>
                            <FormHelperText>Ability +{amount||1}</FormHelperText>
                        </FormControl>
                    </div>);
                }
            }
            
            if (allowAbilityOverride && !(a.str&&a.dex&&a.con&&a.int&&a.wis&&a.cha)) {
                const overrideValues = [];
                for (let i in feature.ability) {
                    overrideValues.push(options[baseName+".abilitymod."+i] || i);
                }
        
                for (let i in feature.ability) {
                    const amount = feature.ability[i];
                    if (stdvalues.abilitiesValues.includes(i) && amount) { 
                        opts.push (<div className="mb2" key={"ability"+i}>
                            <FormControl>
                                <Select
                                    value={options[baseName+".abilitymod."+i]||i}
                                    onChange={this.changeOption.bind(this,baseName+".abilitymod."+i)}
                                    className="w6"
                                >
                                    {getAbilitiesMenus(stdvalues.abilitiesValues, overrideValues, options[baseName+".abilitymod."+i]||i)}
                                </Select>
                                <FormHelperText>Ability +{amount||1}</FormHelperText>
                            </FormControl>
                        </div>);
                    }
                }
            }
        }

        if (feature.customAttribute) {
            const prop = baseName+".te."+feature.customAttribute;
            opts.push(<div key="customattr" className="mb1">
                {!options[prop]?<ConfigureAlert/>:null}
                <TextPlusEdit label={feature.customAttribute} noTextLabel="(no value set)" text={options[prop]||""} noDiv values={feature.customAttributeOptions?feature.customAttributeOptions.split(","):null} editable onChange={this.changeVal.bind(this,prop)} useButton variant="outlined" size="small"/>
            </div>);
        }

        if (feature.languages) {
            opts.push(this.getProficiencyOptions(options, feature.languages, "Language", baseName+"language", character.allLanguagesList, character.selectedLanguages ));
        }

        if (feature.skills) {
            opts.push(this.getProficiencyOptions(options, feature.skills, ["expert", "proficientplus"].includes(feature.skills.proficient)?"Skill Expertise":"Skill Proficiency", baseName+"skill",character.allSkillsList, character.skills, true));
        }

        if (feature.tools) {
            opts.push(this.getProficiencyOptions(options, feature.tools, ["expert", "proficientplus"].includes(feature.tools.proficient)?"Tools Expertise":"Tools", baseName+"tool", stdvalues.toolShortProficiencies, character.selectedTools, true));
        }
        const {allWeaponProficiencies} = campaign.getItemExtensions();

        if (feature.weapons) {
            opts.push(this.getProficiencyOptions(options, feature.weapons, "Weapons", baseName+"weapon", allWeaponProficiencies, character.selectedWeapons));
        }

        if (feature.armor) {
            opts.push(this.getProficiencyOptions(options, feature.armor, "Armor", baseName+"armor", stdvalues.armorTypes, character.selectedArmor));
        }

        if (feature.abilitySaves) {
            opts.push(this.getProficiencyOptions(options, feature.abilitySaves, "Save Proficiency", baseName+"abilitySaves", stdvalues.abilitiesValues, character.abilities, true));
        }

        if (feature.resist) {
            opts.push(this.getProficiencyOptions(options, feature.resist, "Damage Resistances", baseName+"resist", stdvalues.damageTypesList, character.selectedResist));
        }

        if (feature.immune) {
            opts.push(this.getProficiencyOptions(options, feature.immune, "Damage Immunities", baseName+"immune", stdvalues.damageTypesList, character.selectedImmune));
        }

        if (feature.vulnerable) {
            opts.push(this.getProficiencyOptions(options, feature.vulnerable, "Damage Vulnerabilities", baseName+"vulnerable", stdvalues.damageTypesList, character.selectedVulnerable));
        }

        if (feature.conditionImmune) {
            opts.push(this.getProficiencyOptions(options, feature.conditionImmune, "Condition Immunities", baseName+"conditionImmune", stdvalues.conditionList, character.selectedConditionImmune));
        }

        if (feature.spellPick) {
            const spellPick = feature.spellPick;
            const list = [];
            let cantripsC=0;
            let spellsC=0;
            const selected=options[baseName+".spellPick"];
            const recoveryType = feature.spellPick.ritualOnly?"ritual":(feature.spellPick.recoveryType||"long");

            for (let i in selected) {
                const s = campaign.getSpell(i);
                if (s) {
                    if (!s.level){
                        cantripsC++;
                    } else {
                        spellsC++;
                    }
                    if (list.length){
                        list.push(<span key={i+"sp"}>, </span>);
                    }
                    list.push(<LinkHref key={i} href={"#spell?id="+encodeURIComponent(s.name)}>{s.displayName}</LinkHref>);
                }
            }
            let maxSpellLevel = spellPick.spells?(spellPick.spellLevel||1):0;
            let showLevel = spellPick.cantrips?null:(spellPick.spellLevel||1);
            if (maxSpellLevel == "any") {
                if (["slot","ritual"].includes(spellPick.recoveryType)) {
                    maxSpellLevel=0;
                    if (character.pactLevel > 0){
                        maxSpellLevel=character.pactLevel;
                    }
                    for (let i=0; i<9; i++){
                        if (character.spellSlots[i] && (i>=maxSpellLevel)){
                            maxSpellLevel=i+1;
                        }
                    }
                } else {
                    maxSpellLevel=9;
                }
                showLevel=null;
            }

            opts.push (<div className="mv1" key="spellPick">
                {((cantripsC<(spellPick.cantrips||0) || (spellsC<(spellPick.spells||0))) && ((spellPick.spellLevel!="any") || (((spellPick.spells||0)+(spellPick.cantrips||0))<(spellsC+cantripsC))))?<ConfigureAlert/>:null}
                {list.length?list:"(need to select) "}
                <Button className="ml1" onClick={this.onShowFeatureSpellPicker.bind(this, spellPick)} color="primary" variant="outlined" size="small">
                    Pick {spellPick.cantrips?spellPick.cantrips>1?character.t("Cantrips"):character.t("Cantrip"):""}
                    {spellPick.cantrips&&spellPick.spells?" & ":""}
                    {spellPick.spells?(spellPick.spells>1)?character.t("Spells"):character.t("Spell"):""}
                </Button>
                <SpellPicker
                    character={character}
                    cantripStr={character.t("Cantrips")}
                    spellStr={character.t("Spells")}
                    open={this.state.showSpellPicker} 
                    maxSpellLevel={maxSpellLevel}
                    level={showLevel}
                    cclass={spellPick.spellList}
                    spellSources={spellPick.sources}
                    selectedSpells={selected}
                    ritualOnly={recoveryType=="ritual"}
                    school={spellPick.school}
                    knownCantrips={spellPick.cantrips}
                    knownSpells={spellPick.spells}
                    onClose={this.onCloseSpellPicker.bind(this)}
                />
            </div>);
        }

        if (feature.spellLevelAdvance=="any") {
            const slafname = baseName+".spellLevelAdvance";
            const value=options[slafname]||"none";
            const classOptions=[];
            for (let i in character.classes) {
                const cInfo = character.classes[i];
                const cls = campaign.getClassInfo(cInfo.cclass);
                if (!cls) continue;
    
                const subclass = campaign.getSubclassInfo(cInfo.subclass);
                if (cls.spellcaster || (subclass && subclass.spellcaster)) {
                    classOptions.push(cls.displayName);
                }
            }
            if (classOptions.length) {
                opts.push (<div className="mv1" key="spellLevelAdvance">
                    {(value=="none")?<ConfigureAlert className="pa2"/>:null}
                    <SelectVal helperText="Spellcaster level advance" value={value} includeVal="none" values={classOptions} onClick={this.changeVal.bind(this,slafname)}/>
                </div>);
            }
        }

        if (feature.customPick) {
            const customPick = feature.customPick;
            const list = [];
            const selected=(customPick.customOptions && (customPick.customOptions.length==1))?customPick.customOptions[0]:options[baseName+".customPick"];
            const customOptions = [];

             
            for (let i in selected) {
                const ct = campaign.getCustom(customPick.customTable,i);
                if (ct) {
                    if (list.length){
                        list.push(<span key={i+"sp"}>, </span>);
                    }
                    list.push(<LinkHref key={i} href={"#customlist?type="+encodeURIComponent(customPick.customTable)+"&id="+i}>{ct.displayName}</LinkHref>);

                    for (let x in ct.features) {
                        const f = ct.features[x];
                        const custBaseName = baseName+".customtable"+i+"."+(f.id||f.name||"");
    
                        if (hasFeatureConfig(f)) {
                            customOptions.push(<FeatureConfigure
                                key={"customtable."+ct.name+x}
                                character={character}
                                feature={f}
                                baseName={custBaseName}
                                options={options}
                                onChange={this.props.onChange}
                            />);
                        }
                    }
                }
            }

            if (!customPick.customOptions || (customPick.customOptions.length!=1)) {
                let keywords = customPick.keywords;
                if (keywords) {
                    keywords += character.customMods[customPick.customTable]||"";
                }

                opts.push (<div className="mv1" key={"customPick"}>
                    {(list.length>=customPick.count)?null:<ConfigureAlert/>}
                    {list.length?list:"(need to select) "}
                    <Button className="ml1" onClick={this.onShowFeatureCustomPicker.bind(this, customPick)} color="primary" variant="outlined" size="small">Pick {customPick.customTable}</Button>
                    {customOptions}
                    <CustomPicker 
                        character={character}
                        open={this.state.showCustomPicker} 
                        known={customPick.count}
                        type={customPick.customTable}
                        keywords={keywords}
                        selected={options[baseName+".customPick"]}
                        restrictOptions={customPick.customOptions} 
                        gamesystemPref={customPick.gamesystemPref}
                        onClose={this.onCloseCustomPicker.bind(this)}
                    />
                </div>);
            }
        }
        if (!opts.length) {
            return null;
        }

        return <div>{opts}</div>;
    }

    onShowFeatureSpellPicker() {
        this.setState({showSpellPicker:true});
    }

    onCloseSpellPicker(selectedSpells) {
        if (selectedSpells) {
            const baseName = this.props.baseName;
            this.props.onChange(baseName+".spellPick", selectedSpells);
        }
        this.setState({showSpellPicker:false});
    }

    onShowFeatureCustomPicker() {
        this.setState({showCustomPicker:true});
    }

    onCloseCustomPicker(selectedCustom) {
        if (selectedCustom) {
            const baseName = this.props.baseName;
            this.props.onChange(baseName+".customPick", selectedCustom);
        }
        this.setState({showCustomPicker:false});
    }

    getProficiencyOptions(options, l, title, baseName, allList, selList, checkProficiency) { 
        const character = this.props.character;
        const opts = [];

        if (!l) {
            return [];
        }

        if (l.choose) {
            const {chooseProficienciesExpand} = campaign.getItemExtensions();
            const alts = character.getExtensionAltSkills();
            const chooseStart = (l.from && l.from.length)?l.from:allList;
            let choose = [];
            let needsort;

            for (let i in chooseStart) {
                const c = chooseStart[i];
                if (c != "All" || chooseStart != allList) {
                    const cpe = chooseProficienciesExpand[c];
                    if (cpe) {
                        if (chooseStart.length==1) {
                            title = c;
                        }
                        choose = choose.concat(cpe)
                    } else {
                        choose.push(c);
                        if (l.from && alts[c]) {
                            for (let a of alts[c])
                            if (!choose.includes(a)){
                                choose = choose.concat([a]);
                                needsort=true;
                            }
                        }
                    }
                }
            }
            if (needsort){
                choose.sort(function (a,b){return (a||"").toLowerCase().localeCompare((b||"").toLowerCase())});
            }

            const count = l.choose;
            for (let loop=0; loop<count; loop++) {
                const value = options[baseName+"."+loop];
                const list = [];
                let showIndent = false;

                for (let x in choose) {
                    let nl = choose[x];
                    let nots = (value == nl);
                    let isIndented=true;

                    if (nl.startsWith("*")) {
                        showIndent=false;
                        nl=nl.substr(1);
                    }

                    if (!nots && selList) {
                        if (checkProficiency && (l.proficient == "expert")) {
                            nots = selList[nl] && (selList[nl].proficiency!="expert");
                        } else if (checkProficiency && (l.proficient == "proficientplus")) {
                            nots = selList[nl] && ["proficient", "proficientplus"].includes(selList[nl].proficiency);
                        } else if (checkProficiency) {
                            nots = !selList[nl] || !selList[nl].proficiency || (selList[nl].proficiency=="half");
                        } else {
                            nots = !selList[nl];
                        }
                    } 
    
                    list.push(<MenuItem className={"pv2"+(nots?"":" gray-60")+(showIndent&&isIndented?" ml2":"")} key={nl} value={nl}>{expandAbilityAbr(nl)}</MenuItem>);
                }
    
                opts.push (<div key={baseName+"."+loop}>
                    {value?null:<ConfigureAlert className="pa2"/>}
                    <FormControl>
                        <Select
                            id={baseName+loop}
                            value={value||""}
                            onChange={this.changeOption.bind(this,baseName+"."+loop)}
                            className="w6"
                        >
                            {list}
                        </Select>
                        <FormHelperText>{title}</FormHelperText>
                    </FormControl>
                </div>);
            }
        }

        if (opts.length) {
            return <div key={title} className="mb2">
                {opts}
            </div>;
        }
        return null;
    }

    changeOption(option, event) {
        this.props.onChange(option, event.target.value);
    }

    changeVal(option, value) {
        if (value=="none"){
            value="";
        }
        this.props.onChange(option, value);
    }

    showPickFeat() {
        this.setState({showPickFeat:true});
    }

    pickFeat(fid,feat) {
        if (feat) {
            this.changeVal(fid, feat);
        }
        this.setState({showPickFeat:false});
    }
}

function expandAbilityAbr(v) {
    return stdvalues.abilityNames[v] || v;
}

class ExtraDamage extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    handleClose(savechanges) {
        if (savechanges){
            const {damage} = this.state;
            this.props.onChange((damage && Object.keys(damage).length)?damage:null);
        }

        this.setState({open:false})
    };

    onChange(damage) {
        this.setState({damage});
    }

    showDialog() {
        this.setState({open:true,damage:this.props.damage});
    }

    render() {
        const damage = this.props.damage||{};
        const dt = Parser.DMGTYPE_JSON_TO_FULL;
        const list=[];
        for (let i in dt) {
            const d=damage[i];
            if (d) {
                list.push(d+" "+(dt[i]));
            }
        }

        return <span>
            <Button size="small" variant="outlined" color="primary" onClick={this.showDialog.bind(this)}>
                <span className="nowrap">{this.props.label||"Extra Damage"}</span>
            </Button> {list.join(" + ")}
            {this.state.open?<Dialog
                open
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Extra Damage Types</DialogTitle>
                <DialogContent>
                    <DamageListEdit damage={this.state.damage} onChange={this.onChange.bind(this)}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;
    }
}

class TempHPDamage extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    handleClose(savechanges) {
        if (savechanges){
            const {temphp} = this.state;
            this.props.onChange(temphp&&temphp.hp?temphp:null);
        }

        this.setState({open:false})
    };

    onChangeVal(name, val) {
        const temphp = Object.assign({}, this.state.temphp||{});
        temphp[name]=val;
        this.setState({temphp});
    }

    showDialog() {
        this.setState({open:true,temphp:this.props.temphp||{}});
    }

    render() {
        const curtemphp = this.props.temphp||{};
        const {temphp} = this.state;

        return <span>
            <Button size="small" variant="outlined" color="primary" onClick={this.showDialog.bind(this)}>
                Temp HP
            </Button> {curtemphp.hp?(curtemphp.hp+" Temporary HP"):null} {curtemphp.duration?("for "+curtemphp.duration):null}
            {this.state.open?<Dialog
                open
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Grant Temp HP</DialogTitle>
                <DialogContent>
                    <TextVal fullWidth text={temphp.hp||""} onChange={this.onChangeVal.bind(this, "hp")} helperText="Temp HP number/roll"/>
                    <SelectTextVal fullWidth text={temphp.duration||""} onChange={this.onChangeVal.bind(this, "duration")} values={stdvalues.durationSuggestions} helperText="Duration"/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;
    }
}

class AddUses extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    handleClose(savechanges) {
        if (savechanges){
            const {adduses} = this.state;
            this.props.onChange((adduses?.useCount&&adduses?.useName)?adduses:null);
        }

        this.setState({open:false})
    };

    onChangeVal(name, val) {
        const adduses = Object.assign({}, this.state.adduses||{});
        adduses[name]=val;
        this.setState({adduses});
    }

    showDialog() {
        this.setState({open:true,adduses:this.props.adduses||{}});
    }

    render() {
        const curadduses = this.props.adduses;
        const {adduses} = this.state;

        return <span>
            <Button size="small" variant="outlined" color="primary" onClick={this.showDialog.bind(this)}>
                Add Uses
            </Button> {curadduses?<span>{curadduses.useCount} {curadduses.useName}</span>:null}
            {this.state.open?<Dialog
                open
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Add Usage Count</DialogTitle>
                <DialogContent>
                    <TextVal fullWidth text={adduses.useName||""} onChange={this.onChangeVal.bind(this, "useName")} helperText="Usage Name"/>
                    <SelectVal fullWidth isPossibleNum value={adduses.useCount||0} values={this.props.numOnly?bonusValues:posBonusValuesWithAbilities} onClick={this.onChangeVal.bind(this, "useCount")} helperText="Use Count"/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;
    }
}

class ActionConditions extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    handleClose(savechanges) {
        if (savechanges){
            const {conditions} = this.state;
            this.props.onChange(conditions&&(Object.keys(conditions.conditions||{}).length||(conditions.features||[]).length)?conditions:null);
        }

        this.setState({open:false})
    };

    onChangeVal(name, val) {
        if (val=="none") {
            val=null;
        }
        const conditions = Object.assign({}, this.state.conditions||{});
        conditions[name]=val;
        this.setState({conditions});
    }

    showDialog() {
        const conditions = this.props.conditions||{};
        this.setState({open:true,conditions, showPickCondition:false});
    }

    render() {
        const curconditions = this.props.conditions||{};
        const {conditions, open,showPickCondition, editFeature, showEditFeatureOptions} = this.state;
        const {CustomPicker} = require('./customtable.jsx');
        const label = this.props.label||"Apply Conditions";
        const fcList=[];

        if (open) {
            for (let i in conditions.features) {
                const fc = conditions.features[i];
                fcList.push(<tr key={i}>
                    <td className="w-100 hoverhighlight" onClick={this.showFeature.bind(this, i)}>{fc.name}</td>
                    <td><span className="pa1 fas fa-trash hoverhighlight" onClick={this.deleteFC.bind(this,i)}/></td>
                </tr>)
            }
        }

        return <span>
            <Button size="small" variant="outlined" color="primary" onClick={this.showDialog.bind(this)}>
                {label}
            </Button> {getStringFromConditionsStruct(curconditions)} {curconditions.duration?("for "+curconditions.duration):null}
            {open?<Dialog
                open
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>{label}</DialogTitle>
                <DialogContent>
                    <SelectTextVal fullWidth text={conditions.duration||""} onChange={this.onChangeVal.bind(this, "duration")} values={stdvalues.durationSuggestions} helperText="Duration"/>
                    <div className="mv2">
                        <Button onClick={this.pickCondition.bind(this)} color="primary" variant="outlined">
                            Pick Conditions
                        </Button> {conditions.conditions?getStringFromConditions(conditions.conditions):null}
                    </div>
                    <table>
                        <tbody>
                            {fcList}
                        </tbody>
                    </table>
                    <div className="mv1">
                        <Button onClick={this.showFeature.bind(this, -1)} color="primary" variant="outlined">
                            New Feature Condition
                        </Button>
                    </div>
                    {this.props.levelDurations?<div className="mv1">
                        <DurationByLevel durations={conditions.levelDurations} onChange={this.onChangeVal.bind(this, "levelDurations")} label="Durations by Usage Level"/>
                    </div>:null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
                <CustomPicker 
                    open={showPickCondition}
                    type="Conditions"
                    selected={conditions.conditions}
                    onClose={this.onCloseCustomPicker.bind(this)}
                />
                <EditFeatureOptions
                    noShow
                    noFeats
                    open={showEditFeatureOptions} 
                    feature={editFeature} 
                    onClose={this.closeEditFeatureOptions.bind(this)}
                />
            </Dialog>:null}
        </span>;
    }

    showFeature(editFeatureIndex) {
        const {conditions} = this.state;

        const fcList = conditions.features||[];
        let editFeature;
        if (editFeatureIndex >= 0) {
            editFeature = fcList[editFeatureIndex];
        } else {
            editFeature={id:campaign.newUid()};
        }
        this.setState({editFeature, editFeatureIndex,showEditFeatureOptions:true});
    }

    closeEditFeatureOptions(feature) {
        if (feature) {
            if (!feature.name) {
                displayMessage("Cannot have a condition feature without a name");
                return;
            }
            const {conditions, editFeatureIndex} = this.state;

            const fcList = (conditions.features||[]).concat([]);
            if (editFeatureIndex >= 0) {
                fcList[editFeatureIndex] = feature;
            } else {
                fcList.push(feature);
            }
            this.onChangeVal("features", fcList);
        }
        this.setState({showEditFeatureOptions:false});
    }

    deleteFC(i) {
        const fcList = this.state.conditions.features.concat([]);
        fcList.splice(i,1);
        this.onChangeVal("features", fcList);
    }

    pickCondition() {
        this.setState({showPickCondition:true});
    }

    onCloseCustomPicker(selected) {
        this.setState({showPickCondition:false});
        if (selected) {
            this.onChangeVal("conditions", selected)
        }
    }
}

class D20Modifier extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    handleClose(savechanges) {
        if (savechanges){
            const {modifier} = this.state;
            this.props.onChange(modifier&&modifier.dice?modifier:null);
        }

        this.setState({open:false})
    };

    onChangeVal(name, val) {
        if (val=="none") {
            val=null;
        }
        const modifier = Object.assign({}, this.state.modifier||{});
        modifier[name]=val;
        this.setState({modifier});
    }

    showDialog() {
        const modifier = this.props.modifier||{};
        this.setState({open:true,modifier});
    }

    render() {
        const curmodifier = this.props.modifier||{};
        const {modifier, open} = this.state;
        const abilities = modifier?.abilities||[];

        return <span>
            <Button size="small" variant="outlined" color="primary" onClick={this.showDialog.bind(this)}>
                Roll Modifier
            </Button> {curmodifier.dice}
            {open?<Dialog
                open
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Roll Modifier</DialogTitle>
                <DialogContent>
                    <div className="hk-well mb2">
                        Configure extra rolls to be applied to key D20 rolls.
                    </div>
                    <TextVal    
                        text={modifier.dice||""}
                        fullWidth
                        onChange={this.onChangeVal.bind(this,"dice")}
                        helperText="Roll Modifier"
                    />
                    <div className="mv1">
                        <CheckVal value={modifier.minus} onChange={this.onChangeVal.bind(this, "minus")} label="Subtract from roll"/>
                    </div>
                    <div className="titlecolor titletext bb titleborder">Roll Types</div>
                    <div className="mt1">
                        <CheckVal value={modifier.initiative} onChange={this.onChangeVal.bind(this, "initiative")} label="Initiative"/>
                    </div>
                    <div className="mt1">
                        <CheckVal value={modifier.save} onChange={this.onChangeVal.bind(this, "save")} label="Saves"/>
                    </div>
                    <div className="mt1">
                        <CheckVal value={modifier.attack} onChange={this.onChangeVal.bind(this, "attack")} label="Attacks"/>
                    </div>
                    <div className="mt1">
                        <CheckVal value={modifier.spell} onChange={this.onChangeVal.bind(this, "spell")} label="Spell To Hit"/>
                    </div>
                    <div className="mt1">
                        <CheckVal value={modifier.skill} onChange={this.onChangeVal.bind(this, "skill")} label="All Skill/Ability Checks"/>
                    </div>
                    {!modifier.skill?<div className="mt1">
                        <div>Ability Checks</div>
                        <CheckVal className="mr1 ml--1" value={abilities.includes("str")} onChange={this.onToggleAbility.bind(this, "str")} label="STR"/>
                        <CheckVal className="mr1 ml--1" value={abilities.includes("dex")} onChange={this.onToggleAbility.bind(this, "dex")} label="DEX"/>
                        <CheckVal className="mr1 ml--1" value={abilities.includes("con")} onChange={this.onToggleAbility.bind(this, "con")} label="CON"/>
                        <CheckVal className="mr1 ml--1" value={abilities.includes("int")} onChange={this.onToggleAbility.bind(this, "int")} label="INT"/>
                        <CheckVal className="mr1 ml--1" value={abilities.includes("wis")} onChange={this.onToggleAbility.bind(this, "wis")} label="WIS"/>
                        <CheckVal className="mr1 ml--1" value={abilities.includes("cha")} onChange={this.onToggleAbility.bind(this, "cha")} label="CHA"/>
                    </div>:null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;
    }

    onToggleAbility(ability) {
        const abilities = (this.state.modifier.abilities||[]).concat([]);
        const pos = abilities.indexOf(ability);
        if (pos>=0) {
            abilities.splice(pos,1);
        } else {
            abilities.push(ability);
        }
        this.onChangeVal("abilities", abilities);
    }
}


class EditFeatureList extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    handleClose(savechanges) {
        if (savechanges){
            const {features} = this.state;
            this.props.onChange(features&&features.length?features:null);
        }

        this.setState({open:false})
    };

    onChangeFeatures(features) {
        this.setState({features});
    }

    showDialog() {
        const features = this.props.features||[];
        this.setState({open:true,features});
    }

    render() {
        const curfeatures = this.props.features||[];
        const {features, open} = this.state;
        const {label} = this.props;

        return <span>
            <Button size="small" variant="outlined" color="primary" onClick={this.showDialog.bind(this)}>
                {label}
            </Button> {curfeatures.length?curfeatures.length+" features":null}
            {open?<Dialog
                open
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>{label}</DialogTitle>
                <DialogContent>
                    <FeatureListEdit editable features={features} onChange={this.onChangeFeatures.bind(this)}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;
    }
}

const monsterSelectTypeList = {
    all:"All",
    filter:"Filtered List",
    select:"Fixed List"
}
const monsterMovementTypes = {
    walk:"Walking",
    burrow:"Burrowing",
    climb:"Climbing",
    fly:"Flying",
    swim:"Swimming"
}

function getMonsterNames(selected) {
    const list = [];
    for (let i in selected) {
        const mon = campaign.getMonsterInfo(i);
        if (mon && mon.displayName) {
            list.push(mon.displayName);
        }
    }
    return list.join(", ")
}

class MonsterSelector extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    handleClose(savechanges) {
        if (savechanges){
            const {selection} = this.state;
            this.props.onChange(selection&&selection.length?selection:null);
        }

        this.setState({open:false})
    };

    deleteSelection() {
        this.props.onChange(null);
        this.setState({open:false})
    }

    onChange(selection) {
        this.setState({selection});
    }

    showDialog() {
        const selection = this.props.selection||[];
        if (!selection.length) {
            selection.push({type:"all", level:0});
        }
        this.setState({open:true,selection});
    }

    render() {
        const {name,summon} = this.props;
        const curselection = this.props.selection;
        const {selection, open} = this.state;

        return <span>
            <Button size="small" variant="outlined" color="primary" onClick={this.showDialog.bind(this)}>
                {name}
            </Button> {curselection?"Monsters selectable":null}&nbsp;
            {open?<Dialog
                open
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>{name}</DialogTitle>
                <DialogContent>
                    <MonsterSelectorInline summon={summon} includeCounts selection={selection} onChange={this.onChange.bind(this)}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.deleteSelection.bind(this)} color="primary">
                        Delete
                    </Button>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
        </span>;
    }
}

const shapeHPOptions = {
    default:"Use monster's",
    keep:"Use character's",
    size:"Adjust based on monster size",
}
class MonsterSelectorInline extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    onChangeVal(i, name, val) {
        const selection = (this.props.selection||[]).concat([]);
        const s = Object.assign({}, selection[i]||{});
        selection[i] = s;
        if (val=="none") {
            delete s[name];
        } else {
            s[name]=val;
        }

        if (!s.level) {
            s.level=0;
        }
        if (!s.type) {
            s.type="all";
        }
        this.props.onChange(selection)
    }

    render() {
        const {MonsterPicker} = require('./rendermonster.jsx');
        const {summon} = this.props;
        const selection = this.props.selection||[];
        const list=[];
        const clist=[]

        const classes = campaign.getClassesListByName();
        for (let i in classes) {
            clist.push(classes[i].displayName);
        }

        let lastLevel = 0;
        for (let i=0; i<(selection.length||1); i++) {
            const s = selection[i]||{};
            const ns = selection[i+1];
            const nextLevel = (ns&&ns.level)||21;
            const levelOpts = [];
            for (let l=lastLevel+1; l<nextLevel; l++) {
                levelOpts.push({name:"Level "+l, value:l});
            }

            list.push(<div key={i} className="bb bw2 titleborder mb2 pb2">
                {i?<div className="flex">
                    <SelectVal fullWidth isNum helperText="Start Level" values={levelOpts} value={s.level} onClick={this.onChangeVal.bind(this, i, "level")}/>
                    <span className="fas fa-trash pa1 hoverhighlight ml2" onClick={this.deletRow.bind(this, i)}/>
                </div>:null}
                <div className="mb1">
                    <PickVal values={Parser.CRS}  includeVal="none" onClick={this.onChangeVal.bind(this, i, "minCR")} label="Minimum CR" size="small" variant="outlined" color="primary"/> {s.minCR}
                    &nbsp;
                    <PickVal values={Parser.CRS}  includeVal="none" onClick={this.onChangeVal.bind(this, i, "maxCR")} label="Maximum CR" size="small" variant="outlined" color="primary"/> {s.maxCR}
                </div>
                <div className="mb1">
                    <SimpleCheckPick alwaysArray onChange={this.onChangeVal.bind(this,i, "monsterTypes")} value={s.monsterTypes} values={stdvalues.monsterBasicTypeList} label="Monster Types" editable useButton/>
                </div>
                <div className="mb1">
                    <SimpleCheckPick alwaysArray onChange={this.onChangeVal.bind(this,i, "excludeMovement")} value={s.excludeMovement} values={monsterMovementTypes} label="Exclude Movement" editable useButton/>
                </div>
                <div className="mb1">
                    <Button onClick={this.pickMonsters.bind(this, i, s.selected)} size="small" color="primary" variant="outlined">
                        Pick Monsters
                    </Button> {getMonsterNames(s.selected)}
                </div>

                {this.props.includeCounts?<PickCountsByCR label="Summon Count By CR" counts={s.countsByCr} onChange={this.onChangeVal.bind(this,i,"countsByCr")}/>:null}
                {summon?<div>
                    <CheckVal value={!!s.adjustMon} onChange={this.onChangeVal.bind(this,i, "adjustMon")} label=" change statistics based on character"/>
                </div>:<div>
                    <div className="mb1">
                        <SimpleCheckPick alwaysArray onChange={this.onChangeVal.bind(this,i, "shapeAbilities")} value={s.shapeAbilities??stdvalues.defaultShapeAbilities} values={stdvalues.abilityNames} label="Pick Abilities For Shape Change"  editable useButton>
                        </SimpleCheckPick>
                    </div>
                    <div>
                        <CheckVal value={!!s.excludeProficiency} onChange={this.onChangeVal.bind(this,i, "excludeProficiency")} label=" don't use character proficiencies"/>
                    </div>
                    <SelectVal fullWidth helperText="Hit Points" values={shapeHPOptions} value={s.shapeHP||"default"} onClick={this.onChangeVal.bind(this, i, "shapeHP")}/>
                </div>}
                {s.adjustMon&&summon?<div>
                    <div className="mv1 hk-well">
                        <div className="mb1">The summoned creature's Hit Points and proficiency will be calculated from the character.</div>
                        <div className="mb1">base HP + character ability modifier + HP per level times character level</div>

                        <SelectVal className="mr2" value={s.cbase||0} isNum values={stdvalues.zeroTo20} onClick={this.onChangeVal.bind(this,i, "cbase")} helperText="base HP"/>
                        <SelectVal className="mr2" value={s.cability||"none"} includeVal="none" values={stdvalues.abilityNames} onClick={this.onChangeVal.bind(this,i, "cability")} helperText="character ability"/>
                        <SelectVal className="mr2" value={s.cmultiple||1} isNum values={stdvalues.oneTo20} onClick={this.onChangeVal.bind(this,i, "cmultiple")} helperText="HP per level"/>
                        <SelectVal value={s.class||"all"} includeVal="all" values={clist} onClick={this.onChangeVal.bind(this,i, "class")} helperText="character class"/>
                    </div>
                </div>:null}

            </div>)
            lastLevel = s.level||0;
        }

        return <div>
            {list}
            {(!this.props.oneLevel&&lastLevel<20)?<div>
                <Button onClick={this.addLevel.bind(this)} size="small" color="primary" variant="outlined">
                    Add Level Options
                </Button>
            </div>:null}
            <MonsterPicker multiSelect buttonLabel="pick monsters" onClose={this.pickMonster.bind(this)} selected={this.state.pickedSelection} open={this.state.showMonsterPicker||false}/>
        </div>;
    }

    pickMonsters(i, pickedSelection) {
        this.setState({showMonsterPicker:true, monsterSelectIndex:i, pickedSelection})
    }

    pickMonster(selected) {
        if (selected) {
            this.onChangeVal(this.state.monsterSelectIndex, "selected", selected);
        }
        this.setState({showMonsterPicker:false})
    }

    deletRow(i) {
        const selection = (this.props.selection||[]).concat([]);
        selection.splice(i, 1);
        this.props.onChange(selection)
    }

    addLevel() {
        const selection = (this.props.selection||[]).concat([]);
        let newLevel;
        if (selection.length) {
            const lastLevel = selection[selection.length-1];
            newLevel = Object.assign({}, lastLevel);
            newLevel.level = (newLevel.level||1)+1;
        } else {
            selection.push({type:"all", level:0});
            newLevel={type:"all", level:2};
        }
        selection.push(newLevel);
        this.props.onChange(selection)
    }
}

function getStringFromConditions(selected) {
    const list = [];
    for (let i in selected) {
        const c = campaign.getCustom("Conditions", i);
        if (c) {
            list.push(c.displayName);
        }
    }
    return list.join(", ");
}

function getStringFromConditionsStruct(conditions) {
    if (!conditions) {
        return "";
    }
    const clList=[];
    if (conditions.conditions) {
        clList.push(getStringFromConditions(conditions.conditions));
    }
    if (conditions.features) {
        for (let f of conditions.features) {
            clList.push(f.name||"");
        }
    }
    if (conditions.spells) {
        for (let f of conditions.spells) {
            clList.push(f.name||"");
        }
    }
    return clList.join(", ")
}


class DamageListEdit extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }

    onChangeVal(type, val) {
        const damage = Object.assign({}, (this.props.damage||{}));
        if (val){
            damage[type]=val;
        } else {
            delete damage[type];
        }
        this.props.onChange(Object.keys(damage).length?damage:null);
        this.props.onChange(damage);
    }

    render() {
        const damage = this.props.damage || {};
        const dt = Parser.DMGTYPE_JSON_TO_FULL;
        const list = [];

        for (let i in dt) {
            list.push(<tr key={i}>
                <td>
                    {dt[i]}&nbsp;
                </td>
                <td>
                    <TextVal text={damage[i]||""} onChange={this.onChangeVal.bind(this, i)}/>
                </td>
            </tr>)
        }

        return <div>
            <table>
                <tbody>
                    {list}
                </tbody>
            </table>
        </div>;
    }
}

const sizeAdjustValues={
    "-1":"Reduce",
    "0":"No Change",
    "1":"Enlarge"
}

const activationTypes = ["action", "attack", "bonus action", "reaction", "free action"];
const consumeUsageVals = {
    0:"none",
    1:"1",
    2:"2",
    3:"3",
    4:"4",
    5:"5",
    6:"6",
    7:"7",
    8:"8",
    9:"9",
    10:"10",
    11:"11",
    12:"12",
    13:"13",
    14:"14",
    15:"15",
    16:"16",
    17:"17",
    18:"18",
    19:"19",
    20:"20",
    21:"21",
    22:"22",
    23:"23",
    24:"24",
    25:"25",
    26:"26",
    27:"27",
    28:"28",
    29:"29",
    30:"30",
}
const consumeUsageTypes = {
    "uses":"Uses",
    "spell":"Spell Slots",
    "hd":"Hit Dice"
}

const actionOperationList = {
    attack:"Effect",
    activate:"Activate Ability",
    shape:"Shape Change",
    summon:"Summon Companion",
    convert:"Convert Spell Slots",
    create:"Create Item"
}
const actionNameInfoText="Setting an activation name allows other features to be enabled when this action is activated. Create a resriction using the same name.";

class EditActionConfig extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({action:this.props.action||{}, createitem:this.props.createitem});
        }
    }

    handleClose(savechanges) {
        if (savechanges) {
            this.props.onClose(this.state.action,this.state.createitem);
        } else {
            this.props.onClose();
        }
    };

    onChangeVal(prop, val) {
        if (prop=="actionOperation") {
            if (val=="create") {
                if (!this.state.createitem) {
                    this.setState({createitem:{type:"pick"},action:{}})
                    return;
                }
            } else {
                if (this.state.createitem) {
                    this.setState({createitem:null});
                }
            }
        }
        const action = Object.assign({}, this.state.action);
        if (val=="none") {
            val=null;
        }
        action[prop]=val;
        this.setState({action});
    }

    setCounts(i, c) {
        const counts=(this.state.action.counts||[]).concat([]);
        counts[i]=c;
        this.onChangeVal("counts", counts);
    }

    onChangeCreateItem(createitem) {
        this.setState({createitem});
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        const selectedOptions=[], unselected=[];
        const action = this.state.action || {};
        const createitem = this.state.createitem;
        let inside;
        const actionOperation=createitem?"create":action.actionOperation;

        switch (actionOperation) {
            case "create":
                break;
            default:
            case "attack": {
                addFeatureOption(action.extraDamage,unselected,
                    <div key="ed" className="mb1">
                        <ExtraDamage damage={action.extraDamage} onChange={this.onChangeVal.bind(this,"extraDamage")}/>
                    </div>
                );
        
                addFeatureOption(action.temphp,unselected,
                    <div key="td" className="mb1">
                        <TempHPDamage temphp={action.temphp} onChange={this.onChangeVal.bind(this,"temphp")}/>
                    </div>
                );
                
                addFeatureOption(action.conditions,unselected,
                    <div key="c" className="mb1">
                        <ActionConditions conditions={action.conditions} levelDurations onChange={this.onChangeVal.bind(this,"conditions")}/>
                    </div>
                );

                addFeatureOption(action.addUses,unselected,
                    <div key="au" className="mb1">
                        <AddUses adduses={action.addUses} onChange={this.onChangeVal.bind(this,"addUses")}/>
                    </div>
                );

                if (action.consumeUsage) {
                    addFeatureOption(action.useDamageBonus,unselected,
                        <div key="udb" className="mb1">
                            <TextPlusEdit useButton label="Extra Use Effect" text={action.useDamageBonus||""} info="Effect bonus added for each additional usage / spell level / hit die consumed." editable size="small" variant="outlined" color="primary" onChange={this.onChangeVal.bind(this, "useDamageBonus")}/>
                        </div>
                    );
                    addFeatureOption(action.useTemphpBonus,unselected,
                        <div key="utb" className="mb1">
                            <TextPlusEdit useButton label="Extra Use Temp HP" text={action.useTemphpBonus||""} info="Temp HP bonus added for each additional usage / spell level / hit die consumed." editable size="small" variant="outlined" color="primary" onChange={this.onChangeVal.bind(this, "useTemphpBonus")}/>
                        </div>
                    );
                }

                addFeatureOption(action.removeCondition,unselected,
                    <div key="removeCondition" className="mb1">
                        <TextPlusEdit useButton label="Remove Condition" text={action.removeCondition||""} info="Name of condition to remove when action is used." editable size="small" variant="outlined" color="primary" onChange={this.onChangeVal.bind(this, "removeCondition")}/>
                    </div>
                );

                addFeatureOption(action.removeActivated,unselected,
                    <div key="removeActivated" className="mb1">
                        <TextPlusEdit useButton label="Remove Activated Ability" text={action.removeActivated||""} info="Name of activated ability to remove when action is used." editable size="small" variant="outlined" color="primary" onChange={this.onChangeVal.bind(this, "removeActivated")}/>
                    </div>
                );
                
                inside = <div className="mb1">
                    <div className="flex">
                        <TextVal    
                            text={action.attackRoll||""}
                            fullWidth
                            onChange={this.onChangeVal.bind(this,"attackRoll")}
                            helperText="Attack Bonus"
                        />
                        <SelectMultiVal fullWidth className="ml1" selectClass=" " value={alwaysArray(action.save)} values={stdvalues.abilitiesValues} helperText="Save Type" onChange={this.onChangeVal.bind(this, "save")}/>
                        {action.save?<SelectMultiVal fullWidth className="ml1" selectClass=" " value={alwaysArray(action.saveDC)} values={stdvalues.abilityNamesScoresVals} helperText="Save Ability" onChange={this.onChangeVal.bind(this, "saveDC")}/>:null}
                    </div>
                    {action.save?<div className="hk-well mv1">The target should make save using the ability of the save type aginst the save DC.  The Save DC is the specified DC or 8 + save ability modifier + proficiency bonus.</div>:null}
                    <div className="flex">
                        <TextVal fullWidth helperText="Effect roll" text={action.dice||""} onChange={this.onChangeVal.bind(this, "dice")}/>
                        <SelectVal fullWidth className="ml1" selectClass=" " helperText="Effect type" includeVal="none" value={action.type||"none"} values={stdvalues.damageTypesList.concat(stdvalues.extraEffectsList)} onClick={this.onChangeVal.bind(this, "type")}/>
                    </div>
                </div>;
                break;
            }
            case "activate": {
                addFeatureOption(action.actionName,unselected,
                    <div key="actionName" className="mb1">
                        <TextPlusEdit useButton label="Activation Name" text={action.actionName||""} info={actionNameInfoText} editable size="small" variant="outlined" color="primary" onChange={this.onChangeVal.bind(this, "actionName")}/>
                    </div>
                );
                addFeatureOption(action.conditions,unselected,
                    <div key="c" className="mb1">
                        <ActionConditions conditions={action.conditions} levelDurations onChange={this.onChangeVal.bind(this,"conditions")}/>
                    </div>
                );
                
                addFeatureOption(action.temphp,unselected,
                    <div key="td" className="mb1">
                        <TempHPDamage temphp={action.temphp} onChange={this.onChangeVal.bind(this,"temphp")}/>
                    </div>
                );
                
                inside=<div>
                    <SelectTextVal fullWidth text={action.duration||""} onChange={this.onChangeVal.bind(this, "duration")} values={stdvalues.durationSuggestions} helperText="Duration"/>
                    <div className="mv1">
                        <Button onClick={this.showEditFeatureOptions.bind(this)} size="small" color="primary" variant="outlined">
                            Activated Abilities
                        </Button>
                        <div className="titleborder ba br2 mv1 pa1">
                            <div className="mb1 hk-well">
                                Deactivation changes happen when the activated ability gets deactivated.
                            </div>
                            <div className="flex">
                                <TextVal fullWidth helperText="Deactivate roll" text={action.deactivateDice||""} onChange={this.onChangeVal.bind(this, "deactivateDice")}/>
                                <SelectVal fullWidth className="ml1" selectClass=" " helperText="Deactivate type" includeVal="none" value={action.deactivateType||"none"} values={stdvalues.damageTypesList.concat(stdvalues.extraEffectsList)} onClick={this.onChangeVal.bind(this, "deactivateType")}/>
                            </div>
                            <div key="c" className="mb1">
                                <ActionConditions label="Deactivate Conditions" conditions={action.deactivateConditions} onChange={this.onChangeVal.bind(this,"deactivateConditions")}/>
                            </div>
                        </div>
                    </div>
                </div>
                break;
            }
            case "summon": {
                addFeatureOption(action.actionName,unselected,
                    <div key="actionName" className="mb1">
                        <TextPlusEdit useButton label="Activation Name" text={action.actionName||""} info={actionNameInfoText} editable size="small" variant="outlined" color="primary" onChange={this.onChangeVal.bind(this, "actionName")}/>
                    </div>
                );

                inside = <div className="mb1">
                    <SelectTextVal fullWidth text={action.duration||""} onChange={this.onChangeVal.bind(this, "duration")} values={stdvalues.durationSuggestions} helperText="Duration"/>
                    <MonsterSelectorInline summon selection={action.monsterSelection} onChange={this.onChangeVal.bind(this,"monsterSelection")}/>
                </div>;
                addFeatureOption(action.countByCr,unselected,
                    <div key="c" className="mb1">
                        <PickCountsByCR label="Count By CR" counts={action.countByCr} onChange={this.onChangeVal.bind(this,"countByCr")}/>
                    </div>
                );
                break;
            }
            case "shape": {
                inside = <div>
                    <SelectTextVal fullWidth text={action.duration||""} onChange={this.onChangeVal.bind(this, "duration")} values={stdvalues.durationSuggestions} helperText="Duration"/>
                    <MonsterSelectorInline selection={action.monsterSelection} onChange={this.onChangeVal.bind(this,"monsterSelection")}/>
                </div>
                break;
            }
            case "convert": {
                const counts = action.counts||[];
                const clist = [];
                for (let i=0; i<9; i++) {
                    clist.push(<div key={i} className="mr1">
                       <SelectVal helperText={"Level "+(i+1)+" Slot"} values={stdvalues.zeroTo20} value={counts[i]||0} onClick={this.setCounts.bind(this,i)}/>
                    </div>);
                }
                inside = <div>
                    <div className="hk-well pb2">
                        Convert spells to or from usage counts.  Set the usage name on the base feature.
                    </div>
                    <div>
                        <CheckVal value={action.toSpells||false} onChange={this.onChangeVal.bind(this, "toSpells")} label="Convert to spell slot"/>
                    </div>
                    <div className="flex flex-wrap justify-between">
                        {clist}
                    </div>
                </div>
                break;
            }
        }
        
        return <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>Action Configuration</DialogTitle>
            <DialogContent>
                <div className="mb1">
                    <SelectVal fullWidth helperText="Action Type" values={actionOperationList} value={actionOperation||"attack"} onClick={this.onChangeVal.bind(this, "actionOperation")}/>
                </div>
                {actionOperation=="create"?<div>
                    <CreateItemEmbed createitem={this.state.createitem} onChange={this.onChangeCreateItem.bind(this)} allowNotes/>
                </div>:<div>
                    {(actionOperation!="convert")?<div className="flex">
                        <SelectVal fullWidth isNum selectClass=" " helperText="Consume count" values={consumeUsageVals} value={action.consumeUsage||0} onClick={this.onChangeVal.bind(this, "consumeUsage")}/>
                        <SelectVal fullWidth className="ml1" selectClass=" " helperText="Consume on action" values={consumeUsageTypes} value={action.consumeType||"uses"} onClick={this.onChangeVal.bind(this, "consumeType")}/>
                    </div>:null}
                    <div className="flex">
                        <SelectVal fullWidth includeVal="none" selectClass=" " helperText="Activation" values={activationTypes} value={action.usageType||"none"} onClick={this.onChangeVal.bind(this, "usageType")}/>
                        {!["shape","convert"].includes(actionOperation)?<TextVal className="ml1" fullWidth text={action.range||""} onChange={this.onChangeVal.bind(this, "range")} helperText="Range"/>:null}
                    </div>
                    {inside}
                    {selectedOptions}
                    <div className="mv1">
                        <div className="titlecolor titletext mt1 bb titleborder">Action Notes</div>
                        <EntityEditor onChange={this.onChangeVal.bind(this,"extraNotes")} entry={action.extraNotes}/>
                    </div>
                    {unselected.length?<div className="mt2 ba br2 titleborder pa1">
                        <div className="f3 bb titleborder titletext titlecolor mb--2">Action Options</div>
                        <div className="flex flex-wrap">
                            {unselected}
                        </div>
                    </div>:null}
                </div>}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onDelete.bind(this)} color="primary">
                    Delete
                </Button>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <EditFeatureOptions
                noShow
                noFeats
                open={this.state.showEditFeatureOptions} 
                feature={action.enableFeature||{id:campaign.newUid()}} 
                onClose={this.closeEditFeatureOptions.bind(this)}
            />
        </Dialog>;

        function addFeatureOption(selected, unselList, val) {
            if (selected) {
                selectedOptions.push(val);
            } else {
                unselList.push(<div className="mr--2" key={unselList.length}>{val}</div>);
            }
        }
    }

    onDelete() {
        this.props.onClose({},null);
    }

    showEditFeatureOptions() {
        this.setState({showEditFeatureOptions:true});
    }

    closeEditFeatureOptions(feature) {
        this.setState({showEditFeatureOptions:false});
        if (feature) {
            this.onChangeVal("enableFeature",feature);
        }
    }
}

class ActionConfig extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        const {character, action, usageId, onClose} = this.props;
        const options = character.activeOptions[usageId]||{};
        const fname=action.id||action.name||""

        return <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={onClose}>{(action && action.name) ||"Activate Action"}</DialogTitle>
            <DialogContent>
                <Renderentry entry={{entries:action.entries}} depth={2}/>
                <FeatureConfigure 
                    character={character}
                    feature={action}
                    baseName={"."+fname}
                    options={options}
                    onChange={this.changeOptionVal.bind(this)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Activate
                </Button>
            </DialogActions>
        </Dialog>;
    }

    changeOptionVal(option,value) {
        const {usageId,character} = this.props;
        const activeOptions = Object.assign({}, character.activeOptions);
        activeOptions[usageId] = Object.assign({}, activeOptions[usageId]||{});
        activeOptions[usageId][option]=value;
        character.setProperty("activeOptions", activeOptions);
        this.setState({activeOptions});
    }
}

class RenderCharacterFeature extends React.Component {
    constructor(props) {
        super(props);

	    this.state= this.getState(props);
    }

    getState(props) {
        const {characterId, usageId, fname, fhtml} = props;
        let feature=null,options=null;
        const character = getCharacterFromId(characterId);
        if (fhtml || fname) {
            feature={name:fname||null, entries:[{html:fhtml||null, type:"html"}]};
        } else {
            if (character) {
                character.traverseFeatures(function (params) {
                    if (usageId == params.usageId) {
                        feature = params.feature;
                        options = params.options;
                    } else if (params.feature.effects && (usageId == params.usageId+".togglef")) {
                        feature = params.feature.effects;
                        options = character.activeOptions[params.usageId+".e"]||{};
                    }
                },null,null,true); // pass true to ignore restrictions
            }
        }
        return {feature, options, character}
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState(this.getState(this.props));
        }
    }

    render() {
        const {open, onClose} = this.props;
        if (!open) {
            return null;
        }

        const {feature,character} = this.state;
        const {characterId, usageId,href} = this.props;

        return <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={onClose}>{(feature && feature.name) ||"Feature Details"}</DialogTitle>
            <DialogContent>
                <div className="stdcontent">
                    {feature?<Renderentry showInstantRoll entry={{entries:feature.entries}} depth={2} doRoll={this.props.doRoll} doSubRoll={this.props.doSubRoll} addSpellToken={this.props.addSpellToken} character={character} getDiceRoller={this.props.getDiceRoller}/>:"Could not find feature to display."}
                </div>
            </DialogContent>
            <DialogActions>
                {this.props.getDiceRoller?this.props.getDiceRoller():null}
                <AddChatEntry character={character} type="Action" displayName={feature && feature.name||""} href={href}/>
                <Button onClick={onClose} color="primary">
                    close
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

class RenderFeatureDialog extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    render() {
        const {open, onClose} = this.props;
        if (!open) {
            return null;
        }

        const {feature,character,getDiceRoller} = this.props;

        return <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={onClose}>{feature?.name ||"Feature Details"}</DialogTitle>
            <DialogContent>
                <div className="stdcontent">
                    {feature?<Renderentry showInstantRoll entry={{entries:feature.entries}} depth={2} doRoll={this.props.doRoll} doSubRoll={this.props.doSubRoll} addSpellToken={this.props.addSpellToken} character={character} getDiceRoller={this.props.getDiceRoller}/>:"Could not find feature to display."}
                </div>
            </DialogContent>
            <DialogActions>
                {getDiceRoller?this.props.getDiceRoller():null}
                <Button onClick={onClose} color="primary">
                    close
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

function ConfigureAlert(props) {
    return <Tooltip title="Missing Selection"><span><span className={"f3 red fas fa-exclamation-triangle "+(props.className||"")}/>{" "}</span></Tooltip>;
}

function getNumberArray(num, includeZero) {
    const ret = [];
    for (let i=includeZero?0:1; i<=num; i++) {
        ret.push(i);
    }
    return ret;
}

function getAbilitiesMenus(from, unavail, val){
    const abilityList = [];

    for (let x in from) {
        const f = from[x];

        abilityList.push(<MenuItem className={(unavail && unavail.includes(f) && (val!=f))?"gray-60":null} key={f} value={f}>{stdvalues.abilityNames[f]}</MenuItem>);
    }
    return abilityList;
}

function getCastLevels(level) {
    const vals=[];
    for (let i =level; i<=9; i++){
        vals.push(i);
    }
    return vals;
}

function signedMod(bonus) {
    if (isNaN(bonus)) {
        return bonus;
    }
    return signedNum(bonus);
}

function getArrayVal(val) {
    if (val) {
        if (Array.isArray(val)) {
            return val;
        }
        return [val];
    }
    return [];
}

export {
    ExtraSpells,
    EquipmentList,
    FeatureListEdit,
    DamageListEdit,
    ActionConditions,
    ExtraDamage,
    RenderFeature,
    CheckPick,
    AbilityProficiency,
    AbilityRequirements,
    CustomLevelsListEdit,
    DescriptionOptionsListEdit,
    PickCountsByLevel,
    EditItemFeatureOptions,
    UsageListEdit,
    hasFeatureConfig,
    ConfigureAlert,
    FeatureConfigure,
    featureHasOptions,
    ActionConfig,
    RenderCharacterFeature,
    RenderFeatureDialog,
    getStringFromConditions,
    getStringFromConditionsStruct,
    MonsterSelector,
    PickCountsByCR,
    EditFeatureOptions,
    DurationByLevel,
    renderPrintFeatures,
    printDescriptionOptions,
    getClassTable
}