const React = require('react');
const {campaign,globalDataListener,areSameDeep} = require('../lib/campaign.js');
import sizeMe from 'react-sizeme';
const {marketplace} = require('../lib/marketplace.js');
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
const {escapeRegExp, SelectVal} = require('./stdedit.jsx');
const {ListFilter} = require('./listfilter.jsx');
const {joinCommaAnd,defaultSettings,defaultStorylines,packageGenres, packageTypes,defaultRulesets} = require('../lib/stdvalues.js');
const {BuyButton, ProductPrice, getPopularity} = require('./purchase.jsx');
const {Renderentry} = require('./entityeditor.jsx');
const {SearchResults} = require('./search.jsx');

const maxResults=10;
class MarketSearchBox extends React.Component {
    constructor(props) {
        super(props);
        this.state={searchVal:"", showSearch:false, selected:null, showResult:false};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.updateList();
    }

    componentDidMount() {
        globalDataListener.onChangeProducts(this.handleOnDataChange);
        this.updateList();
    }

    componentWillUnmount() {
        globalDataListener.removeProductsListener(this.handleOnDataChange);
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer=null;
        }
    }

    componentDidUpdate(prevProps) {
        if (!areSameDeep(prevProps.page,this.props.page)) {
            this.updateList();
            this.setState({text:this.props.page.q||""});
        }
    }

    updateList() {
        const page = this.props.page||{};
        updateList(page);
        this.setState({list:searchResultsList, fields:searchResultsFields});
    }

    onFocus() {
        if (this.focusTimer) {
            clearTimeout(this.focusTimer);
        }
        this.setState({hasFocus:true});
    }

    onBlur() {
        const t=this;

        if (this.focusTimer) {
            clearTimeout(this.focusTimer);
        }
        this.focusTimer=setTimeout( function () {
            t.setState({hasFocus:false});
            t.focusTimer=null;
        },200);
    }

    render() {
        return <div className="flex items-center bg-white pl1 pb--2 br2">
            <Autocomplete
                classes={{input:"f4"}}
                freeSolo
                autoComplete
                blurOnSelect
                inputValue={this.state.text||""}
                getOptionLabel={renderAutosuggest}
                options={["a", "b", "c"]}
                filterOptions={this.searchFilter.bind(this)}
                onInputChange={this.onInputChange.bind(this)}
                onChange={this.onChange.bind(this)}
                fullWidth
                renderInput={(params) => (
                    <TextField 
                        {...params}
                        placeholder="search"
                    />
                )}
            />
            <span className="fas fa-search f3 gray-60 hoverhighlight pa2" onClick={this.doSearch.bind(this)}/>
        </div>;
    }

    searchFilter(options, state) {
        const val = state.inputValue;
        const list = this.state.list||[];
        if (!val || !list.length) {
            return [];
        }
        const s = new SearchResults(val);
        const searchname = val.length>2;

        for (let i in list) {
            const p = list[i];
            if (searchname) {
                const m= s.test(p.displayName);
                if (m) {
                    s.add({
                        match:m,
                        href:"#product?id="+p.name,
                        name:p.displayName
                    });
                }
            }
    
            searchList(s, p.type, "Category: ", "t");
            searchList(s, p.genre, "Theme: ", "g");
            searchList(s, p.setting, "Setting: ", "s");
            searchList(s, p.storyline, "Storyline: ", "l");
            searchList(s, p.rulesets||defaultRulesets, "Ruleset: ", "r");
            if (p.publisher)
            {
                const m= s.test(p.publisher,5);
                if (m) {
                    s.add({
                        match:m,
                        href:"#?pub="+p.publisher,
                        param:"pub",
                        field:"Publisher: ",
                        name:p.publisher
                    });
                }
            }
        }
        return s.matches;
    }

    onInputChange(event,value, reason) {
        if (!value && reason=="clear") {
            this.onChange(event, value);
            return;
        }
        this.setState({text:value})
    }

    doSearch() {
        this.onChange(null, this.state.text);
    }

    onChange(event, value) {
        const newPage=Object.assign({}, this.props.page||{});
        if (!value) {
            delete newPage.q;
        } else if (typeof value == "string") {
            newPage.q=value;
        } else if (!value.param) {
            window.location.hash = value.href;
            return;
        } else {
            const vals = convertToArray(newPage[value.param])||[];
            const name = value.name.toLowerCase();
            if (!vals.find(function (a) {return a.toLowerCase()==name})) {
                vals.push(name);
            }
            newPage[value.param]=vals.join(",");
        }
        window.location.hash = pathFromPage(newPage);
        this.setState({text:""});
    }
}
const maxFeatured = 6;
class MarketSearchBase extends React.Component {
    constructor(props) {
        super(props);
        this.state={searchVal:"", showSearch:false, selected:null, showResult:false, sortOrder:"Newest"};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.updateList();
    }

    componentDidMount() {
        this.updateCouponInCart(this.props.page);
        globalDataListener.onChangeProducts(this.handleOnDataChange);
        this.updateList();
    }

    componentWillUnmount() {
        globalDataListener.removeProductsListener(this.handleOnDataChange);
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer=null;
        }
    }

    componentDidUpdate(prevProps) {
        this.updateCouponInCart(this.props.page);
        if (!areSameDeep(prevProps.page,this.props.page)) {
            this.updateList();
        }
    }

    updateList() {
        const page = this.props.page||{};
        updateList(page);
        this.setState({list:searchResultsList, text:page.q||"", fields:searchResultsFields});
    }

    updateCouponInCart(page) {
        if (page.code) {
            //make sure code is on cart
            const newCart = Object.assign({}, marketplace.cart);
            if (!newCart.coupons || !newCart.coupons.includes(page.code)) {
                if (!newCart.coupons) {
                    newCart.coupons = [page.code];
                } else {
                    newCart.coupons.push(page.code);
                }
                marketplace.setCart(newCart);
            }
        }
    }

    onFocus() {
        if (this.focusTimer) {
            clearTimeout(this.focusTimer);
        }
        this.setState({hasFocus:true});
    }

    onBlur() {
        const t=this;

        if (this.focusTimer) {
            clearTimeout(this.focusTimer);
        }
        this.focusTimer=setTimeout( function () {
            t.setState({hasFocus:false});
            t.focusTimer=null;
        },200);
    }

    findPublisherName(userId) {
        let list=this.state.list||[];
        for (let i in list) {
            if (list[i].userId == userId) {
                return list[i].publisher;
            }
        }
        return null;
    }

    render() {
        const {MarketProductDetails,ConfigurePublisherInfo} = require('./products.jsx');
        let list=this.state.list||[];
        const singleColumn = this.props.size.width < 500;
        const page=this.props.page;
        const credits = marketplace.purchaseCredits;
        const isAdmin = marketplace.isAdmin();
        let localRender = productRender;
        let publisherUserId;

        let inside=null;
        list.sort(sortWeight);
        let top=0;
        const check = Math.min(list.length, maxFeatured);
        for (let i=0; i<check; i++){
            if (getWeight(list[i])) {
                top=i+1;
            }
        }
        if (top>0) {
            const featured = list.slice(0,top);
            const rest = list.slice(top);
            rest.sort(sortTypesFunctions[this.state.sortOrder]);
            list = featured.concat(rest);
        } else {
            list.sort(sortTypesFunctions[this.state.sortOrder]);
        }

        if (page.page == "product") {
            inside = <div>
                <div className="f5 mb1"><a href={"#"+pathFromPage(page)}>&lt; Back to results</a></div>
                <MarketProductDetails id={page.id} page={page} onClickPublisher={this.onClickPublisher.bind(this)}/>
            </div>;
        } else if (list.length) {
            let header=null;

            let publisherInfo;
            if (page.code) {
                const coupons = marketplace.getCoupons(page.code);
                if (coupons && coupons.length) {
                    const clist = [];
                    const extra = [];
                    let image;
                    let foundName;

                    for (let i in coupons) {
                        const c=coupons[i];
                        if (!image && c.image) {
                            image=c.image;
                        }
                        const publisher = this.findPublisherName(c.user_id);
                        if (!foundName && c.name) {
                            clist.push(<div key="0t" className="mb2">
                                <div className="b f1 titlecolor titleborder bb">{c.name}</div>
                                <div className="i">ends {(new Date(c.end_date.toString())).toLocaleDateString(undefined,{ year: 'numeric', month: 'long', day: 'numeric' })}</div>
                            </div>);
                            foundName=true;
                        }
                        if ((clist.length<4) && c.description) {
                            clist.push(<div key={c.coupon_id} className="mb1">
                                <b>{publisher}:</b> {c.description}
                            </div>)
                        } else {
                            extra.push(publisher);
                        }
                    }
                    header=<div className="flex flex-wrap stdcontent">
                        {image?<div>{singleColumn?<img src={image} width="100%"/>:<img className="pr2" src={image} height="200px"/>}</div>:null}
                        <div className="flex-auto mw7 minw6">
                            {clist}
                            {extra.length?<div>Additional discounts from: <b>{extra.join(", ")}</b></div>:null}
                        </div>
                    </div>;
                }    
            }

            if (!header && isSinglePublisher(page)) {
                publisherUserId = list[0].userId;
                publisherInfo = marketplace.getPublisherInfo(publisherUserId);
                if (publisherInfo && !publisherInfo.enabled) {
                    publisherInfo=null;
                }
                if (!publisherInfo && isAdmin) {
                    publisherInfo={};
                } 
                if (publisherInfo) {
                    header = <div className="flex flex-wrap stdcontent">
                        {publisherInfo.image?<div>{singleColumn?<img src={publisherInfo.image} width="100%"/>:<img className="pr2" src={publisherInfo.image} height="200px"/>}</div>:null}
                        {publisherInfo.description?<div className="flex-auto mw7 minw6 ph2"><Renderentry entry={publisherInfo.description} noDice/></div>:null}
                        {isAdmin?<div>
                            <Button className="ml1" color="primary" variant="outlined" size="small" onClick={this.showConfigurePublisher.bind(this,true)}>Publisher Info</Button>
                            <ConfigurePublisherInfo open={this.state.showConfigurePublisher} userId={publisherUserId} onClose={this.showConfigurePublisher.bind(this,false)}/>
                        </div>:null}
                    </div>;
                }
            }
            inside = <div>
                {header}
                <div className="flex f5 notetext flex-wrap">
                    {(list.length>1)?<div className="f3 notetext titlecolor mr2">{list.length} products</div>:null}
                    {credits?<div className="f3 notetext titlecolor mr2">Credits ${credits.toFixed(2)}</div>:null}
                    <div className="flex-auto"/>
                    <div className="nudge-down--1">Sort&nbsp;by:&nbsp;</div>
                    <SelectVal value={this.state.sortOrder} values={sortTypes} onClick={this.setSort.bind(this)} selectClass="pt0 notetext f5 mt--1"/>
                </div>
                <ListFilter 
                    list={list} 
                    render={localRender} 
                    onClick={this.clickProduct.bind(this)} 
                    showThumbnails
                    border
                    hoverhighlight
                    hideSearch
                />
            </div>
        } else if (marketplace.loaded) {
            inside = <div className="hk-well ma1">
                No matching products.
            </div>
        };

        if (!singleColumn) {
            return <div className="flex w-100 h-100 relative">
                <div className="br titleborder pa2 h-100 mw6 minw-15 overflow-y-auto overflow-x-hidden">
                    <CategoryList page={page} fields={this.state.fields}/>
                </div>
                <div className="flex-auto w-50 pa2 h-100 overflow-y-scroll overflow-x-hidden" key={page.id || pathFromPage(page)}>
                    {inside}
                </div>
            </div>;
        } else {
            return <div className="w-100 h-100 relative ph2" key={page.id || pathFromPage(page)}>
                <CategoryList page={page} fields={this.state.fields} summary/>
                {inside}
            </div>;
        }
    }

    showConfigurePublisher(showConfigurePublisher){
        this.setState({showConfigurePublisher});
    }

    setSort(sortOrder) {
        this.setState({sortOrder});
    }

    onClickPublisher() {
        const page=this.props.page;

        const product = marketplace.getProductInfo(page.id);

        const newPage = Object.assign({}, this.props.page||{});
        const qval = convertToArray(newPage.pub)||[];
        const val = product.publisher.toLowerCase();
        
        const pos = qval.findIndex(function (a) {return a.toLowerCase()==val});
        if (pos <0) {
            qval.push(product.publisher);
        }
        newPage.pub = qval.join(",");
        
        window.location.hash = pathFromPage(newPage);

    }

    clickProduct(name) {
        const path = pathFromPage(this.props.page);
        if (path) {
            window.location.hash = "product"+path+"&id="+name;
        } else {
            window.location.hash = "product?id="+name;
        }
    }
}

const sortTypes=["Newest", "Popularity", "Price: Low to high", "Price: High to Low"];
const sortTypesFunctions = {
    "Newest":function (a,b){
        const pda = a.publishDate?((a.publishDate.toDate && a.publishDate.toDate()) ||(new Date(a.publishDate))).getTime():0;
        const pdb = b.publishDate?((b.publishDate.toDate && b.publishDate.toDate()) ||(new Date(b.publishDate))).getTime():0;

        if (pda != pdb) {
            return pdb-pda;
        }
        const app = getPopularity(a);
        const bpp = getPopularity(b);
        if (app != bpp) {
            return bpp-app;
        }
        return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase())
    }, 
    "Popularity":function (a,b){
        const app = getPopularity(a);
        const bpp = getPopularity(b);
        if (app != bpp) {
            return bpp-app;
        }

        const pda = a.publishDate?((a.publishDate.toDate && a.publishDate.toDate()) ||(new Date(a.publishDate))).getTime():0;
        const pdb = b.publishDate?((b.publishDate.toDate && b.publishDate.toDate()) ||(new Date(b.publishDate))).getTime():0;

        if (pda != pdb) {
            return pdb-pda;
        }
        return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase())
    },
    "Price: Low to high":function (a,b){
        const ap = (a.purchaseType=="subscription")?(a.monthlySalePrice||a.yearlySalePrice||0):(a.salePrice||0);
        const bp = (b.purchaseType=="subscription")?(b.monthlySalePrice||b.yearlySalePrice||0):(b.salePrice||0);
        if (ap != bp) {
            return ap-bp;
        }

        const app = getPopularity(a);
        const bpp = getPopularity(b);
        if (app != bpp) {
            return bpp-app;
        }
        return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase())
    },
    "Price: High to Low":function (a,b){
        const ap = (a.purchaseType=="subscription")?(a.monthlySalePrice||a.yearlySalePrice||0):(a.salePrice||0);
        const bp = (b.purchaseType=="subscription")?(b.monthlySalePrice||b.yearlySalePrice||0):(b.salePrice||0);
        if (ap != bp) {
            return bp-ap;
        }
        const app = getPopularity(a);
        const bpp = getPopularity(b);
        if (app != bpp) {
            return bpp-app;
        }
        return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase())
    }
}

function getPopularityInfo(p) {
    const popularity = ((marketplace.popularityData||{}).products||{})[p.name];
    if (!popularity) {
        return null;
    }
    return (getPopularity(p))+" "+JSON.stringify(popularity, null, 1);
}

function sortWeight(a,b){
    const wa = getWeight(a);
    const wb = getWeight(b);
    return wb-wa;
}

function getWeight(a) {
    return (!marketplace.isProductSubscriptionOwned(a) && marketplace.getProductFeaturedWeight(a.name))||0;
}

function renderAutosuggest(option) {
    if (typeof option=="string") {
        return option
    } else if (option.field) {
        return option.field+option.name
    } else {
        return option.name;
    }
}

function searchList(s, list, field, param) {
    if (!list) {
        return;
    }

    for (let i in list) {
        const v=list[i];
        const m= s.test(v,10);
        if (m) {
            s.add({
                match:m,
                href:"#product?"+param+"="+v,
                param,
                field,
                name:v
            });
        }
    }
}

class CategoryList extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

	render() {
        const page = this.props.page||{};
        const fields = this.props.fields;
        const summary = this.props.summary;
        const list=[];

        if (page.code) {
            const coupons = marketplace.getCoupons(page.code);
            if (coupons && coupons.length) {
                list.push(<div key="coupon" className="mb2">
                    <div className="mb1 f3 b">Coupon</div>
                    <div className="f4 truncate hoverhighlight" onClick={this.toggelParam.bind(this, "code", page.code)}>
                        <span className="far fa-check-square pa1"/>&nbsp;
                        {coupons[0].name||"Coupon Code"}
                    </div>
                </div>);
            }    
        }

        if (fields) {
            for (let x in categoryGroups) {
                const cg = categoryGroups[x];
                const qp = convertToArray(page[cg.queryParam]);
                const clist = [];
                const fvals = fields[cg.field];
                let foundCheck = false;
                
                if (fvals) {
                    for (let y in cg.values) {
                        const cv=cg.values[y];
                        const checked = inList(qp, cv);

                        if (fvals[cv.toLowerCase()] && (!summary || checked)) {
                            if (checked) {
                                foundCheck=true;
                            }
                            clist.push(<div key={cv} className="f4 truncate hoverhighlight" onClick={this.toggelParam.bind(this, cg.queryParam, cv)}>
                                <span className={checked?"far fa-check-square pa1":"far fa-square pa1"}/>&nbsp;
                                {cv}
                            </div>);
                        }
                    }
                }
                if (qp) {
                    for (let i in qp) {
                        const val = qp[i];
                        if (!inList(cg.values, val)) {
                            clist.push(<div key={val} className="f4 truncate hoverhighlight" onClick={this.toggelParam.bind(this, cg.queryParam, val)}>
                                <span className="far fa-check-square pa1"/>&nbsp;
                                <span className="ttc">{val}</span>
                            </div>);
                        }
                    }
                }

                if (clist.length && (foundCheck || !cg.min2 || (clist.length> 1))) {
                    list.push(<div key={x} className="mb2">
                        <div className="mb1 f3 b">{cg.label}</div>
                        {clist}
                    </div>);
                }
            }
        }

        return <div>
            {list}
        </div>
    }

    toggelParam(qp, val) {
        const newPage = Object.assign({}, this.props.page||{});
        const qval = convertToArray(newPage[qp])||[];
        val = val.toLowerCase();
        
        const pos = qval.findIndex(function (a) {return a.toLowerCase()==val});
        if (pos >=0) {
            qval.splice(pos,1);
        } else {
            qval.push(val);
        }
        newPage[qp] = qval.join(",");
        
        window.location.hash = pathFromPage(newPage);
    }
}

const includeParams = ["q", "t", "g", "s", "l", "r", "p", "o", "pub","all","code"]
function pathFromPage(page) {
    const params = [];
    for (let i in includeParams) {
        const p = includeParams[i];
        if (page[p]) {
            params.push(p+"="+encodeURIComponent(page[p]));
        }
    }
    if (params.length) {
        return "?"+params.join("&");
    }
    return "";
}

function isSinglePublisher(page) {
    if (page.pub) {
        const pubs = convertToArray(page.pub);
        if (pubs.length==1) {
            return true;
        }
    }
    return false;
}

function inList(l, val) {
    if (!l) {
        return false;
    }
    return l.find(function (a){return a.toLowerCase()==val.toLowerCase()});
}

const categoryGroups = [
    {
        label:"Pricing",
        values:["Free", "Purchase", "Subscription"],
        queryParam:"p",
        field:"pricing",
    },
    {
        label:"Purchased",
        values:["Owned", "Not Owned"],
        queryParam:"o",
        field:"owned",
        min2:true
    },
    {
        label:"Publisher",
        values:["Shard Tabletop", "Kobold Press", "Petersen Games","Troll Lord Games","Nord Games","Realmwarp Media","Underground Oracle"],
        queryParam:"pub",
        field:"publisher",
    },
    {
        label:"Category",
        values:packageTypes,
        queryParam:"t",
        field:"type",
    },
    {
        label:"Theme",
        values:packageGenres,
        queryParam:"g",
        field:"genre",
    },
    {
        label:"Setting",
        values:defaultSettings,
        queryParam:"s",
        field:"setting",
    },
    {
        label:"Storyline",
        values:defaultStorylines,
        queryParam:"l",
        field:"storyline",
    },
    {
        label:"Ruleset",
        values:[],
        queryParam:"r",
        field:"ruleset",
    }
];


function productRender(product, click, index) {
    const owned = marketplace.isProductSubscriptionOwned(product);
    const featured = !owned && marketplace.getProductFeaturedWeight(product.name) && (index < maxFeatured);
    const subscription = product.purchaseType=="subscription";
    const cdiscount = marketplace.getCouponProductDiscount(product);
    const salePrice = (product.salePrice||0) - Math.trunc((product.salePrice||0)*cdiscount)/100;
    const monthlySalePrice = (product.monthlySalePrice||0) - Math.trunc((product.monthlySalePrice||0)*cdiscount)/100;
    const yearlySalePrice = (product.yearlySalePrice||0) - Math.trunc((product.yearlySalePrice||0)*cdiscount)/100;


    return <div className="pt1 tc flex flex-column h-100">
        <div className="titlecolor titletext f5 ll-3" onClick={click}>{product.displayName}</div>
        {product.shortDescription?<div className="f5 gray-90 ll-3" onClick={click}>{product.shortDescription}</div>:null}
        <div className="flex-auto" onClick={click}/>
        <div className="f5 notetext">
            {featured?<div className="bg-light-red f4 tc pv--2">Featured</div>:null}
            {owned?<span className="b i">(Owned) </span>:null}
            {subscription&&product.monthlyPrice&&monthlySalePrice?<div><ProductPrice price={product.monthlyPrice} sale={monthlySalePrice}/>/month</div>:null}
            {subscription&&!monthlySalePrice&&product.yearlyPrice&&yearlySalePrice?<div><ProductPrice price={product.yearlyPrice} sale={yearlySalePrice}/>/year</div>:null}
            {!subscription?<ProductPrice price={product.listPrice} sale={salePrice}/>:null}
        </div>
    </div>;
}

function calcPricing(p) {
    switch (p.purchaseType) {
        case "single":
            if (p.salePrice) {
                return ["purchase"];
            }
            return ["free"];
        case "subscription":
            return ["subscription"];
    }
    return null;
}

function arrayMatch(val, filter) {
    if (!filter) {
        return true;
    }
    if (!val) {
        return false;
    }
    for (let i in filter) {
        const f = filter[i].toLowerCase().trim();
        if (val.find(function (a) {return a.toLowerCase().trim()==f})) {
            return true;
        }
    }
    return false;
}

function convertToArray(v) {
    if (!v) {
        return null
    }
    const vals = v.split(",").map(function (a){return a.trim()});
    if (!vals.length) {
        return null;
    }
    return vals;
}


let searchResultsList = null;
let searchResultsFields = null;
let searchResultsPage = null;
let searchResultsProducts = null;
let searchResultsPublishers = null;
let searchResultsFreeOnly = false;
let searchResultsUserId = null;
let searchResultsOwned = null;

function updateList(page) {
    const userId = campaign.userId;
    const products = marketplace.products;
    const freeOnly = false;
    const couponProd={};
    const couponPub={};
    let checkCoupons = false;

    if (page.code) {
        const coupons = marketplace.getCoupons(page.code);
        if (!coupons) {
            page = Object.assign({},page);
            delete page.code;
        } else {
            checkCoupons = true;
            for (let i in coupons) {
                const c = coupons[i];
                if (c.products) {
                    const productList = (c.products||"").split(",");
                    for (let x in productList) {
                        couponProd[productList[x]]=true;
                    }
                } else {
                    couponPub[c.user_id]=true;
                }
            }
        }
    }

    if (!areSameDeep(searchResultsPage, page) || !areSameDeep(products,searchResultsProducts) || !areSameDeep(searchResultsOwned,marketplace.owned) || (searchResultsFreeOnly !=freeOnly) || (userId != searchResultsUserId)) {
        const fields = {};
        const f = page.q?(new RegExp(escapeRegExp(page.q), "i")):null;
        const publisher = convertToArray(page.pub);
        const type = convertToArray(page.t);
        const genre= convertToArray(page.g);
        const setting = convertToArray(page.s);
        const storyline = convertToArray(page.l);
        const rulesets = convertToArray(page.r);
        const pricing = convertToArray(page.p);
        const ownership = userId?convertToArray(page.o):null;
        const featured=page.featured;
        const all = page.all;
        const publishers = [];

        const list=[];
        for (let i in products) {
            const p = products[i];
            if (((p.publishState == "public")||all||(p.userId==userId)) && 
                (!freeOnly|| (p.userId==userId) || ((p.purchaseType!="subscription") && !p.salePrice)) &&
                (!featured || marketplace.getProductFeaturedWeight(p.name))
            ) {
                const pval = calcPricing(p); 
                const owned = [marketplace.isProductSubscriptionOwned(p)?"owned":"not owned"];

                if ((!checkCoupons||((couponProd[p.name]||(couponPub[p.userId])) && (p.salePrice||p.monthlySalePrice||p.yearlySalePrice))) && 
                    (!f || f.test(p.displayName))) 
                {
                    addToFields("pricing", pval);
                    addToFields("genre", p.genre);
                    addToFields("type", p.type);
                    addToFields("setting", p.setting);
                    addToFields("storyline", p.storyline);
                    addToFields("ruleset", p.rulesets||defaultRulesets);
                    addToFields("publisher", p.publisher?[p.publisher]:null);
                    addToFields("owned", owned)

                    if (arrayMatch(p.type, type) &&
                        arrayMatch(p.genre, genre) &&
                        arrayMatch(p.setting, setting) &&
                        arrayMatch(p.storyline, storyline) &&
                        arrayMatch(p.rulesets||defaultRulesets, rulesets) &&
                        arrayMatch(pval, pricing) &&
                        arrayMatch(owned, ownership)
                    ) {
                        addToPublishers(p);
                        if (arrayMatch([p.publisher], publisher)) {
                            list.push(products[i]);
                        }
                    }
                }
            }
        }
        publishers.sort(function (a,b) {return b.sum-a.sum});
        searchResultsPublishers=[];
        for (let i in publishers) {
            const p = publishers[i];
            if (i < 10) {
                searchResultsPublishers.push(p.publisher);
            }
        }
        if (categoryGroups[2].label != "Publisher") {
            throw(new Error("bad publisher position"))
        }
        categoryGroups[2].values = searchResultsPublishers;

        searchResultsList = list;
        searchResultsFields = fields;
        searchResultsPage = page;
        searchResultsFreeOnly = freeOnly;
        searchResultsUserId = userId;
        searchResultsOwned = marketplace.owned;
        searchResultsProducts=Object.assign({},products);

        function addToFields(col, vals) {
            if (!vals) {
                return;
            }
            if (!fields[col]) {
                fields[col]={};
            }
            const f = fields[col];
            for (let i in vals) {
                f[vals[i].trim().toLowerCase()] = 1;
            }
        }

        function addToPublishers(p) {
            const pop = getPopularity(p, 0.5, 2);
            const pub = p.publisher.trim();
            const pos = publishers.findIndex(function (a){return a.publisher==pub});
            if (pos >= 0) {
                publishers[pos].count++;
                publishers[pos].sum+=pop;
            } else {
                publishers.push({publisher:pub, count:1, sum:pop});
            }
        }
    }
}

const MarketSearch = sizeMe({monitorHeight:false, monitorWidth:true})(MarketSearchBase);

export {
    MarketSearch,
    MarketSearchBox,
    convertToArray,
    productRender,
    pathFromPage
};