require("@babel/polyfill");
const {campaign} = require('../lib/campaign.js');
const {firebase} = require("./firebase.jsx");
const React = require('react');
const ReactDOM = require('react-dom');
import { MuiThemeProvider, createMuiTheme, StylesProvider } from '@material-ui/core/styles';
const templates = require('../lib/built-templates.json');

const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';

const {CheckVal, SelectVal} = require('./stdedit.jsx');

class ThemeConfig extends React.Component {
    constructor(props) {
        super(props);

        this.state= {theme:props.theme||{}};
    }

    componentDidMount() {
    }
  
    componentWillUnmount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.open!= prevProps.open) {
            this.setState({theme:this.props.theme||{}});
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        const theme = this.state.theme||{};
        const darkMode = campaign.getUserSettings().darkMode||false;
        const {themeVal, themes} = resolveThemes(theme);
        const {ArtPicker} = require('./renderart.jsx');
        const art = campaign.getArtInfo(theme.backgroundArt);

        return <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClick={this.props.onClose}>Character Sheet Styling</DialogTitle>
            <DialogContent>
                <SelectVal className="mb1" fullWidth values={themes} value={themeVal} onClick={this.setThemeValue.bind(this, "theme")} helperText="Theme"/>
                <div className="hk-well mb2">
                    <SelectVal className="mb1" selectClass="notetext f4" fullWidth values={getThemeValues("borders",darkMode)} value={theme.border||borderDefault} onClick={this.setThemeValue.bind(this, "border")} helperText="Border Style"/>
                    <SelectVal className="mb1" menuClassName="tc" selectClass="notetext f4" fullWidth values={getThemeValues("borderColors",darkMode)} value={theme.borderColor||borderColorDefault} onClick={this.setThemeValue.bind(this, "borderColor")} helperText="Border Color"/>
                    <SelectVal className="mb1" selectClass="notetext f4" fullWidth values={getThemeValues("colorSchemes",darkMode)} value={theme.colorScheme||colorSchemeDefault} onClick={this.setThemeValue.bind(this, "colorScheme")} helperText="Color Scheme"/>
                    <SelectVal className="mb1" selectClass="notetext f4" fullWidth values={getThemeValues("backgrounds",darkMode)} value={theme.background||backgroundDefault} onClick={this.setThemeValue.bind(this, "background")} helperText="Background"/>
                    {theme.background=="custom"?<div className="mv1">
                        <b>Background Image:</b> {art?.displayName}
                        <Button className="mh2" onClick={this.onPickBackground.bind(this)} color="primary" size="small" variant="outlined">Pick</Button>
                        <ArtPicker defaultType="Wallpaper" open={this.state.showPickBackground} onClose={this.pickBackground.bind(this)}/>
                    </div>:null}
                </div>
                {!checkPerms({"products":["1lptzx3bblfjy4bm","0mgps243ynv32dvk","2yarsenaw01v6pj7"]})?<div className="mb2">For <a href="https://shardtabletop.com/howto/what-character-sheet-styles-are-available">additional options</a> consider getting a <a href="/marketplace#shardsubscriptions">Shard subscription</a>.</div>:null}
                <div className="mb2 cst herobackground relative">
                    <style dangerouslySetInnerHTML={{__html:getThemeHref("cst", theme,campaign.getUserSettings().darkMode)}}/>
                    <div className="characterSheet">
                        <div className="flex justify-around items-stretch abilityBlock">
                            <CharacterAbility ability="str"/>
                            <CharacterAbility ability="dex"/>
                            <CharacterAbility ability="con"/>
                            <CharacterAbility ability="int"/>
                            <CharacterAbility ability="wis"/>
                            <CharacterAbility ability="cha"/>
                        </div>
                        <div className="savingThrows">
                            <div>
                                <div className="theader">Saving Throws</div>
                                <div className="flex justify-around items-stretch">
                                    <div className="savingThrow w-16 hoverroll">str +0</div>
                                    <div className="savingThrow w-16 hoverroll">dex +0</div>
                                    <div className="savingThrow w-16 hoverroll">con +0</div>
                                    <div className="savingThrow w-16 hoverroll">int +0</div>
                                    <div className="savingThrow w-16 hoverroll">wis +0</div>
                                    <div className="savingThrow w-16 hoverroll">cha +0</div>
                                </div>
                            </div>
                        </div>
                        <div className="profBlock">
                            <div>
                                <b>Languages</b> common, elf, giant
                            </div>
                        </div>
                        <div className="featureBlock">
                            <div>
                                <div className="flex items-center">
                                    <div className="flex-auto theader">
                                        <span className="ttitle">Class Feature</span> INT DC10
                                    </div>
                                </div>
                                <div className="mv1">
                                    <b>Feature Name.</b> Some description of the feature.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <CheckVal label="Dark mode" value={campaign.getUserSettings().darkMode||false} onChange={this.toggleDarkMode.bind(this)}/>
                <Button onClick={this.onClose.bind(this,true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.onClose.bind(this,false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    onPickBackground() {
        this.setState({showPickBackground:true})
    }

    pickBackground(art) {
        if (art) {
            this.setThemeValue("backgroundArt", art.name);
        }
        this.setState({showPickBackground:false})
    }

    setThemeValue(name, value) {
        let theme;
        if (name=="theme") {
            theme = (templates.themes[value]||{}).values||{};
        } else {
            theme = Object.assign({}, this.state.theme||{});
            theme[name]=value;
        }
        this.setState({theme});
    }

    onClose(save) {
        this.props.onClose(save?this.state.theme:null);
    }

    toggleDarkMode() {
        campaign.updateUserSettings({darkMode:!campaign.getUserSettings().darkMode});
    }
}

class CharacterAbility extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }
    
    render() {
        const {ability} = this.props;

        return <div className="w-16 tc abilityScore">
            <div>
                <div className="ability hover-bg-contrast">
                    {ability}
                </div>
                <div className="modifierScore hoverroll">
                    10 <span className="f2">(+0)</span>
                </div>
                <div className="modifier hoverroll">
                    +0
                </div>
                <div className="score hoverroll">
                    <span>10</span>
                </div>
            </div>
        </div>
    }
}

const themeBase = {
    typography: {
        useNextVariants: true,
        fontFamily:'"Convergence", sans-serif',
        h1:{color:"var(--mainHighlightColor)",fontFamily:'"Libre Baskerville", serif'},
        h2:{color:"var(--mainHighlightColor)",fontFamily:'"Libre Baskerville", serif'},
        h3:{color:"var(--mainHighlightColor)",fontFamily:'"Libre Baskerville", serif'},
        h4:{color:"var(--mainHighlightColor)",fontFamily:'"Libre Baskerville", serif'},
        h5:{color:"var(--mainHighlightColor)",fontFamily:'"Libre Baskerville", serif'},
        h6:{color:"var(--mainHighlightColor)",fontFamily:'"Libre Baskerville", serif', fontSize:"17px"},
        body1:{color:"var(--mainTextColor)"},
        body2:{color:"var(--mainTextColor)"},
        body1Next:{color:"var(--mainTextColor)"},
        body2Next:{color:"var(--mainTextColor)"},
    },
    overrides:{
        MuiMenuItem:{
            root:{
                color:"var(--mainTextColor)",
                padding:"5px, 10px, 5px, 10px"
            }
        },
        MuiTooltip:{
            tooltip: {
                fontSize: "11px"
            }
        },
        MuiDialogTitle:{
            root:{
                fontFamily:'"Libre Baskerville", serif',
                fontSize:"22px",
                padding:"16px 24px 0px 24px",
                color:"var(--mainHighlightColor)"
            }
        },
        MuiBackdrop:{
            root:{
                backgroundColor:"rgba(0,0,0,0.25)"
            }
        },
        MuiDialogContent:{
            root:{
                padding:"0px 24px 0px 24px",
                margin:"8px 0px 8px 0px",
                backgroundColor:"var(--mainBackgroundColor)",
                minHeight: "42px"
            }
        },
        MuiInputBase:{
            root:{
                color:"var(--mainTextColor)",
            }
        },
        MuiSelect:{
            icon:{
                color:"var(--lightMainTextColor)",
            }
        },
        MuiFormHelperText:{
            root:{
                color:"var(--lightMainTextColor)",
            }
        },
        MuiButton:{
            sizeSmall: {
                padding:"2px 4px 2px 4px"
            },
            root:{
                color:"var(--mainTextColor)"
            },
            outlined:{
                borderColor:"var(--mainTextColor)"
            }
        },
        MuiChip:{
            root:{
                backgroundColor:"var(--mainHighlightColor)",
                color:"var(--mainBackgroundColor)"
            },
            deleteIcon:{
                color:"var(--mainBackgroundColor)"
            }
        },
        MuiPaper:{
            root:{
                color:"var(--mainTextColor)"
            }
        },
        MuiSpeedDialAction:{
            staticTooltip:{
                backgroundColor:"rgba(128,128,128,0.5)"
            },
            staticTooltipLabel:{
                boxShadow:"0px 2px 1px -1px rgb(128 128 128 / 20%), 0px 1px 1px 0px rgb(128 128 128 / 14%), 0px 1px 3px 0px rgb(128 128 128 / 12%)"
            }
        },
        MuiCheckbox:{
            root:{
                color:"var(--mainTextColor)",
            }
        }
    }
};

const theme = createMuiTheme(Object.assign({
    palette:{
        primary:{main:"#58170D"},
        secondary:{main:"#363217"},
        background:{
            default:"#eee7cd",
            paper:"#eee7cd"
        }
    }
},themeBase));

const themeDark = createMuiTheme(Object.assign({
    palette:{
        mode:"dark",
        primary:{main:"rgba(238, 231, 205, 0.8)"},
        secondary:{main:"rgba(238, 231, 205, 0.8)"},
        background:{
            default:"#182026",
            paper:"#182026"
        }
    }
},themeBase));

function getThemeValues(param, darkMode) {
    const ret = [];
    const vals = templates[param]||{};

    //ret.push({name:"Default", value:"default"});
    for (let value in vals) {
        const v = vals[value];
        if (checkPerms(v.perms)) {
            if (param == "borderColors") {
                ret.push({value, name:<div className="dib w-100 tc"><span style={{border:"2px solid "+(darkMode?(v.dark||v.light):v.light)}} className="ph2 minw5 dib">{v.displayName}</span></div>});
            } else {
                ret.push({value, name:v.displayName});
            }
        }
    }
    return ret;
}

function checkPerms(perms) {
    if (!perms || campaign.allTemplates) {
        return true;
    }
    if (perms.products) {
        for (let p of perms.products) {
            if (campaign.isOwned(p)) {
                return true;
            }
        }
    }

    if (perms.packages) {
        for (let p of perms.packages) {
            if (campaign.isPackageOwned(p)) {
                return true;
            }
        }
    }

    return false;
}

function resolveThemes(theme) {
    const themes = [];
    const vals = templates.themes||{};
    let themeVal = "custom";

    for (let value in vals) {
        const v = vals[value];
        if (checkPerms(v.perms)) {
            const vv = v.values||{}
            themes.push({value, name:v.displayName});
            if (((theme.border||borderDefault)==vv.border) &&
                ((theme.borderColor||borderColorDefault)==vv.borderColor) &&
                ((theme.colorScheme||colorSchemeDefault)==vv.colorScheme) &&
                ((theme.background||backgroundDefault)==vv.background)
            ) {
                themeVal = value;
            }
        }
    }
    if (themeVal=="custom") {
        themes.push({value:"custom", name:"Custom"});
    }
    return {themes,themeVal};
}

const borderColorDefault = "shard";
const borderDefault = "classic";
const colorSchemeDefault = "shard";
const backgroundDefault = "paper";
const defaultTheme = {
    border:borderDefault,
    borderColor:borderColorDefault,
    colorScheme:colorSchemeDefault,
    background:backgroundDefault
}

function getThemeHref(key, theme,darkMode) {
    const {border,borderColor,colorScheme,background} = (theme||{});
    const borderColorT = templates.borderColors[borderColor] || templates.borderColors[borderColorDefault];
    const colorSchemeT = templates.colorSchemes[colorScheme] || templates.colorSchemes[colorSchemeDefault];
    const borderT = templates.borders[border] || templates.borders[borderDefault];
    const backgroundT = templates.backgrounds[background] || templates.backgrounds[backgroundDefault];

    const borderColorVal = darkMode?borderColorT.dark||borderColorT.light:borderColorT.light;
    const backgroundColor = darkMode?colorSchemeT.dark||colorSchemeT.light:colorSchemeT.light;

    let style = borderT.val.replace(/%23eee7cd/ig, encodeURIComponent(backgroundColor)).replace(/%2358170D/ig,encodeURIComponent(borderColorVal));
    style = backgroundT.val+" \r\n"+style;
    if (colorSchemeT.val) {
        style = colorSchemeT.val+" \r\n"+style;
    }
    style = ".characterSheet { --mainBorderColor:"+borderColorVal+"} \r\n "+ style;
    if (theme.background == "custom") {
        const art = campaign.getArtInfo(theme.backgroundArt);

        if (art) {
            style = '.characterSheet { background-image: url("'+art.url+'")}\r\n' + style;
            //console.log("style", style, art.url);
        }
    }

    return rewriteClasses("."+key, style);
}

const classRewriteList = ["characterSheet", "characterHeader", "abilityBlock", "abilityScore",
    "savingThrows", "statBlock", "attuneBlock", "createItemBlock", "companionBlock",
    "profBlock","descBlock","logBlock",
    "skillBlock","weaponsBlock","featureBlock","spellBlock","equipmentBlock","currentLogBlock",
    "traitBlock", "coinBlock"
];

function rewriteClasses(key, style) {
    for (let c of classRewriteList) {
        const f=new RegExp("\."+c, "g");
        style = style.replace(f, key+" ."+c);
    }
    return style;
}

export {
    theme,
    themeDark,
    getThemeHref,
    ThemeConfig
}