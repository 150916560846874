const React = require('react');
const {campaign,globalDataListener,upgradeItem,replaceMetawords} = require('../lib/campaign.js');
const {displayMessage} = require('./notification.jsx');
const Parser = require("../lib/dutils.js").Parser;
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
const {GenCharacterName} = require('./randomname.jsx');
const {EntityEditor,Renderstring,Renderentry} = require('./entityeditor.jsx');
const {DeleteWithConfirm, NumberAdjust, ConfirmDialog,AskYesNo, PickVal, TextLabelEdit, MaxNumberAdjust, NumberAdjustPlusMinus, HDivider, SelectVal,TextBasicEdit, CheckVal,alwaysArray} = require('./stdedit.jsx');
const {CreatureArt,ArtZoomList} = require('./renderart.jsx');
const {Character, getFeaturesTable,getMergedCustomLevels, itemMatchFilter,nameFromFeatureParams, getTextFromDistanceStruct, isCarried} = require('../lib/character.js');
const {Race,RaceSelector,RaceDialog} = require('./renderraces.jsx');
const {ShowClass,ClassSelector,SubclassSelector} = require('./renderclass.jsx');
const {RenderFeature,hasFeatureConfig, FeatureConfigure,ConfigureAlert,ActionConfig} = require('./features.jsx');
const {SpellPicker,getSpellAttributes} = require('./renderspell.jsx');
const {CustomPicker, CustomDialog, CustomAddDialog} = require('./customtable.jsx');
const {GenericDialog} = require('./generic.jsx');
const {ItemBuyPicker, ItemDialog, ItemCreatePicker, getDamageString, getItemPropertyList, getTotalWeight, SharedTreasurePicker, StartingEquipmentPicker, ItemFeatureUsageSlots,DeleteItemFromCharacter,getWeaponProficiency, getSortedEquipment,itemsEquivalent} = require('./items.jsx');
const {MonsterPicker, simplifyMonster,matchRestriction} = require("./rendermonster.jsx");
const {MonObj,updateMonsterAttributes,updateTextActions,findFeatures,findDamageType,findFixedDamage,findAttack,labelUses,addUses,findSpellcasting,findSave} = require('../lib/monobj.js');
const {Background, BackgroundDialog, BackgroundSelector} = require('./renderbackgrounds.jsx');
const {SessionHistoryLog, SessionLogList} = require('./sessionhistory.jsx');
const {AddChatEntry,getBasicHref,LinkHref}=require('./renderhref.jsx');
import sizeMe from 'react-sizeme';
const {getDiceFromString, dicerandom, DiceMenu, doRoll,damagesFromExtraDamage} = require('./diceroller.jsx');
const {Chat} = require('../lib/chat.js');
const {RenderChat,ChatButton} = require('./renderchat.jsx');
const {ShortRest,LongRest} = require('./rest.jsx');
const {CompanionsBlock} = require('./rendercompanions.jsx');
const {CharacterSpells, getAllSpells,getExtraSpells} = require('./characterspells.jsx');
const {ExtensionsPicker,CharacterOptionDialog} = require('./renderextensions.jsx');
const {getThemeHref,ThemeConfig} = require('./theme.jsx');
const {AddObject} = require('./objects.jsx');
const {DiceTower} = require('./dicetower.jsx');
const {ActionBlock,UsageSlots, getDamagesInfo} = require('./actionblock.jsx');

import ReactToPrint from 'react-to-print';

const {
    armorTypes,
    conditionList,
    damageTypesList,
    sizeList,
    initiativeValuesList,
    proficiencyTypes,
    bonus,
    abilityNames,
    characterLevels,
    allignmentTypes,
    signedNum,
    bonusTen,
    actionTypeMap,
    gamesystemOptions,
    baseCoins
} = require('../lib/stdvalues.js');

const minTraySize = 120;

function getMenus(list) {
    let i, ret=[];

    for (i in list){
        const e=list[i];
        ret.push(<MenuItem key={e} value={e}>{e}</MenuItem>);
    }
    return ret;
}

function getNumberMenus(list) {
    let i, ret=[];

    for (i in list){
        const e=list[i];
        ret.push(<MenuItem key={e} value={Number(e)}>{e}</MenuItem>);
    }
    return ret;
}


class CharacterSheetBase extends React.Component {

    constructor(props) {
        super(props);

        this.characterType = props.type||"players";
        this.handleOnDataChange = this.onDataChange.bind(this);
        this.handleOnBasicDataChange = this.onBasicDataChange.bind(this);
        this.idBase=campaign.newUid();
        this.hparentRef = React.createRef();
        const {characterSplit} = campaign.getUserSettings();

        this.state= {character:null, editable:false, hPercent:characterSplit??20};
    }

    componentDidMount() {
        this.setState({character:this.getCharacter(this.props.name)});
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, this.characterType);

        // pick up changes in companions in combat tracker
        globalDataListener.onChangeCampaignContent(this.handleOnBasicDataChange, "adventure");

        // pick up changes in features that might change what's on the character sheet
        globalDataListener.onChangeCampaignContent(this.handleOnBasicDataChange, "classes");
        globalDataListener.onChangeCampaignContent(this.handleOnBasicDataChange, "races");
        globalDataListener.onChangeCampaignContent(this.handleOnBasicDataChange, "backgrounds");
        globalDataListener.onChangeCampaignContent(this.handleOnBasicDataChange, "feats");
        globalDataListener.onChangeCampaignContent(this.handleOnBasicDataChange, "customTypes");
        globalDataListener.onChangeUserSettings(this.handleOnBasicDataChange);
    }
  
    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, this.characterType);
        globalDataListener.removeCampaignContentListener(this.handleOnBasicDataChange, "adventure");
        globalDataListener.removeCampaignContentListener(this.handleOnBasicDataChange, "classes");
        globalDataListener.removeCampaignContentListener(this.handleOnBasicDataChange, "races");
        globalDataListener.removeCampaignContentListener(this.handleOnBasicDataChange, "backgrounds");
        globalDataListener.removeCampaignContentListener(this.handleOnBasicDataChange, "feats");
        globalDataListener.removeCampaignContentListener(this.handleOnBasicDataChange, "customTypes");
        globalDataListener.removeUserSettingsListener(this.handleOnBasicDataChange);
    }

    componentDidUpdate(prevProps) {
        if (this.props.name != prevProps.name) {
            this.setState({character:this.getCharacter(this.props.name), editable:false});
        }
    }

    onDataChange() {
        const character = this.getCharacter(this.props.name);
        this.setState({character});
    }

    onBasicDataChange() {
        this.setState({id:campaign.newUid()});
    }

    render() {
        const character = this.state.character;
        if (!character) {
            return null;
        }
        
        const editable = this.state.editable;
        const readonly = this.props.readonly;
        const print = this.props.print;
        const allSpells = getAllSpells(character);
        const {hPercent} = this.state;
        const {height,width} = this.props.size;
        let cHeight = Math.max(minTraySize,(hPercent||0)*0.01*(height||0));
        let allSmall;
        const small = (width < 600);
        const {weaponProficiencies} = campaign.getItemExtensions();
        let theme = character.getProperty("charactersheetTheme")||{};
        const darkMode = print?false:campaign.getUserSettings().darkMode;
        const isBF = character.isBF;
        if (print) {
            theme=Object.assign({}, theme);
            theme.background="blank";
            theme.colorScheme="bw";
            theme.borderColor="bw";
        }

        if (cHeight < 125) {
            allSmall=true;
        }

        return <div className={(small?"csSmall ":"csLarge ")+(this.props.hideDice?"":"h-100 flex flex-column ")+"f5 notetext mw9 s"+this.idBase} ref={this.hparentRef}>
            <style dangerouslySetInnerHTML={{__html:getThemeHref(("s"+this.idBase), theme, darkMode)}}/>
            <div className={this.props.hideDice?null:"flex-auto h1 overflow-y-auto overflow-x-hidden"}>
                <div id={this.idBase+"top"}/>
                {this.props.noNav?null:<div className="sticktop z-5">
                    <table className="w-100 f8 ttu tc texttools">
                        <tbody>
                            <tr>
                                <td onClick={this.gotoSection.bind(this,"top")}>top</td>
                                <td onClick={this.gotoSection.bind(this,"weapons")}>actions</td>
                                {allSpells.length?<td onClick={this.gotoSection.bind(this,"spells")}>{character.t("Spells")}</td>:null}
                                <td onClick={this.gotoSection.bind(this,"features")}>features</td>
                                <td onClick={this.gotoSection.bind(this,"equipment")}>equipment</td>
                                <td onClick={this.gotoSection.bind(this,"log")}>log</td>
                                <td onClick={this.gotoSection.bind(this,"traits")}>traits</td>
                            </tr>
                        </tbody>
                    </table>
                </div>}
                <div className="ph1 pb1">
                    <div className="characterHeader pt--2 avoidBreak">
                        <div className="flex">
                            <div className="flex-auto">
                                <div className="mb1">
                                    {this.getCharacterMenu()}
                                    <div className="titletext titlecolor b f1 hoverhighlight" onClick={readonly?null:this.showCharacterMenu.bind(this,true)}>{readonly?null:<span className="fas fa-ellipsis-v"/>}{character.displayName||"(no name)"}</div>
                                    {character.playerName?<div className="titletext titlecolor f6 ml2 defaultcolor"><b>Player Name </b>{character.playerName}</div>:null}
                                    {readonly?null:this.getMissingConfig()}
                                    {character.shape?<div className="titletext titlecolor b f2">(Shape: {character.shape.displayName || character.shape.name}){!readonly?<ShapeChange character={character}/>:null}</div>:null}
                                    <div className="titletext titlecolor f5 mv1">
                                        {this.getOrigin(character)}
                                        {" "}<Tooltip title="Show Background"><span className="hoverhighlight" onClick={this.clickedBackground.bind(this)}>{character.backgroundDisplayName}</span></Tooltip>
                                    </div>
                                    <div className="mb1 titletext titlecolor f5">
                                        <Tooltip title="Pick Options"><span className={"hover-bg-contrast"+(((character.xp>=characterLevels[character.level+1])&&character.level)?" fgreen b underline":"")} onClick={this.onShowClassEdit.bind(this,true)}>{readonly?null:<span className="fas fa-user-cog"/>}Level {character.level||"0"}</span></Tooltip>
                                        {" "}<RenderClassText noDiv character={character} editable={editable}/>
                                        <span className="notetext ml2">
                                            <b>XP </b><NumberAdjust className="hover-bg-contrast" positive value={character.xp} altText={character.xp.toLocaleString() + " / "+characterLevels[character.level+1].toLocaleString()} onClose={this.onAdjustVal.bind(this)}/>
                                        </span>
                                        {campaign.isDefaultCampaign()?<span className="ml1 br1 titleborder ba pa--2 hoverhighlight notetext f7" onClick={character.advanceTurn.bind(character)}>NEXT ROUND</span>:null}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <CreatureArt 
                                    onClick={this.showArtZoom.bind(this)} 
                                    onPickToken={readonly?null:this.pickToken.bind(this)}
                                    defaultType="Character Token"
                                    character={character}
                                    defaultName={character.displayName}>
                                    {(character.imageURL||readonly)?<img width="70" height="70" src={character.imageURL || "/blankplayer.png"}/>:null}
                                </CreatureArt>
                            </div>
                        </div>
                    </div>
                    <div className="characterSheet">
                        <div className="statBlock avoidBreak">
                            <div>
                                <div className="flex">
                                    <div className="statBlockBorderR tc flex-auto hover-bg-contrast overflow-hidden ph1" onClick={readonly?null:this.clickedArmor.bind(this)}>
                                        <div>
                                            <div className={(small?"f6":"f3")+" titlecolor b"}>
                                                {character.hasArmorInfo()?<span className="mr1 fas fa-shield-alt orange"/>:null}
                                                AC
                                            </div>
                                            <div className="f3">
                                                {character.ac || 10}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="statBlockBorderR tc flex-auto overflow-hidden">
                                        <div className={readonly?null:"hoverhighlight"} onClick={readonly?null:this.rollInitiative.bind(this)}>
                                            <div className={(small?"f6":"f3")+" titlecolor b"}>
                                                Initiative
                                            </div>
                                            <div className="tc">
                                                {readonly?null:<span className="fas fa-dice-d20 titlecolor f3 ph1 hoverroll"/>}
                                                <PickVal noEdit={readonly} value={character.initiative} noDiv isPossibleNum onClick={this.changeInitiative.bind(this)} values={initiativeValuesList}>
                                                    <span className="hover-bg-contrast ph1 dib f3">
                                                        {(character.initiative==null)?"--":(character.initiative||0)}
                                                        {character.initiativeBonus?<span className="f6"> ({signedNum(character.initiativeBonus)})</span>:null}
                                                    </span>
                                                </PickVal>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="statBlockBorderR tc flex-auto overflow-hidden">
                                        <div>
                                            <div className={(small?"f6":"f3")+" titlecolor b"}>
                                                Hit Dice
                                            </div>
                                            <div className="f3">
                                                {this.getHitDice()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tc flex-auto overflow-hidden">
                                        <div className="flex">
                                            <div className="f3 mr1 flex-auto hover-bg-contrast" onClick={readonly?null:this.clickHP.bind(this)}>
                                                <div className={(small?"f6":"f3")+" titlecolor b"}>Hit Points</div>
                                                <div className="flex justify-center">
                                                    <span className="ph1 w-30 tc">{character.hp}</span>
                                                    <div>/</div>
                                                    <span className="ph1 w-30 tc">{character.maxhp}</span>
                                                </div>
                                            </div>
                                            <div className="tc hover-bg-contrast pr1" onClick={readonly?null:this.clickTemphp.bind(this)}>
                                                <div className={(small?"f6":"f5 pt--3")+" titlecolor truncate"}>Temp HP</div>
                                                <div className="f5 ph2">{character.temphp||"--"}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {readonly?null:<div className="statBlockBorderL tc titlecolor flex-auto overflow-hidden">
                                        <div>
                                            <PickVal noEdit={readonly} value="Short Rest" onClick={this.doRest.bind(this)} values={["Short Rest", "Long Rest"]}>
                                                <div className="hover-bg-contrast">
                                                    <div className={(small?"f6":"f3")+" b"}>Rest</div>
                                                    <div className="fas fa-bed f3 ph1"/>
                                                </div>
                                            </PickVal>
                                        </div>
                                    </div>}
                                </div>
                                {((character.hp==0)&&character.maxhp)?<div className="mt1 f3 encountertablehover br3">
                                    {(character.deathFails>0)&&(character.deathSaves>0)&&!readonly?<span className="fas fa-dice-d20 titlecolor f3 pa1 hoverroll" onClick={this.rollDeathSave.bind(this)}/>:null}
                                    Death
                                    {((character.deathFails>0)||(character.deathSaves==0))?<span className="nowrap">  Saves<MaxNumberAdjust max={3} value={character.deathSaves} onAdjustValue={this.changeAttribute.bind(this,"deathSaves")}/></span>:null}
                                    {(character.deathSaves>0)?<span className="nowrap">  Fails<MaxNumberAdjust max={3} value={character.deathFails} onAdjustValue={this.changeAttribute.bind(this,"deathFails")}/></span>:null}
                                    <b>{(character.deathSaves==0)?" Stable":(character.deathFails==0)?" Dead":""}</b>
                                </div>:null}
                            </div>
                        </div>
                        <div className="profBlock avoidBreak">
                            <div>
                                <table className="w-100" style={{borderSpacing:0}}>
                                    <tbody>
                                        <tr>
                                            <td className="w-50">
                                                <PickVal noEdit={readonly} value={character.size} onClick={this.changeAttribute.bind(this,"size")} values={sizeList}>
                                                    <span className="hover-bg-contrast mb1"><b>Size </b>{Parser.sizeAbvToFull(character.size)}</span>
                                                </PickVal>
                                            </td>
                                            <td className="w-50">
                                                <b>Proficiency Bonus</b> +{character.proficiency}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-50">
                                                <TextClick noDiv label="Speed" text={getTextFromDistanceStruct(character.speed)} editable={editable} onClick={editable?this.clickedMovement.bind(this):null}/>
                                                {isBF?<div>
                                                    <b>Luck</b> <MaxNumberAdjust inverse max={character.getUsageMax({usageName:"luck"})} value={character.getUsageVal({usageName:"luck"})} onAdjustValue={readonly?null:this.setUsageValue.bind(this,"luck")}/> {readonly?null:<span className="fas fa-dice-d20 titlecolor ph1 hoverroll" onClick={this.rollNewLuck.bind(this)}/>}
                                                </div>:null}
                                            </td>
                                            <td className="w-50">
                                                <b>Passive Perception</b>&nbsp;{character.passive || 10}
                                                {character.passiveInsight?<span>&nbsp;| <b>Insight</b>&nbsp;{character.passiveInsight}</span>:null}
                                                {character.passiveInvestigate?<span>&nbsp;| <b>Investigate</b>&nbsp;{character.passiveInvestigate}</span>:null}
                                            </td>
                                        </tr>
                                        {this.getCharacterOptions()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="flex justify-around items-stretch abilityBlock avoidBreak">
                            <CharacterAbility character={character} readonly={readonly} ability="str"/>
                            <CharacterAbility character={character} readonly={readonly} ability="dex"/>
                            <CharacterAbility character={character} readonly={readonly} ability="con"/>
                            <CharacterAbility character={character} readonly={readonly} ability="int"/>
                            <CharacterAbility character={character} readonly={readonly} ability="wis"/>
                            <CharacterAbility character={character} readonly={readonly} ability="cha"/>
                        </div>
                        <RenderSavingsThrows character={character} editable={editable} readonly={readonly} onClick={editable?this.clickedSaves.bind(this):null} small={small}/>
                        <RenderSkills character={character} editable={editable} readonly={readonly} onClick={editable?this.clickedSkills.bind(this):null}/>
                        {(editable || character.sensesText || getTextFromDistanceStruct(character.senses)||character.languages||character.vulnerable||character.immune||character.conditionImmune||character.tools||character.armor||character.weapons)?<div className="profBlock avoidBreak">
                            <div>
                                <TextClick label="Senses" text={character.sensesText || getTextFromDistanceStruct(character.senses)} editable={editable}  onClick={editable&&!readonly?this.clickedSenses.bind(this):null}/>
                                <CheckEdit label="Languages" values={character.languages} character={character} modName="manualLanguages" property="languages" options={character.allLanguagesList} editable={editable}/>
                                <CheckEdit label="Armor" values={character.armor} character={character} modName="manualArmor" property="armor" options={armorTypes} editable={editable}/>
                                <CheckEdit label="Weapons" values={character.weapons} character={character} modName="manualWeapons" property="weapons" options={weaponProficiencies} editable={editable}/>
                                <RenderTools character={character} editable={editable}/>
                                <CheckEdit label="Damage Vulnerabilities" values={character.vulnerable} character={character} modName="manualVulnerabilities" property="vulnerable" options={damageTypesList} editable={editable}/>
                                <CheckEdit label="Damage Resistance" values={character.resist} character={character} modName="manualResist" property="resist" options={damageTypesList} editable={editable}/>
                                <CheckEdit label="Damage Immunities" values={character.immune} character={character} modName="manualImmune" property="immune" options={damageTypesList} editable={editable}/>
                                <CheckEdit label="Condition Immunities" values={character.conditionImmune} character={character} modName="manualCondition" property="conditionImmune" options={conditionList} editable={editable}/>
                            </div>
                        </div>:null}
                        <div id={this.idBase+"weapons"} style={{position:"relative", top:"-25px", left: 0}}/>
                        {this.getShapeChange()}
                        <ActionBlock character={character} readonly={readonly} getDiceRoller={this.props.getDiceRoller} addSpellToken={this.props.addSpellToken} />
                        {this.getWeaponAttacks()}
                        <div id={this.idBase+"spells"} style={{position:"relative", top:"-25px", left: 0}}/>
                        <CharacterSpells idBase={this.idBase} allSpells={allSpells} character={character} readonly={readonly} editable={editable}  getDiceRoller={readonly?null:this.getDiceRoller.bind(this)}  addSpellToken={this.props.addSpellToken&&!readonly?this.props.addSpellToken:null}/>
                        <div id={this.idBase+"features"} style={{position:"relative", top:"-25px", left: 0}}/>
                        {this.getFeatureGroups()}
                        <div id={this.idBase+"equipment"} style={{position:"relative", top:"-25px", left: 0}}/>
                        {this.getAttunementItems()}
                        {this.getEquipment()}
                        <div id={this.idBase+"traits"} style={{position:"relative", top:"-25px", left: 0}}/>
                        <CompanionsBlock character={character} readonly={readonly} addSpellToken={this.props.addSpellToken} eventSync={this.props.eventSync}/>
                        <CharacterDescription character={character} readonly={readonly}/>
                        <div id={this.idBase+"log"} style={{position:"relative", top:"-25px", left: 0}}/>
                        {this.props.readonly?null:<div className="currentLogBlock avoidBreak">
                            <SessionLogList  maxNum={1} noMore character={character} saveDelay={2000}/>
                        </div>}
                        {this.props.readonly?null:<div className="logBlock avoidBreak">
                            <SessionLogList  maxNum={3} skipFirst character={character}/>
                        </div>}
                    </div>
                </div>
            </div>
            {this.props.hideDice?null:<HDivider onSlide={this.onHSlide.bind(this)} getSections={this.getHSections.bind(this)} parentRef={this.hparentRef}/>}
            {this.props.hideDice?null:<div style={{height:(Math.min(80,this.state.hPercent||0))*0.01*height,minHeight:minTraySize}}>
                <RenderChat allowDiceSounds={!this.props.disallowDiceSounds} character={character} width={width} allSmall={allSmall} addSpellToken={this.props.addSpellToken}/>
            </div>}

            <SpellPicker
                character={character}
                cantripStr={character.t("Cantrips")}
                spellStr={this.state.pickRituals?"Rituals":character.t("Spells")}
                levelStr={character.spellLevelName()}
                open={this.state.showSpellPicker} 
                maxSpellLevel={this.state.selectedMaxSpellLevel} 
                cclass={this.state.selectedClass}
                spellSources={this.state.spellSources}
                selectedSpells={this.state.selectedSpells} 
                selectableSpells={this.state.selectableSpells} 
                extraSpells={this.state.extraSpells}
                knownCantrips={this.state.knownCantrips}
                knownSpells={this.state.knownSpells}
                prepareSpells={this.state.spellPrepareCount||0}
                onClose={this.onCloseSpellPicker.bind(this)}
                ritualOnly={this.state.pickRituals}
                noRituals={this.state.noRituals}
            />
            <CustomPicker 
                character={character}
                open={this.state.showCustomPicker}
                type={this.state.customType} 
                selected={this.state.selected} 
                known={this.state.customKnown}
                gamesystemPref={this.state.customGamesystemPref}
                onClose={this.onCloseCustomPicker.bind(this)}
            />
            <PickRace open={this.state.pickRace} editable renameFn={this.showRename.bind(this)} character={character} onClose={this.onClosePickRace.bind(this)}/>
            <PickLevels open={this.state.pickClass} character={character} noAutoClass={this.state.noAutoClass} onClose={this.onClosePickClass.bind(this)}/>
            <PickBackground open={this.state.pickBackground} editable character={character} onClose={this.onClosePickBackground.bind(this)}/>
            <NumberAdjust open={this.state.showHPAdjust} positive={false} anchorEl={this.state.showHPAnchorEl} noShowValue value={character.hp} onClose={this.onDamageHeal.bind(this)}/>
            <NumberAdjust open={this.state.showTemphpAdjust} anchorEl={this.state.showTemphpAnchorEl} noShowValue value={character.temphp} onClose={this.onChangeTemphp.bind(this)}/>
            <ArmorSelect open={this.state.showArmorSelect} character={character} onClose={this.onCloseArmorSelect.bind(this)}/>
            <Movement open={this.state.showMovement} character={character} onClose={this.onCloseMovement.bind(this)}/>
            <Senses open={this.state.showSenses} character={character} onClose={this.onCloseSenses.bind(this)}/>
            <SavingsThrows open={this.state.showSaves} character={character} onClose={this.onCloseSaves.bind(this)}/>
            <Skills open={this.state.showSkills} character={character} onClose={this.onCloseSkills.bind(this)} editable={editable}/>
            <CustomDialog open={this.state.showCustom||false} id={this.state.selectedCustomId} type={this.state.selectedCustomType} onClose={this.hideCustom.bind(this)} doSubRoll={this.doTextRoll.bind(this)}  getDiceRoller={readonly?null:this.getDiceRoller.bind(this)} addSpellToken={readonly?null:this.props.addSpellToken?this.addSpellToken.bind(this):null}/>
            <SessionHistoryLog character={character} open={this.state.showSessionHistoryLog} onClose={this.closeSessionHistoryLog.bind(this)}/>
            <GenericDialog open={this.state.showGenericDialog} feature={this.state.showGenericFeature} onClose={this.closeGenericDialog.bind(this)} depth={1} doRoll={this.doTextRoll.bind(this,this.state.showGenericFeature&& this.state.showGenericFeature.name)} doSubRoll={this.doTextRoll.bind(this)}  getDiceRoller={readonly?null:this.getDiceRoller.bind(this)} addSpellToken={readonly?null:this.props.addSpellToken?this.addSpellToken.bind(this):null} character={character}/>
            <GenCharacterName noHelp show={this.state.showRename} character={character} label="Character Name" text={this.state.character.displayName} onChange={this.onRename.bind(this)} />
            <HeroAbilities character={character} open={this.state.showHeroAbilities} onClose={this.showHeroAbilitities.bind(this,false)}/>
            <ShortRest character={character} open={this.state.showShortRest} onClose={this.finishRest.bind(this)}/>
            <LongRest character={character} open={this.state.showLongRest} onClose={this.finishRest.bind(this)}/>
            <TextBasicEdit label="Player Name" allowNull text={character.playerName} show={this.state.showPlayerName} onChange={this.onSetPlayerName.bind(this)}/>
            <ExtensionsPicker open={this.state.showExtensionsPicker} selected={character.extensions} onClose={this.closeExtensionsPicker.bind(this)}/>
            <EditBaseAbilities open={this.state.editAbilities} character={character} abilities={character.state.baseAbilities} onClose={this.onCloseEditAbilities.bind(this)}/>
            {this.state.showArtZoom?<ArtZoomList 
                open
                editable={!this.props.readonly}
                artList={character.artList}
                onClose={this.onSaveArtwork.bind(this)}
                pickToken preferToken
                defaultToken={character.tokenArt}
                description={character.displayName}
                pageSync={this.props.eventSync}
                defaultSearch={character.getCharacterWebSearchDefault()}
                defaultType="Character Token"
            />:null}
            <CharacterPrint name={this.props.name} characterType={this.characterType} open={this.state.showPrint} onClose={this.showPrint.bind(this,false)}/>
            {this.props.includeDiceTray?<DiceTower character={character}/>:null}
        </div>;
    }

    getHSections(pos) {
        const pRect = this.hparentRef.current.getBoundingClientRect();
        const height = pRect.height;

        const bottom = Math.min(height*0.8,Math.max(minTraySize, height-pos));
        const top = height-bottom;

        return [top,bottom];
    }

    onHSlide(pos) {
        const pRect = this.hparentRef.current.getBoundingClientRect();
        const height = pRect.height;
        const hPercent = Math.min(80, (height - pos)/height*100);

        campaign.updateUserSettings({characterSplit:hPercent});
        this.setState({hPercent});
    }

    onSaveArtwork(artList, defaultArt, defaultToken) {
        if (artList) {
            this.state.character.setArtwork(artList, defaultToken);
        }
        this.setState({showArtZoom:false})
    }

    gotoSection(section) {
        const t=this;
//        console.log("goto",section);
        if (this.doScroll) {
//            console.log("end");
            endTimeout(this.doScroll);
        }
        this.doScroll = setTimeout(function(){
            const element = document.getElementById(t.idBase+section);
//            console.log("do scroll now",section, element);
            if (element) {
                element.scrollIntoView({behavior: "smooth"});
            }
            t.doScroll=null;
        },100);
    }

    getDiceRoller() {
        if (this.props.getDiceRoller) {
            return this.props.getDiceRoller();
        }
        return <ChatButton character={this.state.character}/>;
    }

    showHeroAbilitities(showHeroAbilities){
        this.setState({showHeroAbilities,showcharactermenu:false});
    }

    getCharacter(name) {
        let c;
        switch (this.characterType) {
            case "mycharacters":
                c=campaign.getMyCharacterInfo(name);
                break;
            case "monsters":
                c=campaign.getMonster(name);
                break;
            case "players":
            default:
                c=campaign.getPlayerInfo(name);
                break;
        }
        if (!c) {
            c = {name:name};
        }
        const character = new Character(c, this.characterType, this.props.readonly);
        if (this.props.saveCharacter) {
            this.props.saveCharacter(character);
        }
        return character;
    }

    changeAttribute(attribute, value){
        this.state.character.setProperty(attribute, value);
    }

    changeInitiative(value){
        if (value == "--") {
            this.state.character.initiative = null;
        } else {
            this.state.character.initiative = value + this.state.character.initiativeBonus;
        }
    }

    rollInitiative() {
        const character = this.state.character;
        character.doRoll({"D20":1, bonus:character.initiativeBonus}, "Initiative", "roll", "initiative");
    }

    rollNewLuck() {
        const {character} = this.state;
        const counts = {"D4":1, bonus:1};
        const {rolls,sum} = doRoll(counts);
        this.setUsageValue("luck",sum);
        character.addRoll({dice:counts, rolls, source:"Luck", action:"Roll"});
    }

    rollDeathSave() {
        const {character} = this.state;
        const counts = {"D20":1, bonus:character.allSavingThrowBonus||0};
        const {rolls,sum} = doRoll(counts);
        let prop;
        let value;
        if (sum<=1) {
            prop = "deathFails";
            value = Math.max(0, character.deathFails-2);
        } else if (sum>=20) {
            prop = "hp";
            value = 1;
        } else if (sum < 10) {
            prop = "deathFails";
            value = Math.max(0, character.deathFails-1);
        } else {
            prop = "deathSaves";
            value = Math.max(0, character.deathSaves-1);
        }

        character.addRoll({dice:counts, rolls, source:"Death Save", action:"Roll"}, prop, value);
    }

    doItemAttack(id, it, bonus, damages, otherDamages){
        const {character} =this.state;
        const altEffect = it?.feature?.altEffect;
        const displayName = it.displayName||it.name;
        if (it.consumable) {
            if (it.feature?.usage?.baseCount) {
                let {usage,uses} = it.feature;
                if (uses == undefined) {
                    uses = usage.baseCount;
                }
                if (uses) {
                    const feature = Object.assign({}, it.feature);
    
                    feature.uses = Math.max(0,uses-1);
                    this.onAdjustFeature(id, feature);
                }
            } else {
                this.onAdjustQuantity(true, Math.max(0,(it.quantity??1)-1), -1, id);
            } 
        }
        const href = "#item?iid="+encodeURIComponent(id)+"&cid="+encodeURIComponent(character.name);
        if (bonus=="use") {
            damages = (otherDamages?.length&&otherDamages[0].damages)||null;
            if (damages && !(altEffect?.save || altEffect?.conditions || altEffect?.temphp)) {
                Chat.addCharacterDamageRoll(character, displayName, href, damages);
                return;
            }
            bonus=null;
            otherDamages=null;
        }

        Chat.addAction(character, displayName, href, (Number.isInteger(bonus)?"+"+bonus:null), damages, altEffect?.save, altEffect?.saveDC,"Item", altEffect?.conditions, altEffect?.temphp, otherDamages);
    }

    doUrlAttack(href, displayName, bonus, damages, d20Bonus, otherDamages){
        const character = this.state.character;
        const dice = getDiceFromString("1d20"+(bonus?"+"+bonus:""),0,false);
        const {rolls} = doRoll(dice);
        const roll = {dice, rolls, source:displayName, sourceHref:href||null, action:dice?"to hit":null, damages:damages||null};
        if (otherDamages) {
            roll.otherDamages=otherDamages;
        }
        if (d20Bonus) {
            Chat.addD20BonusRolls(character, roll, d20Bonus);
        }

        character.addRoll(roll);
    }

    doItemDamage(id, displayName, damages) {
        this.doUrlDamage(id?"#item?iid="+encodeURIComponent(id)+"&cid="+encodeURIComponent(this.state.character.name):null, displayName, damages);
    }

    doUrlDamage(href, displayName, damages) {
        const character = this.state.character;
        if (damages.length) {
            Chat.addCharacterDamageRoll(character, displayName, href||null, damages);
        }
    }

    doItemTextRoll(name, text){
        const character = this.state.character;
        if (name && ((typeof name) == "object")) {
            // if name is an object then it actually contains the roll
            character.addRoll(name);
            return name;
        }
        const dice = getDiceFromString(text);
        if (!name) {
            const {rolls} = doRoll(dice);
            const id = this.state.showItemId;
            const it = character.equipment[id];
            const roll = {dice, rolls, source:it.displayName||it.name, sourceHref:"#item?iid="+encodeURIComponent(id)+"&cid="+encodeURIComponent(character.name)};

            return character.addRoll(roll);
        }

        return character.doRoll(dice, name,null);
    }

    doTextRoll(name, text){
        if (name && (typeof name == "object")) {
            // if name is an object then it actually contains the roll
            Chat.addCharacterRoll(this.state.character, name);
            return name;
        }
        const dice = getDiceFromString(text);

        return this.state.character.doRoll(dice, name,null);
    }

    getFeatureGroups() {
        const character = this.state.character;
        const readonly=this.props.readonly;
        const ret=[];

        for (let i in character.classes) {
            const cInfo = character.classes[i];
            const cls = campaign.getClassInfo(cInfo.cclass);
            if (!cls) continue;

            const subclass = campaign.getSubclassInfo(cInfo.subclass);
            const customLevels = getMergedCustomLevels(cls, subclass);
            let levels = [];

            const compact = character.getOption("compact."+cls.className);

            const abilityDC = (subclass && subclass.abilityDC) || (cls && cls.abilityDC);
            const abilityInfo = abilityDC?<span>
                {abilityDC.toUpperCase()} DC{character.getAbility(abilityDC).modifier+8+character.proficiency+character.spellDCBonus}
                , <b className="dodieroll" onClick={this.doUrlAttack.bind(this,null, cls.displayName, character.getAbility(abilityDC).modifier+character.proficiency+character.spellAttackBonus, null, character.spellDice,null)}>attack+{character.getAbility(abilityDC).modifier+character.proficiency+character.spellAttackBonus}</b>
                , modifier +{character.getAbility(abilityDC).modifier}
            </span>:null;
            
            for (let x in customLevels) {
                const cl=customLevels[x];
                const count = cl.levelsCount[cInfo.level-1];

                if (count) {
                    switch (cl.attributeType) {
                    case "display":{
                        const str = (cl.prefix||"")+count+(cl.suffix||"");
                        let displayText;
                        if ((str.match(/[\s\+-dD\d]+/)==str)&&str.match(/[dD]/)) {
                            displayText=<b className="dodieroll" onClick={this.doTextRoll.bind(this,cl.name, str)}>{str}</b>;
                        } else {
                            displayText = str;
                        }
                        levels.push(<b key={x}>{cl.name} {displayText} </b>);
                        break;
                    }
                    case "points":
                        levels.push(<span key={x}>
                            <b key={x}>{cl.name} </b>
                            <MaxNumberAdjust
                                value={character.getCustomPoints(cls.className, cl.name)} 
                                max={count} 
                                onAdjustValue={this.onSetCustomPoints.bind(this, cls.className, cl.name)}
                            />
                            &nbsp;
                        </span>)
                        break;
                    case "select":{
                        const selected = (cInfo.customSelected && cInfo.customSelected[cl.name])||{};
                        const list = [];
                        const selist = [];

                        for (let s in selected) {
                            const ct = campaign.getCustom(cl.name, s);
                            if (ct) {
                                list.push(ct);
                            }
                        }
                        list.sort(function (a,b) {return (a.displayName||"").toLowerCase().localeCompare((b.displayName||"").toLowerCase())});

                        for (let s in list) {
                            const ct=list[s];
                            selist.push(<span key={s}>{selist.length?", ":""}<a onClick={this.showCustom.bind(this,cl.name, ct.id)}>{ct.displayName}</a></span>);
                        }

                        levels.push(<div key={x}>
                            <b>{cl.name}. </b>
                            {selist}
                        </div>);
                    }
                        break;
                    }
                }
            }

            levels =  levels.concat(this.getTypeUsage("class", cInfo.cclass, compact));

            if (cls.spellcaster || levels.length) {
                let prepareCount = 0;
                const spellcls = (cls.spellcaster && cls) || subclass;

                const caster = ((cls.spellcaster || subclass?.spellcaster) && (cInfo.attributes.knownCantrips || cInfo.attributes.knownSpells || ((character.spellSlots && (character.spellSlots[0] || character.spellSlots[1])) || character.maxPactSlots)));

                if (spellcls?.spellcaster && spellcls.prepareSpells && spellcls.abilityDC) {
                    const prepareMultiple = {"full":1, "half":0.5, "halfplus":0.5, "third":0.34, "pact":1, "halfpact":0.5, "thirdpact":0.34}[spellcls.spellcaster]||1;
                    prepareCount = Math.trunc(cInfo.level * prepareMultiple + character.getAbility(spellcls.abilityDC).modifier) || 1;
                }

                ret.push(<div key={cInfo.cclass} className="featureBlock avoidBreak">
                    <div>
                        <div className="flex items-center">
                            <div className="flex-auto theader">
                                <span className="ttitle">{cls.displayName}</span> {abilityInfo}
                            </div>
                            <div className="w55 tr">
                                {!cInfo.attributes.knownRituals || readonly?null:<Button className="ml1" onClick={this.onShowSpellPicker.bind(this, i, 0,true)} color="primary" variant="outlined" size="small">Rituals</Button>}
                                {!caster || readonly?null:<Button className="ml1" onClick={this.onShowSpellPicker.bind(this, i, prepareCount,false)} color="primary" variant="outlined" size="small">{character.t("Spells")}</Button>}
                                {readonly?null:<span onClick={this.toggleCompact.bind(this, cls.className)} className={compact?"titlecolor f3 far fa-plus-square hoverhighlight pa1":"titlecolor f3 far fa-minus-square hoverhighlight pa1"}/>}
                            </div>
                        </div>
                        {levels.length?<div className="mt1">
                            {levels}
                        </div>:null}
                    </div>
                </div>);
            }
        }

        switch (character.gamesystem) {
            case "5e":{
                const race = campaign.getRaceInfo(character.race);
                if (race) {
                    const compact = character.getOption("compact.~_race");
                    const raceOpts = this.getTypeUsage("race", null, compact);
        
                    if (raceOpts.length) {
                        ret.push(<div key={"race."+race.name} className="featureBlock avoidBreak">
                            <div>
                                <div className="flex items-center">
                                    <div className="flex-auto theader">
                                        <span className="ttitle">{race.displayName}</span>
                                    </div>
                                    <div className="w55 tr">
                                        {readonly?null:<span onClick={this.toggleCompact.bind(this, "~_race")} className={compact?"titlecolor f3 far fa-plus-square hoverhighlight pa1":"titlecolor f3 far fa-minus-square hoverhighlight pa1"}/>}
                                    </div>
                                </div>
                                {raceOpts}
                            </div>
                        </div>);
                    }
                }
                break;
            }
            case "bf":{
                const lineage = campaign.getCustom("Lineages", character.lineage);
                if (lineage) {
                    const compact = character.getOption("compact.~_lineage");
                    const lOpts = this.getTypeUsage("lineage", null, compact);
        
                    if (lOpts.length) {
                        ret.push(<div key={"lineage."+lineage.name} className="featureBlock avoidBreak">
                            <div>
                                <div className="flex items-center">
                                    <div className="flex-auto theader">
                                        <span className="ttitle">{lineage.displayName}</span>
                                    </div>
                                    <div className="w55 tr">
                                        {readonly?null:<span onClick={this.toggleCompact.bind(this, "~_lineage")} className={compact?"titlecolor f3 far fa-plus-square hoverhighlight pa1":"titlecolor f3 far fa-minus-square hoverhighlight pa1"}/>}
                                    </div>
                                </div>
                                {lOpts}
                            </div>
                        </div>);
                    }
                }
                const heritage = campaign.getCustom("Heritages", character.heritage);
                if (heritage) {
                    const compact = character.getOption("compact.~_heritage");
                    const lOpts = this.getTypeUsage("heritage", null, compact);
        
                    if (lOpts.length) {
                        ret.push(<div key={"heritage."+heritage.name} className="featureBlock avoidBreak">
                            <div>
                                <div className="flex items-center">
                                    <div className="flex-auto theader">
                                        <span className="ttitle">{heritage.displayName}</span>
                                    </div>
                                    <div className="w55 tr">
                                        {readonly?null:<span onClick={this.toggleCompact.bind(this, "~_heritage")} className={compact?"titlecolor f3 far fa-plus-square hoverhighlight pa1":"titlecolor f3 far fa-minus-square hoverhighlight pa1"}/>}
                                    </div>
                                </div>
                                {lOpts}
                            </div>
                        </div>);
                    }
                }
                break;
            }
        }

        const background = campaign.getBackgroundInfo(character.background);
        if (background) {
            const compact = character.getOption("compact.~_background");
            const backgroundOpts = this.getTypeUsage("background", null, compact);

            if (backgroundOpts.length) {
                ret.push(<div key={"background."+background.name} className="featureBlock avoidBreak">
                    <div>
                        <div className="flex items-center">
                            <div className="flex-auto theader">
                                <span className="ttitle">{background.displayName}</span>
                            </div>
                            <div className="w55 tr">
                                {readonly?null:<span onClick={this.toggleCompact.bind(this, "~_background")} className={compact?"titlecolor f3 far fa-plus-square hoverhighlight pa1":"titlecolor f3 far fa-minus-square hoverhighlight pa1"}/>}
                            </div>
                        </div>
                        {backgroundOpts}
                    </div>
                </div>);
            }
        }

        const heroAbilities = character.heroAbilities;
        if (heroAbilities) {
            const compact = character.getOption("compact.~_hero");
            const heroOpts = this.getTypeUsage("hero", null, compact, true);
            if (heroOpts.length) {
                ret.push(<div key="heroAbilities" className="featureBlock avoidBreak">
                    <div>
                        <div className="flex items-center">
                            <div className="flex-auto theader">
                                <span className="ttitle">Hero Abilities</span>
                            </div>
                            <div className="w55 tr">
                                {readonly?null:<span onClick={this.toggleCompact.bind(this, "~_hero")} className={compact?"titlecolor f3 far fa-plus-square hoverhighlight pa1":"titlecolor f3 far fa-minus-square hoverhighlight pa1"}/>}
                            </div>
                        </div>
                        {heroOpts}
                    </div>
                </div>);
            }
        }
        const cfopts = this.getCharacterFeatureOptions();
        if (cfopts) {
            ret.push(cfopts)
        }

        return <div className="stdcontent">{ret}</div>;
    }

    showDetailsGenericDialog(showGenericFeature, displayName) {
        if (!showGenericFeature.name) {
            showGenericFeature = Object.assign({}, showGenericFeature);
            showGenericFeature.name = displayName||"";
        }
        this.setState({showGenericDialog:true, showGenericFeature});
    }

    closeGenericDialog(){
        this.setState({showGenericDialog:false});
    }

    setUsageValue(prop, value) {
        this.state.character.setFeatureUsage(prop, value);
    }

    toggleCompact(group) {
        const character = this.state.character;
        character.setOption("compact."+group, !character.getOption("compact."+group));
    }

    getTypeUsage(type, value, compact, includeCustom) {
        const t = this;
        const {character} = this.state;
        const {readonly} = this.props;

        let list=[];

        character.traverseFeatures(function (params) {
            const e = params.feature;
            const {level,type, typeValue, usageId,fid, options} = params;
            const usage = e.usage;

            if (e.usage && (e.usage.type !="hide")) {
                list.push(<div className="mb1" key={usageId}>
                    {
                        e.entries?((compact||(!e.usage&&e.entries))?<a className="b" onClick={t.showDetailsGenericDialog.bind(t, e,typeValue?.displayName)}>{e.name||(typeValue?.displayName)}</a>:<span className="b titletext">{e.name||(typeValue?.displayName)}</span>):
                        typeValue?<a className="b" onClick={t.showDetailsGenericDialog.bind(t, e,typeValue?.displayName)}>{e.name||(typeValue?.displayName)}</a>:
                        <span className="b">{e.name||typeValue?.displayName}</span>
                    }
                    <UsageSlots 
                        character={character} 
                        readonly={readonly}
                        usage={usage} 
                        level={level-1}
                        doRoll={t.doTextRoll.bind(t,e.name||"")}
                        id={usageId}
                        useNumbers={usage && usage.longRest<0}
                    >
                        {(e.name=="Wild Shape")?<ShapeChange character={character}/>:null}
                    </UsageSlots>
                    {t.getFeatureCustom(e, options, fid)}
                    {e.showProficiencies?getProficiencesFromFeature(e, options, fid):null}
                    {!compact&&e.usage&&e.entries?<span>
                        {(e.name||typeValue?.displayName)?<span><b>.</b> </span>:null}<RenderFeature noDiv isOption feature={{entries:e.entries}} depth={2} doRoll={t.doTextRoll.bind(t,e.name||(typeValue?.displayName))} doSubRoll={t.doTextRoll.bind(t)} character={character}/>
                    </span>:null}
                </div>);
            }

            if (e.customAttribute) {
                const prop = fid+ ".te."+e.customAttribute;
                list.push(<div className="mb1" key={prop}><b>{e.customAttribute}</b> {options[prop]||"(no value set)"}</div>);
            }
        }, [type], value)

        return list;
    }

    getFeatureCustom(feature, options, optionsBase) {
        const ret = [];
        let useSpan=false;

        if (feature.customPick) {
            let pickedCustom = (options||{})[optionsBase+".customPick"]||[];
            if ((feature.customPick.customOptions && (feature.customPick.customOptions.length==1))) {
                pickedCustom={};
                pickedCustom[feature.customPick.customOptions[0]]=1;
            }

            const pc = [];
            for (let i in pickedCustom) {
                const ct = campaign.getCustom(feature.customPick.customTable, i);
                if (ct) {
                    pc.push(<span key={i}>{pc.length?", ":""}<a onClick={this.showCustom.bind(this,feature.customPick.customTable, i)}>{ct.displayName}</a></span>);
                }
            }
            ret.push(<span className="mv1" key="custompick"> {pc}</span>);
            useSpan=true;
        }

        if (!ret.length) {
            return null;
        }
        if (useSpan) {
            return <span className="mv1">{ret}</span>;
        }
        return <div className="mv1">{ret}</div>;
    }

    addSpellToken(ao) {
        this.props.addSpellToken(ao);
    }

    showCustom(type, name) {
        this.setState({showCustom:true, selectedCustomId:name, selectedCustomType:type});
    }

    hideCustom() {
        this.setState({showCustom:false});
    }

    onClosePickLevels() {
        this.setState({pickLevels:false});
    }

    getEquipment() {
        const {character, equipFilter} = this.state;
        const {readonly} = this.props;
        const w = character.getCarriedWeight();
        const base = character.getCarryBase();
        let carryText="";
        if (w> (base*3)) {
            carryText = "over capacity"
        } else if (w>(base*2)) {
            carryText="heavily encumbered";
        } else if (w>base) {
            carryText="encumbered";
        }

        return <div className="equipmentBlock avoidBreak">
            <div>
                <div className="flex flex-wrap items-center theader">
                    <span className="flex-auto ttitle">Equipment {w?<span className="ttn f6 normal">{carryText} carrying {Math.round(w)} lb.</span>:null}</span>
                    <SelectVal value={equipFilter||"all"} values={["all", "carried", "currency", "not currency", "containers"]} onClick={this.setEquipFilter.bind(this)}/>
                    {!campaign.isDefaultCampaign()&&!readonly?<Button className="ml1 minw2" onClick={this.onShowSharedItemPicker.bind(this)} color="primary" variant="outlined" size="small">Shared Treasure</Button>:null}
                    {readonly?null:<Button className="mh1 minw2" onClick={this.onShowItemPicker.bind(this,true)} color="primary" variant="outlined" size="small">Add</Button>}
                </div>
                {this.getCoinBlock()}
                {this.getEquipmentList()}
                <ItemBuyPicker 
                    open={this.state.showItemPicker} 
                    onClose={this.onShowItemPicker.bind(this,false)}
                    character={character}
                />
                <SharedTreasurePicker
                    open={this.state.showSharedItemPicker} 
                    onClose={this.closeSharedItemPicker.bind(this)}
                    character={this.state.character}
                />
                <StartingEquipmentPicker
                    open={this.state.showStartingEquipmentPicker} 
                    onClose={this.onCloseStartingEquipment.bind(this)}
                    character={this.state.character}
                />
                <ItemDialog editable={!readonly} open={this.state.showItemDetails} characterId={character.name} itemId={this.state.showItemId} onClose={this.closeItem.bind(this)} onDelete={readonly?null:this.onDeleteItem.bind(this, this.state.showItemId)} character={character} noAddToEquipment doSubRoll={readonly?null:this.doItemTextRoll.bind(this)} getDiceRoller={readonly?null:this.getDiceRoller.bind(this)} addSpellToken={readonly?null:this.props.addSpellToken?this.addSpellToken.bind(this):null}/>
                <NumberAdjust open={this.state.showPickCoins} positive onClose={this.onChangeCoin.bind(this)} value={this.state.showCoinValue} noShowValue anchorEl={this.state.coinAnchor}/>
            </div>
        </div>;
    }

    getCoinBlock() {
        const {character, equipFilter} = this.state;
        const {readonly} = this.props;
        const list = [];
        const coins = character.findCoins();
        const keys = Object.keys(coins);

        for (let a of ["gp", "sp", "cp", "pp", "ep"]) {
            if (!keys.includes(a) && (keys.length < 5)) {
                keys.push(a);
            }
        }
        
        keys.sort(function (a, b) {
            const v=(((coins[b]||baseCoins[b])?.countPerGP||1)-((coins[a]||baseCoins[a])?.countPerGP||1));
            if (v!=0) 
                return v; 
            return a.toLowerCase().localeCompare(b.toLowerCase())
        });

        for (let coin of keys) {
            list.push(<div key={coin} className="tc coinBlock w-20-2 pa--1" onClick={readonly?null:this.clickCoins.bind(this, coin, coins[coin])}><div className="hoverhighlight"><div className="b">{coin}</div>{(coins[coin]?.quantity||0).toLocaleString()}</div></div>);
        }

        return <div className="flex flex-wrap justify-center mv1">
            {list}
        </div>

    }

    setEquipFilter(equipFilter) {
        if (equipFilter == "all") {
            equipFilter=null;
        }
        this.setState({equipFilter})
    }

    clickCoins(coin, coinIt, evt) {
        this.setState({showPickCoins:true, showCoinType:coin, coinIt, showCoinValue:this.state.character.getCoins(coin), coinAnchor:evt.target});
    }

    onChangeCoin(quantity, adjust) {
        if (adjust) {
            const {showCoinType, coinIt, character} = this.state;
            
            const added=character.adjustCoins(showCoinType, adjust, coinIt);
            if (added) {
                Chat.addEquipment(character, adjust>0?"added":"dropped", {added});
            }
        }
        this.setState({showPickCoins:false});
    }

    getWeaponAttacks() {
        const character = this.state.character;
        const eq = character.equipment;
        const el = [];
        let ret=[];
        const ammunitionList = [];
        const otherList = []
        let secondWeapon = false;
        const itemMods = character.getItemMods();

        for (let i in eq) {
            const it=eq[i];
            upgradeItem(it);
            el.push(i);
            secondWeapon = secondWeapon || ((it.dmg1 || it.weapon) && (it.equip=="OH"));
        }
        el.sort(function(a,b){return ((eq[a].displayName||"").localeCompare(eq[b].displayName)|| a.localeCompare(b))});

        // included unarmed attacks
        {
            let unarmedAttacks = character.unarmedAttacks;
            unarmedAttacks.sort(function (a,b) {return (a.name||"unarmed strike").toLowerCase().localeCompare((b.name||"unarmed strike").toLowerCase())});

            if (!unarmedAttacks.length) {
                unarmedAttacks = [{damage:"1", damageType:"bludgeoning"}].concat(unarmedAttacks);
            }

            for (let i in unarmedAttacks) {
                const ua = unarmedAttacks[i];
                const {damages, hitBonus,extraNotes} = getUAActionInfo(character, ua, itemMods);
                const en = [];
                const {damageTypes, dmgList} = getDamagesInfo(damages);

                for (let i in extraNotes) {
                    const e = extraNotes[i];
                    en.push(<Renderentry key={i} entry={e.notes} doRoll={this.doTextRoll.bind(this,e.name)} doSubRoll={this.doTextRoll.bind(this)} character={character}/>)
                }

                ret.push(<tr key={"unarmedstrike"+i} >
                    <td className="tc mw3">
                    </td>
                    <td className="vat cursor-default">
                        <div>{ua.name||"Unarmed Strike"}</div>
                    </td>
                    <td className="tc vat"></td>
                    <td className="tc vat b hoverroll" onClick={this.doUrlAttack.bind(this, null, ua.name||"Unarmed Strike", hitBonus,damages,character.attackDice,null)}>{signedNum(hitBonus)}</td>
                    <td className="tc vat b hoverroll" onClick={this.doUrlDamage.bind(this, null, ua.name||"Unarmed Strike", damages)}>
                        {dmgList}
                    </td>
                    <td className="vat">
                        {damageTypes}
                        {ua.extraNotes?<Renderentry key="base" entry={ua.extraNotes} doRoll={this.doTextRoll.bind(this,ua.name||"Unarmed Strike")} doSubRoll={this.doTextRoll.bind(this)} character={character}/>:null}
                        {en}
                    </td>
                </tr>);
            }
        }

        for (let i in el) {
            const id=el[i];
            const it=eq[id];
            const {dmg, damages, effects, ammunition, other, hitBonus, damageBonus, isProficient, extraNotes, needsAttunement} = getItemActionInfo(character, id, it, itemMods, secondWeapon);
            const carried = isCarried(it);

            if (needsAttunement || !(dmg || ammunition || other || (hitBonus=="use") || effects.length) || !carried) {
                // skip items that require attunement that are not attuned
            } else {
                const extraNotesList = [];
                const proficientNode = isProficient?null:<div className="red">(not proficient)</div>;
                const usageNode = <ItemFeatureUsageSlots feature={it.feature} onChange={this.onAdjustFeature.bind(this, id)}/>;
                const quantityNode = <NumberAdjustPlusMinus positive value={it.quantity ?? 1} paramA={id} onChange={this.onAdjustQuantity.bind(this,false)}/>;
                const equipNode = <ItemEquip item={it} id={id} character={character}/>;
                const clickItem = this.onClickItem.bind(this,id);
                const itemName = <div>{it.displayName||it.name}{it.extraFeatures?<span className="fas fa-sun pl1"/>:null}</div>;

                for (let i in extraNotes) {
                    const e = extraNotes[i];
                    if (e.feature && e.feature.usage) {
                        extraNotesList.push(<ItemFeatureUsageSlots key={"u"+i} feature={e.feature} onChange={this.onAdjustExtraFeature.bind(this, id,e.index)} />)
                    }
                    if (e.notes) {
                        extraNotesList.push(<Renderentry key={i} entry={e.notes} doRoll={this.doTextRoll.bind(this,it.displayName)} doSubRoll={this.doTextRoll.bind(this)} character={character}/>);
                    }
                }
                if (dmg || (hitBonus=="use") || effects.length) {
                    const pl =getItemPropertyList(it);
                    const dList = [];
                    const dtList = [];
                    const altEffect = it?.feature?.altEffect;
                    let attackAction;

                    if (damages) {
                        const {damageTypes, dmgList} = getDamagesInfo(damages);
                        dList.push(<div key="d" className="hoverroll" onClick={this.doItemDamage.bind(this, id, it.displayName||it.name, damages)}><b>{dmgList}</b></div>)
                        dtList.push(damageTypes);
                    }

                    for (let e in effects) {
                        const effect=effects[e];
                        const {damageTypes, dmgList} = getDamagesInfo(effect.damages);
                        dList.push(<div key={"e"+e} className={dList.length?"bt b--gray-50 hoverroll":"hoverroll"} onClick={this.doItemDamage.bind(this, id, it.displayName||it.name, effect.damages)}><b>{dmgList}</b></div>)
                        dtList.push(damageTypes);
                    }

                    if ((hitBonus=="use" || (!dmg && altEffect?.showAttack)) && !canUseItem(it))  {
                        // no click action
                    } else {
                        attackAction = (damages||(hitBonus=="use")||altEffect?.showAttack)?this.doItemAttack.bind(this, id, it, hitBonus,damages, effects):null;
                    }

                    ret.push(<tr key={id} >
                        {equipNode}
                        <td className="vat hoverhighlight" onClick={clickItem}>
                            {itemName}
                            <div className="ttl f6">{it.weaponCategory}</div>
                        </td>
                        <td className="tc vat">{it.range}</td>
                        <td className={"tc vat"+(attackAction?" hoverroll":"")} onClick={attackAction}>
                            {damages?<b>{signedNum(hitBonus)}</b>:altEffect?.showAttack?<b className={attackAction?null:"fdarkgray"}>{signedNum(hitBonus)}</b>:(hitBonus=="use")?<span className={attackAction?"f7 ba titleborder br1 ph--2":"f7 fdarkgray"}>use</span>:null}
                            {altEffect&&altEffect.save?<div className="f7 ttu">{altEffect.save}&nbsp;{altEffect.saveDC}</div>:null}
                        </td>
                        <td className="tc vat">
                            {dList}
                        </td>
                        <td className="vat">
                            <i>{dtList.join("; ")}</i> {pl?(" | "+pl):null}
                            <div>
                                {usageNode}
                                {((it.quantity??1)!=1)?<span>quantity {quantityNode}</span>:null}
                            </div>
                            {extraNotesList}
                            {proficientNode}
                        </td>
                    </tr>);
                } else if (ammunition) {
                    ammunitionList.push(<tr key={id}>
                        <td className="tc vat w4">
                            {quantityNode}
                        </td>
                        <td className="vat hoverhighlight" onClick={clickItem}>
                            {itemName}
                        </td>
                        <td>
                            {hitBonus?<span>to hit bonus {(hitBonus>0)?"+":""}{hitBonus}</span>:null}&nbsp;
                            {damageBonus?<span>damage bonus {(damageBonus>0)?"+":""}{damageBonus}</span>:null}
                        </td>
                        <td>{extraNotesList}</td>
                    </tr>);
                } else if (other) {
                    otherList.push(<tr key={id} >
                        {equipNode}
                        <td className="vat hoverhighlight" onClick={this.onClickItem.bind(this,id)}>
                            {itemName}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="vat">
                            {usageNode}
                            {extraNotesList}
                            {proficientNode}
                        </td>
                    </tr>);
                }
            }
        }

        if (otherList.length) {
            ret = ret.concat(otherList);
        }

        if (ret.length == 0) {
            return null;
        }

        return <div className="weaponsBlock avoidBreak">
            <div>
                <div className="theader">
                    <span className="ttitle">Weapons, Armor & Tools</span>{character.extraAttacks?(" ("+(character.extraAttacks+1)+" attacks)"):null}
                </div>
                <div className="overflow-x-auto">
                    <table className="w-100 stdcontent">
                        <tbody>
                            <tr>
                                <td className="tc f6 mw3">equip</td>
                                <td></td>
                                <td className="b tc w3">range</td>
                                <td className="b tc w3">hit</td>
                                <td className="b tc w3">damage</td>
                                <td className="b tl">notes</td>
                            </tr>
                            {ret}
                        </tbody>
                    </table>
                    {ammunitionList.length?<div>
                        <table className="w-100 stdcontent mt1">
                            <tbody>
                                {ammunitionList}
                            </tbody>
                        </table>
                    </div>:null}
                </div>
            </div>
        </div>;
    }

    getAttunementItems() {
        const character = this.state.character;
        const eq = character.equipment;
        const attuned = character.attuned;
        const el = Object.keys(eq);
        const attuneEl = [];
        let attuneCount = 0;
        let ret=[];

        for (let i in el) {
            const id=el[i];
            const it=eq[id];
            if (it.reqAttune) {
                upgradeItem(it);
                if (attuned[id]) {
                    attuneCount++;
                }
                attuneEl.push(id);
            }
        }
        if (!attuneEl.length) {
            return null;
        }
        attuneEl.sort(function(a,b){return ((eq[a].displayName||"").localeCompare(eq[b].displayName) || (a.localeCompare(b)))});

        for (let i in attuneEl) {
            const id=attuneEl[i];
            const it=eq[id];

            ret.push(<span key={id}><span className="truncate pr2 titletext">
                <span onClick={this.toggleAttune.bind(this, id)} className={attuned[id]?"f3 far fa-check-circle hoverhighlight pa1":"f3 far fa-circle hoverhighlight pa1"}/>
                <a className="b f5" onClick={this.onClickItem.bind(this,id)}>{it.displayName}{it.extraFeatures?<span className="fas fa-sun pl1"/>:null}</a>
            </span> </span>);
        }

        return <div className="attuneBlock avoidBreak">
            <div>
                <div className="theader">Item Attunement {attuneCount}/{character.attuneCount}</div>
                {ret}
            </div>
        </div>;
    }

    toggleAttune(id) {
        const character = this.state.character;
        const attuned = Object.assign({}, character.attuned);
        const equipment = Object.assign({}, character.equipment);
        const bit = equipment[id];
        const it = bit?Object.assign({}, bit):null;
        let changed;

        if (attuned[id]) {
            delete attuned[id];
            if (it) {
                for (let y in equipment) {
                    const nev = equipment[y];
                    if ((y != id) && !nev.equip && !attuned[y] && itemsEquivalent(nev, it)) {
                        it.quantity = (it.quantity??1)+(nev.quantity??1);
                        delete equipment[y];
                        changed=true;
                    }
                }
            }
        } else {
            attuned[id] = true;
            if (it?.quantity > 1) {
                const newIt = Object.assign({}, it);
                delete newIt.equip;
                newIt.quantity --;
                it.quantity = 1;
                equipment[id+campaign.newUid()] = newIt;
                changed=true;
            }
        }
        if (changed) {
            equipment[id]=it;
            character.equipment = equipment;
        }
        character.attuned= attuned;
    }

    getShapeChange() {
        const shape = this.state.character.shape;

        if (!shape) {
            this.shapeFeatures=null;
            return null;
        }
        if (!this.shapeFeatures) {
            this.shapeFeatures={};
        }
        this.doAnnotateUses();

        return <div className="shapeBlock avoidBreak">
            <div>
                <div className="theader"><span className="ttitle">Shape Change Abilities: </span>{shape.displayName || shape.name}</div>
                {this.getEntryRenderValue(shape.trait, "trait",null)}
                {this.getEntryRenderValue(shape.action, "action","Actions")}
                {this.getEntryRenderValue(shape.bonusaction, "bonusaction","Bonus Actions")}
                {this.getEntryRenderValue(shape.reaction, "reaction", "Reactions")}
            </div>
        </div>
    }

    getEntryRenderValue(entry, field, title) {

        let renderEntry;

        if (!entry||entry.length==0)
            return null;

        if (entry && entry.length==1)
            renderEntry= entry[0];
        else
            renderEntry = entry||emptyEntry;
    
        return <div className="stdcontent">
            {title?<h3>{title}</h3>:null}
            <Renderentry 
                entry={renderEntry} 
                depth="2" 
                doRoll={this.doTextRoll.bind(this,null)}
                doSubRoll={this.doTextRoll.bind(this)}
                character={this.state.character}
                extraScan={this.props.readonly?null:this.annotateActions.bind(this,field)}
            />
        </div>;
    }

    annotateActions(field, root) {
        const features = findFeatures(root.firstChild);

        for (const i in  features) {
            const f = features[i];
            const text = f.node.textContent;
            findAttack(f.node,f, this.clickShapeDamage.bind(this),this.clickShapeAttack.bind(this),this.clickRoll.bind(this));
            f.save=findSave(text);
            f.order = i;
            f.field= field;
            labelUses(f.node, field+(f.name||i));
        }
        //console.log("annotate actions", field, features);
        this.shapeFeatures[field] = features;
        this.doAnnotateUses();
    }

    clickShapeAttack(attack, feature, event) {
        //console.log("attack", attack, feature);
        const {character} = this.state;
        Chat.addAction(character, feature.name, null, attack.attackRoll, attack.damages, feature.save?.ability, feature.save?.dc," ",null,null,attack.altDamages?[{damages:attack.altDamages}]:null);
        event.preventDefault();
        event.stopPropagation();
    }
   
    clickShapeDamage(dmg, dmgType, feature, event) {
        const {character} = this.state;
        //console.log("damage", dmg, dmgType, feature);
        Chat.addCharacterDamageRoll(character, feature.name, null, [{dmg, dmgType}], null, null, feature.save?.ability, feature.save?.dc);
        event.preventDefault();
        event.stopPropagation();
    }

    clickRoll(rollText, feature, event) {
        this.doTextRoll(feature.name||null, rollText);
        event.preventDefault();
        event.stopPropagation();
    }

    getCurrentShapeUsed(id) {
        const shape = this.state.character.shape;
        const usages = shape?.usages||[];
        for (let u of usages) {
            if (u.id==id) {
                return u.current || 0;
            }
        }
        return 0;
    }

    adjustCurrentShapeUsed(id, longrest, adjust) {
        const {character} = this.state;
        let shape = character.shape;
        if (shape) {
            shape = Object.assign({}, shape);
            const usages = (shape.usages||[]).concat([]);
            let found=usages.length;
            let current = 0;

            for (let i in usages) {
                const u=usages[i];
                if (u.id==id) {
                    found = i;
                    current = u.current || 0;
                    break;
                }
            }
            let u = {
                id,
                current:Math.max(0,current + adjust),
                longrest,
                hidden:true
            };

            usages[found]=u;
            shape.usages = usages;
            character.setProperty("shape", shape);
        }
    }

    rechargeShape(id, die, min, event) {
        event.preventDefault();
        event.stopPropagation();
        const c = this.doTextRoll("Recharge", "1d"+die);
        if (c.rolls[0]>=min) {
            this.adjustCurrentShapeUsed(id, false, -100);
        }
    }

    doAnnotateUses() {
        if (!this.props.readonly) {
            const t = this;
            if (t.annotateTimer) {
                clearTimeout(t.annotateTimer);
            }
            t.annotateTimer=setTimeout(function (){
                t.annotateFeatureUses();
                t.annotateTimer=null;
            }, 10);
        }
    }

    annotateFeatureUses() {
        for (let field in this.shapeFeatures) {
            const features=this.shapeFeatures[field];
            for (let i in features) {
                const f = features[i];

                if (f.node.getElementsByClassName) {
                    const monuses = f.node.getElementsByClassName("monuses");

                    for (let mu = 0; mu < monuses.length; mu++) {
                        const m = monuses[mu];
                        const d = m.dataset;
                        const unit = d.unit;
                        const id = d.useId;

                        switch (unit) {
                            case "slots":
                                break;
                            case "short":
                            case "long":
                                addUses(m, this.getCurrentShapeUsed(id), d.count, this.adjustCurrentShapeUsed.bind(this,id, unit=="long"));
                                break;
                            case "recharge":
                                const current = this.getCurrentShapeUsed(id);
                                addUses(m, current, 1, this.adjustCurrentShapeUsed.bind(this,id,false));
                                if (current) {
                                    const roll= document.createElement('b');
                                    roll.classList.add("pip","hoverhighlight","dodieroll","ph--2");
                                    roll.addEventListener("click",this.rechargeShape.bind(this, id, d.die, d.min));
                                    m.after(roll);
                                }
                                break;
                        }
                    }
                }
            }
        }
    }

    getEquipmentList() {
        const t = this;
        const {character, expanded, equipFilter} = t.state;
        const eq = character.equipment;
        const el =getSortedEquipment(eq);
        const sep = " | ";
        const ret=[];

        for (let i in el) {
            const id=el[i];
            const it=eq[id];
            if (matchFilter(it)) {
                const carried = isCarried(it);
                let expand, isExpanded;
                if (it.container && it.contained && Object.keys(it.contained).length) {
                    isExpanded = (expanded||{})[id];
                    expand=<span onClick={this.toggleEquipExpanded.bind(this,id)} className={isExpanded?"far fa-minus-square":"far fa-plus-square"}>&nbsp;</span>;
                }
                ret.push(<tr key={id} >
                    <td className="hoverhighlight tc" onClick={this.setCarried.bind(this, id, !carried)}><span className={"pa--2 far f4 "+(carried?"fa-check-square":"fa-square")}/></td>
                    {carried?<ItemEquip item={it} id={id} character={character}/>:<td></td>}
                    <td className="tc w4">
                        {character.allowItemQuantity(it,id)?<NumberAdjustPlusMinus positive value={it.quantity ?? 1} paramA={id} onChange={this.onAdjustQuantity.bind(this,false)}/>:null}
                    </td>
                    <td className="vat hoverhighlight" onClick={this.onClickItem.bind(this,id)}>
                        {expand}{it.displayName||it.name}{it.extraFeatures?<span className="fas fa-sun pl1"/>:null}
                    </td>
                    <td className="vat">
                        <ItemFeatureUsageSlots feature={it.feature} onChange={this.onAdjustFeature.bind(this, id)} />
                    </td>
                    {this.props.readonly?null:<td className="tc vat w2">
                        <DeleteItemFromCharacter it={it} id={id} character={character}/>
                    </td>}
                </tr>);
                if (isExpanded) {
                    getNestedEquipment(it, sep, id);
                }
            }
        }

        if (ret.length == 0) {
            return null;
        }

        return <div>
            <div className="overflow-x-auto">
                <table className="w-100 stdcontent">
                    <tbody>
                        <tr>
                            <td className="tc f6 w3">carry</td>
                            <td className="tc f6 w3">equip</td>
                            <td className="tc f6 w4">#</td>
                            <td></td>
                            <td className="f6">uses</td>
                            <td className="tc f6 w2"></td>
                        </tr>
                        {ret}
                    </tbody>
                </table>
            </div>
        </div>;

        function getNestedEquipment(baseit, prefix, baseid) {
            if (baseit.container) {
                const contained = baseit.contained||{};
                const keys = Object.keys(contained);
                keys.sort(function (a,b){
                    return (contained[a].displayName||"").toLowerCase().localeCompare((contained[b].displayName||"").toLowerCase());
                })
        
                const list = [];
                for (let id of keys) {
                    const it = contained[id];
                    if (matchFilter(it)) {
                        const did = baseid+"."+id;
                        let expand, isExpanded;
                        if (it.container && it.contained && Object.keys(it.contained).length) {
                            isExpanded = (expanded||{})[did];
                            expand=<span onClick={t.toggleEquipExpanded.bind(t,did)} className={isExpanded?"far fa-minus-square":"far fa-plus-square"}>&nbsp;</span>;
                        }
                
                        ret.push(<tr key={did}>
                            <td/>
                            <td/>
                            <td className="tc w4">
                                {character.allowItemQuantity(it)?<NumberAdjustPlusMinus positive value={it.quantity ?? 1} paramA={did} onChange={t.props.readonly?null:t.onAdjustQuantity.bind(t, false)}/>:null}
                            </td>
                            <td className="hoverhighlight" onClick={t.onClickItem.bind(t,did)}>
                                <span className="pre-wrap nv1 dib f2">{prefix}</span>{expand||" "}{it.displayName}
                            </td>
                            <td/>
                            {t.props.readonly?null:<td className="tc vat w2">
                                <DeleteItemFromCharacter it={it} id={did} character={character}/>
                            </td>}
                        </tr>);
                        if (isExpanded) {
                            getNestedEquipment(it, prefix+sep, did);
                        }
                    }
                }
            }
        }

        function matchFilter(it) {
            if (!equipFilter) {
                return true;
            }
            switch (equipFilter) {
                case "carried":
                    return isCarried(it);
                case "currency":
                    return (it.coin || it.type=="coin");
                case "not currency":
                    return !(it.coin || it.type=="coin");
                case "containers":
                    return it.container;
                default:
                    return true;
            }
        }
    }

    toggleEquipExpanded(id, evt) {
        evt.preventDefault();
        evt.stopPropagation();
        const expanded = Object.assign({}, this.state.expanded||{});
        expanded[id] = !expanded[id];
        this.setState({expanded});
    }

    setCarried(id, carried) {
        const character = this.state.character;
        const attuned = character.attuned;
        const equipment = Object.assign({}, character.equipment);
        const it = Object.assign({}, equipment[id]);
        equipment[id]=it;
        it.carried = carried;
        if (!carried) {
            delete it.equip;

            if (!attuned[id]) {
                // look to merge with dups
                for (let y in equipment) {
                    const nev = equipment[y];
                    if ((y != id) && !nev.equip && !attuned[y] && itemsEquivalent(nev, it)) {
                        it.quantity = (it.quantity??1)+(nev.quantity??1);
                        delete equipment[y];
                    }
                }
            }
        }

        character.equipment = equipment;
    }

    onAdjustQuantity(doDelete, quantity, adjust, id) {
        const {character} = this.state;
        const change = Object.assign({}, character.getEquipmentItem(id));
        const newIt = Object.assign({}, change);

        if (quantity < 0) {
            if (newIt.quantity === 0) {
                return;
            }
            adjust = -(newIt.quantity||1);
            quantity=0;
        }
        change.quantity = Math.abs(adjust);
        delete change.equip;
        newIt.quantity = quantity;

        character.setEquipmentItem((doDelete&&!quantity)?null:newIt, id);

        Chat.addEquipment(character, adjust<0?"dropped":"added", {change});
    }

    onAdjustHitDice(count, adjust, faces) {
        this.state.character.setHitDice(faces, count);
    }

    onDeleteItem(id) {
        this.state.character.setEquipmentItem(null, id);
        if (this.state.showItemDetails) {
            this.setState({showItemDetails:false});
        }
    }

    onAdjustFeature(id, feature) {
        const {character} = this.state;
        const newIt = Object.assign({}, character.getEquipmentItem(id));
        newIt.feature = feature;
        character.setEquipmentItem(newIt, id);
    }

    onAdjustExtraFeature(id, i, feature) {
        const {character} = this.state;
        const newIt = Object.assign({}, character.getEquipmentItem(id));

        newIt.extraFeatures = Object.assign({},newIt.extraFeatures);
        newIt.extraFeatures[i]=feature;
        character.setEquipmentItem(newIt, id);
    }

    onClickItem(id) {
        this.setState({showItemDetails:true, showItemId:id});
    }

    closeItem() {
        this.setState({showItemDetails:false});
    }

    onShowItemPicker(showItemPicker) {
        this.setState({showItemPicker});
    }

    onShowStartingEquipment() {
        this.setState({showStartingEquipmentPicker:true});
    }

    onCloseStartingEquipment() {
        this.setState({showStartingEquipmentPicker:false});
    }

    onShowSharedItemPicker() {
        this.setState({showSharedItemPicker:true});
    }

    closeSharedItemPicker() {
        this.setState({showSharedItemPicker:false});
    }

    getHitDice() {
        const character = this.state.character;
        const hitDice = character.hitDice;
        const maxHitDice = character.maxHitDice;
        const ret=[];

        for (let faces in maxHitDice) {
            const hd = maxHitDice[faces];

            if (hd) {
                ret.push(<div key={faces}>
                    <NumberAdjust positive={false} value={hitDice[faces] || 0} paramA={faces} onChange={this.onAdjustHitDice.bind(this)}>
                        <b> d{faces}</b>
                    </NumberAdjust>
                </div>);
            }
        }
        return ret;
    }

    getTextFromArmorInfo() {
        const armorInfo = this.state.character.getArmorInfo();
        const ret=[];

        if (armorInfo.armorItem) {
            ret.push(armorInfo.armorItem.name)
        } else if (armorInfo.armor) {
            ret.push(armorInfo.armor);
        }
        if (armorInfo.shieldItem) {
            ret.push(armorInfo.shieldItem.name)
        }

        let j = ret.join(", ");
        if (j) {
            j = "("+j+")";
        }
        return j;
    }

    clickedAbilities(abilityClicked) {
        this.setState({editAbilities:true});
    }

    clickHP(event) {
        this.setState({showHPAdjust:true, showHPAnchorEl:event.target});
    }

    onAdjustVal(value, adjust) {
        if (adjust) {
            this.state.character.xp = value;
        }
    }
        
    onSetCustomPoints(className, type, value) {
        this.state.character.setCustomPoints(className, type, value);
    }


    onDamageHeal(value, adjust) {
        if (adjust) {
            this.state.character.damageHeal(adjust)
        }
        this.setState({showHPAdjust:false});
    }

    clickTemphp(event) {
        this.setState({showTemphpAdjust:true, showTemphpAnchorEl:event.target});
    }

    onChangeTemphp(value, adjust) {
        if (adjust) {
            if (value<0){
                value=0;
            }
            this.state.character.setProperty("temphp", value);
        }
        this.setState({showTemphpAdjust:false});
    }

    onCloseEditAbilities(abilities) {
        if (abilities) {
            this.state.character.setProperty("baseAbilities", abilities);
            //this.state.character.rest(true);
        }
        this.setState({editAbilities:false});
    }

    showSessionHistoryLog() {
        this.setState({showSessionHistoryLog:true});
    }

    closeSessionHistoryLog() {
        this.setState({showSessionHistoryLog:false});
    }

    onShowSpellPicker(i, spellPrepareCount,rituals) {
        const character = this.state.character;
        const cInfo = character.classes[i];
        const classInfo = campaign.getClassInfo(cInfo.cclass);
        const subclassInfo = campaign.getSubclassInfo(cInfo.subclass);
        const maxSpellLevel = cInfo.attributes.maxSpellLevel;

        let spellClass = subclassInfo?.spellList || classInfo?.spellList || ((classInfo?.spellList !== null) && classInfo.displayName);
        let spellSources = subclassInfo?.spellSources || classInfo?.spellSources;

        const {selectableSpells,extraSpells} = getExtraSpells(character, cInfo);
        this.setState({showSpellPicker:true, selectedClassIndex:i, selectedClass:spellClass, spellSources,
            selectedMaxSpellLevel:maxSpellLevel, selectedSpells:rituals?cInfo.selectedRituals:cInfo.selectedSpells, extraSpells,selectableSpells, pickRituals:rituals, noRituals:(classInfo.gamesystem=="5e")?!rituals:false,
            knownCantrips:rituals?0:(cInfo.attributes.knownCantrips||0), knownSpells:rituals?cInfo.attributes.knownRituals:(cInfo.attributes.knownSpells||0), spellPrepareCount});
    }

    onCloseSpellPicker(selectedSpells) {
        if (selectedSpells) {
            const {character,pickRituals} = this.state;
            const classes = character.classes.concat([]);
            const cInfo = Object.assign({}, classes[this.state.selectedClassIndex]);
            classes[this.state.selectedClassIndex] = cInfo;
            if (pickRituals) {
                cInfo.selectedRituals = selectedSpells
            } else {
                cInfo.selectedSpells = selectedSpells
            }

            character.setClasses(classes);
        }
        this.setState({showSpellPicker:false});
    }

    onShowCustomPicker(i, type, known, selected,customGamesystemPref) {
        this.setState({showCustomPicker:true, customType:type, selectedClassIndex:i, selected, customKnown:known,customGamesystemPref});
    }

    onCloseCustomPicker(selected) {
        if (selected) {
            const character = this.state.character;
            const classes = character.classes.concat([]);
            const cInfo = Object.assign({}, classes[this.state.selectedClassIndex]);
            classes[this.state.selectedClassIndex] = cInfo;
            cInfo.customSelected = Object.assign({}, cInfo.customSelected||{});
            cInfo.customSelected[this.state.customType] = selected;
            character.setClasses(classes);
        }
        this.setState({showCustomPicker:false});
    }

    getOrigin(character) {
        const t=this;
        switch (character.gamesystem) {
        case "5e": {
            return <Tooltip title="Show Race"><span className="hoverhighlight" onClick={this.clickedRace.bind(this)}>{character.raceDisplayName}</span></Tooltip>;
            break;
        }
        case "bf":{
            const {showPickOrigin, showOrigin, originSelected, showOriginType} = this.state;
            return <span>
                <Tooltip title="Show Heritage"><span className="hoverhighlight" onClick={this.clickOriginVal.bind(this,"Heritages", character.heritage)}>{character.heritageDisplayName}</span></Tooltip>
                {" "}
                <Tooltip title="Show Lineage"><span className="hoverhighlight" onClick={this.clickOriginVal.bind(this,"Lineages", character.lineage)}>{character.lineageDisplayName}</span></Tooltip>
                <CustomPicker 
                    character={character}
                    open={showPickOrigin}
                    type={showOriginType} 
                    known={1}
                    onClose={this.onClosePickOrigin.bind(this)}
                />
                <CustomDialog 
                    open={showOrigin||false} 
                    id={originSelected} 
                    type={showOriginType} 
                    onClose={this.hideOrigin.bind(this)}
                    extraButtonsFn={changeButton}
                />
            </span>

            function changeButton() {
                return <Button onClick={t.clickOriginVal.bind(t,showOriginType, null)} color="primary">
                    change
                </Button>
            }

            break;
        }
        }
        return null;

    }

    clickOriginVal(type, val) {
        if (val) {
            this.setState({showOrigin:true, originSelected:val, showOriginType:type});
        } else {
            this.setState({showPickOrigin:true, showOrigin:false, showOriginType:type});
        }
    }

    hideOrigin() {
        this.setState({showPickOrigin:false, showOrigin:false});
    }

    onClosePickOrigin(sel) {
        if (sel) {
            const {character, showOriginType} = this.state;
            const [id] = Object.keys(sel);
            character.setOrigin(showOriginType=="Lineages"?"lineage":"heritage", id);
        }
        this.setState({showPickOrigin:false, showOrigin:false});
    }

    clickedRace() {
        this.setState({pickRace:true})
    }

    onClosePickRace() {
        this.setState({pickRace:false});
    }

    onShowClassEdit(noAutoClass) {
        this.setState({pickClass:true,noAutoClass});
    }

    onClosePickClass() {
        this.setState({pickClass:false});
    }

    clickedBackground() {
        this.setState({pickBackground:true})
    }

    onClosePickBackground() {
        this.setState({pickBackground:false});
    }

    clickedMovement() {
        this.setState({showMovement:true})
    }

    onCloseMovement() {
        this.setState({showMovement:false});
    }

    clickedSenses() {
        this.setState({showSenses:true})
    }

    onCloseSenses() {
        this.setState({showSenses:false});
    }

    clickedSaves() {
        this.setState({showSaves:true})
    }

    onCloseSaves() {
        this.setState({showSaves:false});
    }

    clickedSkills() {
        this.setState({showSkills:true})
    }

    onCloseSkills() {
        this.setState({showSkills:false});
    }

    clickedArmor() {
        this.setState({showArmorSelect:true})
    }

    onCloseArmorSelect() {
        this.setState({showArmorSelect:false});
    }

    getCharacterMenu() {
        if (!this.state.showcharactermenu) {
            return null;
        }
        const {DiceConfig} = require('./campaignconfig.jsx');
        const {character,anchorGamesystem,showGamesystemMenu,anchorPickCopyEl,showpickcopymenu,showThemeConfig,anchorEl,editable,showDiceConfig}=this.state;
        const gamesystem = character.gamesystem;
        const theme = character.getProperty("charactersheetTheme");
        const gamesystems = campaign.gamesystems;

        return <Menu
            anchorEl={anchorEl||null}
            open
            onClose={this.showCharacterMenu.bind(this,false)}
        >
            <MenuItem onClick={this.showRename.bind(this)}>Rename</MenuItem>
            <MenuItem onClick={this.showPlayerName.bind(this)}>Set Player Name</MenuItem>
            <ShapeChange asMenu character={character} onClick={this.showCharacterMenu.bind(this,false)}/>
            <MenuItem onClick={this.toggleEdit.bind(this)}>{editable?"Stop Editing":"Edit"}</MenuItem>
            <MenuItem onClick={this.showHeroAbilitities.bind(this,true)}>Hero Abilities</MenuItem>
            <MenuItem onClick={this.showDiceConfig.bind(this,true)}>Dice</MenuItem>
            <MenuItem onClick={this.showCopyToPicker.bind(this,true)}>Copy To</MenuItem>
            <MenuItem onClick={this.showGamesystemMenu.bind(this,true)}>Game System</MenuItem>
            {this.characterType=="players"&&!character.state.shareCampaign&&!campaign.isSharedCampaign()?<MenuItem onClick={this.changeAttribute.bind(this,"claimable",!character.state.claimable)}><span className="flex-auto">Claimable</span><span className={character.state.claimable?"ml2 far fa-check-square":"ml2 far fa-square"}/></MenuItem>:null}
            <DiceConfig open={showDiceConfig} onClose={this.showDiceConfig.bind(this,false)} character={character}/>
            <Menu
                anchorEl={anchorPickCopyEl||null}
                open={showpickcopymenu||false}
                onClose={this.showCopyToPicker.bind(this,false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right',}}
            >
                <MenuItem onClick={this.doCopyTo.bind(this,"character")}>New Character</MenuItem>
                <MenuItem onClick={this.doCopyTo.bind(this,"pregen")}>New Pregenerated Character</MenuItem>
                {(campaign.isGMCampaign()&&(character.characterType=="players"))?<MenuItem onClick={this.doCopyTo.bind(this,"campaign")}>Campaign Character</MenuItem>:null}
                <MenuItem onClick={this.doCopyTo.bind(this,"npc")}>NPC Monster</MenuItem>
            </Menu>
            <Menu
                anchorEl={anchorGamesystem||null}
                open={showGamesystemMenu||false}
                onClose={this.showGamesystemMenu.bind(this,false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right',}}
            >
                <MenuItem selected={gamesystem=="5e"} onClick={this.pickGamesystem.bind(this,"5e")}>{gamesystemOptions["5e"]}</MenuItem>
                <MenuItem selected={gamesystem=="bf"} onClick={this.pickGamesystem.bind(this,"bf")}>{gamesystemOptions.bf}</MenuItem>
            </Menu>
            {campaign.isDefaultCampaign()?<MenuItem onClick={this.showExtensionsPicker.bind(this,true)}>Select Extensions</MenuItem>:null}
            {true?<MenuItem onClick={this.showTheme.bind(this)}>Character Sheet Style</MenuItem>:null}
            <MenuItem onClick={this.showPrint.bind(this,true)}>Print</MenuItem>
            <ThemeConfig open={showThemeConfig} theme={theme} onClose={this.closeTheme.bind(this)}/>
        </Menu>;
    }
    
    showPrint(showPrint) {
        this.setState({showPrint,showcharactermenu:false});
    }

    showExtensionsPicker() {
        this.setState({showExtensionsPicker:true, showcharactermenu:false});
    }

    closeExtensionsPicker(selected) {
        if (selected) {
            const {character} = this.state;
            character.extensions = selected;
        }
        this.setState({showExtensionsPicker:false});
    }

    showCopyToPicker(show, e) {
        this.setState({showpickcopymenu:show, anchorPickCopyEl:show&&e.currentTarget});
    }

    showGamesystemMenu(show, e) {
        this.setState({showGamesystemMenu:show, anchorGamesystem:show&&e.currentTarget});
    }

    pickGamesystem(gamesystem) {
        const {character} = this.state;
        character.setProperty({gamesystem});
        this.setState({showGamesystemMenu:false,showcharactermenu:false});
    }

    showTheme() {
        this.setState({showThemeConfig:true});
    }

    closeTheme(theme) {
        if (theme) {
            const character = this.state.character;
            character.setProperty("charactersheetTheme", theme);
        }

        this.setState({showThemeConfig:false,showcharactermenu:false});
    }

    showStylePicker(show, e) {
        this.setState({showStyleMenu:show, anchorStyleEl:show&&e.currentTarget});
    }

    setStyle(style) {
        const character = this.state.character;
        character.setProperty("charactersheetStyle", style);
        this.setState({showStyleMenu:false,showcharactermenu:false});
    }

    showDiceConfig(showDiceConfig) {
        this.setState({showDiceConfig, showcharactermenu:showDiceConfig});
    }

    showCharacterMenu(show, e) {
        this.setState({showcharactermenu:show, anchorEl:show&&e&&e.currentTarget});
    }

    toggleEdit(e) {
        e.preventDefault(); 
        this.setState({showcharactermenu:false, editable:(!this.state.editable)});
    }

    showRename(e) {
        if (e) {
            e.preventDefault(); 
        }
        this.setState({showcharactermenu:false, showRename:true});
    }
    
    onRename(name) {
        if (name) {
            this.state.character.displayName = name;
        }
        this.setState({showRename:false});
    }

    showPlayerName(e) {
        e.preventDefault(); 
        this.setState({showcharactermenu:false, showPlayerName:true});
    }
    
    onSetPlayerName(name) {
        if (name != undefined) {
            this.state.character.playerName = name;
        }
        this.setState({showPlayerName:false});
    }

    doCopyTo(type) {
        if ((type=="character") && (campaign.getMyCharacters().length >= campaign.maxCharacters)) {
            displayMessage(<span>You have reached your limit of {campaign.maxCharacters} characters that you can create.  See <a href="/marketplace#shardsubscriptions">subscriptions</a> to change your limits.</span>);
            return;
        }

        // copy character state
        const newCharacter = Object.assign({}, this.state.character.state);
        newCharacter.name = campaign.newUid();

        if (!(["npc", "campaign"].includes(type))) {
            // find a unique name
            let check = (type=="pregen")?campaign.getPregens():campaign.getMyCharacters();
            let base = newCharacter.displayName;
            let n = 0;
            let name=base;

            const m = name.match(/ V\d+$/)
            if (m) {
                base = base.substr(0, m.index);
                n = Number(m[0].substr(2));
            }

            while (check.find(function (a) {return a.displayName==name})) {
                if (n < 2) {
                    n=2;
                } else {
                    n++;
                }
                name = base+" V"+n;
            }
            newCharacter.displayName = name;
        }

        if (type=="pregen") {
            newCharacter.pregen = true;
        } else {
            delete newCharacter.pregen;
        }

        delete newCharacter.sharedAdventureName;
        delete newCharacter.shareCampaign;
        delete newCharacter.shareUser;
        delete newCharacter.shareCampaignName;
        delete newCharacter.claimable;

        if (type == "npc") {
            newCharacter.npc = true;
            newCharacter.unique = true;
            campaign.updateCampaignContent("monsters", newCharacter);
            displayMessage("NPC monster created");
        } else if (type == "campaign") {
            campaign.updateCampaignContent("players", newCharacter);
            campaign.deleteCampaignContent("players", this.state.character.state.name)
            displayMessage("Campaign character created, and original character removed from the campaign.");
        } else {
            campaign.updateCampaignContent("mycharacters", newCharacter);
            if (campaign.getPrefs().playerMode=="ruleset") {
                campaign.joinCharacterToSharedCampaign(id, campaign.getCurrentCampaign());
                window.location.href = addCampaignToPath("/#mycharacters?id="+encodeURIComponent(newCharacter.name),true);
            } else {
                window.location.href = "/#mycharacters?id="+encodeURIComponent(newCharacter.name);
            }
        }
        this.setState({showpickcopymenu:false, showcharactermenu:false});
    }

    addPregen() {
        // copy character state
        const newCharacter = Object.assign({}, this.state.character.state);
        newCharacter.name = campaign.newUid();
        newCharacter.claimable = true;        

        delete newCharacter.pregen;
        delete newCharacter.sharedAdventureName;
        delete newCharacter.shareCampaign;
        delete newCharacter.shareUser;
        delete newCharacter.shareCampaignName;
        delete newCharacter.diceRolls;
        delete newCharacter.lastRoll;

        campaign.updateCampaignContent("players", newCharacter);
        displayMessage("Character added to Campaign Characters");
    }

    doRest(rest) {
        switch (rest) {
            case "Short Rest":
                this.setState({showShortRest:true});
                break;
            case "Long Rest":
                this.setState({showLongRest:true});
                break;
        }
    }

    finishRest() {
        this.setState({showShortRest:false, showLongRest:false});
    }

    updateToken() {
        this.setState({showcharactermenu:false});
    }

    changeEntry(field, value) {
        this.changeAttribute(field, value);
    }

    getMissingConfig() {
        const character = this.state.character;
        const baseAbilities = character.state.baseAbilities;
        const readonly=this.props.readonly;
        let list = [];

        if (character.state.pregen && !campaign.isPlayerMode()) {
            list.push(<div key="pregen" className="hk-well titletext hoverhighlight tl mr2" onClick={this.addPregen.bind(this)}>
                Add to Campaign Characters as unclaimed character.
            </div>);
        }

        switch (character.gamesystem) {
            case "5e":{
                if (!character.race) {
                    list.push(<div key="race" className="hk-well titletext hoverhighlight tl mr2" onClick={readonly?null:this.clickedRace.bind(this)}>
                        Pick Race
                    </div>);
                }
                break;
            }
            case "bf":{
                if (!character.lineage) {
                    list.push(<div key="linage" className="hk-well titletext hoverhighlight tl mr2" onClick={readonly?null:this.clickOriginVal.bind(this,"Lineages", character.lineage)}>
                        Pick Lineage
                    </div>);
                }
                if (!character.heritage) {
                    list.push(<div key="heritage" className="hk-well titletext hoverhighlight tl mr2" onClick={readonly?null:this.clickOriginVal.bind(this,"Heritages", character.heritage)}>
                        Pick Heritage
                    </div>);
                }
                break;
            }
        }

        if (!character.background) {
            list.push(<div key="bkgrnd" className="hk-well titletext hoverhighlight tl mr2" onClick={readonly?null:this.clickedBackground.bind(this)}>
                Pick Background
            </div>);
        }

        if (!character.level) {
            list.push(<div key="level" className="hk-well titletext hoverhighlight tl mr2" onClick={readonly?null:this.onShowClassEdit.bind(this,false)}>
                Pick Class
            </div>);
        }

        if (!baseAbilities.str || !baseAbilities.con || !baseAbilities.dex || !baseAbilities.int || !baseAbilities.wis || !baseAbilities.cha) {
            list.push(<div key="abil" className="hk-well titletext hoverhighlight tl mr2" onClick={readonly?null:this.clickedAbilities.bind(this)}>
                Pick Abilities
            </div>);
        }

        if (character.level && (character.missingAnyConfig||character.missingExtensionConfig.length)) {
            list.push(<div key="level" className="hk-well titletext hoverhighlight tl mr2" onClick={readonly?null:this.onShowClassEdit.bind(this,true)}>
                Pick Options
            </div>);
        }

        if (character.level && character.background && !Object.keys(character.equipment).length && !character.getCoins("cp") && !character.getCoins("sp") && !character.getCoins("ep") && !character.getCoins("gp") && !character.getCoins("pp")) {
            list.push(<div key="start" className="hk-well titletext hoverhighlight tl mr2" onClick={readonly?null:this.onShowStartingEquipment.bind(this)}>
                Pick Starting Equipment
            </div>);
        }
        list=list.concat(this.checkSpells());

        if (character.missingHeroConfig) {
            list.push(<div key="hero" className="hk-well titletext hoverhighlight tl mr2" onClick={readonly?null:this.showHeroAbilitities.bind(this, true)}>
                Pick Hero Options
            </div>);
        }

        if (!list.length) {
            return null;
        }
        return <div>
            {list}
        </div>;
    }

    checkSpells() {
        const character = this.state.character;
        const ret=[];

        for (let i in character.classes) {
            const cInfo = character.classes[i];
            const cls = campaign.getClassInfo(cInfo.cclass);

            if (!cls) continue;

            const subclass = campaign.getSubclassInfo(cInfo.subclass);
            const customLevels = getMergedCustomLevels(cls, subclass);

            if (cls.spellcaster || (subclass && subclass.spellcaster)) {
                let prepareCount = 0;
                let prepared = 0;
                let selectedSpells=0;
                let selectedCantrips=0;
                const knownSpells = cInfo.attributes.knownSpells;
                const spellcls = (cls.spellcaster && cls) || subclass;
                const prepareMultiple = {"full":1, "half":0.5, "halfplus":0.5, "third":0.34, "pact":1, "halfpact":0.5, "thirdpact":0.34}[spellcls.spellcaster]||1;

                if (spellcls.prepareSpells && (Math.trunc(cInfo.level*prepareMultiple)>0)) {
                    prepareCount = Math.trunc(cInfo.level * prepareMultiple + character.getAbility(spellcls.abilityDC).modifier) || 1;
                }
                const ss = cInfo.selectedSpells || {};

                for (let i in ss) {
                    if ((ss[i].prepared||!knownSpells) && ss[i].level) {
                        prepared++;
                    }
                    if (ss[i].level) {
                        selectedSpells++;
                    } else {
                        selectedCantrips++;
                    }
                }
                if ((prepareCount && (prepared<prepareCount)) ||
                    (cInfo.attributes.knownCantrips && (selectedCantrips < cInfo.attributes.knownCantrips)) ||
                    (cInfo.attributes.knownSpells && (selectedSpells < cInfo.attributes.knownSpells))) 
                {
                    ret.push(<div key={spellcls.name} className="hk-well titletext hoverhighlight tl mr2" onClick={this.props.readonly?null:this.onShowSpellPicker.bind(this, i, prepareCount,false)}>
                        Pick {spellcls.displayName} {character.t("Spells")}
                    </div>);
                }
            }

            for (let x in customLevels) {
                const cl=customLevels[x];
                const count = cl.levelsCount[cInfo.level-1];

                if (count) {
                    if (cl.attributeType == "select") {
                        const selected = (cInfo.customSelected && cInfo.customSelected[cl.name])||{};
                        let selectedCount=0;

                        for (let s in selected) {
                            selectedCount++;
                        }

                        if (selectedCount < count) {
                            ret.push(<div key={cls.name+"."+cl.name} className="hk-well titletext hoverhighlight tl mr2" onClick={this.props.readonly?null:this.onShowCustomPicker.bind(this, i, cl.name, count, selected,cls.gamesystem||"5e")}>
                                Pick {cl.name}
                            </div>);
                        }
                    }
                }
            }

        }
        return ret;
    }

    showArtZoom(){
        const character = this.state.character;
        if (this.props.eventSync && !character.tokenArt && character.imageURL) {
            this.props.eventSync.emit("showImageHandout", {
                type:"art",
                art:null,
                url:character.imageURL,
                description:this.state.character.displayName,
                imgHeight:560,
                imgWidth:560
            });
        } else {
            this.setState({showArtZoom:true});
        }
    }

    pickToken(token) {
        if (token){
            const character = this.state.character;
            let artList = character.artList||[];
            if (!artList.includes(token.name)) {
                artList = [token.name].concat(artList)
            }
            character.setArtwork(artList, token.name);
        } 
    }

    getCharacterFeatureOptions() {
        const character = this.state.character;
        const extensionOptions = character.characterExtensionsOptions;
        const readonly=this.props.readonly;
        const allfopts = [];

        for (let x in extensionOptions) {
            const ext = extensionOptions[x];
            const options = ext.features;
            const compact = character.getOption("compact.~copt"+ext.extension.name);
            const fopts=[];

            for (let i in options) {
                const o=options[i];
                const usageOpt = "charopt.c."+ext.extension.name+"."+o.name;
                const clickable = o.features || o.type=="select";

                const opts = this.getTypeUsage("extensions", {extensionId:ext.extension.name, name:o.name}, compact, true);

                if (opts.length) {
                    let nameextra;
                    let val;

                    switch (o.type) {
                        case "counter":
                            if (o.max) {
                                val = <MaxNumberAdjust max={o.max} useNumbers value={character.getExtensionVal(ext.extension.name,o.name)} readonly={this.props.readonly} onAdjustValue={this.setUsageValue.bind(this, character.getExtensionValId(o.name))}/>
                            }
                            break;
                        case "features":
                            break;
                        case "uses":
                            val = <MaxNumberAdjust max={o.max||1} value={character.getExtensionVal(ext.extension.name,o.name)} readonly={this.props.readonly} onAdjustValue={this.setUsageValue.bind(this, character.getExtensionValId(o.name))}/>
                            break;
                        case "check":
                            val = <MaxNumberAdjust inverse max={o.max||1} value={character.getExtensionVal(ext.extension.name,o.name)} readonly={this.props.readonly} onAdjustValue={this.setUsageValue.bind(this, character.getExtensionValId(o.name))}/>
                            break;
                        case "select":
                            const selected = character.getExtensionCharacterOption(usageOpt);
                            const selectedVals = Object.keys(selected||{});
                            const ct = campaign.getCustom(o.customType, selectedVals[0]);
                            if (ct) {
                                nameextra = ": "+ct.displayName;
                            }
                            break;
                        default:
                            console.log("unknown char opt", o.type);
                            break;
                    }
    
                    fopts.push(<div key={i}>
                        <div className="mb1">
                            {(clickable&&!readonly)?<a className="f4 b" onClick={this.onClickCharacterOption.bind(this, ext.extension.name, o.name)}>{o.name}</a>:<span className="f4 b">{o.name}</span>}
                            {nameextra}&nbsp;{val}
                        </div>
                        {opts}
                    </div>);
                }
            }

            if (fopts.length) {
                allfopts.push(<div key={x} className="featureBlock avoidBreak">
                    <div>
                        <div className="flex items-center">
                            <div className="flex-auto theader">
                                <span className="ttitle">{ext.extension.onSheetName}</span>
                            </div>
                            <div className="w55 tr">
                                {readonly?null:<span onClick={this.toggleCompact.bind(this, "~copt"+ext.extension.name)} className={compact?"titlecolor f3 far fa-plus-square hoverhighlight pa1":"titlecolor f3 far fa-minus-square hoverhighlight pa1"}/>}
                            </div>
                        </div>
                        {fopts}
                    </div>
                </div>);
            }
        }

        if (allfopts.length) {
            return <div key="characterfeatureoptions">
                {allfopts}
            </div>
        }
        return null;
    }

    getCharacterOptions() {
        const character = this.state.character;
        const options = character.characterOptions;
        let r=1;
        const rows = []
        let row=[];

        for (let i in options) {
            const o=options[i];
            const usageOpt = "charopt.c."+o.extensionId+"."+o.name;
            const clickable = o.features || o.type=="select";
            const isLuck = (o.name == "Luck");
            let hide = character.isBF && isLuck;
            let val;
            switch (o.type) {
                case "counter":
                    if (o.max) {
                        val = <MaxNumberAdjust max={o.max} useNumbers value={character.getExtensionVal(o.extensionId,o.name)} readonly={this.props.readonly} onAdjustValue={this.setUsageValue.bind(this, character.getExtensionValId(o.name))}/>
                    }
                    break;
                case "features":
                    hide=true;
                    break;
                case "uses":
                    val = <MaxNumberAdjust max={o.max||1} value={character.getExtensionVal(o.extensionId,o.name)} readonly={this.props.readonly} onAdjustValue={this.setUsageValue.bind(this, character.getExtensionValId(o.name))}/>
                    break;
                case "check":
                    val = <span>
                        <MaxNumberAdjust inverse max={o.max||1} value={character.getExtensionVal(o.extensionId,o.name)} readonly={this.props.readonly} onAdjustValue={this.setUsageValue.bind(this, character.getExtensionValId(o.name))}/>
                        {isLuck?<span className="fas fa-dice-d20 titlecolor ph1 hoverroll" onClick={this.rollNewLuck.bind(this)}/>:null}
                    </span>;
                    break;
                case "select":
                    const selected = character.getExtensionCharacterOption(usageOpt);
                    const selectedVals = Object.keys(selected||{});
                    const ct = campaign.getCustom(o.customType, selectedVals[0]);
                    val = ct?<LinkHref href={"#customlist?type="+encodeURIComponent(o.customType)+"&id="+selectedVals[0]} doSubRoll={this.doTextRoll.bind(this)}  getDiceRoller={this.props.readonly?null:this.getDiceRoller.bind(this)} character={character}>{ct.displayName}</LinkHref>:<span className="hoverhighlight" onClick={this.onClickCharacterOption.bind(this, o.extensionId, o.name)}>--pick--</span>;
                    break;
                default:
                    console.log("unknown char opt", o.type);
                    break;
            }
            if (!hide) {
                row.push(<td key={r} className="w-50">
                    <span className={"b"+(clickable?" hoverhighlight":"")} onClick={clickable?this.onClickCharacterOption.bind(this, o.extensionId, o.name):null}>{o.name}</span> {val}
                </td>);
                r++;
                if (row.length == 2) {
                    rows.push(<tr key={r} className="v-base">
                        {row}
                    </tr>);
                    row=[];
                }
            }
        }


        const handled={};
        character.traverseFeatures(function (params) {
            const e = params.feature;
            const {level,type, typeValue, usageId,fid, options} = params;
            const usage = e.usage;
            let add;

            if (usage?.showAsAttribute) {
                if (usage?.usageName && !handled[usage.usageName.toLowerCase()]) {
                    const max = character.getUsageMax(usage,level-1);
                    if (max) {
                        add = <span><b>{usage.usageName}</b> <UsageSlots 
                            character={character} 
                            useNumbers
                            numOnly
                            usage={usage} 
                            level={level-1}
                            id={usageId}
                        /> {usage.extraValue?replaceMetawords(usage.extraValue,character.namedValues):null}
                        </span>;
                        handled[usage.usageName.toLowerCase()]=true;
                    }
                }
                if (!add && (usage?.valueName && usage.displayLevels && !handled[usage.valueName.toLowerCase()])) {
                    add = <span><b>{usage.valueName}</b> <UsageSlots 
                        character={character} 
                        noCounts
                        useNumbers
                        usage={usage} 
                        level={level-1}
                        id={usageId}
                    /> {usage.extraValue?replaceMetawords(usage.extraValue,character.namedValues):null}
                    </span>;
                    handled[usage.valueName.toLowerCase()]=true;
                }
            }
            if (add) {
                row.push(<td key={r} className="w-50">{add}</td>);
                r++;
                if (row.length == 2) {
                    rows.push(<tr key={r} className="v-base">
                        {row}
                    </tr>);
                    row=[];
                }
            }

            if (e.customAttribute) {
                const prop = fid+ ".te."+e.customAttribute;
                row.push(<td key={r} className="w-50"><b>{e.customAttribute}</b> {options[prop]||"--"}</td>);
                r++;
                if (row.length == 2) {
                    rows.push(<tr key={r} className="v-base">
                        {row}
                    </tr>);
                    row=[];
                }
            }

        });

        if (row.length) {
            rows.push(<tr key={r} className="v-base">
                {row}
            </tr>);
            row=[];
        }
        rows.push(<CharacterOptionDialog key="dialog" editable={!this.props.readonly} open={this.state.showCharacterOptionDialog} character={character} extension={this.state.charOptExtension} name={this.state.charOptName} onClose={this.closeClickCharacterOption.bind(this)}/>);
        return rows;
    }

    onClickCharacterOption(extension, name) {
        this.setState({showCharacterOptionDialog:true, charOptExtension:extension, charOptName:name});
    }

    closeClickCharacterOption(){
        this.setState({showCharacterOptionDialog:false});
    }
}

function getProficiencesFromFeature(e, options, optionsBase) {
    const ret=[];
    const name = e.name||""

    if (e.weapons && e.weapons.choose) {
        const count = e.weapons.choose;
        for (let i=0; i<count; i++) {
            const v = options[optionsBase+"."+name+"weapon."+i];
            if (v) {
                ret.push(v);
            }
        }
    }
    if (e.armor && e.armor.choose) {
        const count = e.weapons.choose;
        for (let i=0; i<count; i++) {
            const v = options[optionsBase+"."+name+"armor."+i];
            if (v) {
                ret.push(v);
            }
        }
    }

    if (ret.length) {
        ret.sort();
        return <div>({ret.join(", ")})</div>;
    }
    return null;
}

class RenderClassText extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {pickClass:false,showClassDetails:false };
    }
    

    render() {
        const {character} = this.props;
        const classes = character.classes;
        const list = [];

        for (let i =0; i<classes.length; i++) {
            const c = classes[i];
            if (c.cclass) {
                if (list.length > 0) {
                    list.push(<span key={"k"+i}>, </span>);
                }
                const clsInfo = campaign.getClassInfo(c.cclass)||{};

                list.push(<Tooltip key={i} title="Show Class">
                    <span 
                        className={!this.props.readonly?"hover-bg-contrast":null}
                        onClick={!this.props.readonly?this.onClickClass.bind(this, c.cclass, c.subclass):null}
                    >
                        {(clsInfo.displayName||"")+((classes.length>1)?(" "+c.level):"")}
                    </span>
                </Tooltip>);
            }
        }

        if (!list.length && this.props.readonly) {
            return null;
        }

        let r = <span>
            <span className={!list.length?"hover-bg-contrast":""} onClick={!list.length?this.onShowClassEdit.bind(this):null}>
                {this.props.noDiv?null:<b>Class </b>}
                {list}
            </span>
            <PickLevels open={this.state.pickClass} character={character} onClose={this.onClosePickClass.bind(this)}/>
            <ShowClass open={this.state.showClassDetails} hideSubclasses cclass={this.state.showDetailsClass} subclass={this.state.showDetailsSubclass} extraButtonsFn={this.levelUp.bind(this)} onClose={this.onCloseDetails.bind(this)} character={character} disableEdit/>
        </span>;

        if (this.props.noDiv) {
            return <span>{r}</span>
        } else {
            return <div className="mb1">{r}</div>;
        }
    }

    levelUp() {
        const {character} = this.props;
        if (character.level < 20) {
            return <Button onClick={this.addClassLevel.bind(this)} color="primary">
                Level Up
            </Button>;
        }
    }

    addClassLevel() {
        addClass(this.props.character, this.state.showDetailsClass, 1);
        this.setState({showClassDetails:false, pickClass:true});
    }

    onClickClass(cclass, subclass) {
        this.setState({showClassDetails:true, showDetailsClass:cclass, showDetailsSubclass:subclass});
    }

    onShowClassEdit() {
        this.setState({pickClass:true});
    }

    onClosePickClass() {
        this.setState({pickClass:false});
    }

    onCloseDetails() {
        this.setState({showClassDetails:false});
    }
}

class RenderSavingsThrows extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }
    

    render() {
        const character = this.props.character;
        const abilities = character.abilities;
        const readonly=this.props.readonly;
        const short = this.props.small;
        let row=[];

        for (let i in abilities) {
            const a = abilities[i];

            row.push(<div key={i} className={(a.proficiency?"b ":"")+"savingThrow"+(readonly?"":" hoverroll")} onClick={readonly?null:this.doRoll.bind(this,i)}>
                {short?abilityNames[i].substr(0,3):abilityNames[i]} {signedNum(a.spellSave)}
            </div>);
        }

        return <div className="savingThrows avoidBreak">
            <div>
                <div className={this.props.editable?"theader hoverhighlight":"theader"} onClick={this.props.onClick}>Saving Throws</div>
                <div className="flex justify-around items-stretch">
                    {row}
                </div>
            </div>
        </div>
    }

    doRoll(i) {
        const character = this.props.character;
        const abilities = character.abilities;
        const a = abilities[i];
        character.doRoll({"D20":1, bonus:a.spellSave||0}, abilityNames[i], "Save");
    }
}

class RenderSkills extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }
    

    render() {
        const character = this.props.character;
        const readonly=this.props.readonly;
        const skills = character.skills;
        const allSkills = character.allSkillsWithAbilities;
        const ret=[];
        const numRows = Math.trunc((allSkills.length+2)/3);

        for (let x=0; x<numRows; x++) {
            const row=[];
            for (let y=0; y<3; y++) {
                const i = allSkills[x+y*numRows];
                if (i) {
                    const skillName = i.skill;
                    const s = skills[i.skill]||{};
                    
                    row.push(<td key={"p"+y} className={(readonly?"":"hoverroll ")+(s.proficiency?"b ":"")+"tr mw2"} onClick={(readonly||this.props.editable)?null:this.doRoll.bind(this,skillName)}>{signedNum(s.modifier)}</td>);
                    row.push(<td key={y} className={(readonly?"":"hoverroll ")+"overflow-hidden backgroundborder br"} onClick={(readonly||this.props.editable)?null:this.doRoll.bind(this,skillName)}><span className={s.proficiency?"b f6":"f6"}>{skillName}</span> <span className="f7 i gray-90">({i.mod})</span></td>);
                }
            }
            ret.push(<tr key={x}>{row}</tr>);
        }

        return <div className="skillBlock avoidBreak">
            <div className={this.props.editable?"hoverhighlight":null} onClick={this.props.onClick}>
                <div className="theader">Skills</div>
                <table className="w-100 collapse stdcontent">
                    <tbody>
                        {ret}
                    </tbody>
                </table>
            </div>
        </div>
    }

    doRoll(skill) {
        const character = this.props.character;
        const skills = character.skills;
        const s = skills[skill];
        character.doRoll({"D20":1, bonus:s.modifier||0}, skill, "Check");
    }

}

class RenderTools extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }
    

    render() {
        const character = this.props.character;
        const editable = this.props.editable;
        const list = [];
        const {toolSingleProficiencies} = campaign.getItemExtensions();

        if (!editable) {
            const tools = character.selectedTools;
            const toolsList = Object.keys(tools||{});
            toolsList.sort();

            for (let x in toolsList) {
                const i = toolsList[x];
                list.push(<span key={i}>
                    {list.length?", ":""}
                    <span className="hoverroll" onClick={this.showRoll.bind(this, i)}>{i}{(tools[i].proficiency == "expert")?"*":null}</span>
                </span>);
            }
        }

        return <div>
            {(editable||character.tools)?<CheckEdit label="Tools" values={editable?character.tools:null} character={character} modName="manualTools" property="tools" options={toolSingleProficiencies} editable={editable}>
                {list}
            </CheckEdit>:null}
            {this.state.showrolls?<Menu
                anchorEl={this.state.anchorEl||null}
                open
                onClose={this.closeRolls.bind(this)}
            >
                <MenuItem onClick={this.doRoll.bind(this, "str")}>Strength Check</MenuItem>
                <MenuItem onClick={this.doRoll.bind(this, "dex")}>Dexterity Check</MenuItem>
                <MenuItem onClick={this.doRoll.bind(this, "con")}>Constitution Check</MenuItem>
                <MenuItem onClick={this.doRoll.bind(this, "int")}>Intelligence Check</MenuItem>
                <MenuItem onClick={this.doRoll.bind(this, "wis")}>Wisdom Check</MenuItem>
                <MenuItem onClick={this.doRoll.bind(this, "cha")}>Charisma Check</MenuItem>
            </Menu>:null}
        </div>
    }

    showRoll(selectedTool, e) {
        this.setState({showrolls:true, selectedTool, anchorEl:e.target});
    }

    closeRolls() {
        this.setState({showrolls:false});
    }

    doRoll(ability) {
        const character = this.props.character;
        const tools = character.selectedTools;
        const tool = this.state.selectedTool;
        const expert = (tools[tool].proficiency == "expert");
        const abilities = character.abilities;
        const a = abilities[ability];

        character.doRoll({"D20":1, bonus:(a.modifier||0)+character.proficiency+(expert?character.proficiency:0)}, abilityNames[ability]+" ("+tool+")", "Check");

        this.setState({showrolls:false});
    }
}

class ItemEquip extends React.Component {
    
    render() {
        const equipment = this.props.character.equipment;
        const it = this.props.item;
        let inside = null;
        let et = getItemEquip(it);
        let disable = false;

        switch (et) {
            case "2H":
                if (it.equip) {
                    inside = <span className="f6">2H</span>;
                } else {
                    if (checkCollide(equipment, "2H")) {
                        disable=true;
                    }

                    inside = <span className="far fa-circle f4"/>;
                }
                break;
            case "V":
                if (it.equip == "2H") {
                    inside = <span className="f6">2H</span>;
                } else if (it.equip == "PH") {
                    inside = <span className="f6">PH</span>;
                } else if (it.equip == "OH") {
                    inside = <span className="f6">OH</span>;
                } else {
                    if (checkCollide(equipment, "PH") && checkCollide(equipment, "OH")) {
                        disable=true;
                    }
                    inside = <span className="far fa-circle f4"/>;
                }
                break;
            case "A":
                if (it.equip) {
                    inside = <span className="f6">A</span>;
                } else {
                    if (checkCollide(equipment, "A")) {
                        disable=true;
                    }
                    inside = <span className="far fa-circle f4"/>;
                }
                break;
            case "E":
                if (it.equip) {
                    inside = <span className="far fa-dot-circle f4"/>;
                } else {
                    inside = <span className="far fa-circle f4"/>;
                }
                break;
            case "H":
                if (it.equip == "PH") {
                    inside = <span className="f6">PH</span>;
                } else if (it.equip == "OH") {
                    inside = <span className="f6">OH</span>;
                } else {
                    if (checkCollide(equipment, "PH") && checkCollide(equipment, "OH")) {
                        disable=true;
                    }
                    inside = <span className="far fa-circle f4"/>;
                }
                break;
            case "OH":
                if (it.equip) {
                    inside = <span className="f6">OH</span>;
                } else {
                    if (checkCollide(equipment, "OH")) {
                        disable=true;
                    }
                    inside = <span className="far fa-circle f4"/>;
                }
                break;
            default:
        }

        return <td className={"tc w3"+((inside&&!disable)?" hoverhighlight":"")} onClick={this.onClick.bind(this)}><span className={"pa--2 f6"+(disable?" fdarkgray":"")}>{inside}</span></td>;
    }

    onClick() {
        const {character,id} = this.props;
        const attuned = character.attuned;
        const equipment = Object.assign({}, character.equipment);
        const it = Object.assign({}, equipment[id]);
        let et = getItemEquip(it);

        if (et == "V" && (it.equip == "2H")) {
            it.equip = "PH";
        } else if (it.equip) {
            delete it.equip;
        } else {
            switch (et) {
                case "V":
                    if (!checkCollide(equipment, "2H")) {
                        it.equip = "2H";
                    } else if (!checkCollide(equipment, "PH")) {
                        it.equip = "PH";
                    } else if (!checkCollide(equipment, "OH")) {
                        it.equip = "OH";
                    }
                    break;
                case "2H":
                    if (!checkCollide(equipment, "2H")) {
                        it.equip = "2H";
                    }
                    break;
                case "E":
                    it.equip = "E";
                    break;
                case "A":
                    if (!checkCollide(equipment, "A")) {
                        it.equip = "A";
                    }
                    break;
                case "H":
                    if (!checkCollide(equipment, "PH")) {
                        it.equip = "PH";
                    } else if (!checkCollide(equipment, "OH")) {
                        it.equip = "OH";
                    }
                    break;
                case "OH":
                    if (!checkCollide(equipment, "OH")) {
                        it.equip = "OH";
                    }
                    break;
                default:
            }
        }

        equipment[id] = it;
        if (it.equip) {
            // check to see if equip needs to split due to equip
            if (it.quantity > 1) {
                const newIt = Object.assign({}, it);
                delete newIt.equip;
                newIt.quantity --;
                it.quantity = 1;
                equipment[id+campaign.newUid()] = newIt;
            }
        } else if (!attuned[id]) {
            // look to merge with dups
            for (let y in equipment) {
                const nev = equipment[y];

                if ((y != id) && !nev.equip && !attuned[y] && itemsEquivalent(nev, it)) {
                    it.quantity = (it.quantity??1)+(nev.quantity??1);
                    delete equipment[y];
                }
            }
        }

        character.equipment = equipment;
    }
}

function checkCollide(equipment, et) {
    for (let i in equipment) {
        const e = equipment[i];

        switch (et) {
            case "2H":
                if ((e.equip == "2H") || (e.equip == "PH") || (e.equip == "OH")) {
                    return true;
                }
                break;
            case "PH":
                if ((e.equip == "2H") || (e.equip == "PH")) {
                    return true;
                }
                break;
            case "OH":
                if ((e.equip == "2H") || (e.equip == "OH")) {
                    return true;
                }
                break;
            case "A":
                if (e.equip == "A") {
                    return true;
                }
                break;
            default:
        }
    }
    return false;
}

function getItemEquip(it) {
    if ((it.property||[]).includes("2H")) {
        return "2H";
    }
    if ((it.property||[]).includes("V")) {
        return "V";
    }

    switch (it.type) {
        case "S":
            return "OH";
        case "HA":
        case "LA":
        case "MA":
            return "A";
        case "M":
        case "R":
        case "RD":
        case "WD":
            return "H";
    }

    const f = it.feature||{};
    if (it.reqAttune || f.armor || f.weapons || f.tools || f.skills || f.languages || 
        f.abilitySaves || f.speed || f.senses || f.ability || f.baseAC || f.rangedAttackBonus || 
        f.hpLevelMod || f.maxHP || f.acBonus || f.itemmod || f.initiativeBonus || f.perceptionBonus || 
        f.proficiencyBonus || f.spellDCBonus || f.spellAttackBonus || f.savingThrowBonus || f.skillCheckBonus ||
        f.speedAdjustment || f.unarmedAttack || f.resist || f.immune || f.vulnerable || f.conditionImmune || 
        f.extraAttack || f.castableSpells || f.extraNotes || it.extraFeatures) {
        return "E";
    }
    return null;
}



const emptyEntry = [""];

class CharacterAbility extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }
    
    render() {
        const {character, ability, readonly}  = this.props;
        const abilityVal = character.getAbility(ability);

        return <div className="w-16 tc abilityScore">
            <div onClick={readonly?null:this.doCheckRoll.bind(this)}>
                <div 
                    className={readonly?"ability":"ability hover-bg-contrast"} 
                    onClick={readonly?null:this.clickedAbilities.bind(this)}
                >
                    {ability}
                </div>
                <div className={readonly?"modifierScore":"modifierScore hoverroll"}>
                    {abilityVal.score} <span className="f2">({signedNum(abilityVal.modifier)})</span>
                </div>
                <div className={readonly?"modifier":"modifier hoverroll"}>
                    {signedNum(abilityVal.modifier)}
                </div>
                <div className={readonly?"score":"score hoverroll"}>
                    <span>{abilityVal.score}</span>
                </div>
            </div>
            <EditBaseAbilities open={this.state.editAbilities} character={character} abilities={character.state.baseAbilities} onClose={this.onCloseEditAbilities.bind(this)}/>
        </div>
    }

    clickedAbilities(event) {
        event.stopPropagation();
        event.preventDefault();
        this.setState({editAbilities:true});
    }

    onCloseEditAbilities(abilities) {
        if (abilities) {
            this.props.character.setProperty("baseAbilities", abilities);
        }
        this.setState({editAbilities:false});
    }
    
    doCheckRoll() {
        const {character,ability} = this.props;
        const a = character.getAbility(ability);

        character.doRoll({"D20":1, bonus:(a&&a.modifier)||0}, abilityNames[ability], "Check");
    }

}

class EditBaseAbilities extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            const {character,abilities}=this.props;
            this.setState({
                str:abilities.str, 
                dex:abilities.dex, 
                con:abilities.con, 
                int:abilities.int, 
                wis:abilities.wis, 
                cha:abilities.cha,
                maxstr:abilities.maxstr,
                maxdex:abilities.maxdex,
                maxcon:abilities.maxcon,
                maxint:abilities.maxint,
                maxwis:abilities.maxwis,
                maxcha:abilities.maxcha,
                modstr:abilities.modstr,
                moddex:abilities.moddex,
                modcon:abilities.modcon,
                modint:abilities.modint,
                modwis:abilities.modwis,
                modcha:abilities.modcha,
                selectType:abilities.selectType||"fixed",
                abilityRolls:abilities.abilityRolls,
                showOverrides:false,
                gamesystem:character.gamesystem,
                bfBonus1:abilities.bfBonus1||null,
                bfBonus2:abilities.bfBonus2||null
            });
        }
    }

    handleClose(savechanges, event) {
        if (savechanges){
            this.props.onClose({
                str:this.state.str,
                dex:this.state.dex,
                con:this.state.con,
                int:this.state.int,
                wis:this.state.wis,
                cha:this.state.cha,
                maxstr:this.state.maxstr||20,
                maxdex:this.state.maxdex||20,
                maxcon:this.state.maxcon||20,
                maxint:this.state.maxint||20,
                maxwis:this.state.maxwis||20,
                maxcha:this.state.maxcha||20,
                modstr:this.state.modstr||0,
                moddex:this.state.moddex||0,
                modcon:this.state.modcon||0,
                modint:this.state.modint||0,
                modwis:this.state.modwis||0,
                modcha:this.state.modcha||0,
                selectType:this.state.selectType,
                abilityRolls:this.state.abilityRolls||null,
                bfBonus1:this.state.bfBonus1||null,
                bfBonus2:this.state.bfBonus2||null,
            });
        } else {
            this.props.onClose();
        }
        event.stopPropagation();
    };

    get fixedList(){
        switch (this.state.gamesystem) {
            case "5e":
                return [15,14,13,12,10,8];
            case "bf":
                return [16,14,14,13,10,8];
        }
    }

    get abilityScorePointsMax() {
        switch (this.state.gamesystem) {
            case "5e":
                return 15;
            case "bf":
                return 18;
        }
    }

    get abilityScorePointsTotal() {
        switch (this.state.gamesystem) {
            case "5e":
                return 27;
            case "bf":
                return 32;
        }
    }

    render() {
        const selectType = this.state.selectType;
        const scores=[];
        const points=[];
        const selectedValues=selectType=="fixed"?this.fixedList:(this.state.abilityRolls||[]);
        const valueOptions=[];
        const baseClass = ((this.props.character.levels || [])[1]||{}).cclass;
        const classInfo =campaign.getClassInfo(baseClass)||{};
        let hint = classInfo.abilitiesHint;

        for (let i in abilityScorePoints) {
            if (i <= this.abilityScorePointsMax) {
                points.push(<td className="bl ph1" key={i}>{abilityScorePoints[i]}</td>);
                scores.push(<td className="bl ph1" key={i}>{i}</td>);
            }
        }

        for (let i=0; i<6; i++) {
            const v = selectedValues[i] || "-";
            if (selectType=="fixed") {
                valueOptions.push(<span className="pr2 f3" key={i}>{v}</span>);
               
            } else {
                valueOptions.push(<SelectVal className="pr1" key={i} value={v} values={rollValues} disabled={selectType=="fixed"} onClick={this.changeAbilityRoll.bind(this,i)}/>);
            }
        }

        return  <Dialog
            open={this.props.open||false}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>Ability Scores</DialogTitle>
            <DialogContent>
                <div className="hk-well mb3">
                    <SelectVal value={selectType} onClick={this.changeSelectType.bind(this)} values={selectTypes}/>
                    {selectType=="points"?<div className="mv2">{this.calcPointsText()}</div>:null}
                    {selectType=="points"?<div className="flex ma2">
                        <div className="flex-auto"></div>
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Score</td>{scores}
                                    </tr>
                                    <tr>
                                        <td>Points</td>{points}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="flex-auto"></div>
                    </div>:(selectType!="freeform")?<div className="ma2">
                        {selectType=="random"?<div className="mb2">Record your dice roles and then assign the values to your ability scores.</div>:null}
                        {valueOptions}
                        {selectType=="random"?<div className="mv2">
                            <table className="w-100">
                                <tbody>
                                    <tr>
                                        <td className="tc">Standard</td>
                                        <td className="tc">Hero</td>
                                        <td className="tc">Classic</td>
                                    </tr>
                                    <tr>
                                        <td className="tc">Best of 4d6</td>
                                        <td className="tc">2d6+6</td>
                                        <td className="tc">3d6</td>
                                    </tr>
                                    <tr>
                                        <td className="tc">
                                            <Button size="small" onClick={this.rollScores.bind(this, "standard", 6)} color="primary"><DiceIcon/> Roll 6</Button>
                                            <Button size="small" onClick={this.rollScores.bind(this, "standard", 7)} color="primary"><DiceIcon/> Roll 7</Button>
                                        </td>
                                        <td className="tc">
                                            <Button size="small" onClick={this.rollScores.bind(this, "hero", 6)} color="primary"><DiceIcon/> Roll 6</Button>
                                            <Button size="small" onClick={this.rollScores.bind(this, "hero", 7)} color="primary"><DiceIcon/> Roll 7</Button>
                                        </td>
                                        <td className="tc">
                                            <Button size="small" onClick={this.rollScores.bind(this, "classic", 6)} color="primary"><DiceIcon/> Roll 6</Button>
                                            <Button size="small" onClick={this.rollScores.bind(this, "classic", 7)} color="primary"><DiceIcon/> Roll 7</Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>:null}
                    </div>:null}
                </div>
                {this.getGamesystem()}
                <div className="w-100 flex flex-wrap tc">
                    {this.getAbilityEdit("str")}
                    {this.getAbilityEdit("dex")}
                    {this.getAbilityEdit("con")}
                    {this.getAbilityEdit("int")}
                    {this.getAbilityEdit("wis")}
                    {this.getAbilityEdit("cha")} 
                </div>
            {hint?<div className="mv2 stdcontent"><Renderentry entry={hint}/></div>:null}
            <div className="mv1">
                <span className={this.state.showOverrides?"far fa-check-square pa1 hoverhighlight":"far fa-square pa1 hoverhighlight"} onClick={this.toggleOverrides.bind(this)}/>
                Adjust abilities
            </div>
            {this.state.showOverrides?<div className="titleborder pa1 ba br2">
                <div className="w-100 flex flex-wrap tc">
                    {this.getAbilityOverride("str")}
                    {this.getAbilityOverride("dex")}
                    {this.getAbilityOverride("con")}
                    {this.getAbilityOverride("int")}
                    {this.getAbilityOverride("wis")}
                    {this.getAbilityOverride("cha")} 
                </div>
            </div>:null}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    toggleOverrides() {
        this.setState({showOverrides:!this.state.showOverrides});
    }

    rollScores(type, num) {
        const abilityRolls=[];
        let extra;

        for (let i=0; i<num; i++) {
            let r;
            switch(type){
            case "standard":
                const a=dicerandom(6);
                const b=dicerandom(6);
                const c=dicerandom(6);
                const d=dicerandom(6);
                r = a+b+c+d-Math.min(a,b,c,d);
                break;
            case "hero":
                r=dicerandom(6)+dicerandom(6)+6;
                break;
            case "classic":
                r=dicerandom(6)+dicerandom(6)+dicerandom(6);
                break;
            }
            if (i>=6) {
                extra=r;
            } else {
                abilityRolls[i]=r;
            }
        }
        if (num > 6) {
            let min=0;

            for (let i=1; i<6; i++){
                if (abilityRolls[i]<abilityRolls[min]){
                    min=i;
                }
            }
            if (abilityRolls[min]<extra) {
                abilityRolls[min] = extra;
            }
        }

        this.setState({abilityRolls});
    }

    changeSelectType(selectType) {
        this.setState({selectType});
    }

    changeAbilityRoll(i, select) {
        const abilityRolls = (this.state.abilityRolls||[]).concat([]);
        abilityRolls[i]=(select=="-")?null:Number(select);
        this.setState({abilityRolls});
    }

    getAbilityEdit(ability) {
        const score = this.state[ability];
        const modifier = this.props.character.getAbility(ability).mods+this.getAbilityMod(ability);
        const valuelist=[];
        const bonusValuelist=[];
        const selectType = this.state.selectType;
        const selectedValues=selectType=="fixed"?this.fixedList:(this.state.abilityRolls||[]);
        const usedValues = selectedValues.concat([]);
        const availPoints = this.calcAvailPoints(ability);
        let badSelection = false;

        for (let i in abilityList) {
            const l = abilityList[i];
            if (l != ability) {
                const pos = usedValues.indexOf(this.state[l]);
                if (pos >= 0) {
                    usedValues.splice(pos,1);
                }
            }
        }

        valuelist.push(<MenuItem className="pv1 " key={0} value={0}>-</MenuItem>);
        if (score==0) {
            badSelection=true;
        }
        for (let i=20; i>0; i--){
            const isRed = (selectType=="freeform")?false:(selectType=="points")?(i>this.abilityScorePointsMax||i<8):!selectedValues.includes(i);
            const isGray = (selectType=="freeform")?false:(selectType=="points")?(abilityScorePoints[i] > availPoints):!usedValues.includes(i);
            if ((i == score) && (isRed || isGray)) {
                badSelection = true;
            }
            if (!isRed || (i == score)) {
                valuelist.push(<MenuItem className={"pv1 "+(isRed?" light-red":isGray?" gray-80":"")} key={i} value={i}>{i}</MenuItem>);
                bonusValuelist.push(<MenuItem className={"pv1 "+(isRed?" light-red":isGray?" gray-80":"")} key={i+modifier} value={i+modifier}>{i+modifier}</MenuItem>);
            }
        }

        return <div className="flex-auto">
            <div className="ttu titlecolor f2">{ability}</div>
            <div className="titleborder ba br2 mh--2 pa--1">
                <Select
                    value={score||0}
                    onChange={this.changeAbility.bind(this, ability, false)}
                    className="f3 mv1"
                    style={{width:"44px"}}
                >
                    {valuelist}
                </Select>
                {badSelection?<span className="f6 red fas fa-exclamation-triangle"/>:null}
                {(modifier!=0)?<div className="f3">{signedNum(modifier)}</div>:null}
                {(modifier!=0 && score)?<span className="f3 mv1">{Math.min(score+modifier, (this.state["max"+ability]||20))}</span>:null}
            </div>
        </div>
    }

    getAbilityMod(ability) {
        const abilities = this.props.abilities || {};
        const {gamesystem, selectType,bfBonus1,bfBonus2} = this.state;
        let mod = -Number((abilities||{})["mod"+ability]||0)+Number(this.state["mod"+ability]||0)
        
        if ((gamesystem == "bf") && (selectType=="random")) {
            if (ability == bfBonus1) {
                mod+=1;
            }
            if (ability == bfBonus2) {
                mod+=2;
            }
            if (ability == abilities.bfBonus1) {
                mod-=1;
            }
            if (ability == abilities.bfBonus2) {
                mod-=2;
            }
        }
        return mod;
    }

    getAbilityOverride(ability) {
        return <div className="flex-auto">
            <div className="ttu titlecolor f2">{ability}</div>
            <div className="mh--2 pa--1">
                <SelectVal value={this.state["mod"+ability]||0} values={bonusTen} onClick={this.changeVal.bind(this,"mod"+ability)} isNum helperText="bonus"/>
            </div>
            <div className="mh--2 pa--1">
                <SelectVal value={this.state["max"+ability]||20} values={[30,29,28,27,26,25,24,23,22,21,20]} onClick={this.changeVal.bind(this,"max"+ability)} isNum helperText="max"/>
            </div>
        </div>
    }

    getGamesystem() {
        const {gamesystem, selectType,bfBonus1,bfBonus2} = this.state;
        if ((gamesystem == "bf") && (selectType=="random")) {
            return <div className="tc titleborder ba br2 mh--2 pa--1 mv1">
                <SelectVal className="mh2" values={abilityNames} value={bfBonus2||"-"} includeVal="-" onClick={this.onChangeBonus.bind(this,"bfBonus2")} helperText="Ability +2"/>
                <SelectVal className="mh2" values={abilityNames} value={bfBonus1||"-"} includeVal="-" onClick={this.onChangeBonus.bind(this,"bfBonus1")} helperText="Ability +1"/>
            </div>
        }
        return null;
    }

    onChangeBonus(name,val) {
        
        const ns = {};
        ns[name]=(val=="-")?null:val;
        this.setState(ns);
    }

    calcPointsText() {
        let points =this.abilityScorePointsTotal;

        for (let i in abilityList) {
            const v = this.state[abilityList[i]]||10;

            if (v > this.abilityScorePointsMax) {
                return "Bad points score, a selected score is too high, over "+this.abilityScorePointsMax+"."
            }

            if (v < 8) {
                return "Bad points score, a selected score is too low, under 8."
            }
            points = points - abilityScorePoints[v];
        }

        if (points == 0) {
            return "All points utilized.";
        }

        if (points > 0) {
            return "Not all points utilized, "+points+" remaining.";
        }
        return "Too many points utilized.  Over by "+(-points).toString()+ " points.";
    }

    calcAvailPoints(skip) {
        let points = this.abilityScorePointsTotal;

        for (let i in abilityList) {
            if (abilityList[i] != skip) {
                const v = this.state[abilityList[i]];

                points = points - abilityScorePoints[v];
            }
        }
        if (points < 0) {
            points=0;   
        }

        return points;
    }

    changeAbility(ability, adjustWithModifier, event) {
        const state={};
        const modifier = this.props.character.getAbility(ability).mods;

        state[ability]=event.target.value-(adjustWithModifier?modifier:0);
        this.setState(state);
    }

    changeVal(prop, val) {
        const state={};
        state[prop]=val;

        this.setState(state);
    }
}

function DiceIcon(props) {
    return <span className="fas fa-dice-d20 titlecolor mh1"/>;
}

const selectTypes = {
    "freeform":"Freeform",
    "points":"Point Buy",
    "random":"Roll Scores",
    "fixed":"Standard Array",
}
const rollValues=["-",18,17,16,15,14,13,12,11,10,9,8,7,6,5,4,3];
const abilityList = ["str", "int", "dex", "wis", "con", "cha"];


class PickRace extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            this.setState({openSelect:!this.props.character.race, askRename:false});
        }
    }

    handleClose(event) {
        this.props.onClose();
        event.stopPropagation();
    };

    render() {
        if (!this.props.open) {
            return null;
        }

        const character = this.props.character;
        const race = character.race;
        const rinfo = campaign.getRaceInfo(race);

        if (this.state.askRename) {
            return <AskYesNo open onClose={this.onCloseRename.bind(this)}>
                <div className="tc">Do you want to rename character using race names?</div>
            </AskYesNo> 
        }

        if (!rinfo || this.state.openSelect) {
            return <RaceSelector open background={race} onClose={this.changeRace.bind(this)} character={character}/>
        } 

        return <RaceDialog open race={race} disableEdit extraButtonsFn={this.getExtraButtons.bind(this)} onClose={this.handleClose.bind(this)}/>
    }

    getExtraButtons() {
        return <Button onClick={this.onOpenSelect.bind(this)} color="primary">
            Change Race
        </Button>
    }

    onCloseRename(yes) {
        if (yes) {
            this.props.renameFn();
        }
        this.props.onClose();
    }

    onSetAllowRaceOverride(allowRaceOverride) {
        this.props.character.allowRaceOverride=allowRaceOverride;
    } 

    onOpenSelect() {
        this.setState({openSelect:true});
    }

    changeRace(race) {
        const {character,renameFn} = this.props;
        if (race) {
            if (race != character.race) {
                this.props.character.setRace(race, {});
                const rInfo = campaign.getRaceInfo(race);
                if (rInfo?.maleNames || rInfo?.femailNames || rInfo?.familyNames) {
                    this.setState({askRename:true});
                    return;
                }
            }
        }
        this.props.onClose();
    }
}

class PickBackground extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            this.setState({openSelect:!this.props.character.background});
        }
    }

    handleClose(event) {
        this.props.onClose();
        event.stopPropagation();
    };

    render() {
        if (!this.props.open) {
            return null;
        }

        const character = this.props.character;
        const background = character.background;
        const binfo = campaign.getBackgroundInfo(background);

        if (!binfo || this.state.openSelect) {
            return <BackgroundSelector open background={background} onClose={this.closeBackgroundSelector.bind(this)} character={character}/>
        } 

        return <BackgroundDialog open background={background} disableEdit extraButtonsFn={this.getExtraButtons.bind(this)} onClose={this.handleClose.bind(this)}/>
    }

    getExtraButtons() {
        return <Button onClick={this.showBackgroundSelector.bind(this)} color="primary">
            Change Background
        </Button>
    }


    showBackgroundSelector() {
        this.setState({openSelect:true})
    }

    closeBackgroundSelector(background) {
        const character = this.props.character;
        if (background && (background != character.background)) {
            character.setBackground(background, {});
        }
        this.props.onClose();
        this.setState({openSelect:false})
    }
}

class HeroAbilities extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            this.setState({});
        }
    }

    handleClose(event) {
        this.props.onClose();
        event.stopPropagation();
    };

    render() {
        if (!this.props.open) {
            return null;
        }

        const character = this.props.character;
        const options = character.heroOptions;
        const heroAbilities = character.heroAbilities||[];
        const list=[];

        for (let i in heroAbilities) {
            const h = heroAbilities[i];
            let it;

            if (h.customType=="Feats") {
                it = campaign.getFeatInfo(h.customId);
            } else {
                it = campaign.getCustom(h.customType, h.customId);
            }

            if (it) {
                const baseName ="hero."+h.id+".";
                const r = it.features;
                const opts=[];
                for (let i in r) {
                    if (hasFeatureConfig(r[i]) && character.checkRestriction(r[i].restriction)) {
                        const featureBaseName = baseName+(r[i].id||r[i].name||"");
                        opts.push(<FeatureConfigure key={featureBaseName+i}
                            character={this.props.character}
                            feature={r[i]}
                            baseName={featureBaseName}
                            options={options}
                            onChange={this.changeOptionVal.bind(this)}
                        />);
                    }
                }
        
                list.push(<div key={h.id} className="mb2">
                    <div className="flex items-center titlecolor">
                        <h2 className="flex-auto">{it.displayName} from {h.customType}</h2>
                        <DeleteWithConfirm name={h.displayName} onClick={this.onDeleteAbility.bind(this,i)}/>
                    </div>
                    {opts.length?<div className="hk-well">
                        {opts}
                    </div>:null}
                </div>);
            }
        }

        return  <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this)}>Hero Abilities</DialogTitle>
            <DialogContent>
                <div className="stdcontent">
                    {list}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.addAbility.bind(this)} color="primary">
                    Add
                </Button>
                <Button onClick={this.handleClose.bind(this)} color="primary">
                    Close
                </Button>
            </DialogActions>
            <CustomAddDialog open={this.state.showAddAbilities} onClose={this.onAddAbility.bind(this)} character={character}/>
        </Dialog>;
    }

    addAbility() {
        this.setState({showAddAbilities:true})
    }

    changeOptionVal(option, value) {
        const character = this.props.character;
        const heroAbilities = character.heroAbilities;
        const options = character.heroOptions;
        const opts = Object.assign({}, options);

        opts[option] = value;

        this.props.character.setHeroAbilities(heroAbilities, opts);
    }

    onDeleteAbility(i) {
        const character = this.props.character;
        const heroAbilities = character.heroAbilities.concat([]);
        heroAbilities.splice(i,1);
        character.setHeroAbilities(heroAbilities, Object.assign({},character.heroOptions));
    }

    onAddAbility(customType, customId) {
        if (customType && customId) {
            const character = this.props.character;
            let it;
            if (customType=="Feats") {
                it = campaign.getFeatInfo(customId);
            } else {
                it = campaign.getCustom(customType, customId);
            }
            const heroAbilities = (character.heroAbilities||[]).concat([{
                id:campaign.newUid(),
                customType,
                customId,
                displayName:it.displayName
            }]);
            character.setHeroAbilities(heroAbilities, Object.assign({},character.heroOptions));
        }
        this.setState({showAddAbilities:false})
    }
}

class PickLevels extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {classes:[],expanded:[] };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            const level = this.props.character.level;
            const expanded = [];

            if (level > 1) {
                expanded[level]=true;
            }

            this.setState({showAddClass:!level&&!this.props.noAutoClass,
                expanded
            });
        }
    }

    handleClose(event) {
        this.props.onClose();
    };

    render() {
        const {open, character} = this.props;
        if (!open) {
            return null;
        }
        const spellcastingOptions = {
            "default":"Default ("+(character.defaultUseSpellPoints?"Spell Points)":"Spell Slots)"),
            "spellpoints":"Always use Spell Points",
            "spellslots":"Always use Spell Slots",
        }

        return  <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                Character Options {this.getSetClassLevels()}
            </DialogTitle>
            <DialogContent>
                {this.getLevelsList()}
                {this.getOriginOptions()}
                {this.getBackgroundOptions()}
                {this.getExtensionOptions()}
                {character.isSpellCaster?<div className="mv1">
                    <div className="f2 mt2 mb2 titletext titlecolor titlebordercolor bb">Spellcasting</div>
                    <div className="mv1 hk-well tc">
                        <SelectVal values={spellcastingOptions} value={character.state.spellcastingOverride||"default"} onClick={this.onChangeOverride.bind(this)}/>
                    </div>
                </div>:null}
            </DialogContent>
            <DialogActions>
                {(character.level<20)?<Button onClick={this.onShowAddClass.bind(this)} color="primary">
                    Level UP
                </Button>:null}
                <Button onClick={this.handleClose.bind(this)} color="primary">
                    Close
                </Button>
            </DialogActions>
            <AddClass open={this.state.showAddClass} character={character} onClose={this.onAddClass.bind(this)}/>
            <CustomPicker 
                character={this.props.character}
                open={this.state.showCustomPicker}
                type={this.state.customType} 
                selected={this.state.selected} 
                known={this.state.customKnown}
                gamesystemPref={this.state.customGamesystemPref}
                onClose={this.onCloseCustomPicker.bind(this)}
            />
            <CustomDialog open={this.state.showCustom||false} id={this.state.selectedCustomId} type={this.state.selectedCustomType} onClose={this.hideCustom.bind(this) }/>
        </Dialog>;
    }

    onChangeOverride(value) {
        this.props.character.setProperty("spellcastingOverride", value);
    }

    onShowAddClass() {
        this.setState({showAddClass:true});
    }

    onAddClass(cclass, count) {
        const character = this.props.character;

        addClass(character, cclass, count);

        const level =character.level;
        const expanded = [];

        if (level > 1) {
            expanded[level]=true;
        }

        if (!cclass && !character.level) {
            this.props.onClose();
        }
        this.setState({showAddClass:false, expanded});
    }

    getLevelsList() {
        const levels = this.props.character.levels || [];
        const {expanded} = this.state;
        const ret = [];

        for (let i=(levels.length-1); i>0; i--) {
            const l = levels[i];
            const clsInfo = campaign.getClassInfo(l.cclass)||{};
            const multiclassLevel = (i!=l.level);
            const {hp, options,renderFeatures} = this.getLevelOptions(l, i);
            ret.push(<div key={i}>
                <div className="f2 mb1 titletext titlecolor titlebordercolor bb flex">
                    <div className="flex-auto">
                        {multiclassLevel?<span>{i}: </span>:null}{clsInfo.displayName} Level {l.level} 
                        {renderFeatures.length?<Tooltip title={expanded[i]?"Hide Details":"Show Details"}><span onClick={this.toggleExpanded.bind(this,i)} className={"hoverhighlight "+(expanded[i]?"fas fa-caret-up pa1":"fas fa-caret-down pa1")}/></Tooltip>:null}
                    </div>
                    <div> {hp}</div>
                </div>
                {renderFeatures.length&&expanded[i]?<div className="stdcontent f4 ph2 mb1">{renderFeatures}</div>:null}
                {options}
            </div>);
        }
        return ret;
    }

    toggleExpanded(level) {
        const expanded= this.state.expanded.concat([]);
        expanded[level]=!expanded[level];
        this.setState({expanded});
    }

    getBackgroundOptions() {
        const {character} = this.props;
        const options = character.backgroundOptions;
        const backgroundName = character.background;
        const background = campaign.getBackgroundInfo(backgroundName);
        if (!background) {
            return null;
        }
        const opts = [];

        const baseName ="background."+background.name+".";
        const r = background.features;
        for (let i in r) {
            if (hasFeatureConfig(r[i]) && character.checkRestriction(r[i].restriction)) {
                const featureBaseName = baseName+(r[i].id || r[i].name||"");
                opts.push(<FeatureConfigure key={featureBaseName+i}
                    character={character}
                    feature={r[i]}
                    baseName={featureBaseName}
                    options={options}
                    onChange={this.changeGenOptionVal.bind(this,"backgroundOptions")}
                />);
            }
        }

        if (opts.length){
            return <div>
                <div className="f2 mt2 mb2 titletext titlecolor titlebordercolor bb">{background.displayName||"Background"} Options</div>
                <div className="mv1 hk-well tc">
                    {opts}
                </div>
            </div>
        }
        return null;
    }

    getExtensionOptions() {
        const {character} = this.props;
        const options = character.extensionOptions;
        const extensionOptions = character.characterExtensionsOptions;
        const opts = [];

        for (let x in extensionOptions) {
            const ext = extensionOptions[x];
            const foptions = ext.features;

            for (let i in foptions) {
                const charOpt=foptions[i];

                const baseName ="charopt.c."+ext.extension.name+"."+charOpt.name;
                if (charOpt.type == "select") {
                    const selected = character.getExtensionCharacterOption(baseName);
                    const selectedVals = Object.keys(selected||{});
                    const ct = campaign.getCustom(charOpt.customType, selectedVals[0]);
                    opts.push(<div className="mb1" key={"sel"+i+baseName}>
                        {ct?<LinkHref href={"#customlist?type="+encodeURIComponent(charOpt.customType)+"&id="+selectedVals[0]}>{ct.displayName}</LinkHref>:null} <Button onClick={this.pickCharacterOpt.bind(this, charOpt.customType, baseName, selected)} variant="outlined" size="small">Pick {charOpt.customType}</Button>
                    </div>)
        
                    if (ct) {
                        for (let x in ct.features) {
                            const f = ct.features[x];
                            const custBaseName = baseName+".customtable."+(f.id||f.name||"");
        
                            if (hasFeatureConfig(f) && character.checkRestriction(f.restriction)) {
                                opts.push(<FeatureConfigure
                                    key={baseName+".customtable."+x}
                                    character={character}
                                    feature={f}
                                    baseName={custBaseName}
                                    options={options}
                                    onChange={this.changeGenOptionVal.bind(this,"extensionOptions")}
                                />);
                            }
                        }
                    }
                }
        
                const r = charOpt.features;
                for (let i in r) {
                    if (hasFeatureConfig(r[i]) && character.checkRestriction(r[i].restriction)) {
                        const featureBaseName = baseName+"."+(r[i].id||r[i].name||"");
                        opts.push(<FeatureConfigure key={featureBaseName+i}
                            character={character}
                            feature={r[i]}
                            baseName={featureBaseName}
                            options={options}
                            onChange={this.changeGenOptionVal.bind(this,"extensionOptions")}
                        />);
                    }
                }
            }
        }

        if (opts.length){
            return <div className="mv1 hk-well tc">
                {opts}
                <CustomPicker 
                    open={this.state.charOptShowCustomPicker}
                    type={this.state.charOptCustomType} 
                    selected={this.state.charOptPickselected} 
                    known={1}
                    onClose={this.onCloseCharOptCustomPicker.bind(this)}
                />

            </div>
        }
        return null;
    }

    pickCharacterOpt(customType, key, selected) {
        this.setState({charOptShowCustomPicker:true, charOptCustomType:customType, charOptKey:key, charOptPickselected:selected});
    }

    onCloseCharOptCustomPicker(selected) {
        if (selected) {
            this.props.character.setExtensionCharacterOption(this.state.charOptKey, selected);
        }
        this.setState({charOptShowCustomPicker:false});
    }


    getLineageOptions() {
        const {character} = this.props;
        const options = character.lineageOptions;
        const lineage = campaign.getCustom("Lineages", character.lineage);
        const opts = [];

        if (!lineage) {
            return null;
        }

        const baseName ="lineage."+lineage.name+".";
        const r = lineage.features;
        for (let i in r) {
            if (hasFeatureConfig(r[i]) && character.checkRestriction(r[i].restriction)) {
                const featureBaseName = baseName+(r[i].id || r[i].name||"");
                opts.push(<FeatureConfigure key={featureBaseName+i}
                    character={character}
                    feature={r[i]}
                    baseName={featureBaseName}
                    options={options}
                    onChange={this.changeGenOptionVal.bind(this,"lineageOptions")}
                />);
            }
        }

        if (opts.length){
            return <div>
                <div className="f2 mt2 mb2 titletext titlecolor titlebordercolor bb">{lineage.displayName||"Lineage"} Options</div>
                <div className="mv1 hk-well tc">
                    {opts}
                </div>
            </div>
        }
        return null;
    }

    getHeritageOptions() {
        const {character} = this.props;
        const options = character.heritageOptions;
        const heritage = campaign.getCustom("Heritages", character.heritage);
        if (!heritage) {
            return null;
        }
        const opts = [];

        const baseName ="heritage."+heritage.name+".";
        const r = heritage.features;
        for (let i in r) {
            if (hasFeatureConfig(r[i]) && character.checkRestriction(r[i].restriction)) {
                const featureBaseName = baseName+(r[i].id || r[i].name||"");
                opts.push(<FeatureConfigure key={featureBaseName+i}
                    character={character}
                    feature={r[i]}
                    baseName={featureBaseName}
                    options={options}
                    onChange={this.changeGenOptionVal.bind(this,"heritageOptions")}
                />);
            }
        }

        if (opts.length){
            return <div>
                <div className="f2 mt2 mb2 titletext titlecolor titlebordercolor bb">{heritage.displayName||"Heritage"} Options</div>
                <div className="mv1 hk-well tc">
                    {opts}
                </div>
            </div>
        }
        return null;
    }

    getOriginOptions() {
        const {character} = this.props;
        switch (character.gamesystem) {
            case "5e":
                return this.getRaceOptions();
            case "bf":{
                return <div>
                    {this.getLineageOptions()}
                    {this.getHeritageOptions()}
                </div>;
            }
        }
    }

    getRaceOptions() {
        const {character} = this.props;
        const options = character.raceOptions;
        const raceName = character.race;
        const race = campaign.getRaceInfo(raceName);
        const allowRaceOverride = character.allowRaceOverride;
        const sizeOptions = [{name:"Small", value:"S"},{name:"Medium", value:"M"},];

        if (!race) {
            return null;
        }
        let opts = [];

        const baseName ="race."+race.name+".";
        const r = race.raceFeatures;

        if (race.size == "V") {
            opts.push(<SelectVal key="racepick" className="mb1" value={options.raceSize||"M"} helperText="character size" values={sizeOptions} onClick={this.changeOptionVal.bind(this,"raceSize")} fullWidth/>);
        }
        for (let i in r) {
            if (hasFeatureConfig(r[i],allowRaceOverride) && character.checkRestriction(r[i].restriction)) {
                const featureBaseName = baseName+(r[i].id || r[i].name || "");
                opts.push(<FeatureConfigure key={featureBaseName+i}
                    allowAbilityOverride={allowRaceOverride}
                    character={character}
                    feature={r[i]}
                    baseName={featureBaseName}
                    options={options}
                    onChange={this.changeGenOptionVal.bind(this,"raceOptions")}
                />);
            }
        }

        return <div>
            <div className="f2 mt2 mb2 titletext titlecolor titlebordercolor bb">{race.displayName||"Race"} Options</div>
            <div className="mv1 hk-well tc">
                <div>
                    <CheckVal value={allowRaceOverride} onChange={this.onSetAllowRaceOverride.bind(this)} label="Allow selection for all abilities"/>
                    {allowRaceOverride?<div className="hk-well mb1">You can now select your own ability bonuses instead of taking the defaults assigned by the race.</div>:null}
                </div>

                {opts}
            </div>
        </div>
    }

    onSetAllowRaceOverride(allowRaceOverride) {
        this.props.character.allowRaceOverride=allowRaceOverride;
    } 
        
    changeGenOptionVal(oval, option, value) {
        const {character} = this.props;
        const options = character.state[oval]||{};
        const opts = Object.assign({}, options);

        opts[option] = value;

        character.setProperty(oval, opts);
    }

    getSetClassLevels() {
        const {character} = this.props;
        const {confirmDeleteLevels,removeClassName} = this.state;
        const res=[];
        const remainingLevels = 20-character.level;

        for (let x in character.classes) {
            const cls = character.classes[x];
            const clsInfo = campaign.getClassInfo(cls.cclass);
            const levelVals =[];
            const dn = clsInfo?.displayName||"unknown";

            levelVals.push({name:"Remove "+dn, value:0});

            for (let i=1; i<=(cls.level+remainingLevels); i++) {
                levelVals.push(i);
            }
            res.push(<span className="ml2" key={x}><span className="f3">{dn}&nbsp;</span><SelectVal basic selectClass="f3" value={cls.level} values={levelVals} isNum onClick={this.setClassLevel.bind(this, dn, cls.cclass, cls.level)}/></span>);
        }
        if (!res.length) {
            return null;
        }

        return <span className="ignoreDrag notetext defaultcolor">
            {res}
            <AskYesNo open={confirmDeleteLevels} onClose={this.onConfirmRemoveLevels.bind(this)}>
                <div className="tc">Are you sure that you want to remove {removeClassName} levels?</div>
            </AskYesNo>
        </span>;
    }

    setClassLevel(removeClassName, cclass, prevLevel, level) {
        if (prevLevel==level) {
            return;
        }

        if (prevLevel < level) {
            const {character} = this.props;
            const levels = character.levels.concat([]);
    
            for (let i=prevLevel+1; i<=level; i++){
                levels.push({cclass:cclass,level:i});
            }
            character.setLevels(levels);
        } else {
            this.setState({removeClassName, confirmDeleteLevels:true, deleteClass:cclass, maxLevel:level});
        }
    }

    onConfirmRemoveLevels(yes) {
        if (yes) {
            const {character} = this.props;
            const {deleteClass, maxLevel} = this.state;
            const levels = character.levels.concat([]);
    
            for (let i=20; i>0; i--) {
                const l = levels[i];
                if ((l?.cclass==deleteClass) && (l.level >maxLevel)) {
                    levels.splice(i,1);
                }
            }
            character.setLevels(levels);
        }
        this.setState({confirmDeleteLevels:false})
    }

    getLevelOptions(lInfo, clevel) {
        const character = this.props.character;
        let classIndex = lInfo.classIndex;
        const classes = character.classes;
        const renderFeatures = [];

        for (let i in classes) {
            if (classes[i].cclass == lInfo.cclass) {
                classIndex = i;
            }
        }
        const clsInfo = classes[classIndex] || {};
        const level = lInfo.level;
        const l = lInfo.level-1;
        const options = clsInfo.options||{};
        const cls = campaign.getClassInfo(clsInfo.cclass);
        const hpVals = clsInfo.hp || [];
        if (!cls) {
            return {renderFeatures};
        }
        const opts = [];

        const hpOptions = [];

        hpOptions.push({name:"Avg", value:0});
        for (let i=1; i<= clsInfo.faces; i++) {
            hpOptions.push({name:i, value:i});
        }

        const curHP = hpVals[level-1];
        const hp = <span>
            <PickVal values={hpOptions} onClick={this.changeHP.bind(this,classIndex, level-1)}>
                <span className="hoverhighlight">{curHP||Math.round((clsInfo.faces+1)/2)} HP</span>
            </PickVal>
            {curHP?null:<Tooltip title="Roll HP"><span className="ml1 f3 hoverroll b titlecolor" onClick={this.rollHP.bind(this, cls.displayName, classIndex, level-1, clsInfo.faces)}><span className="fas fa-dice-d20"/></span></Tooltip>}
        </span>;

        if (level == 1) {
            let proficiencies;

            if (clevel > 1) {
                if (cls.multiclassing) {
                    proficiencies = cls.multiclassing.proficienciesGained;
                }
            } else {
                proficiencies = cls.startingProficiencies;
            }

            if (hasFeatureConfig(proficiencies)) {
                opts.push(<div key="startingproficiencies" className="hk-well mb2">
                    <div className="f2 mb2">Proficiencies</div>
                    <FeatureConfigure
                        character={character}
                        feature={proficiencies}
                        baseName=""
                        options={options}
                        onChange={this.changeOptionVal.bind(this,classIndex)}
                    />
                </div>);
            }
        }

        if (level == (cls.startSubclass||1)) {
            opts.push(<div key="subclass" className="hk-well mb2">
                <PickSubclass title={cls.subclassTitle} cclass={cls.className} subclass={clsInfo.subclass} onChange={this.changeSubclass.bind(this,classIndex)} character={character}/>
            </div>);
        }

        const {table} = getFeaturesTable(cls, clsInfo.subclass);

        let featureOpts = [];

        const r = table[l];

        for (let i in r) {
            renderFeatures.push(<RenderFeature h3 feature={r[i]} key={i}/>);
            if (hasFeatureConfig(r[i]) && character.checkRestriction(r[i].restriction)) {
                const baseName = "feature"+l+"."+(r[i].id||r[i].name||"");
                featureOpts.push(<FeatureConfigure key={baseName+i}
                    character={character}
                    feature={r[i]}
                    baseName={baseName}
                    options={options}
                    onChange={this.changeOptionVal.bind(this,classIndex)}
                />);
            }
        }


        if (featureOpts.length) {
            opts.push(<div key="featureopts" className="hk-well mb2">
                <div className="f2 mb2">Class Feature Options</div>
                {featureOpts}
            </div>);
        }

        if (clsInfo.level == level) {
            const subclass = campaign.getSubclassInfo(clsInfo.subclass);
            const customLevels = getMergedCustomLevels(cls, subclass);
            for (let x in customLevels) {
                const cl=customLevels[x];
                const count = cl.levelsCount[clsInfo.level-1];

                if (count && (cl.attributeType == "select")) {
                    const selected = (clsInfo.customSelected && clsInfo.customSelected[cl.name])||{};
                    const customOptions=[];
                    const list = [];
                    const selist = [];

                    for (let s in selected) {
                        const ct = campaign.getCustom(cl.name, s);
                        const custBaseName = "custom."+clsInfo.cclass+"."+cl.name+"."+s;
                        if (ct) {
                            list.push(ct);

                            for (let x in ct.features) {
                                const f = ct.features[x];
            
                                if (hasFeatureConfig(f) && character.checkRestriction(f.restriction)) {
                                    customOptions.push(<FeatureConfigure
                                        key={"customtable."+ct.name+x}
                                        character={character}
                                        feature={f}
                                        baseName={custBaseName+"."+(f.id||f.name||"")}
                                        options={options}
                                        onChange={this.changeOptionVal.bind(this,classIndex)}
                                    />);
                                }
                            }
                        }    
                    }
                    list.sort(function (a,b) {return (a.displayName||"").toLowerCase().localeCompare((b.displayName||"").toLowerCase())});

                    for (let s in list) {
                        const ct=list[s];
                        selist.push(<span key={s}>{selist.length?", ":""}<a onClick={this.showCustom.bind(this,cl.name, ct.id)}>{ct.displayName}</a></span>);
                    }

                    opts.push(<div key={cl.name+"."+x} className="hk-well mb2">
                        <div className="f2 mb2">{cl.name}</div>
                        <div>
                            {selist.length<count?<ConfigureAlert/>:null}
                            {selist.length?selist:"(need to select) "}
                            <Button className="ml1" onClick={this.onShowCustomPicker.bind(this, classIndex, cl.name, count, selected,cls.gamesystem||"5e")} color="primary" variant="outlined" size="small">Pick</Button>
                        </div>
                        {customOptions}
                    </div>);
                }
            }
        }

        return {hp, options:<div key="">{opts}</div>,renderFeatures};
    }

    onShowCustomPicker(i, type, known, selected,customGamesystemPref) {
        this.setState({showCustomPicker:true, customType:type, selectedClassIndex:i, selected, customKnown:known,customGamesystemPref});
    }

    onCloseCustomPicker(selected) {
        if (selected) {
            const character = this.props.character;
            const classes = character.classes.concat([]);
            const cInfo = Object.assign({}, classes[this.state.selectedClassIndex]);
            classes[this.state.selectedClassIndex] = cInfo;
            cInfo.customSelected = Object.assign({}, cInfo.customSelected||{});
            cInfo.customSelected[this.state.customType] = selected;
            character.setClasses(classes);
        }
        this.setState({showCustomPicker:false});
    }

    showCustom(type, name) {
        this.setState({showCustom:true, selectedCustomId:name, selectedCustomType:type});
    }

    hideCustom() {
        this.setState({showCustom:false});
    }

    changeOption(index, option, event) {
        this.changeOptionVal(index, option, event.target.value);
    }

    changeOptionVal(index, option, value) {
        const classes = this.props.character.classes.concat([]);
        const cls = Object.assign({}, classes[index] || {});
        cls.options = Object.assign({}, cls.options);
        cls.options[option] = value;
        classes[index] = cls;

        this.props.character.setClasses(classes);
    }

    setFeat(index, level, event) {
        const classes = this.props.character.classes.concat([]);
        const cls = Object.assign({}, classes[index] || {});
        cls.options = Object.assign({}, cls.options);
        cls.options["feat"+level] = event.target.value;
        const start = "ability"+level;
        for (let i in cls.options) {
            if (i.startsWith(start)) {
                delete cls.options[i];
            }
        }
        classes[index] = cls;

        this.props.character.setClasses(classes);
    }

    toggleFeat(index, level){
        const classes = this.props.character.classes.concat([]);
        const cls = Object.assign({}, classes[index] || {});
        cls.options = Object.assign({}, cls.options);
        cls.options["setfeat"+level] = !cls.options["setfeat"+level];
        const start = "ability"+level;
        for (let i in cls.options) {
            if (i.startsWith(start)) {
                delete cls.options[i];
            }
        }
        classes[index] = cls;

        this.props.character.setClasses(classes);
    }

    changeHP(index, hplevel, value) {
        const classes = this.props.character.classes.concat([]);
        const cls = Object.assign({}, classes[index] || {});

        cls.hp = (cls.hp||[]).concat([]);
        cls.hp[hplevel] = value;

        // make sure that blank spaces are correctly filled
        for (let i=0;i<cls.hp.length;i++) {
            if (!cls.hp[i]) {
                cls.hp[i]=null;
            }
        }
        classes[index] = cls;

        this.props.character.setClasses(classes);
    }

    rollHP(displayName, index, hplevel, faces) {
        const {character} = this.props;
        const counts = {};
        counts["D"+faces]=1;
        const res = character.doRoll(counts,displayName, "HP roll");

        this.changeHP(index, hplevel, res.rolls[0]);
    }

    changeSubclass(classIndex, sc) {
        const classes = this.props.character.classes.concat([]);
        classes[classIndex] = Object.assign({}, classes[classIndex]);
        classes[classIndex].subclass = sc;

        this.props.character.setClasses(classes);
    }

    getSubclasses(classIndex) {
        const clsInfo = this.props.character.classes[classIndex] || {};
        const subclasses = campaign.getSubclasses(clsInfo.cclass);
        const ret=[];
       
        ret.push(<MenuItem className="pv2" key="none" value="-- pick subclass --">-- pick subclass --</MenuItem>);

        for (let i in subclasses){
            const sc=subclasses[i];
            ret.push(<MenuItem className="pv2" key={sc.subclassName} value={sc.subclassName}>{sc.displayName}</MenuItem>);
        }
        return ret;
    }

    getLevels() {
        const ret=[];

        for (let i=1; i<=20; i++){
            ret.push(<MenuItem className="pv2" key={i} value={i}>{i}</MenuItem>);
        }
        return ret;
    }
}

const includeCharacterDescriptions = {age:true, size:true, appearance:true};

class CharacterDescription extends React.Component {
   constructor(props) {
        super(props);

	    this.state= {};
    }

    getDescriptionOptions() {
        const character = this.props.character;
        const background = campaign.getBackgroundInfo(character.background);
        const tables = findTables(background);
        let race =campaign.getRaceInfo(character.race);
        if (character.is5E) {
            Object.assign(tables, findTables(race)); 
        }
        const classes = character.classes;

        for (let i in classes) {
            const c = campaign.getClassInfo(classes[i].cclass);
            Object.assign(tables, findTables(c)); 
            if (classes[i].subclass) {
                const subc = campaign.getSubclassInfo(classes[i].subclass);
                Object.assign(tables, findTables(subc)); 
            }
        }

        character.traverseFeatures(function (params) {
            const e = params.feature;
            if (e.descriptionOptions) {
                Object.assign(tables, findTables(e)); 
            }
        });
        return tables;
    }

    render() {
        const character = this.props.character;
        const readonly = this.props.readonly;

        return <div>
            <div className="traitBlock avoidBreak">
                <div>
                    <div className="theader">
                        Traits
                    </div>
                    <table className={"ttable w-100"+(readonly?"":" hover-bg-contrast")} onClick={!readonly?this.onShowEditBasicDescription.bind(this):null}>
                        <tbody>
                            <tr className="b">
                                <td>Alignment</td>
                                <td>Gender</td>
                                <td>Eyes</td>
                                <td>Size</td>
                                <td>Height</td>
                            </tr>
                            <tr className="pb1">
                                <td className="pb1">{character.getProperty("alignment") ||"--"}</td>
                                <td className="pb1">{character.getProperty("gender") ||"--"}</td>
                                <td className="pb1">{character.getProperty("eyes") ||"--"}</td>
                                <td className="pb1">{Parser.sizeAbvToFull(character.size) ||"--"}</td>
                                <td className="pb1">{character.getProperty("height") ||"--"}</td>
                            </tr>
                            <tr className="b">
                                <td>Faith</td>
                                <td>Hair</td>
                                <td>Skin</td>
                                <td>Age</td>
                                <td>Weight</td>
                            </tr>
                            <tr>
                                <td>{character.getProperty("faith") ||"--"}</td>
                                <td>{character.getProperty("hair") ||"--"}</td>
                                <td>{character.getProperty("skin") ||"--"}</td>
                                <td>{character.getProperty("age") ||"--"}</td>
                                <td>{character.getProperty("weight") ||"--"}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {(!readonly || character.getProperty("personality")||character.getProperty("ideals")||character.getProperty("bonds")||
            character.getProperty("flaws")||character.getProperty("faction")||character.getProperty("allies")||character.getProperty("traits")||
            character.getProperty("backstory"))?<div className="descBlock avoidBreak">
                <div className="maintext">
                    {!readonly||character.getProperty("personality")?<TextClick editable={!this.state.readonly} label="Personality Traits" text={character.getProperty("personality")||"--"} onClick={this.onShowChangeText.bind(this, "personality")}/>:null}
                    {!readonly||character.getProperty("ideals")?<TextClick editable={!this.state.readonly} label="Ideals" text={character.getProperty("ideals")||"--"} onClick={this.onShowChangeText.bind(this, "ideals")}/>:null}
                    {!readonly||character.getProperty("bonds")?<TextClick editable={!this.state.readonly} label="Bonds" text={character.getProperty("bonds")||"--"} onClick={this.onShowChangeText.bind(this, "bonds")}/>:null}
                    {!readonly||character.getProperty("flaws")?<TextClick editable={!this.state.readonly} label="Flaws" text={character.getProperty("flaws")||"--"} onClick={this.onShowChangeText.bind(this, "flaws")}/>:null}
                    {!readonly||character.getProperty("faction")?<TextClick editable={!this.state.readonly} label="Faction" text={character.getProperty("faction")||"--"} onClick={this.onShowChangeText.bind(this, "faction")}/>:null}
                    {!readonly||character.getProperty("allies")?<TextClick editable={!this.state.readonly} label="Allies & Organization" text={character.getProperty("allies")||"--"} onClick={this.onShowChangeText.bind(this, "allies")}/>:null}
                    {!readonly||character.getProperty("traits")?<TextClick editable={!this.state.readonly} label="Other Traits" text={character.getProperty("traits")||"--"} onClick={this.onShowChangeText.bind(this,"traits")}/>:null}
                    {!readonly||character.getProperty("backstory")?<TextClick editable={!this.state.readonly} label="Backstory" text={character.getProperty("backstory")||"--"} onClick={this.onShowChangeText.bind(this, "backstory")}/>:null}
                </div>
            </div>:null}
            <EditBasicDescription open={this.state.showBasicDescription} character={character} onClose={this.onCloseEditBasicDescription.bind(this)}/>
            <TextEditWithAdd title={this.state.showTextTitle} open={this.state.showText} text={this.state.showTextValue} includeDesc={this.state.showTextIncludeDesc} onChange={this.onChangeText.bind(this)} pickList={this.state.showTextPickList} showCount={this.state.showCount}/>
        </div>;
    }

    onChangeText(value) {
        if (value != null) {
            this.changeAttribute(this.state.showTextProp, value);
        }
        this.setState({showText:false})
    }

    onShowChangeText(property) {
        let title;
        let value = this.props.character.getProperty(property);
        let opts = this.getDescriptionOptions();
        let pickList;
        let showTextIncludeDesc = false;
        let showCount=1;

        switch (property) {
            case "ideals":
                title = "Ideals";
                pickList = {Ideal:opts.Ideal||opts.Ideals};
                break;
            case "personality":
                title = "Personality Traits";
                pickList = {"Personality Trait":opts["Personality Trait"]||opts["Personality Traits"]};
                showCount=2;
                break;
            case "bonds":
                title = "Bonds";
                pickList = {Bond:opts.Bond||opts.Bonds};
                break;
            case "flaws":
                title = "Flaws";
                pickList = {Flaw:opts.Flaw||opts.Flaws};
                break;
            case "faction":
                title = "Faction";
                pickList = {};
                break;
            case "allies":
                title = "Allies & Organization";
                pickList = {};
                break;
            case "traits":
                title = "Other Traits";
                pickList = Object.assign(opts);
                showTextIncludeDesc = true;
                delete pickList.Ideal;
                delete pickList["Personality Trait"];
                delete pickList.Bond;
                delete pickList.Flaw;
                delete pickList.Ideals;
                delete pickList["Personality Traits"];
                delete pickList.Bonds;
                delete pickList.Flaws;
                break;
            case "backstory":
                title = "Backstory";
                pickList = {};
                break;
            default:
                console.log("unknown description property", property);
        }
        this.setState({showText:true, showTextTitle:title, showTextProp:property, showTextValue:value, showTextPickList:pickList, showTextIncludeDesc, showCount});
    }

    onShowEditBasicDescription() {
        this.setState({showBasicDescription:true});
    }

    onCloseEditBasicDescription() {
        this.setState({showBasicDescription:false});
    }

    changeAttribute(prop, value) {
        this.props.character.setProperty(prop, value);
    }
}

class EditBasicDescription extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            const character = this.props.character;
            this.setState({
                alignment:character.getProperty("alignment"),
                gender:character.getProperty("gender"),
                eyes:character.getProperty("eyes"),
                height:character.getProperty("height"),
                faith:character.getProperty("faith"),
                hair:character.getProperty("hair"),
                skin:character.getProperty("skin"),
                age:character.getProperty("age"),
                weight:character.getProperty("weight"),
            });
        }
    }

    onChange(newVal) {
        this.setState({item:newVal, dirty:true})
    }

    onChangeVal(prop, val) {
        const newState = {};
        newState[prop]=val;
        this.setState(newState);
    }

    onSave(save) {
        if (save) {
            const s=this.state;
            this.props.character.setProperty({
                alignment:s.alignment,
                gender:s.gender,
                eyes:s.eyes,
                height:s.height,
                faith:s.faith,
                hair:s.hair,
                skin:s.skin,
                age:s.age,
                weight:s.weight
            });
        }
        this.props.onClose();
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const state = this.state;
        const character = this.props.character;
       
        let race =campaign.getRaceInfo(character.race);
        const features = [];

        if (character.is5E && race) {
            for (let i in race.raceFeatures) {
                const f = race.raceFeatures[i];
                if (f.name) {
                    if (includeCharacterDescriptions[(f.name||"").toLowerCase()]) {
                        features.push(<RenderFeature h3 feature={f} key={i} character={character}/>);
                    }
                }
            }
        }

        return <Dialog
            scroll="paper"
            maxWidth="xs"
            fullWidth={true}
            className="nodrag"
            open
        >
            <DialogTitle onClose={this.onSave.bind(this, false)}>
                {character.displayName} Description
            </DialogTitle>
            <DialogContent className="stdcontent f4">
                <TextLabelEdit label="Alignment" text={state.alignment||"--"} editable values={allignmentTypes} onChange={this.onChangeVal.bind(this,"alignment")}/>                
                <TextLabelEdit fullWidth label="Gender" text={state.gender} editable onChange={this.onChangeVal.bind(this,"gender")}/>                
                <TextLabelEdit fullWidth label="Age" text={state.age} editable onChange={this.onChangeVal.bind(this,"age")}/>                
                <TextLabelEdit fullWidth label="Height" text={state.height} editable onChange={this.onChangeVal.bind(this,"height")}/>                
                <TextLabelEdit fullWidth label="Weight" text={state.weight} editable onChange={this.onChangeVal.bind(this,"weight")}/>                
                <TextLabelEdit fullWidth label="Faith" text={state.faith} editable onChange={this.onChangeVal.bind(this,"faith")}/>                
                <TextLabelEdit fullWidth label="Eyes" text={state.eyes} editable onChange={this.onChangeVal.bind(this,"eyes")}/>                
                <TextLabelEdit fullWidth label="Hair" text={state.hair} editable onChange={this.onChangeVal.bind(this,"hair")}/>                
                <TextLabelEdit fullWidth label="Skin" text={state.skin} editable onChange={this.onChangeVal.bind(this,"skin")}/>
                {features.length?<div className="mv2">
                    <h3>{race && race.displayName} Features</h3>
                    {features}
                </div>:null}                
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onSave.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.onSave.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }    
}


class TextEditWithAdd extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            text:props.text
        };
    }

    handleClose(savechanges, event) {
        this.props.onChange(savechanges?this.state.text:null);
        event.stopPropagation();
    };

    onChange(event) {
        this.setState({text:event.target.value});
    }

    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({text:this.props.text});
        }
    }

    pickVal(i, value) {
        const p = this.props.pickList[i].values;
        let text = this.state.text;
        let found;
        const includeDesc = this.props.includeDesc;
        let prefix = includeDesc?(i+": "):"";
        let count = 0;

        if (value=="---roll") {
            if (!p || !p.length) {
                return;
            }
            value = p[Math.trunc(Math.random()*p.length)];
        }

        value = prefix+value;

        if (text) {
            for (let x in p) {
                if (text.indexOf(prefix+p[x]) >= 0) {
                    if (!found) {
                        found = prefix+p[x];
                    }
                    count++;
                }
            }
        }
        if (found && count >= this.props.showCount){
            text = text.replace(found+"\n\n", "");
            text = text.replace(found, "");
        }
        while (text && text.length && (text[text.length-1]=="\n")) {
            text = text.substr(0,text.length-1);
        }

        if (!text) {
            text = value;
        } else {
            text = text+"\n\n"+value;
        }
        this.setState({text});
    }

    render() {
        if (!this.props.open)
            return null;

        const buttons = [];
        const pick = this.props.pickList||{};
        for (let i in pick) {
            const p=pick[i];
            const vals = [{value:"---roll", name:"--Random Roll--"}];

            if (p) {
                const d100 = !([4,6,8,10,12,20].includes(p.values.length));
        
                for (let v in p.values) {
                    let n = Number(v);
                    let num = n+1;

                    if (d100) {
                        num = (twoDigitNum(Math.trunc(n*100/p.values.length)+1)) + "-" + twoDigitNum(Math.trunc((n+1)*100/p.values.length));
                    }
                    vals.push({value:p.values[v], name:num+". "+p.values[v]});
                }
                buttons.push(<PickVal key={i} value="" onClick={this.pickVal.bind(this, i)} values={vals}>
                    <Button className="ma1" color="primary" variant="outlined" size="small">Pick {i}</Button>
                </PickVal>);

                function twoDigitNum(num) {
                    if (num < 10) {
                        return "0"+num;
                    }
                    if (num == 100) {
                        return "00"
                    }
                    return num;
                }
            }
        }
        if (this.props.showCount>1) {
            buttons.push(<span key="count"> pick {this.props.showCount}</span>);
        }

        
        return <Dialog
            open
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                {this.props.title}
            </DialogTitle>
            <DialogContent>
                <div>
                    {buttons}
                </div>
                <TextField
                    autoFocus
                    multiline
                    fullWidth
                    placeholder={this.props.title}
                    rows={4}
                    rowsMax={10}
                    value={this.state.text||""}
                    onChange={this.onChange.bind(this)}
                    margin="normal"
                    variant="outlined"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    OK
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

function findTables(val) {
    const tables = {};

    if (val){
        for (let i in val.descriptionOptions) {
            tables[val.descriptionOptions[i].name.trim()] = val.descriptionOptions[i];
        }
    }

    return tables;
}

class AddClass extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { openSelect:true};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            const {character} = this.props;
            const levels = character.levels;
            let cclass = null;
            let clevel;
            let clevels;
            let askAdd = false;

            if (character.level >= 1) {
                cclass = levels[levels.length-1].cclass;
                askAdd = true;
            }
            this.setState({addClass:cclass, askAdd, showClassSelector:true});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges){
            this.props.onClose(this.state.addClass, 1);
        } else {
            this.props.onClose();
        }
        event.stopPropagation();
    };

    render() {
        if (!this.props.open) {
            return null;
        }

        if (this.state.askAdd) {
            const addClassInfo = campaign.getClassInfo(this.state.addClass);
            const {character} = this.props;

            return <Dialog
                open
                onClose={this.handleClose.bind(this, false)}
                aria-labelledby="form-dialog-title"
                className="nodrag"
            >
                <DialogContent>
                    {addClassInfo?<div className="tc pa1">
                        <Button onClick={this.handleClose.bind(this, true)} variant="outlined" color="primary">
                            {((addClassInfo.displayName)||"")+" Level "+(character.getMaxClassLevel(addClassInfo.className).level+1)}
                        </Button>
                    </div>:null}
                    <div className="tc pa1">
                        <Button onClick={this.pickClass.bind(this)} variant="outlined" color="primary">
                            Pick Another Class
                        </Button>
                    </div>
                    <div className="tc pa1">
                        <Button onClick={this.handleClose.bind(this, false)} variant="outlined"  color="primary">
                            Cancel
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        }

        return <ClassSelector open onClose={this.closePickClass.bind(this)} character={this.props.character}/>
    }

    closePickClass(addClass) {
        this.props.onClose(addClass,1);
    }

    showPickClass() {
        this.setState({showClassSelector:true});
    }

    pickClass() {
        this.setState({askAdd:false});
    }
}

class PickSubclass extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }

    render() {
        const sc = campaign.getSubclassInfo(this.props.subclass);
        const baseClass = campaign.getClassInfo(this.props.cclass);
        const vbase = (baseClass&&(baseClass.variantBase||baseClass.className))||this.props.cclass;
        
        return  <div className="f2">
            {this.props.subclass?null:<ConfigureAlert/>}
            {this.props.subclass?((this.props.title||"Subclass") +": "+(sc?sc.displayName:"")):("Need to pick "+(this.props.title||"subclass"))} <Button onClick={this.clickPick.bind(this)} color="primary" variant="outlined" size="small">
                {this.props.subclass?"Change":"Pick"}
            </Button>
            <SubclassSelector open={this.state.open} cclass={vbase} onClose={this.closePick.bind(this)} character={this.props.character}/>
        </div>;
    }

    clickPick() {
        this.setState({open:true});
    }

    closePick(subclass) {
        if (subclass) {
            this.props.onChange(subclass);
        }
        this.setState({open:false});

    }
}

class ArmorSelect extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
            idkey:Math.random()
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            const armorInfo = this.props.character.getArmorInfo();
            this.setState({
                armor:armorInfo.armor, 
                armorExtraBonus:armorInfo.armorExtraBonus,
                equipment:this.props.character.equipment,
                shieldItem:armorInfo.shieldItem,
                armorItem:armorInfo.armorItem,
            });
        }
    }

    getArmorInfo() {
        const newAI = Object.assign({}, this.props.character.getArmorInfo());
        newAI.armorExtraBonus = this.state.armorExtraBonus||0;
        newAI.armor = this.state.armor||null;
        newAI.armorItem = this.state.armorItem||null;
        newAI.shieldItem = this.state.shieldItem||null;
        return newAI;
    }

    handleClose(savechanges, event) {
        if (savechanges){
            this.props.character.setProperty("armorInfo", this.getArmorInfo());
            this.props.character.equipment = this.state.equipment;
        }
        this.props.onClose();
        this.setState({showmenu:false});
    };

    onChangeArmorVal(armor) {
        this.setState({armor});
    }

    onChangeExtraBonus(event) {
        let bonus = Number(event.target.value);
        this.setState({armorExtraBonus:bonus});
    }

    onChangeArmor(event) {
        const eq=Object.assign({}, this.state.equipment);
        const val = event.target.value;
        let armor = null;
        let armorItem = null;

        for (let i in eq) {
            if (eq[i].equip == "A") {
                eq[i] = Object.assign({}, eq[i]);
                eq[i].equip = null;
            }
        }
        switch (val) {
            case 'none':
                break;
            case 'Mage Armor':
                armor="Mage Armor";
                break;
            default:
                eq[val] = Object.assign({}, eq[val]);
                eq[val].equip = "A";
                armorItem = eq[val];
                break;
        }
        this.setState({equipment:eq, armor, armorItem})
    }

    onChangeShield(event) {
        const eq=Object.assign({}, this.state.equipment);
        const val = event.target.value;
        let shieldItem = null;

        for (let i in eq) {
            if (eq[i].equip == "OH") {
                eq[i] = Object.assign({}, eq[i]);
                eq[i].equip = null;
            }
        }
        switch (val) {
            case 'none':
                break;
            default:
                eq[val] = Object.assign({}, eq[val]);
                eq[val].equip = "OH";
                shieldItem = eq[val];
                break;
        }
        this.setState({equipment:eq, shieldItem})
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        const armorEquipment = this.getArmorEquipmentInfo();
        
        return <Dialog
            open
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>Armor</DialogTitle>
            <DialogContent>
                <div className="mb2">
                    <FormControl fullWidth>
                        <InputLabel htmlFor={"armor"+this.state.idkey}>Armor</InputLabel>
                        <Select
                            id={"armor"+this.state.idkey}
                            value={armorEquipment.selArmor}
                            onChange={this.onChangeArmor.bind(this)}
                        >
                            {armorEquipment.armorList}
                        </Select>
                    </FormControl>
                </div>
                <div className="mb2">
                    {!armorEquipment.shieldHandBusy?<FormControl fullWidth>
                        <InputLabel htmlFor={"armor"+this.state.idkey}>Shield</InputLabel>
                        <Select
                            id={"armor"+this.state.idkey}
                            value={armorEquipment.selShield}
                            onChange={this.onChangeShield.bind(this)}
                        >
                            {armorEquipment.shieldList}
                        </Select>
                    </FormControl>:<span>No shield, shield hand in use.</span>}
                </div>
                <div className="mv2">
                    <FormControl fullWidth>
                        <InputLabel htmlFor={"extra"+this.state.idkey}>Extra Bonus</InputLabel>
                        <Select
                            id={"extra"+this.state.idkey}
                            value={this.state.armorExtraBonus||0}
                            onChange={this.onChangeExtraBonus.bind(this)}
                        >
                            {getNumberMenus(bonus)}
                        </Select>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    getArmorEquipmentInfo(){
        const equipment = this.state.equipment;
        const el = Object.keys(equipment||{});
        const armorList = [];
        const shieldList = [];
        let selArmor = (this.state.armor == "Mage Armor")?"Mage Armor":"none";
        let selShield = "none"; 
        let shieldHandBusy = false;
        el.sort();

        armorList.push(<MenuItem className="pv2" key="none" value="none">none</MenuItem>);
        shieldList.push(<MenuItem className="pv2" key="none" value="none">none</MenuItem>);

        for (let e in el) {
            let i = el[e];
            let it = equipment[i];
         
            if (["LA", "MA", "HA"].includes(it.type)) {
                // armor
                armorList.push(<MenuItem className="pv2" key={i} value={i}>{it.displayName}</MenuItem>);
                if (it.equip) {
                    selArmor = i;
                }
            } else if (it.type == "S") {
                // shield
                shieldList.push(<MenuItem className="pv2" key={i} value={i}>{it.displayName}</MenuItem>);
                if (it.equip) {
                    selShield = i;
                }
            } else if ((it.equip == "OH")||(it.equip == "2H")) {
                shieldHandBusy = true;
            }
        }
        armorList.push(<MenuItem className="pv2" key="ma" value="Mage Armor">Mage Armor</MenuItem>);
        return {armorList, shieldList, selArmor, selShield, shieldHandBusy};
    }
}

class SavingsThrows extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {abilities:{}};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            const {abilities} = this.props.character.partialComputeValues("manualSaves");
            this.setState({baseAbilities:abilities, abilities:this.props.character.getModifier("manualSaves").abilityScores||{}});
        }
    }

    handleClose(savechanges) {
        if (savechanges){
            this.props.character.setModifier("manualSaves", {abilityScores:this.state.abilities});
        }

        this.props.onClose();
    };

    onChange(event) {
        if (this.state.abilities[event.target.value]) {
            delete this.state.abilities[event.target.value];
        } else {
            this.state.abilities[event.target.value]={proficiency:"proficient"};
        }
        this.setState({abilities:this.state.abilities});
    }

    render() {
        const t=this;
        if (!this.props.open) {
            return null;
        }
        
        return <Dialog
            open
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>Saving Throw Proficiencies</DialogTitle>
            <DialogContent>
                <Ability ability="str"/>
                <Ability ability="dex"/>
                <Ability ability="con"/>
                <Ability ability="int"/>
                <Ability ability="wis"/>
                <Ability ability="cha"/>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;

        function Ability(props) {
            const a = props.ability;
            const value = !!(t.state.abilities[a] && t.state.abilities[a].proficiency);
            const baseValue = !!t.state.baseAbilities[a].proficiency;

            return <FormControlLabel
                control={<Checkbox checked={value||baseValue} indeterminate={baseValue && !value} onChange={t.onChange.bind(t)} value={a}/> }
                label={a.toUpperCase()}
            />;
        }
    }
}

class Movement extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            const {speed} = this.props.character.partialComputeValues("manualMovement");
            this.setState({baseSpeed:speed, speed:this.props.character.getModifier("manualMovement").speed||{}});
        }
    }

    handleClose(savechanges) {
        if (savechanges){
            this.props.character.setModifier("manualMovement", {speed:this.state.speed});
        }

        this.props.onClose();
    };

    onChange(speed) {
        this.setState({speed:speed});
    }

    render() {
        if (!this.props.open)
            return null;
        
        return <Dialog
            open
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>Movement Speeds</DialogTitle>
            <DialogContent>
                <EditNN baseVals={this.state.baseSpeed} vals={this.state.speed} onChange={this.onChange.bind(this)} nntype='walk'/>
                <EditNN baseVals={this.state.baseSpeed} vals={this.state.speed} onChange={this.onChange.bind(this)} nntype='burrow'/>
                <EditNN baseVals={this.state.baseSpeed} vals={this.state.speed} onChange={this.onChange.bind(this)} nntype='climb'/>
                <EditNN baseVals={this.state.baseSpeed} vals={this.state.speed} onChange={this.onChange.bind(this)} nntype='fly'/>
                <EditNN baseVals={this.state.baseSpeed} vals={this.state.speed} onChange={this.onChange.bind(this)} nntype='swim'/>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

class EditNN extends React.Component {
    onChange(event) {
        let nn = this.getNNVals();
        let val = event.target.value;
        let newNN;

        if (!val) {
            nn.number = null;
        } else if (/[0-9]*/.exec(val) == val) {
            nn.number = Number(val);
        } 

        newNN = Object.assign({}, this.props.vals);

        if (!nn.number||nn.number=="0") {
            delete newNN[this.props.nntype];
        } else {
            newNN[this.props.nntype] = nn;
        }

        if (this.props.onChange)
            this.props.onChange(newNN);
    }

    getNNVals() {
        let number;
        let nn;

        nn = this.props.vals[this.props.nntype]||{};
        number = nn.number || "0";

        return {number:number};
    }

    render() {
        let val = this.getNNVals()
        const number=val.number;
        const baseVal = (this.props.baseVals[this.props.nntype]||{}).number||"";

        return <div className="mv1 flex items-end">
        <div className="ttc pa2 w5">{this.props.nntype}</div>
        <TextField
          label="Base (ft.)"
          value={baseVal}
          disabled
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          style={{marginLeft:10,marginRight:10, width:64}}
        />
        <TextField
          label="(ft.)"
          value={number}
          onChange={this.onChange.bind(this)}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          style={{marginLeft:10,marginRight:10, width:64}}
        />
        </div>;
    }
}

class Senses extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            const {senses} = this.props.character.partialComputeValues("manualSenses");
            this.setState({baseSenses:senses, senses:this.props.character.getModifier("manualSenses").senses||{}});
        }
    }

    handleClose(savechanges) {
        if (savechanges){
            this.props.character.setModifier("manualSenses", {senses:this.state.senses});
        }

        this.props.onClose();
    };

    onChange(senses) {
        this.setState({senses:senses});
    }

    render() {
        if (!this.props.open)
            return null;
        
        return <Dialog
            open
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>Senses</DialogTitle>
            <DialogContent>
                <EditNN baseVals={this.state.baseSenses} vals={this.state.senses} onChange={this.onChange.bind(this)} nntype='blindsight'/>
                <EditNN baseVals={this.state.baseSenses} vals={this.state.senses} onChange={this.onChange.bind(this)} nntype='darkvision'/>
                <EditNN baseVals={this.state.baseSenses} vals={this.state.senses} onChange={this.onChange.bind(this)} nntype='tremorsense'/>
                <EditNN baseVals={this.state.baseSenses} vals={this.state.senses} onChange={this.onChange.bind(this)} nntype='truesight'/>
                <EditNN baseVals={this.state.baseSenses} vals={this.state.senses} onChange={this.onChange.bind(this)} nntype='keen smell'/>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Save
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

class Skills extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {skills:{}};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            if (this.props.editable) {
                const {skills} = this.props.character.partialComputeValues("manualSkills");
                this.setState({baseSkills:skills, skills:this.props.character.getModifier("manualSkills").skills||{}});
            } else {
                this.setState({baseSkills:{}, skills:this.props.character.skills});
            }
        }
    }

    handleClose(savechanges) {
        if (savechanges){
            this.props.character.setModifier("manualSkills", {skills:this.state.skills});
        }

        this.props.onClose();
    };

    onChange(skill, value) {
        if (!value) {
            delete this.state.skills[skill];
        } else {
            this.state.skills[skill]={proficiency:value};
        }
        this.setState({skills:this.state.skills});
    }

    render() {
        if (!this.props.open){
            return null;
        }
        const character = this.props.character;

        let skills = [];
        let i;
        const skillVals = character.allSkillsWithAbilities;
        for (i in skillVals){
            const s = skillVals[i];

            skills.push(<EditSkill key={i} 
                skillmod={s.mod} 
                skillname={s.skill} 
                skills={this.state.skills}
                baseSkills={this.state.baseSkills}
                onChange={this.onChange.bind(this)}
                editable={this.props.editable}
            />);
        }
        
        return <Dialog
            open
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>Skill Proficiency</DialogTitle>
            <DialogContent>
                {skills}
            </DialogContent>
            <DialogActions>
                {this.props.editable?
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                :null}
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    {this.props.editable?"Cancel":"Close"}
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

class ShapeChange extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    render() {
        const pickMenu = [];

        if (this.state.showPickMenu) {
            const history = this.props.character.shapeHistory
            
            pickMenu.push(<MenuItem key="pick" className="pv2" onClick={this.showMonsterPicker.bind(this)}>Pick...</MenuItem>);
            for (let i in history) {
                const m = history[i];
                const mon = campaign.getMonster(m);

                if (mon) {
                    const cr = mon.cr;
                    const swim = (mon.speed||{}).swim;
                    const fly = (mon.speed||{}).fly;

                    pickMenu.push(<MenuItem key={m} className="pv2" onClick={this.pickMonster.bind(this, m)}>{mon.displayName} (CR {cr}) {swim?"swimming ":""}{fly?"flying":""}</MenuItem>);
                }
            }
        }

        return <span>
            {this.props.asMenu?
                <MenuItem className="pv2" onClick={this.pickShapeChange.bind(this)}>{this.props.character.shape?"Revert Shape":"Change Shape"} <span className="fas fa-cat f3 pl1"/></MenuItem>
            :
                <Button color="primary" size="small" onClick={this.pickShapeChange.bind(this)}>{this.props.character.shape?"Revert Shape":"Change Shape"} <span className="fas fa-cat f3 pl1"/></Button>
            }
            <span onClick={eatEvents} onKeyDown={eatEvents} onKeyUp={eatEvents} onKeyPress={eatEvents}>
                <MonsterPicker open={this.state.showPickMonster} monsterType="beast" onClose={this.pickMonster.bind(this)}/>
                <Menu
                    className="nodrag"
                    anchorEl={this.state.anchorEl}
                    open={this.state.showPickMenu||false}
                    onClose={this.pickMonster.bind(this,null)}
                >
                    {pickMenu}
                </Menu>
            </span>
        </span>;
    }

    pickShapeChange(event) {
        if (this.props.character.shape) {
            this.props.character.shape = null;

            if (this.props.onClick) {
                this.props.onClick();
            }
        } else {
            const history = (this.props.character.shapeHistory||[]).length;
            this.setState({showPickMenu:!!history, showPickMonster:!history, anchorEl:event.currentTarget});
        } 
    }

    showMonsterPicker(event) {
        this.setState({showPickMonster:true, showPickMenu:false});
    }

    pickMonster(selected) {
        if (selected){
            const mon = campaign.getMonster(selected);
            simplifyMonster(mon, true);
            this.props.character.setShapeWithHistory(mon);
        }
        if (this.props.onClick) {
            this.props.onClick();
        }
        this.setState({showPickMonster:false, showPickMenu:false});
    }
}

class EditSkill extends React.Component {
    onChange(event) {
        let val=event.target.value;
        if (val=="none") val = null;
        this.props.onChange(this.props.skillname, val);
    }

    render() {
        const s = this.props.skills[this.props.skillname]||{};
        const bs = this.props.baseSkills[this.props.skillname]||{};

        return <div className="flex">
            <div className="ttc pv2" style={{width:"170px"}}><div className="dib" style={{width:"28px"}}>{this.props.skillmod.toUpperCase()}</div> - <Renderstring entry={"{@skill "+this.props.skillname+"}"}/></div>
            {this.props.editable?
                <Select
                        value={s.proficiency||"none"}
                        onChange={this.onChange.bind(this)}
                        className="f4 mv1 w5"
                        autoWidth
                >
                    {getMenus(proficiencyTypes)}
                </Select>
            :
                <div className="w3 pv2">
                    {signedNum(s.modifier||0)}
                </div>
            }
            {this.props.editable?
                <div className="w5 pa2">{bs.proficiency||""}</div>
            :
                <div className="w5 pa2">{s.proficiency||""}</div>
            }
        </div>;
    }
}

class CheckEdit extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
        };
    }

    showMenu(event){
        if (this.props.editable) {
            const values = this.props.character.partialComputeValues(this.props.modName);
            const mods = this.props.character.getModifier(this.props.modName);

            this.setState({ showmenu:true, values:values[this.props.property]||{}, mods:mods[this.props.property]||{}});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const val={};
            val[this.props.property]=this.state.mods;
            this.props.character.setModifier(this.props.modName, val);
        }
        this.setState({showmenu:false});
        event.stopPropagation();
    };

    onChange(event) {
        if (this.state.mods[event.target.value]) {
            delete this.state.mods[event.target.value];
        } else {
            this.state.mods[event.target.value]=true;
        }
        this.setState({mods:this.state.mods});
    }

    render() {
        const t=this;
        let text = this.props.values;
        let options = [];
        let foundBold = false;

        if (!this.props.editable && !text && !this.props.children)
            return null;

        if (this.state.showmenu) {
            for (let i in this.props.options) {
                let o = this.props.options[i];
                let bold = false;

                if (o.startsWith("*")) {
                    bold=true;
                    foundBold=true;
                    o=o.substr(1);
                }

                options.push(<Option key={o} bold={bold} values={this.state.values} mods={this.state.mods} option={o}/>);
            }
        }
        
        let r = <span><span className={this.props.editable?"hover-bg-contrast":""} onClick={this.showMenu.bind(this)}><b>{this.props.label} </b>{text}</span>
            {this.props.children}
            <Dialog
                open={this.state.showmenu}
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>{this.props.label}</DialogTitle>
                <DialogContent>
                    {options}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </span>;

        if (this.props.noDiv) {
            return r;
        } else {
            return <div className="">{r}</div>
        }

        function Option(props) {
            const a = props.option;
            const value = !!t.state.mods[a];
            const baseValue = !!t.state.values[a];
            return <div key={a} className={foundBold?(props.bold?"":"ml2"):""}><FormControlLabel className={props.bold?"b":""}
                control={<Checkbox checked={value||baseValue} indeterminate={baseValue && !value} onChange={t.onChange.bind(t)} value={a}/> }
                label={a}
            /></div>;
        }

    }
}

class TextClick extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    render() {
        if (!this.props.editable && !this.props.text)
            return null;
        
    let r = <span className={this.props.className}><span className={(this.props.editable||this.props.alwaysHighlight)?"hover-bg-contrast pre-wrap":"pre-wrap"} onClick={this.props.onClick}>{this.props.label?<b>{this.props.label} </b>:null}{this.props.text}</span>
            {this.props.children}
        </span>;

        if (this.props.noDiv) {
            return r;
        } else {
            return <div className="">{r}</div>
        }
    }
}

const abilityScorePoints = {
    8:0,
    9:1,
    10:2,
    11:3,
    12:4,
    13:5,
    14:7,
    15:9,
    16:11,
    17:13,
    18:16
}

class CharacterPrint extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
    }

    render () {
        if (!this.props.open) {
            return null;
        }

        return <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}></DialogTitle>
            <DialogContent>
                <div className="lightT" ref={ref => this.ref=ref}>
                    <CharacterSheet name={this.props.name} type={this.props.characterType} print readonly noNav hideDice/>
                </div>
            </DialogContent>
            <DialogActions>
                <ReactToPrint
                    trigger={() => {
                        return <Button color="primary">Print</Button>;
                    }}
                    content={() => this.ref}
                    pageStyle="@page {margin: 0.5in}"
                    onAfterPrint={() => this.props.onClose()}
                />
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

function getItemActionInfo(character, id, it, itemMods, secondWeapon) {
    const attuned = character.attuned||{};

    let finesse=it.property && it.property.includes("F");
    let isProficient = it.feature.alwaysProficient||false;
    let twoWeaponFighting=false;
    let itAbilityMods = [];
    let extraNotes = [];
    let needsAttunement=false;
    let itemAttackBonus = character.getBonusMod(it?.feature?.attackBonus);
    let itemDamageBonus = (it.feature && it.feature.damageBonus)||0;
    let damages, altEffect;
    const dmgType = Parser.DMGTYPE_JSON_TO_FULL[it.dmgType]?it.dmgType:null;
    const effects=[];
    const extraDamage = {};

    if (it.feature) {
        altEffect = it.feature.altEffect;
        if (it.feature.extraNotes) {
            extraNotes.push({notes:it.feature.extraNotes});
        }
        if (altEffect) {
            const effect = damagesFromExtraDamage(altEffect.effect||"", altEffect.damageType||dmgType||null, altEffect.extraDamage||{});
            character.resolveDamages(effect)
            effects.push({damages:effect});
        }

        mergeExtraDamage(extraDamage, it.feature.extraDamage);
    }

    if (it.extraFeatures) {
        for (let x in it.extraFeatures) {
            const ef= it.extraFeatures[x];
            if (ef) {
                itemAttackBonus += character.getBonusMod(ef.attackBonus);
                itemDamageBonus += (ef.damageBonus||0);
                isProficient |= ef.alwaysProficient;
                extraNotes.push({feature:ef, index:x, notes:ef.extraNotes||null})
                mergeExtraDamage(extraDamage, ef.extraDamage);
            }
        }
    }

    for (let x in itemMods) {
        let im = itemMods[x];

        if (itemMatchFilter(it, im)) {
            itemAttackBonus += character.getBonusMod(im.attackBonus);
            itemDamageBonus += (im.damageBonus||0);
            if (im.finesse) {finesse=true}
            if (im.attackAbility) {itAbilityMods.push(im.attackAbility)}
            if (im.secondWeaponBonus) {twoWeaponFighting=true}
            if (im.extraNotes) {
                extraNotes.push({notes:im.extraNotes});
            }
            mergeExtraDamage(extraDamage, im.extraDamage);
        }
    }
    
    let hitBonus=0, damageBonus=0, dmg, ammunition, other;

    if (it.reqAttune && !attuned[id]) {
        needsAttunement = true;
        // skip items that require attunement that are not attuned
    }else if (it.dmg1 || it.weapon || (it.type == "S") || altEffect?.showAttack) {
        let bonusAbility="str";
        dmg=getDamageString(it.dmg1||"0");

        if (it.type == "R") {
            // ranged weapon use dex
            bonusAbility = "dex";
        } else if (finesse) {
            // pick best of dex/str
            if (character.getAbility("dex").modifier > character.getAbility("str").modifier) {
                bonusAbility = "dex";
            }
        } else {
            // use STR mod
        }

        if (it.type=="S") {
            other=true; // always include shields
            isProficient |= characterHasArmorProficiency(character.armorList,it);
            if (isProficient && (it.equip=="OH") && !dmg) {
                dmg="1d4";
            }
        }

        if (it.feature && it.feature.attackAbility) {
            if (character.getAbility(it.feature.attackAbility).modifier > character.getAbility(bonusAbility).modifier) {
                bonusAbility = it.feature.attackAbility;
            }
        }
        if (it.extraFeatures) {
            for (let x in it.extraFeatures) {
                const feature = it.extraFeatures[x];
                if (feature.attackAbility) {
                    if (character.getAbility(feature.attackAbility).modifier > character.getAbility(bonusAbility).modifier) {
                        bonusAbility = feature.attackAbility;
                    }
                }
            }
        }

        for (let x in itAbilityMods) {
            const ab = itAbilityMods[x];
            if (((character.getAbility(ab)||{}).modifier||0) > ((character.getAbility(bonusAbility)||{}).modifier||0)) {
                bonusAbility = ab;
            }
        }

        isProficient |= characterHasWeaponProficiency(character.weaponsList,it);
        damageBonus = character.getAbility(bonusAbility).modifier;
        hitBonus = damageBonus + itemAttackBonus;
        if (isProficient) {
            hitBonus += character.proficiency;
        }
        if ((damageBonus > 0) && (it.equip == "OH") && !twoWeaponFighting) {
            damageBonus=0;
        }
        damageBonus = damageBonus + itemDamageBonus;
        damageBonus += character.damageBonus;

        if (it.equip == "2H" && it.dmg2) {
            dmg=getDamageString(it.dmg2);
        }
        if ((it.equip == "PH") && !secondWeapon) {
            damageBonus = damageBonus+character.dueling;
        }
        if (it.dmg1 || it.dmg2) {
            dmg = dmg+(damageBonus?signedNum(damageBonus):"");
            damages = damagesFromExtraDamage(dmg, dmgType, extraDamage);
        }else {
            dmg=null;
            isProficient=true;
        }
        if (altEffect?.includeBaseDamage && effects.length) {
            effects[0].damages = (damages||[]).concat(effects[0].damages);
        }

    } else if (["A","AF"].includes(it.type)) {
        ammunition = true;
        hitBonus = itemAttackBonus;
        damageBonus = itemDamageBonus;
    } else {
        if ((it.usage && it.usage.baseCount) || getItemEquip(it)) {
            other=true;
            isProficient |= characterHasArmorProficiency(character.armorList,it);
        } else {
            isProficient=true;
        }
        hitBonus=(it.consumable||effects.length||altEffect?.save||altEffect?.conditions||altEffect?.temphp)?"use":null;
    }

    if (damages) {
        character.resolveDamages(damages);
    }

    for (let x in itemMods) {
        let im = itemMods[x];
        if (itemMatchFilter(it, im)) {
            if (im.altEffect || im.altExtraDamage) {
                const altExtraDamage = Object.assign({},im.altExtraDamage||{});
                if (im.altIncludeDamageBonus) {
                    mergeExtraDamage(altExtraDamage, extraDamage)
                }
                const effect = damagesFromExtraDamage((im.altEffect||"")+((damageBonus&&im.altIncludeDamageBonus)?signedNum(damageBonus):""), im.altDamageType||dmgType||null, altExtraDamage);
                character.resolveDamages(effect)
                effects.push({damages:effect});
            }
            //console.log("matched", it, itemAttackBonus, itemDamageBonus, twoWeaponFighting, im);
        }
    }
    
    const ret= {dmg, damages, effects, ammunition, other, hitBonus, damageBonus, isProficient, extraNotes, needsAttunement};
    return ret;
}

function getUAActionInfo(character, ua, itemMods) {
    let unarmedAttackBonus=0;
    let unarmedDamageBonus=0;
    let finesse = false;
    let unarmedAbilityMods = [];
    const extraNotes=[];
    let damages =[];
    const dmgType = ua.damageType||"bludgeoning";
    const extraDamage = {};

    for (let x in itemMods) {
        let im = itemMods[x];

        if (itemMatchFilter({displayName:"unarmed strike "+ua.name, rarity:"none", type:"unarmed"}, im)) {
            unarmedAttackBonus += character.getBonusMod(im.attackBonus);
            unarmedDamageBonus += (im.damageBonus||0);
            if (im.finesse) {finesse=true}
            if (im.attackAbility) {unarmedAbilityMods.push(im.attackAbility)}
            if (im.extraNotes) {
                extraNotes.push({notes:im.extraNotes, name:ua.name||"Unarmed Strike"});
            }
            mergeExtraDamage(extraDamage, im.extraDamage);
        }
    }

    let damageBonus=0;
    if (ua.ability) {
        if (Array.isArray(ua.ability)) {
            for (let a of ua.ability) {
                damageBonus = Math.max(damageBonus,character.getAbility(a).modifier||0);
            }
        } else {
            damageBonus = character.getAbility(ua.ability).modifier||0;
        }
    } else {
        damageBonus = Math.max(character.getAbility("str").modifier, (ua.finesse||finesse)?character.getAbility("dex").modifier:-50);
    }

    for (let x in unarmedAbilityMods) {
        const ab = unarmedAbilityMods[x];
        damageBonus = Math.max(damageBonus,(character.getAbility(ab)||{}).modifier||0);
    }

    const hitBonus = damageBonus + character.proficiency+unarmedAttackBonus;
    damageBonus += (character.damageBonus+unarmedDamageBonus);
    let dmg = ua.damage+(damageBonus?signedNum(damageBonus):"");
    damages = damagesFromExtraDamage(dmg, dmgType, extraDamage);
    character.resolveDamages(damages);

    return {damages, hitBonus,extraNotes};
}

function mergeExtraDamage(base, add) {
    for (let i in add) {
        base[i] = base[i]?(base[i]+"+"+add[i]):add[i];
    }
}

function characterHasWeaponProficiency(wp, it) {
    if (!wp){
        return false;
    }
    const wc = it.weaponCategory;
    if (wc) {
        if (wp.findIndex(function(a) {return a.toLowerCase()==wc.toLowerCase()})>=0) {
            return true;
        }
    }

    const prof = getWeaponProficiency(it).toLowerCase();
    if (wp.findIndex(function(a) {return a.toLowerCase()==prof})>=0) {
        return true;
    }
    return false;
}

function characterHasArmorProficiency(ap, it) {
    let match;
    switch (it.type) {
        case "S":
            match="shields";
            break;
        case "LA":
            match="light";
            break;
        case "MA":
            match="medium";
            break;
        case "HA":
            match="heavy";
            break;
        default:
            // proficient since it isn't armor
            return true;
    }
    if (ap.findIndex(function(a) {return a.toLowerCase()==match})>=0) {
        return true;
    }
    return false;
}

function eatEvents(e) {
    e.stopPropagation();
}

function calcMonRestriction(restriction, level) {
    if (!restriction) {
        return null;
    }
    let ret = restriction[0];
    for (let i=1; i<restriction.length; i++) {
        const r = restriction[i];
        if (level >= r.level) {
            ret = r;
        }
    }
    return ret;
}

function canUseItem(it) {
    if (it.consumable) {
        if (it.feature?.usage?.baseCount) {
            let uses = it.feature.uses;
            if (uses == undefined) {
                uses = it.feature.usage.baseCount;
            }
            return it.equip && (uses> 0);
        }
        if ((it.quantity??1) > 0) {
            return true;
        }
        return false;
    }
    return true;
}

const actionPickVals={
    all:"No Filter",
    A:"Actions",
    ATK:"Attack",
    BA:"Bonus Actions",
    RA:"Reactions",
    FA:"Free Actions"
}

class CharacterSheetViewBase extends React.Component {

    constructor(props) {
        super(props);

        this.state= {};
    }

    render() {
        const {width} = this.props.size;

        if (width > 800) {
            let chatWidth = Math.max(320, Math.min(700, width * 0.35));
            const charWidth = Math.min(width-chatWidth, 850);
            if ((charWidth+chatWidth) < width) {
                chatWidth=Math.min(700, width-charWidth);
            }

            return <div className="h-100 w-100 flex">
                <div className="flex-auto"/>
                <div style={{width:charWidth}} className="overflow-y-auto"><CharacterSheet {...this.props} saveCharacter={this.saveCharacter.bind(this)} hideDice/></div>
                <div style={{width:chatWidth}}><RenderChat allowDiceSounds={!this.props.disallowDiceSounds} character={this.state.character} width={chatWidth} addSpellToken={this.props.addSpellToken}/></div>
                <div className="flex-auto"/>
            </div>;
        } 
        return <div className="h-100 w-100">
            <CharacterSheet {...this.props}/>
        </div>
    }

    saveCharacter(character) {
        if (this.props.saveCharacter) {
            this.props.saveCharacter(character);
        }

        this.setState({character});
    }
}

function addClass(character, cclass, count) {
    if (cclass) {
        const levels = character.levels.concat([]);
        const maxLevel = character.getMaxClassLevel(cclass);

        for (let i =1; i<= count; i++) {
            levels.push({
                cclass:cclass,
                level:((maxLevel?(maxLevel.level):0)+i),
            })
        }
        character.setLevels(levels);
    }
}

const CharacterSheet = sizeMe({monitorHeight:true, monitorWidth:true})(CharacterSheetBase);
const CharacterSheetView = sizeMe({monitorHeight:true, monitorWidth:true})(CharacterSheetViewBase);
export {
    CharacterSheet,
    CharacterSheetView,
    mergeExtraDamage,
    calcMonRestriction
}