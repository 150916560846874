const React = require('react');
const {campaign,globalDataListener,isNativeUrl,getDirectDownloadUrl,httpHeadRequest,areSameDeep,sortDisplayName} = require('../lib/campaign.js');
const {firebase} = require("./firebase.jsx");

class ImageCache {
    constructor(max) {
        this.loadedImages = {};
        this.count=0;
        this.max = max || 1;
        this.counter = 0;
    }

    loadImage(url) {
        const t=this;
        return new Promise(function(resolve, reject) {
            const imageUrl = t.getImage(url,function (imageUrl){
                resolve(imageUrl);
            },function (err) {
                reject(err);
            });
            if (imageUrl){
                resolve(imageUrl);
            }
        });
    }

    getImage(url, loadedFn, errorFn, forceCallback) {
        if (!url) {
            return null;
        }
        let li = this.loadedImages[url];
        let doLoad;
        if (li) {
            if (li.image) {
                li.lastAccessed = Date.now();
                if (forceCallback && Math.random() < forceCallback) {
                    setTimeout(function (){loadedFn(li.image)},1000);
                    return;
                }
                return li.image;
            }
        } else {
            li = {success:[], failed:[]};
    
            this.loadedImages[url]=li;
            doLoad=true;
            this.count++;
        }

        if ((this.count >= this.max) && !this.reapTimer) {
            this.startReaper();
        }

        if (loadedFn) {
            li.success.push(loadedFn);
        }
        if (errorFn) {
            li.failed.push(errorFn);
        }

        if (doLoad) {
            const t=this;
            const image = new Image();

            image.onload = function() {
                const nli = t.loadedImages[url];
                if (nli) {
                    nli.image = image;
                    //console.log("loaded image", image);
                    nli.lastAccessed=Date.now();
                    const success = nli.success;
                    nli.success=[];
                    nli.failed=[];

                    this.counter++;
                    for (let i in success) {
                        success[i](image);
                    }
                }
            };
            image.onerror = function (err) {
                console.log("error loading image", err, url);
                const nli = t.loadedImages[url];
                delete t.loadedImages[url];
                t.count--;
                if (nli) {
                    const failed = nli.failed;
                    for (let i in failed) {
                        failed[i](err);
                    }
                }
            };
            image.src = url;
        }

        return null;
    }

    startReaper() {
        //console.log("start timer");
        this.reapTimer = setTimeout(this.doReaping.bind(this),60000);
    }

    doReaping() {
        const loadedImages = this.loadedImages;
        let newest = 0;
        for (let i in loadedImages) {
            const li = loadedImages[i];
            newest = Math.max(newest, li.lastAccessed);
        }

        let didReaping = true;

        while (didReaping && (this.count > this.max)) {
            let oldest = 0;
            didReaping = false;
            for (let i in loadedImages) {
                const li = loadedImages[i];
                oldest = Math.min(oldest||li.lastAccessed, li.lastAccessed);
            }
    
            if ((newest-oldest)>30000) {
                for (let i in loadedImages) {
                    const li = loadedImages[i];
                    if (li.lastAccessed == oldest) {
                        delete loadedImages[i];
                        this.count--;
                        //console.log("removed", i, this.count);
                    }
                }
            }
        }

        //console.log("end timer");
        this.reapTimer = null;
    }
}

const imageCache = new ImageCache(2);
export {
    imageCache,
    ImageCache
}