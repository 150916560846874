const React = require('react');
const {campaign,globalDataListener,addCampaignToPath} = require('../lib/campaign.js');
import sizeMe from 'react-sizeme';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
const {displayMessage} = require('./notification.jsx');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
const {SelectVal, defaultSourceFilter,defaultBookFilter, DeleteWithConfirm} = require('./stdedit.jsx');
const stdValues = require('../lib/stdvalues.js');
const {ListFilter} = require('./listfilter.jsx');
const {CharacterSheet} = require('./charactersheet.jsx');
const {GenCharacterName,randomName} = require('./randomname.jsx');
const EventEmitter = require('events');
const {ChatButton} = require('./renderchat.jsx');
const {getImageUrlFromCharacter, getCharacterFromId,extensionsFromSaved} = require('../lib/character.js');
const {ImportCharacter} = require('./searchfs.jsx');

class RenderplayersBase extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnDataChange = this.onDataChange.bind(this);
    
        var selectedPlayer;
        this.characterType = props.type||"players";
        const players = this.getList();
        
        this.showActionFn = this.showAction.bind(this);
        if (props.selectedPlayer) {
            selectedPlayer = props.selectedPlayer;
        } else {
            if (players && players.length>1){
                selectedPlayer = players[0].name;
            }
        }
	    this.state= {players:players, selectedPlayer:selectedPlayer, showPlayerDetails:(props.selectedPlayer && props.size && props.size.width < 700)};
    }

    componentDidMount() {
        if (this.characterType=="mycharacters" && !this.props.pregen) {
            const chl = campaign.getMyCharacters();
            for (let c of chl) {
                campaign.checkCharacter(c.name);
            }
        }
        if (this.props.pageSync){
            this.props.pageSync.on("action", this.showActionFn);
        }
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, this.characterType);
    }
  
    componentWillUnmount() {
        if (this.props.pageSync){
            this.props.pageSync.removeListener("action", this.showActionFn);
        }
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, this.characterType);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.type != this.props.type) {
            console.log("error: changing type on RenderPlayer not supported");
        }
        if (prevProps.selectedPlayer != this.props.selectedPlayer) {
            this.setState({selectedPlayer:this.props.selectedPlayer});
        }

        if (prevProps.size != this.props.size) {
            if ((this.props.selectedPlayer == this.state.selectedPlayer) && ((this.props.size.width <700) && (!prevProps.size || (prevProps.size.width >= 700)))) {
                //this.setState({showPlayerDetails:true});
            }
        }
    }

    getList() {
        if (this.characterType == "mycharacters") {
            if (this.props.pregen) {
                return campaign.getPregens();
            } else {
                return getMatchingCharacters();
            }
        } else {
            return campaign.getPlayers();
        }
    }

    getInfo(name) {
        if (this.characterType == "mycharacters") {
            return campaign.getMyCharacterInfo(name);
        } else {
            return campaign.getPlayerInfo(name);
        }
    }

    onDataChange() {
        this.setState({players:this.getList()});
    }

	render() {
        return <div className="h-100">
            <div className="h-100 overflow-y-auto overflow-x-hidden">
                {this.getPlayerList()}
            </div>
            <NewCharacter open={this.state.showNewPC} onClose={this.onNewPC.bind(this)} type={this.characterType} pregen={this.props.pregen}/>
            <DialogPlayer show={this.state.showPlayerDetails} onClose={this.hidePlayerDetails.bind(this)} name={this.state.selectedPlayer} type={this.characterType}/>
        </div>;
    }

    showAction(action) {
        switch (action) {
            case "newcharacter":
                this.setState({showNewPC:true});
                break;
        }
    }

    onNewPC(id) {
        if (id) {
            if (this.props.doNav) {
                gotoPlayer(id);
            } else {
                this.setState({showNewPC:false, selectedPlayer:id, showPlayerDetails:true});
            }
        } else {
            this.setState({showNewPC:false, showPlayerDetails:false});
        }
    }

    getPlayerList() {
        const players = this.state.players;
        let mruList = [];
        let ret = [];
        const fullList = [];
        const showShared = (this.characterType=="mycharacters");
        let campaignList,foundCampaign;
        const count = Math.trunc(this.props.size.width / 210)||1;
        const width = this.props.size.width / count;
        const max = count * 4;
        let more = false;
        let mru;

        if (showShared) {
            campaignList=getCampaignList();
            foundCampaign = campaignList.length>1;
            mru = campaign.getUserMRUList("mruCharacters");
        }

        for (let i in players) {
            const p=players[i];

            let record = <div key={i} className="cursor-context-menu pb1 flex hoverhighlight overflow-hidden" style={{width}}>
                <img height="70" width="70" src={getImageUrlFromCharacter(p,true) || "/blankplayer.png"} onClick={this.onSelectPlayer.bind(this, p.name)}/>
                <div className="flex-auto ph1">
                    <div className="f3 titletext titlecolor">
                        {this.props.noDelete?null:<div className="fr"><DeleteWithConfirm className="pa1 titlecolor" name={p.displayName} onClick={this.onDeletePlayer.bind(this,p.name)}/></div>}
                        <div onClick={this.onSelectPlayer.bind(this, p.name)}>{p.displayName}</div>
                    </div>
                    <div className="f6 near-black i" onClick={this.onSelectPlayer.bind(this, p.name)}>
                        <div>{p.originDisplayName||(!p.gamesystem?p.raceDisplayName:null)} {p.backgroundDisplayName}</div>
                        <div>Level {p.level} {p.classDisplayNames}</div>
                    </div>
                    {p.claimable?<div className="b i">Unclaimed</div>:null}
                    {foundCampaign&&!this.props.pregen?<SelectVal value={p.sharedAdventureName||"*none"} values={campaignList} onClick={this.pickAdventure.bind(this, p.name)} fullWidth selectClass="f6 pt0 notetext pb--2 mt--1 near-black relative" style={{bottom:0}}/>:null}
                </div>
            </div>;
            if (mru) {
                const pos = mru.findIndex(function (a) {
                    return a.description==p.name;
                });
                if (pos>=0) {
                    mruList[pos]=record;
                } else {
                    ret.push(record);
                }
            } else {
                ret.push(record);
            }
        }

        if (this.props.limit) {
            for (let i in mruList) {
                if (mruList[i] && fullList.length < max) {
                    fullList.push(mruList[i]);
                } else {
                    more=true;
                }
            }
            for (let i in ret) {
                if (ret[i] && fullList.length < max) {
                    fullList.push(ret[i]);
                } else {
                    more=true;
                }
            }
            mruList=null;
            ret=null;
        }

        return <div className="stdcontent flex flex-wrap items-stretch">
            {fullList}{mruList}{ret}
            {more?<div className="w-100 tc"><a href={addCampaignToPath("/#mycharacters")}>(some characters not shown)</a></div>:null}
        </div>
    }

    pickAdventure(character, name) {
        if (name == "*none") {
            name=null;
        }
        campaign.joinCharacterToSharedCampaign(character, name).catch(function (err){
            displayMessage("Error joining campaign. "+err);
        });
    }

    onSelectPlayer(name) {
        if (this.characterType == "mycharacters") {
            campaign.addUserMRUList("mruCharacters", {description:name});
        }
        if (this.props.doNav) {
            gotoPlayer(name);
        } else {
            this.setState({selectedPlayer:name, showPlayerDetails:true});
        }
    }

    hidePlayerDetails() {
        this.setState({showPlayerDetails:false});
    }

    onDeletePlayer(name) {
        if (this.state.selectedPlayer == name) {
            this.setState({selectedPlayer:null});
        }
        campaign.deleteCampaignContent(this.characterType, name);
    }
}

function gotoPlayer(name) {
    const cInfo = campaign.getMyCharacterInfo(name);
    if (cInfo.sharedAdventureName) {
        const camp = campaign.getCampaignInfo(cInfo.sharedAdventureName);
        if (camp.playerMode=="ruleset") {
            window.location.href = "/#mycharacters?id="+encodeURIComponent(name)+"&cid="+cInfo.sharedAdventureName;
        }else {
            window.location.href = "/#play?mode=character&campaign="+encodeURIComponent(cInfo.sharedAdventureName)+"&character="+encodeURIComponent(name);
        }
    } else {
        window.location.href = "/#mycharacters?id="+encodeURIComponent(name);
    }
}

function getMatchingCharacters() {
    const myc = campaign.getMyCharacters();
        
    if (!campaign.isDefaultCampaign()) {
        const isRuleset = (campaign.getPrefs().playerMode == "ruleset");
        const extensions = isRuleset?extensionsFromSaved(campaign.getGameState()?.extensions):null;
        const matchingCampaigns = getMatchingCampaigns(extensions);

        const current = campaign.getCurrentCampaign();
        const list = [];
        for (let i in myc) {
            const c = myc[i];
            if (c.sharedAdventureName) {
                if ((c.sharedAdventureName==current) || matchingCampaigns[c.sharedAdventureName]) {
                    list.push(c);
                }
            } else if (matchExtensions(extensions,extensionsFromSaved(c.extensions))){
                list.push(c);
            }
        }
        return list;
    } else {
        return myc;
    }

}

class RenderCharacters extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnDataChange = this.onDataChange.bind(this);
    
        var selectedPlayer;
        this.characterType = props.type||"players";
        const players = this.getList();
        
        this.showActionFn = this.showAction.bind(this);
        if (props.selectedPlayer) {
            selectedPlayer = props.selectedPlayer;
        } else {
            if (players && players.length>1){
                selectedPlayer = players[0].name;
            }
        }
	    this.state= {players:players, selectedPlayer:selectedPlayer, showPlayerDetails:(props.selectedPlayer && props.size && props.size.width < 700)};
    }

    componentDidMount() {
        if (this.characterType=="mycharacters" && !this.props.pregen) {
            const chl = campaign.getMyCharacters();
            for (let c of chl) {
                campaign.checkCharacter(c.name);
            }
        }
        if (this.props.pageSync){
            this.props.pageSync.on("action", this.showActionFn);
        }
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, this.characterType);
    }
  
    componentWillUnmount() {
        if (this.props.pageSync){
            this.props.pageSync.removeListener("action", this.showActionFn);
        }
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, this.characterType);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.type != this.props.type) {
            console.log("error: changing type on RenderPlayer not supported");
        }
        if (prevProps.selectedPlayer != this.props.selectedPlayer) {
            this.setState({selectedPlayer:this.props.selectedPlayer});
        }
    }

    getList() {
        if (this.characterType == "mycharacters") {
            if (this.props.pregen) {
                return campaign.getPregens();
            } else {
                return getMatchingCharacters();
            }
        } else {
            return campaign.getPlayers();
        }
    }

    getInfo(name) {
        if (this.characterType == "mycharacters") {
            return campaign.getMyCharacterInfo(name);
        } else {
            return campaign.getPlayerInfo(name);
        }
    }

    onDataChange() {
        this.setState({players:this.getList()});
    }

	render() {
        const showShared = (this.characterType=="mycharacters");

        if (showShared) {
            this.campaignList=getCampaignList();
            this.foundCampaign = this.campaignList.length>1;
        }

        return <div className="h-100">
            <div className="h-100 overflow-y-auto overflow-x-hidden">
                <ListFilter 
                    list={this.state.players}
                    render={this.characterRender.bind(this)}
                    filters={showShared&&this.foundCampaign?campaignCharacterListFilters:characterListFilters}
                    onClick={this.onSelectPlayer.bind(this)}
                    showCards
                    smallCard
                />
            </div>
            <NewCharacter open={this.state.showNewPC} onClose={this.onNewPC.bind(this)} type={this.characterType} pregen={this.props.pregen}/>
            <DialogPlayer show={this.state.showPlayerDetails} onClose={this.hidePlayerDetails.bind(this)} name={this.state.selectedPlayer} type={this.characterType}/>
        </div>;
    }

    characterRender(p, width) {
        return <div key={p.name} className="cursor-context-menu pb1 flex hoverhighlight overflow-hidden" style={{width}}>
            <img height="70" width="70" src={getImageUrlFromCharacter(p,true) || "/blankplayer.png"} onClick={this.onSelectPlayer.bind(this, p.name)}/>
            <div className="flex-auto ph1">
                <div className="f3 titletext titlecolor">
                    {this.props.noDelete?null:<div className="fr"><DeleteWithConfirm className="pa1 titlecolor" name={p.displayName} onClick={this.onDeletePlayer.bind(this,p.name)}/></div>}
                    <div onClick={this.onSelectPlayer.bind(this, p.name)}>{p.displayName}</div>
                </div>
                <div className="f6 near-black i" onClick={this.onSelectPlayer.bind(this, p.name)}>
                    <div>{p.originDisplayName||(!p.gamesystem?p.raceDisplayName:null)} {p.backgroundDisplayName}</div>
                    <div>Level {p.level} {p.classDisplayNames}</div>
                </div>
                {p.claimable?<div className="b i">Unclaimed</div>:null}
                {this.foundCampaign&&!this.props.pregen?<SelectVal value={p.sharedAdventureName||"*none"} values={this.campaignList} onClick={this.pickAdventure.bind(this, p.name)} fullWidth selectClass="f6 pt0 notetext pb--2 mt--1 near-black relative" style={{bottom:0}}/>:null}
            </div>
        </div>;
    }

    showAction(action) {
        switch (action) {
            case "newcharacter":
                this.setState({showNewPC:true});
                break;
        }
    }

    onNewPC(id) {
        if (id) {
            if (this.props.doNav) {
                gotoPlayer(id);
            } else {
                this.setState({showNewPC:false, selectedPlayer:id, showPlayerDetails:true});
            }
        } else {
            this.setState({showNewPC:false, showPlayerDetails:false});
        }
    }

    getPlayerList() {
        const players = this.state.players;
        let mruList = [];
        let ret = [];
        const fullList = [];
        const showShared = (this.characterType=="mycharacters");
        let campaignList,foundCampaign;
        const count = Math.trunc(this.props.size.width / 210)||1;
        const width = this.props.size.width / count;
        const max = count * 4;
        let more = false;
        let mru;

        if (showShared) {
            campaignList=getCampaignList();
            foundCampaign = campaignList.length>1
            mru = campaign.getUserMRUList("mruCharacters");
        }

        for (let i in players) {
            const p=players[i];

            let record = <div key={i} className="cursor-context-menu pb1 flex hoverhighlight overflow-hidden" style={{width}}>
                <img height="70" width="70" src={getImageUrlFromCharacter(p,true) || "/blankplayer.png"} onClick={this.onSelectPlayer.bind(this, p.name)}/>
                <div className="flex-auto ph1">
                    <div className="f3 titletext titlecolor">
                        {this.props.noDelete?null:<div className="fr"><DeleteWithConfirm className="pa1 titlecolor" name={p.displayName} onClick={this.onDeletePlayer.bind(this,p.name)}/></div>}
                        <div onClick={this.onSelectPlayer.bind(this, p.name)}>{p.displayName}</div>
                    </div>
                    <div className="f6 near-black i" onClick={this.onSelectPlayer.bind(this, p.name)}>
                        <div>{p.originDisplayName||(!p.gamesystem?p.raceDisplayName:null)} {p.backgroundDisplayName}</div>
                        <div>Level {p.level} {p.classDisplayNames}</div>
                    </div>
                    {p.claimable?<div className="b i">Unclaimed</div>:null}
                    {foundCampaign&&!this.props.pregen?<SelectVal value={p.sharedAdventureName||"*none"} values={campaignList} onClick={this.pickAdventure.bind(this, p.name)} fullWidth selectClass="f6 pt0 notetext pb--2 mt--1 near-black relative" style={{bottom:0}}/>:null}
                </div>
            </div>;
            if (mru) {
                const pos = mru.findIndex(function (a) {
                    return a.description==p.name;
                });
                if (pos>=0) {
                    mruList[pos]=record;
                } else {
                    ret.push(record);
                }
            } else {
                ret.push(record);
            }
        }

        if (this.props.limit) {
            for (let i in mruList) {
                if (mruList[i] && fullList.length < max) {
                    fullList.push(mruList[i]);
                } else {
                    more=true;
                }
            }
            for (let i in ret) {
                if (ret[i] && fullList.length < max) {
                    fullList.push(ret[i]);
                } else {
                    more=true;
                }
            }
            mruList=null;
            ret=null;
        }

        return <div className="stdcontent flex flex-wrap items-stretch">
            {fullList}{mruList}{ret}
            {more?<div className="w-100 tc"><a href="/#mycharacters">(some characters not shown)</a></div>:null}
        </div>
    }

    pickAdventure(character, name) {
        if (name == "*none") {
            name=null;
        }
        campaign.joinCharacterToSharedCampaign(character, name).catch(function (err){
            displayMessage("Error joining campaign. "+err);
        });
    }

    onSelectPlayer(name) {
        if (this.characterType == "mycharacters") {
            campaign.addUserMRUList("mruCharacters", {description:name});
        }
        if (this.props.doNav) {
            gotoPlayer(name);
        } else {
            this.setState({selectedPlayer:name, showPlayerDetails:true});
        }
    }

    hidePlayerDetails() {
        this.setState({showPlayerDetails:false});
    }

    onDeletePlayer(name) {
        if (this.state.selectedPlayer == name) {
            this.setState({selectedPlayer:null});
        }
        campaign.deleteCampaignContent(this.characterType, name);
    }
}

function getCampaignList() {
    const campaigns = campaign.getCampaignsList();
    const rules=[], player=[], camps=[];


    for (let i in campaigns) {
        const cInfo = campaigns[i];

        if (cInfo.name != "emptycampaign") {
            if (cInfo.playerMode=="ruleset") {
                rules.push({value:cInfo.name, name:<span><span className="fas fa-atlas"/> {cInfo.displayName}</span>});
            } else if (cInfo.shareCampaign) {
                player.push({value:cInfo.name, name:<span><span className="fas fa-walking"/> {cInfo.displayName}</span>});
            } else {
                camps.push({value:cInfo.name, name:<span><span className="fas fa-dungeon"/> {cInfo.displayName}</span>});
            }
        }
    }


    return [{value:"*none", name:"Not in campaign"}].concat(player).concat(rules).concat(camps);
}

class RenderPregens extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnDataChange = this.onDataChange.bind(this);
    
        const players = this.getList();
        
        this.showActionFn = this.showAction.bind(this);
	    this.state= {players:players};
    }

    componentDidMount() {
        if (this.props.pageSync){
            this.props.pageSync.on("action", this.showActionFn);
        }
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "mycharacters");
    }
  
    componentWillUnmount() {
        if (this.props.pageSync){
            this.props.pageSync.removeListener("action", this.showActionFn);
        }
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "mycharacters");
    }

    getList() {
        return campaign.getPregens();
    }

    getInfo(name) {
        return campaign.getMyCharacterInfo(name);
    }

    onDataChange() {
        this.setState({players:this.getList()});
    }

	render() {
        return <div className="h-100">
            <div className="h-100 overflow-y-auto overflow-x-hidden">
                <ListFilter 
                    list={this.state.players}
                    render={characterRender}
                    filters={characterListFilters}
                    onClick={this.onSelectPlayer.bind(this)}
                />
            </div>
            <NewCharacter open={this.state.showNewPC} onClose={this.onNewPC.bind(this)} type="mycharacters" pregen/>
            <DialogPlayer show={this.state.showPlayerDetails} onClose={this.hidePlayerDetails.bind(this)} name={this.state.selectedPlayer} type="mycharacters" hideDice/>
        </div>;
    }

    showAction(action) {
        switch (action) {
            case "newcharacter":
                this.setState({showNewPC:true});
                break;
        }
    }

    onNewPC(id) {
        if (id) {
            this.setState({showNewPC:false, selectedPlayer:id, showPlayerDetails:true});
        } else {
            this.setState({showNewPC:false, showPlayerDetails:false});
        }
    }

    onSelectPlayer(name) {
        this.setState({selectedPlayer:name, showPlayerDetails:true});
    }

    hidePlayerDetails() {
        this.setState({showPlayerDetails:false});
    }

    onDeletePlayer(name) {
        campaign.deleteCampaignContent("mycharacters", name);
    }
}

class Player extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {};
    }

	render() {
        const editDetails = <CharacterSheet hideDice readonly={this.props.readonly} name={this.props.name} type={this.props.type} eventSync={this.props.eventSync} addSpellToken={this.props.addSpellToken} getDiceRoller={this.props.useCampaign?this.getDiceRoller.bind(this):null}/>;

        if (this.props.baseOnly) {
            return editDetails;
        }

        return <div key={this.props.name} className="h-100">
            <div className="h-100 w-100 notetext f5 overflow-y-auto overflow-x-hidden">
                {editDetails}
            </div>
        </div>
    }

    getDiceRoller() {
        const character = getCharacterFromId(this.props.name);
        return <ChatButton character={character}/>;
    }
}

class DialogPlayer extends React.Component {
    constructor(props) {
        super(props);

        this.handleOnDataChange = this.onDataChange.bind(this);
	    this.state= {};
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "players");
    }
  
    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange,"players");
    }

    onDataChange() {
        if (this.props.show && this.props.type=="players") { // handle case of copying a character to a campaign character
            const  character = campaign.getPlayerInfo(this.props.name);
            if (!character) {
                this.props.onClose();
            }
        }
    }

    render() {
        if (!this.props.show)
            return null;
        
        return <Dialog
            open
            maxWidth="md"
            fullWidth
        >
            <div/>
            <DialogContent classes={{root:"ph2"}} className="defaultbackground">
                <Player name={this.props.name} type={this.props.type} baseOnly readonly={this.props.readonly}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }
}

class NewCharacter extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            text:props.text||randomName()
        };
    }

    handleClose() {
        this.props.onClose(null);
    };

    onChange(event) {
        if (/[^\n]*/.exec(event.target.value) == event.target.value) {
            this.setState({text:event.target.value});
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({text:this.props.text || randomName()});
        }
    }

    render() {
        return <GenCharacterName show={this.props.open} label="Character Name" title="Create New Character" onChange={this.onNewPC.bind(this)}/>

    }

    newRandom() {
        this.setState({text:randomName()});
    }

    onNewPC(name) {
        if (name) {
            this.createCharacter(name);
        } else {
            this.props.onClose(null);
        }

    }

    createCharacter(name) {
        if (!this.props.pregen && (campaign.getMyCharacters().length >= campaign.maxCharacters)) {
            displayMessage(<span>You have reached your limit of {campaign.maxCharacters} characters that you can create.  See <a href="/marketplace#shardsubscriptions">subscriptions</a> to change your limits.</span>);
            return;
        }

        const id =campaign.newUid();

        campaign.updateCampaignContent(this.props.type, {name:id, displayName:name, type:"pc", pregen:!!this.props.pregen, gamesystem:campaign.defaultGamesystem});

        if (!this.props.pregen && (this.props.type == "mycharacters")) {
            if (campaign.getPrefs().playerMode=="ruleset") {
                campaign.joinCharacterToSharedCampaign(id, campaign.getCurrentCampaign());
            }
            campaign.addUserMRUList("mruCharacters", {description:id});
        }


        this.props.onClose(id);
    }
}


class PickCharacter extends React.Component {
    constructor(props) {
        super(props);

        this.state= {selected:{}};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            this.setState({selected:{}});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges){
            const s = Object.keys(this.state.selected);
            if (!s.length) {
                this.props.onClose();
            }else if (this.props.single) {
                this.props.onClose(s[0]);
            } else {
                this.props.onClose(s);
            }
        } else {
            this.props.onClose();
        }
        event.stopPropagation();
    };

    render() {
        if (!this.props.open) {
            return null;
        }
        let list = [];

        if (this.props.type == "players") {
            const players = campaign.getPlayers();

            for (let i in players) {
                const p=players[i];
                if (!this.props.claimable || p.claimable) {
                    list.push(p);
                }
            }
        } else {
            if (this.props.pregen) {
                list = campaign.getPregens();
            } else {
                list = campaign.getMyCharacters();
            }
        }

        return  <Dialog
            open={this.props.open||false}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                Select Character
            </DialogTitle>
            <DialogContent>
                <ListFilter 
                    list={list}
                    render={characterRender}
                    filters={characterListFilters}
                    onClick={this.clickCharacter.bind(this)}
                    onSelectedChange={this.onSelectedChange.bind(this)}
                    select="list"
                    single={!!this.props.single}
                    selected={this.state.selected}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Select
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <DialogPlayer show={this.state.showDetails} onClose={this.hideDetails.bind(this)} name={this.state.selectedCharacter} type={this.props.type} readonly/>
        </Dialog>;
    }

    onSelectedChange(selected) {
        this.setState({selected});
    }

    clickCharacter(selectedCharacter){
        this.setState({selectedCharacter,showDetails:true});
    }

    hideDetails(){
        this.setState({showDetails:false});
    }
}

function characterRender(s) {
    return <div className="cursor-context-menu pb1 flex hoverhighlight overflow-hidden w-100">
        <img height="70" width="70" src={getImageUrlFromCharacter(s,true) || "/blankplayer.png"}/>
        <div className="flex-auto ph1">
            <div className="f3 titletext titlecolor">
                {s.displayName}
            </div>
            <div className="f6 near-black i">
                <div>{s.originDisplayName||(!s.gamesystem?s.raceDisplayName:null)} {s.backgroundDisplayName}</div>
                <div>Level {s.level||0} {s.classDisplayNames}</div>
            </div>
        </div>
    </div>;
}

const characterListFilters = [
    {
        filterName:"Class",
        fieldName:"classDisplayNames",
        convertField: function (v) {
            if (v) {
                return v.split("/").map(function(a){return a.trim().toLowerCase()}); 
            }
            return null;
        }

    }, 
    {
        filterName:"Level",
        fieldName:"level",
        advancedOnly:true,
        useRange:true,
        sortFn(a,b) {
            return Number(a||0)-Number(b||0);
        }
    },
    {
        filterName:"Origin",
        fieldName:"originDisplayName",
        convertField: function (v,it) {
            if (v) {
                return v.toLowerCase(); 
            }
            if (!it.gamesystem && it.raceDisplayName) {
                return it.raceDisplayName.toLowerCase();
            }
            return null;
        }
    },
    {
        filterName:"Background",
        fieldName:"backgroundDisplayName",
        convertField: function (v) {
            if (v) {
                return v.toLowerCase(); 
            }
            return null;
        }
    },
    defaultSourceFilter,
    defaultBookFilter
];

const campaignCharacterListFilters= [
    {
        filterName:"In Campaign",
        fieldName:"sharedAdventureName",
        convertField: function (v) {
            return v?"yes":"no";
        }
    }
].concat(characterListFilters);

class PlayerUserList extends React.Component {
    constructor(props) {
        super(props);

        this.state= {selected:{}};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            this.setState({selected:{}});
        }
    }

    handleClose(event) {
        this.props.onClose();
        event.stopPropagation();
    };

    render() {
        if (!this.props.open) {
            return null;
        }

        return  <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this)}>
                Campaign Player List
            </DialogTitle>
            <DialogContent>
                <ListFilter 
                    list={campaign.getUserList()}
                    render={characterPlayerRender}
                    onSelectedChange={this.onSelectedChange.bind(this)}
                    select="list"
                    selected={this.state.selected}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.resetInvite.bind(this)} color="primary">
                    Reset Invite Key
                </Button>
                <Button disabled={!Object.keys(this.state.selected).length} onClick={this.removeSelected.bind(this)} color="primary">
                    Remove Players
                </Button>
                <Button onClick={this.handleClose.bind(this)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }

    onSelectedChange(selected) {
        this.setState({selected});
    }

    resetInvite() {
        campaign.resetCampaignInvite();
        displayMessage("Invite has been reset.  Previous invite links will no longer work, but current players will continue to hav access to the campaign.");
    }

    removeSelected() {
        const selected = this.state.selected;
        const shared = campaign.getAllSharedCharacters();
       
        for (let sl in selected) {
            const s = campaign.getUserInfo(sl);
            for (let i in shared) {
                const p=shared[i];
                if (p.userId == s.user) {
                    //console.log("delete player", p.displayName, s.user, p);
                    campaign.deleteCampaignContent("players", p.name);
                }
            }
            campaign.deleteCampaignContent("users", s.user);
        }
        displayMessage("Players removed from the campaign.  Reset invite to prevent them from rejoining.");
        this.setState({selected:{}}); 
    }
}

function characterPlayerRender(s) {
    const players = campaign.getPlayers();
    const list=[];

    for (let i in players) {
        const p=players[i];
        const userInfo = campaign.getSharedCharacterInfo(p.name);

        if (userInfo && userInfo.userId == s.user) {
            list.push(<div key={i} className="pb1 flex overflow-hidden w-100">
                <img height="70" width="70" src={getImageUrlFromCharacter(p,true) || "/blankplayer.png"}/>
                <div className="flex-auto ph1">
                    <div className="f3 titletext titlecolor">
                        {p.displayName}
                    </div>
                    <div className="f6 near-black i">
                        <div>{p.originDisplayName||(!p.gamesystem?p.raceDisplayName:null)} {p.backgroundDisplayName}</div>
                        <div>Level {p.level||0} {p.classDisplayNames}</div>
                    </div>
                </div>
            </div>)
        }
    }


    return  <div>
        <div className="f3 titletext titlecolor">
            {s.displayName}
        </div>
        <div className="hk-well f5 tl">
            {list.length?list:<div>
                No characters in campaign
            </div>}
        </div>
    </div>;
}

class DialogCampaignCharacters extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
        this.eventSync = new EventEmitter();
    }

    render() {
        if (!this.props.open)
            return null;
        
        return <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>Campaign Characters</DialogTitle>    
            <DialogContent classes={{root:"ph2"}} className="defaultbackground">
                <Renderplayers key="players" type="players" pageSync={this.eventSync}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={triggerEvent.bind(null, this.eventSync,"action","newcharacter")} color="primary">New</Button>
                <Button onClick={this.showAddPregen.bind(this)} color="primary">Add Pregenerated Characters</Button>
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            <PickCharacter open={this.state.showPickPregen} onClose={this.pickPregen.bind(this)} type="mycharacters" pregen/>
        </Dialog>;
    }

    showAddPregen() {
        this.setState({showPickPregen:true});
    }

    pickPregen(selected) {
        if (selected) {
            for (let i in selected) {
                const p= campaign.getMyCharacterInfo(selected[i]);
                if (p) {
                    const newCharacter = Object.assign({}, p);
                    newCharacter.name = campaign.newUid();
                    newCharacter.claimable = true;        

                    delete newCharacter.pregen;
                    delete newCharacter.sharedAdventureName;
                    delete newCharacter.shareCampaign;
                    delete newCharacter.shareUser;
                    delete newCharacter.shareCampaignName;
                    delete newCharacter.diceRolls;
                    delete newCharacter.lastRoll;

                    campaign.updateCampaignContent("players", newCharacter);
                }
            }
            displayMessage("Characters added to Campaign Characters");

        }
        this.setState({showPickPregen:false});
    }
}

class CharactersHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
    }

    render() {
        return <span>
            <span>
                {this.props.pregen?"Pregenerated Characters":"My Characters"}
            </span>
            <Button onClick={triggerEvent.bind(null, this.props.pageSync,"action","newcharacter")} className="ml1 minw2" color="secondary" variant="outlined" size="small">New</Button>
            <ImportCharacter/>
        </span>;
    }
}

function triggerEvent(eventSync, event, data) {
    eventSync.emit(event, data);
}

function getMatchingCampaigns(extensions) {
    const campaigns = campaign.getCampaignsList();

    const matches = {};
    for (let i in campaigns) {
        const cInfo = campaigns[i];

        if (matchExtensions(extensions, extensionsFromSaved(cInfo.extensions||cInfo.sharedExtensions))) {
            matches[cInfo.name] = 1;
        }
    }
    return matches;
}

function matchExtensions(extensions, cExtensions) {
    if (!extensions || !extensions.length) {
        return true;
    }
    if (!cExtensions || !cExtensions.length) {
        return false;
    }

    for (let i in extensions) {
        const ex = campaign.getExtensionInfo(extensions[i]);
        if (ex?.template) {
            if (!cExtensions.includes(ex.name)) {
                return false;
            }
        }
    }

    for (let i in cExtensions) {
        const ex = campaign.getExtensionInfo(cExtensions[i]);
        if (ex?.template) {
            if (!extensions.includes(ex.name)) {
                return false;
            }
        }
    }

    return true;
}


const Renderplayers = sizeMe({monitorHeight:true, monitorWidth:true})(RenderplayersBase);


export {
    Renderplayers, 
    RenderCharacters,
    RenderPregens,
    Player,
    DialogPlayer,
    CharactersHeader,
    NewCharacter,
    PickCharacter,
    characterListFilters,
    characterRender,
    PlayerUserList,
    DialogCampaignCharacters,
    getMatchingCampaigns,
    matchExtensions
}