const React = require('react');
const {campaign,globalDataListener,getExtensionEntryCheckFn} = require('../lib/campaign.js');
import TextField from '@material-ui/core/TextField';
const {Rendersource} = require("./rendersource.jsx");
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
const {RenderFeature,FeatureListEdit,renderPrintFeatures} = require('./features.jsx');
const {ExtraArtList,ArtZoomList, FloatArt,FloatCardArt,printFloatArt} = require('./renderart.jsx');
const {ListFilter} = require('./listfilter.jsx');
const {Renderentry} = require('./entityeditor.jsx');
const {AddChatEntry,getBasicHref} = require('./renderhref.jsx');
import {htmlFromEntry} from "../lib/entryconversion.js";
const {gamesystemOptions} = require('../lib/stdvalues.js');

const {SelectVal, TextVal, DeleteEntry, defaultSourceFilter,defaultBookFilter,defaultGamesystemFilter} = require('./stdedit.jsx');

class RenderFeats extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {list:campaign.getSortedFeatsList()};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({list:campaign.getSortedFeatsList()})
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "feats");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "feats");
    }

	render() {
        return <div>
            <ListFilter 
                list={this.state.list}
                showCards
                showAll
                render={this.renderCard.bind(this)}
                filters={[defaultSourceFilter,defaultBookFilter,defaultGamesystemFilter]}
                getListRef={this.saveRef.bind(this)}
                entryCheckFn={getExtensionEntryCheckFn()}
                noResort
                headerOutline={this.props.headerOutline}
            />
            <FeatDialog open={this.state.feat} feat={this.state.feat} extraButtonsFn={this.getExtraButtons.bind(this)} onClose={this.clickFeat.bind(this,null)}/>
        </div>;
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    getExtraButtons(feat) {
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(feat))||{});
        return <span>
            <Button disabled={!prev} onClick={prev?this.clickFeat.bind(this,prev.name):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.clickFeat.bind(this,next.name):null} color="primary"><span className="b fas fa-step-forward"/></Button>
        </span>
    }

    renderCard(it, width, bc) {
        const entries = it.features&&it.features[0]&&it.features[0].entries;
        return <div key={it.name} style={{width:width-10}} className="defaultbackground shadow-3 ma1 stdcontent overflow-hidden" onClick={this.clickFeat.bind(this,it.name)}>
            <div className={"h-100 hoverhighlight pa1 "+(bc||"")}>
                <div className="f1 titlecolor">{it.displayName}</div>
                <div className="f6 near-black bb titleborder mb1"><Rendersource className=" " entry={it}/></div>
                {it.prerequisites?<div className="i f5 mb1"> (Prerequisite: {it.prerequisites})</div>:null}
                <FloatCardArt art={it.defaultArt} width={width}/>
                <Renderentry className="ll-8" entry={entries}/>
            </div>
        </div>
    }

    clickFeat(feat){
        this.setState({feat});
    }
}

class Feat extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({feat:campaign.getFeatInfo(this.props.feat)})
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "feats");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "feats");
    }

	render() {
        var feat = campaign.getFeatInfo(this.props.feat);
        const features = [];
        if (!feat){
            return <div>Could not find feat {this.props.feat}.</div>;
        }

        for (let i in feat.features) {
            features.push(<RenderFeature h3 feature={feat.features[i]} key={i}/>);
        }

        return <div className="stdcontent" key={feat.name} >
            {this.props.noTitle?null:<h3>{feat.displayName}</h3>}
            {this.props.noArt?null:<FloatArt art={feat.defaultArt} artList={feat.artList} defaultArt={feat.defaultArt} pageSync={this.props.pageSync}/>}
            {feat.prerequisites?<span className="i titletext near-black f5"> (Prerequisite: {feat.prerequisites})</span>:null}
            {features}
            {this.props.noSource?null:<Rendersource entry={feat}/>}
        </div>;
    }
}

function printFeat(id,noTitle,header) {
    const list=[];
    const it = campaign.getFeatInfo(id);
    if (!it) {
        return;
    }
    if (!noTitle) {
        list.push(`<h${header}>${it.displayName}</h${header}>`);
    }

    if (campaign.getSourcePreventEmbedding(it.source)) {
        list.push("<p>Not allowed to publish.</p>");
    } else {
        list.push(printFloatArt(it.defaultArt))
        if (it.prerequisites) {
            list.push(`<div><i>(Prerequisite: ${it.prerequisites})</i></div>`);
        }
        list.push(renderPrintFeatures(it.features, noTitle?header:header+1));
    }
    return list.join("\n");
}



class FeatDialog extends React.Component {
    constructor(props) {
        super(props);

           this.state= {editable:props.openEditable };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open!= prevProps.open) {
            this.setState({editable:this.props.openEditable});
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        if (this.state.editable) {
            return <EditFeat open feat={this.props.feat} onClose={this.props.onClose}/>
        }
        const feat = campaign.getFeatInfo(this.props.feat);
        const extraButtonsFn = this.props.extraButtonsFn;
        const showEdit = !campaign.isSharedCampaign()&&!this.props.disableEdit;

        return  <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>{feat&&feat.displayName}</DialogTitle>
            <DialogContent>
                <Feat noTitle feat={this.props.feat}/>
            </DialogContent>
            <DialogActions>
                <AddChatEntry type="Feat" displayName={feat.displayName} href={getBasicHref("feats",feat.name)}/>
                {extraButtonsFn && extraButtonsFn(this.props.feat)}
                {showEdit?<DeleteEntry type="feats" entry={feat} onClose={this.props.onClose}/>:null}
                {showEdit?<Button onClick={this.clickEdit.bind(this)} color="primary">
                    Edit
                </Button>:null}
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }

    clickEdit() {
        this.setState({editable:true});
    }
}
    
class EditFeat extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {feat:campaign.getFeatInfo(props.feat)};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.feat != prevProps.feat)||(this.props.open && this.props.open!= prevProps.open)) {
            this.setState({feat:campaign.getFeatInfo(this.props.feat)});
        }
    }

    render() {
        const feat = this.state.feat;
        if (!this.props.open || !feat) {
            return null;
        }

        return  <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>
                <TextVal 
                    text={feat.displayName}
                    fullWidth
                    inputProps={{className:"f1 titletext titlecolor ignoreDrag"}}
                    onChange={this.onChangeField.bind(this,"displayName")}
                />
            </DialogTitle>
            <DialogContent className="stdcontent">
                <TextField 
                    className="mb2"
                    helperText="Prerequisites"
                    placeholder="Optional prerequisites"
                    value={feat.prerequisites||""}
                    fullWidth
                    onChange={this.changePrerequisites.bind(this)}
                />
                <FeatureListEdit editable noFeats features={feat.features} onChange={this.onChangeField.bind(this, "features")} displayType="Feat" type={feat.displayName} emptyName={feat.displayName} name={feat.name}/>
                <h2>Artwork</h2>
                <ExtraArtList artList={feat.artList} onChange={this.onChangeArtwork.bind(this)} pickArt defaultArt={feat.defaultArt}/>
                <ArtZoomList editable open={this.state.showExtraArtDialog} artList={feat.artList} onClose={this.onSaveArtwork.bind(this)} pickArt defaultArt={feat.defaultArt} defaultSearch={feat.displayName}/>
                <div className="pt2">
                    <SelectVal value={feat.gamesystem||"5e"} values={gamesystemOptions} onClick={this.onChangeField.bind(this,"gamesystem")} helperText="Game System"/>
                </div>
            </DialogContent>
            <DialogActions>
                <DeleteEntry type="feats" entry={feat} onClose={this.props.onClose}/>
                <Button onClick={this.showExtraArt.bind(this)} color="primary">
                    Select Artwork
                </Button>
                <Button onClick={this.saveData.bind(this)} color="primary">
                    Save
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    changePrerequisites(e) {
        this.onChangeField("prerequisites", e.target.value);
    }

    saveData() {
        campaign.updateCampaignContent("feats", this.state.feat);
        this.props.onClose();
    }

    onChangeField(field,val) {
        const feat = Object.assign({}, this.state.feat);
        if (val == "none") {
            val = null;
        } else if (val == "true") {
            val=true;
        } else if (val == "false") {
            val=false;
        }
        feat[field] = val;
        this.setState({feat});
    }

    showExtraArt() {
        this.setState({showExtraArtDialog:true});
    }

    onSaveArtwork(artList, defaultArt, defaultToken) {
        if (artList) {
            this.onChangeArtwork(artList, defaultArt,defaultToken);
        }
        this.setState({showExtraArtDialog:false})
    }

    onChangeArtwork(artList, defaultArt, defaultToken) {
        const feat = Object.assign({}, this.state.feat)
        feat.artList = artList;
        if (defaultArt) {
            feat.defaultArt = defaultArt;
        } else {
            delete feat.defaultArt;
        }
        if (defaultToken) {
            feat.defaultToken = defaultToken;
        } else {
            delete feat.defaultToken;
        }
        this.setState({feat})
    }
}

const emptyFeat =     {
    "size": "M",
    "features": [
      {
        "entries": [
          ""
        ],
        auto:true
      }
    ]
}

class NewFeat extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    createFeat() {
        const name = this.state.name;
        const selectedFeat = this.state.selectedFeat;
        let newFeat = Object.assign({}, selectedFeat?campaign.getFeatInfo(selectedFeat):emptyFeat);

        newFeat.displayName=name;
        newFeat.name=campaign.newUid();
        campaign.updateCampaignContent("feats", newFeat);
        this.props.onClose(newFeat.name);
    }

    onClose() {
        this.props.onClose();
    }

    onChange(event) {
        if (/[^\n]*/.exec(event.target.value) == event.target.value) {
            this.setState({name:event.target.value});
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({name:""});
        }
    }

    render() {
        if (!this.props.open)
            return null;

        const feats = campaign.getSortedFeatsList();
        const featsList=[];
        const selectedFeat = this.state.selectedFeat;
        const name=this.state.name||"";

        featsList.push({name:"Empty Template",value:"Empty Template"});
        for (var i in feats){
            featsList.push({name:feats[i].displayName, value:feats[i].name});
        }
    
        return <Dialog
            open={this.props.open||false}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle onClose={this.onClose.bind(this)}>
                Create Feat
            </DialogTitle>
            <DialogContent>
                <TextField
                    value={name}
                    onChange={this.onChange.bind(this)}
                    margin="normal"
                    label="Feat Name"
                    fullWidth
                />
                <div className="mv1">
                    <SelectVal fullWidth noteText label="Base Feat" value={selectedFeat||"Empty Template"} values={featsList} onClick={this.changeFeat.bind(this)}/>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={!name || name==""} onClick={this.createFeat.bind(this)} color="primary">
                    New
                </Button>
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    changeFeat(selectedFeat) {
        if (selectedFeat=="Empty Template") {
            selectedFeat = null;
        }
        this.setState({selectedFeat});
    }
}

class PickFeat extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            this.setState({feat:null});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges){
            this.props.onClose(this.state.feat);
        } else {
            this.props.onClose(null);
        }
    };

    render() {
        if (!this.props.open) {
            return null;
        }

        const feats = campaign.getSortedFeatsList();
        const character=this.props.character;
        const extensionEntryCheckFn = getExtensionEntryCheckFn(character);

        return  <Dialog
            open
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                Select Feat
            </DialogTitle>
            <DialogContent>
                <ListFilter 
                    list={feats}
                    showCards
                    showAll
                    render={this.renderCard.bind(this)}
                    filters={[defaultSourceFilter,defaultBookFilter,defaultGamesystemFilter]}
                    getListRef={this.saveRef.bind(this)}
                    entryCheckFn={extensionEntryCheckFn}
                    noResort

                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <FeatDialog open={this.state.feat} feat={this.state.feat} extraButtonsFn={this.getExtraButtons.bind(this)} onClose={this.clickFeat.bind(this,null)} disableEdit/>
        </Dialog>;
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    getExtraButtons(feat) {
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(feat))||{});
        return <span>
            <Button disabled={!prev} onClick={prev?this.clickFeat.bind(this,prev.name):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.clickFeat.bind(this,next.name):null} color="primary"><span className="b fas fa-step-forward"/></Button>
            <Button onClick={this.handleClose.bind(this,true)} color="primary">Select</Button>
        </span>
    }

    renderCard(it, width, bc) {
        const entries = it.features&&it.features[0]&&it.features[0].entries;
        return <div key={it.name} style={{width:width-10}} className={"shadow-3 ma1 pa1 stdcontent hoverhighlight overflow-hidden "+(bc||"")} onClick={this.clickFeat.bind(this,it.name)}>
            <div className="f1 titlecolor">{it.displayName}</div>
            <div className="f6 near-black bb titleborder mb1"><Rendersource className=" " entry={it}/></div>
            {it.prerequisites?<div className="i f5 mb1"> (Prerequisite: {it.prerequisites})</div>:null}
            <FloatCardArt art={it.defaultArt} width={width}/>
            <Renderentry className="ll-8" entry={entries}/>
        </div>
    }

    clickFeat(feat){
        this.setState({feat});
    }
}

class FeatsHeader extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    onNew() {
        this.setState({showNew:true});
    }

    render() {
        return <span>
            Feats
            {!campaign.isSharedCampaign()?<Button className="ml2 minw2" color="secondary" variant="outlined" size="small" onClick={this.onNew.bind(this)}>New</Button>:null}
            <NewFeat open={this.state.showNew} onClose={this.closeNew.bind(this)}/>
            <FeatDialog open={this.state.editFeat} onClose={this.closeEditFeat.bind(this)} feat={this.state.editFeat} openEditable/>
        </span>;
    }

    closeNew(name) {
        this.setState({showNew:false, editFeat:name||null});
    }

    closeEditFeat() {
        this.setState({editFeat:null});
    }
}

export {
    RenderFeats,
    Feat,
    FeatDialog,
    NewFeat,
    FeatsHeader,
    EditFeat,
    PickFeat,
    printFeat
}