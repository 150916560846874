const React = require('react');
const {campaign,globalDataListener,areSameDeep} = require('../lib/campaign.js');
const {displayMessage} = require('./notification.jsx');
const {Character,getImageUrlInfoFromCharacter} = require('../lib/character.js');
const {MonObj} = require('../lib/monobj.js');
const Parser = require("../lib/dutils.js").Parser;
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
const {PickVal, NumberAdjust, TextBasicEdit, getAnchorPos,maxLen} = require('./stdedit.jsx');
const {levelXPTarget, armorClassValuesList, initiativeValuesList,sizeScaleMap, coinNames} = require('../lib/stdvalues.js');
const {MonsterPCDialog} = require("./monsterpc.jsx");
const {ItemListPicker,mergeItemList} = require('./items.jsx');
const {EditMapObject} = require('./objects.jsx');
const {ConditionList,PickCondition,addConditionInfoToConditions} = require('./conditions.jsx');
const {doRoll,getDiceFromString,dicerandom, getRollLastActions} = require('../src/diceroller.jsx');
const {Chat} = require('../lib/chat.js');
const {PickArtMenu} = require('./renderart.jsx');

function getXP(d){
    const mon = campaign.getMonster(d.type);
    if (!mon) {
        return 0;
    }
    const cr=(d.cr|| mon.cr); 
    
    const xp = (cr&&(cr!="0"))?Parser.crToXp(cr):10;
    return Number.parseInt(xp.toString().replace(",", ""))
}

function monToLevel(d) {
    const mon = campaign.getMonster(d.type);
    if (!mon) {
        return 0;
    }
    if (mon.npc) {
        return mon.level || 0;
    }
    const cr=(d.cr|| mon.cr); 
	if (cr === "Unknown" || cr === undefined) return 0;
	if (cr === "0") return 0;
	if (cr === "1/8") return 0;
	if (cr === "1/4") return 1;
	if (cr === "1/2") return 2;
    const crNum = Number(cr);
    if (crNum == 1) {
        return 4;
    }
    if (crNum >= 10) {
        return 20;
    }
    return crNum*2+1;
};

class EncounterMonsterList extends React.Component {
    constructor(props) {
        super(props);
        this.onCampaignChangeFn = this.onCampaignChange.bind(this);

        this.state= {
            players:campaign.getPlayers()
        };
        this.onChangeRawFn = this.onChangeRaw.bind(this);
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.onCampaignChangeFn, "players");
    }
  
    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.onCampaignChangeFn, "players");
    }

    onCampaignChange() {
        this.setState({players:campaign.getPlayers()})
    }

	render() {
        let rows = [], irows=[], drows=[], orows=[];
        const data = this.props.members;
        const {planning,doSelection} = this.props;

        let num=1;
        for (let i in data){
            const d = data[i];
            let addRows;
            let cnum=null;

            if ((d.ctype == "object") && !d.includeInCombat) {
                addRows = orows;
            } else {
                switch (d.state) {
                    case "inactive":
                        addRows = irows;
                        break;
                
                    case "active":
                    default:
                        addRows = rows;
                        if (!d.hidden) {
                            cnum=num;
                            num++;
                        }
                        break;
                }
            }
            addRows.push(<CombatantRow 
                {...this.props}
                key={i}
                parent={this}
                onChangeConditions={this.onChangeVal.bind(this, i, "conditions")}
                onChange={this.onChangeRawFn}
                onAdjustChange={this.onAdjustChange.bind(this)}
                showPicker={this.showPicker} 
                combatant={d} 
                index={i}
                number={cnum}
                even={(addRows.length%2)==1} 
            />);
        }

        const {xpTotal, target, xpAdjusted} = calcXPTotals(data, planning?this.props.level:null, planning?this.props.players:null);

        return <div className="defaultcolor f3 w-100 defaultbackground overflow-x-auto">
            <table className="w-100 encountertable">
                <thead>
                    <tr>
                        <th className="sticktop tr"># {doSelection?<span className="far fa-square hoverhighlight" onClick={this.showGroupSelect.bind(this,true)}/>:null}</th>
                        <th className="sticktop w-100">Name</th>
                        <th onClick={this.unhideAll.bind(this)} className="sticktop tw2"><Tooltip title="show all"><i className="fas fa-eye"/></Tooltip></th>
                        {planning?null:<th className="sticktop tw2"><Tooltip title="passive perception"><i className="fas fa-low-vision"/></Tooltip></th>}
                        <th className="sticktop tw2"><Tooltip title="armor class"><i className="fas fa-shield-alt"/></Tooltip></th>
                        <th className="sticktop tw2"><Tooltip title="hit points"><i className="fas fa-heart"/></Tooltip></th>
                        {planning?null:<th className="sticktop tw2"><Tooltip title="initiative"><i className="fas fa-fist-raised"/></Tooltip></th>}
                        {planning?<th className="sticktop tw2"><Tooltip title="challenge rating"><span>CR</span></Tooltip></th>:null}
                        {planning?<th className="sticktop tw3"><Tooltip title="experience points"><span>XP</span></Tooltip></th>:null}
                        {planning?<th className="sticktop tw2"><Tooltip title="treasure"><i className="fas fa-coins"/></Tooltip></th>:null}
                    </tr>
                </thead>
                <tbody>
                    {rows}
                    {(orows.length > 0)?<tr><td className="encountertableheader b tc" colSpan="10">Tokens</td></tr>:null}
                    {orows}
                    {(drows.length > 0)?<tr><td className="encountertableheader b tc" colSpan="10">Dead</td></tr>:null}
                    {drows}
                    {(irows.length > 0)?<tr><td className="encountertableheader b tc" colSpan="10">Inactive</td></tr>:null}
                    {irows}
                </tbody>
            </table>
            {planning?<div className="tr"><span className="pr--2">XP: <span className="minw3 dib">{xpTotal.toLocaleString()}</span></span></div>:null}
            {planning?<div className="tr"><span className="pr--2">Adjusted XP: <span className={"minw3 dib "+getXPColor(xpAdjusted, target)}>{xpAdjusted.toLocaleString()}</span></span></div>:null}
            {this.getContextMenu()}
            {this.getGroupSelectMenu()}
            <TextBasicEdit show={this.state.showEditName} onChange={this.changeName.bind(this)} label="Name" text={this.state.editName}/>
            <EditMapObject open={this.state.showEditObject} object={this.state.editobject} onClose={this.handleEditObject.bind(this)}/>
            <MonsterPCDialog
                open={this.state.showCid}
                onClose={this.showDetails.bind(this,null)}
                cid={this.state.showCid}
                eventSync={this.props.eventSync}
                members={this.props.members}
                onChange={this.props.onChange}
                addSpellToken={this.props.addSpellToken}
            />
            {this.state.showItemListPicker?<ItemListPicker 
                open
                onClose={this.closeItemListPicker.bind(this)}
                equipment={this.state.pickTreasure.items}
                coins={this.state.pickTreasure.coins} 
            />:null}
            <MonsterTokenSelect open={this.state.pickMonsterTokens} monsters={this.state.pickMonsterTokens} onClose={this.closePickMonsterTokens.bind(this)}/>
        </div>;
    }

    showGroupSelect(showGroupSelect, e) {
        this.setState({showGroupSelect,anchorEl:showGroupSelect?e.target:null});
    }

    getGroupSelectMenu() {
        if (!this.state.showGroupSelect) {
            return null;
        }


        return <Menu open disableAutoFocusItem anchorEl={this.state.anchorEl} anchorReference="anchorEl" transitionDuration={0} onClose={this.showGroupSelect.bind(this, false)}>
            <MenuItem onClick={this.doSelection.bind(this, "clear")}>Clear Selection</MenuItem>
            <MenuItem onClick={this.doSelection.bind(this, "all")}>Select All</MenuItem>
            <MenuItem onClick={this.doSelection.bind(this, "monsters")}>Select Monsters</MenuItem>
            <MenuItem onClick={this.doSelection.bind(this, "characters")}>select Characters</MenuItem>
        </Menu>;
    }

    doSelection(type) {
        this.props.doSelection(type);
        this.setState({showGroupSelect:false});
    }

    showDetails(cid) {
        this.setState({showCid:cid, showContextMenu:false});
    }

    changeName(name) {
        if (name) {
            this.onChangeVal(this.state.contextIndex, "displayName", name);
        }
        this.setState({showEditName:false});
    }

    onChange(index, prop, e) {
        this.onChangeVal(index, prop, e.target.value);
    }

    onChangeCrow(newC) {
        if (newC) {
            const newEM = this.props.members.concat([]);
            for (let i in newEM) {
                if (newEM[i].id == newC.id) {
                    newEM[i] = newC;
                }
            }
            this.props.onChange(newEM);
        }
    }

    onAdjustChange(value, adjust, index, prop) {
        const c = this.props.members[index];

        if (adjust && (prop == "hp")) {
            let description;

            if (adjust < 0) {
                description = "Damage "+(-adjust);
            } else {
                description = "Heal "+adjust;
            }
            if (adjust > -100) {
                campaign.addMRUList("damageMRU", {description:description, adjust:adjust});
            }
        } else if (prop=="hpnohistory") {
            prop = "hp";
        }

        if (value < 0) {
            value=0;
        }

        const combatant = new Combatant(c);
        this.onChangeCrow(combatant.adjustValue(prop, value, adjust));

        this.setState({showContextMenu:false});
    }

    onChangeRaw(value, index, prop) {
        if ((value == "--") && (prop=="initiative")) {
            value = null;
        }
        this.onChangeVal(index, prop, value);
    }

    onChangeVal(index, prop, value) {
        const combatant = new Combatant(this.props.members[index]);
        this.onChangeCrow(combatant.changeValue(prop, value));

        this.setState({showContextMenu:false});
    }

    unhideAll(e) {
        let newEM = this.props.members.concat([]);
        let allHidden = true;

        for (let i in newEM) {
            let e = newEM[i];

            if (e.hidden && ((e.ctype != "object")||e.includeInCombat)) {
                e = Object.assign({}, e);
                allHidden = false;
                e.hidden=false;
                newEM[i]=e;
            }
        }
        if (allHidden) {
            for (let i in newEM) {
                let e = newEM[i];

                if (e.includeInCombat || !["object","pc","cmonster"].includes(e.ctype)) {
                    e = Object.assign({}, e);
                    e.hidden=true;
                    newEM[i]=e;
                }
            }
        }
        this.props.onChange(newEM);
    }

    showPicker(index, e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            anchorEl:e.target,
            anchorPos:null,
            showContextMenu:true,
            contextIndex:index,
            mapVersion:false,
            showCombatantName:false
        });
    }

    displayContextMenu(index, anchorPos) {
        this.setState({
            anchorPos:anchorPos,
            anchorEl:null,
            showContextMenu:true,
            contextIndex:index,
            mapVersion:true,
            showCombatantName:true
        });
    }

    getContextMenu() {
        const t=this;
        if (!this.state.showContextMenu)
            return null;

        const c = this.props.members[this.state.contextIndex];
        const crow = new Combatant(c);
        const player = crow.isPlayer;
        const isCombatToken = ((c.ctype == "object") && c.includeInCombat);
        const hp = crow.hp;

        if ((c.ctype != "object")||isCombatToken) {
            return <Menu open={true} disableAutoFocusItem anchorEl={this.state.anchorEl} anchorPosition={this.state.anchorPos} anchorReference={this.state.anchorPos?"anchorPosition":"anchorEl"} transitionDuration={0} onClose={function(){t.setState({showContextMenu:false})}}>
                {this.state.showCombatantName&&!isCombatToken?<div className="ph1 pb1 f5 tc titlecolor titleborder bb" disabled>{crow.displayName}</div>:null}
                {this.state.mapVersion?<NumberAdjust positive={false} value={hp} useMenu onChange={this.onAdjustChange.bind(this)} paramA={this.state.contextIndex} paramB="hp" altText="Damage/Heal"/>:null}
                {this.getDamageMRU(hp)}
                <PickCondition onAddCondition={this.closeAddCondition.bind(this)}/>
                {(this.state.mapVersion)?<MenuItem onClick={this.onChangeVal.bind(this, this.state.contextIndex, "hidden", !c.hidden,null, null)}>{c.hidden?"Show":"Hide"}</MenuItem>:null}
                {(c.ctype!="object" || (c.custom && c.customId))?<MenuItem onClick={this.showDetails.bind(this,c.id)}>View Details</MenuItem>:null}
                <Divider/>
                {(this.props.getMapRef&&!this.state.mapVersion)?<MenuItem onClick={this.onAddToMap.bind(this, this.state.contextIndex)}>Add To Map</MenuItem>:null}
                {(c.tokenMap)?<MenuItem onClick={this.onChangeVal.bind(this, this.state.contextIndex, "tokenMap", null,null,null)}>Remove From Map</MenuItem>:null}
                {(c.state&&(c.state!="active"))?<MenuItem onClick={this.onChangeVal.bind(this, this.state.contextIndex, "state", "active",null,null)}>Set Active</MenuItem>:null}
                {c.type?<MenuItem onClick={this.onChangeVal.bind(this, this.state.contextIndex, "friendly", !c.friendly,null,null)}>{(c.friendly)?"Set as Foe":"Set as Friend"}</MenuItem>:null}
                {(c.state!="inactive")?<MenuItem onClick={this.onChangeVal.bind(this, this.state.contextIndex, "state", "inactive",null,null)}>Set Inactive</MenuItem>:null}
                {c.type?<MenuItem onClick={this.onChangeVal.bind(this, this.state.contextIndex, "hideName", !c.hideName,null,null)}>{(c.hideName)?"Show Name":"Hide Name"}</MenuItem>:null}
                {crow.editInline&&!isCombatToken?<MenuItem onClick={this.onEditName.bind(this, crow.displayName)}>Edit name</MenuItem>:null}
                {isCombatToken?<MenuItem onClick={this.editMapObject.bind(this)}>Edit</MenuItem>:null}
                {!player?<MenuItem onClick={this.deleteRow.bind(this)}>Delete</MenuItem>:null}
            </Menu>;
        } else {
            return <Menu open={true} disableAutoFocusItem anchorEl={this.state.anchorEl} anchorPosition={this.state.anchorPos} anchorReference={this.state.anchorPos?"anchorPosition":"anchorEl"} transitionDuration={0} onClose={function(){t.setState({showContextMenu:false})}}>
                {(this.state.mapVersion)?<MenuItem onClick={this.onChangeVal.bind(this, this.state.contextIndex, "hidden", !c.hidden,null,null)}>{c.hidden?"Show":"Hide"}</MenuItem>:null}
                {(c.ctype!="object" || (c.custom && c.customId))?<MenuItem onClick={this.showDetails.bind(this,c.id)}>View Details</MenuItem>:null}
                <Divider/>
                {(this.props.getMapRef&&!this.state.mapVersion)?<MenuItem onClick={this.onAddToMap.bind(this, this.state.contextIndex)}>Add To Map</MenuItem>:null}
                {(c.tokenMap)?<MenuItem onClick={this.onChangeVal.bind(this, this.state.contextIndex, "tokenMap", null,null,null)}>Remove From Map</MenuItem>:null}
                <MenuItem onClick={this.editMapObject.bind(this)}>Edit</MenuItem>
                <MenuItem onClick={this.showItemListPicker.bind(this, crow.treasure)}>Edit Treasure</MenuItem>
                <MenuItem onClick={this.deleteRow.bind(this)}>Delete</MenuItem>
            </Menu>;
        }
    }

    showItemListPicker(treasure) {
        this.setState({
            showItemListPicker:true,
            pickTreasure:treasure||{}
        });
    }

    closeItemListPicker(items, coins, save) {
        if (save) {
            let treasure = null;
            if (items || coins) {
                treasure = {items, coins};
            }
            this.onChangeRaw(treasure, this.state.contextIndex, "treasure");
        }
        this.setState({showItemListPicker:false});
    }

    onEditName(name) {
        this.setState({showContextMenu:false, showEditName:true, editName:name});
    }

    getDamageMRU(hp) {
        if (!this.state.mapVersion) {
            return null;
        }
        const t=this;
        const ret=[];
        const mru =  campaign.getMRUList("damageMRU");
        let len = Math.min(2, mru.length);
        const lr = getRollLastActions(2);

        for (let i in lr) {
            const {chat, roll, sum} = lr[i];
            const actor = roll.source || roll.playerDisplayName || chat.actor;
            if (roll.action == "heal") {
                ret.push(<MenuItem key={"heal"+i} onClick={this.onAdjustChange.bind(this, hp+sum, sum, t.state.contextIndex, "hpnohistory")}><span className="ml2">{actor} Heal {sum}</span></MenuItem>);

            } else {
                const half = Math.trunc(sum/2);

                ret.push(<MenuItem key={"full"+i} onClick={this.onAdjustChange.bind(this,hp-sum, -sum, t.state.contextIndex, "hpnohistory")}><span className="ml2">{actor} Damage {sum}</span></MenuItem>);
                if (half) {
                    ret.push(<MenuItem key={"half"+i} onClick={this.onAdjustChange.bind(this,hp-half, -half, t.state.contextIndex, "hpnohistory")}><span className="ml2">{actor} Half Damage {half}</span></MenuItem>);
                }
            }
        }
        for (let i=0; i<len; i++) {
            const m = mru[i];
            ret.push(<MenuItem key={"mru"+i} onClick={this.onAdjustChange.bind(this,hp+m.adjust, m.adjust, t.state.contextIndex, "hp")}><span className="ml2">{m.description}</span></MenuItem>);
        }
        ret.push(<MenuItem key="kill" onClick={this.onAdjustChange.bind(this,0, -10000, t.state.contextIndex, "hpnohistory")}><span className="ml2">Kill</span></MenuItem>);

        return ret;
    }

    onAddToMap(index) {
        this.setState({showContextMenu:false});

        const mapRef = this.props.getMapRef();
        const mapName = mapRef && mapRef.state.imageName;

        if (mapRef && mapName) {
            const center = mapRef.getCenter();
            const newCombatants = this.props.members.concat([]);
            const c = Object.assign({}, newCombatants[index]);
            c.tokenMap = mapName;
            this.findFreeSpot(mapRef, newCombatants, center.x, center.y, c, index);
            newCombatants[index] = c;
            this.props.onChange(newCombatants);
        }
    }

    addObject(obj, pos, mapRefToUse) {
        const emon = this.props.members.concat([]);
        const mapRef = mapRefToUse || this.props.getMapRef();
        const mapName = mapRef && mapRef.state.imageName;

        addObject(emon, obj, pos || mapRef.getCenter(), mapName, !!this.state.planning);

        if (mapName) {
            mapRef.setState({mode:"select"});
        }
        this.props.onChange(emon);
    }

    addMonster(monster, count, pos, mapRefToUse) {
        const selList = {};
        selList[monster] = count;
        this.addMonsters(selList, pos, mapRefToUse);
    }

    addMonsters(selList, pos, mapRefToUse, showPickTokens, monsterTokens) {
        if (showPickTokens) {
            this.setState({pickMonsterTokens:selList, pickMonsterTokenPos:pos, pickMonsterTokensMapRef:mapRefToUse});
            return;
        }
        const emon = this.props.members.concat([]);
        const mapRef = mapRefToUse||this.props.getMapRef();
        const mapName = mapRef && mapRef.state.imageName;
        const center = pos || (mapRef && mapRef.getCenter());

        addMonsters(emon, selList, center, mapName,this.props.defaultShown, monsterTokens);

        this.props.onChange(emon);
    }

    closePickMonsterTokens(monsterTokens) {
        if (monsterTokens) {
            this.addMonsters(this.state.pickMonsterTokens, this.state.pickMonsterTokenPos, this.state.pickMonsterTokensMapRef, false, monsterTokens);
        }
        this.setState({pickMonsterTokens:null});
    }

    addToEncounter(row) {
        const emon = this.props.members.concat([]);
        const mapRef = this.props.getMapRef();
        const mapName = mapRef && mapRef.state.imageName;
        const mapPos = (mapRef && mapRef.getCenter());

        const combatants = updateCombatants(row, emon, mapPos, mapName, true);

        if (combatants) {
            this.props.onChange(combatants);
            displayMessage("Successfully added to encounter");
        }
    }

    getHuddleMap(c) {
        if (c.tokenMap) {
            let mapRef = this.props.getMapRef();
            if (mapRef && (c.tokenMap == mapRef.state.imageName)) {
                return mapRef;
            }
        }
        return null;
    }

    onHuddle(index) {
        this.setState({showContextMenu:false});

        const base = this.props.members[index];
        const baseInfo = getTokenInfo(base);

        let mapRef = this.getHuddleMap(base);
        if (!mapRef) {
            console.log("no map, something went wrong with huddle.  can't huddle");
            return;
        }

        this.doHuddle(base.tokenX, base.tokenY, baseInfo.group, base.state, mapRef, base.tokenMap, index);
    }

    doHuddle(x, y, group, state, mapRef, mapName, index) {
        const newCombatants = this.props.members.concat([]);

        for (let i in newCombatants) {
            if (i != index) {
                const c = Object.assign({}, newCombatants[i]);
                const cInfo = getTokenInfo(c);

                if ((group == cInfo.group)&&((c.state||"active")==(state||"active"))) {
                    c.tokenMap = mapName;
                    this.findFreeSpot(mapRef, newCombatants, x, y, c, i);
                    newCombatants[i] = c;
                } else {
                }
            }
        }
        this.props.onChange(newCombatants);

    }

    findFreeSpot(mapRef, combatants, nextX, nextY, c, ci) {
        return findFreeSpot(mapRef.state.imageName, combatants, nextX, nextY, c, ci);
    }

    closeAddCondition(conditionInfo) {
        if (conditionInfo) {
            const newEM = this.props.members.concat([]);
            const combatant = new Combatant(newEM[this.state.contextIndex]);
            let c = Object.assign({}, combatant.conditions||{});
            addConditionInfoToConditions(c, conditionInfo);
    
            this.onChangeVal(this.state.contextIndex, "conditions", c);
        }


        this.setState({showContextMenu:false});
    }

    onChangeCombatantPos(index, x, y, rotation) {
        const newC = this.props.members.concat([]);
        if (Array.isArray(index)) {
            for (let i in index) {
                const a = index[i];

                const c = Object.assign({}, newC[a.index]);
                c.tokenX = a.x;
                c.tokenY = a.y;
                if (a.rotation != null) {
                    c.rotation=a.rotation;
                }
                newC[a.index]=c;
            }
        } else {
            const c = Object.assign({}, newC[index]);
            c.tokenX = x;
            c.tokenY = y;
            if (rotation !=null) {
                c.rotation=rotation;
            }
            newC[index]=c;
        }
        this.props.onChange(newC);
    }

    deleteRow() {
        let newEM = this.props.members.concat([]);

        newEM.splice(this.state.contextIndex,1);
        this.setState({showContextMenu:false});
        this.props.onChange(newEM);
    }

    editMapObject() {
        this.setState({showContextMenu:false, showEditObject:true, editobject:this.props.members[this.state.contextIndex]});
    }

    handleEditObject(ao) {
        if (ao) {
            let newEM = this.props.members.concat([]);

            newEM[this.state.contextIndex] = ao;
            this.props.onChange(newEM);
        }
        this.setState({showEditObject:false});
    }
}

class CombatantRow extends React.Component {
    constructor(props) {
        super(props);
        this.state= {hover:false};
        this.hoverChangeFn = this.hoverChange.bind(this);
    }

    componentDidMount() {
        if (this.props.eventSync) {
            this.props.eventSync.addListener("mapToken", this.hoverChangeFn);
        }
    }

    componentWillUnmount() {
        if (this.props.eventSync) {
            this.props.eventSync.removeListener("mapToken", this.hoverChangeFn);
        }
    }

    hoverChange(index) {
        const hover = (this.props.index == index);
        if (hover != this.state.hover) {
            this.setState({hover:hover});
        }
    }

	render() {
        const {planning, selected, softSelected, toggleSelected, parent, combatant, number,showPicker,onChangeConditions,onChange,onAdjustChange} = this.props;
        const i = this.props.index;

        let selClass="far fa-square hoverhighlight";
        if (selected) {
            if (selected[combatant.id]) {
                selClass = "far fa-check-square hoverhighlight";
            }
        } else if (combatant.id==softSelected) {
            selClass = "gray-80 far fa-check-square hoverhighlight";
        }

        const crow = new Combatant(combatant);
        return <tr onClick={this.onClickRow.bind(this)} onMouseOver={this.onMouseEnterMonster.bind(this, i)} onMouseOut={this.onMouseLeaveMonster.bind(this)} className={((this.state.hover)?"encountertablehover ":((this.props.even)?"encountertableeven ":""))}>
            <td className="tr bn cursor-default nowrap">{number} {toggleSelected?<span className={selClass} onClick={this.toggleSelected.bind(this,combatant.id)}/>:null}</td>
            <td className="word-wrap">
                <span className="far fa-caret-square-down mr1" onClick={showPicker.bind(parent, i)}/>
                {crow.currentTurn?<span className="fas fa-arrow-right"/>:crow.showDead?<span className="titlecolor fas fa-skull-crossbones"/>:null}&nbsp;
                {crow.friendly?<span className="titlecolor far fa-smile mr--3"/>:null}
                {maxLen(crow.displayName,30)}
                <ConditionList conditions={crow.conditions} onChange={onChangeConditions} doSubRoll={this.doSubRoll.bind(this,crow.displayName)}/>
            </td>
            <td className="tc hover-bg-contrast w2" onClick={this.onToggle.bind(this, i, "hidden", !crow.hidden)}>{crow.hidden?<i className="far fa-eye-slash"/>:<i className="far fa-eye"/>}</td>
            {planning?null:<td className="tr">{crow.passive}</td>}
            {!crow.editInline?<td className="tr">
                {crow.ac}
            </td>:<td>
                <PickVal isNum value={crow.ac} paramA={i} paramB="ac" onClick={onChange} values={armorClassValuesList}>
                    <div className="hover-bg-contrast tr">{crow.ac||<span className="ph1">-</span>}</div>
                </PickVal>
            </td>}
            <td className="tr"><NumberAdjust useDiv positive={false} value={crow.hp} paramA={i} paramB="hp" onChange={onAdjustChange}/></td>
            {planning?null:<td>
                <PickVal isPossibleNum value={crow.initiative} paramA={i} paramB="initiative" onClick={onChange} values={initiativeValuesList}>
                    <div className="hover-bg-contrast tr">{(crow.initiative==null)?<span className="ph1">-</span>:crow.initiative}</div>
                </PickVal>
            </td>}
            {planning?<td className="tr">{crow.cr}</td>:null}
            {planning?<td className="tr">{crow.xp}</td>:null}
            {planning?<td className="tc hover-bg-contrast" onClick={this.onTreasure.bind(this)}>
                {crow.treasure?<i className="gold fas fa-coins"/>:<i className="fas fa-coins"/>}
            </td>:null}
            {this.state.showItemListPicker?<ItemListPicker 
                open
                onClose={this.closeItemListPicker.bind(this)}
                equipment={crow.treasure && crow.treasure.items}
                coins={crow.treasure && crow.treasure.coins} 
            />:null}
        </tr>;
    }

    toggleSelected(id) {
        this.props.toggleSelected(id);
    }

    doSubRoll(name, text){
        const dice = getDiceFromString(text);
        const {rolls} = doRoll(dice);
        let attack = (dice.D20==1);
        for (let i in dice) {
            if ((i!="D20") && (i!="bonus") && (i!="extraBonus")) {
                attack = false;
            }
        }

        const newRoll = {dice, rolls, source:name, action:attack?"to hit":null};

        return Chat.addGMRoll(newRoll);
    }

    onToggle(i, colName, value) {
        this.props.onChange(value, i, colName);
    }

    onTreasure() {
        this.setState({showItemListPicker:true});
    }

    closeItemListPicker(items, coins, save) {
        if (save) {
            let treasure = null;
            if (items || coins) {
                treasure = {items, coins};
            }
            this.props.onChange(treasure, this.props.index, "treasure");
        }
        this.setState({showItemListPicker:false});
    }

    onClickRow() {
        if (this.props.onClickCombatant) {
            this.props.onClickCombatant(this.props.combatant);
        }
    }

    onMouseEnterMonster(i) {
        if (this.props.onHover) {
            this.props.onHover(i);
        }
        if (this.props.eventSync) {
            this.props.eventSync.emit("listToken", this.props.index);
        }
    }

    onMouseLeaveMonster() {
        if (this.props.onHover) {
            this.props.onHover(-1);
        }
        if (this.props.eventSync) {
            this.props.eventSync.emit("listToken", -1);
        }
    }
}

class MonsterTokenSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state={monsterTokens:{}};
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open != prevProps.open) {
            this.setState({monsterTokens:{}});
        }
    }

    render () {
        if (!this.props.open) {
            return null;
        }

        const selected = this.props.monsters;
        const monsterTokens = this.state.monsterTokens;
        const list=[];
        
        for (let m in selected) {
            const mon = campaign.getMonster(m);
            if (mon) {
                let tokens = monsterTokens[m]||[];
                const alist = [];

                if (!tokens.length && mon.tokenArt) {
                    tokens = [mon.tokenArt];
                }

                let artList = mon.artList||[];
                if (mon.tokenArt && !artList.includes(mon.tokenArt)) {
                    artList = [mon.tokenArt].concat(artList);
                }
                for (let t of tokens) {
                    if (!artList.includes(t)) {
                        artList.push(t);
                    }
                }

                for (let i of artList) {
                    const art = campaign.getArtInfo(i);
                    if (art) {
                        const val =tokens.includes(i);
                        alist.push(<div key={i} className="dib tc hoverhighlight pa1" onClick={this.setMonsterToken.bind(this,m,i,!val)}>
                            <div>
                                <img width="100px" src={art.url}/>
                            </div>
                            <span className={val?"far fa-check-square pa1 f3 titlecolor":"far fa-square pa1 f3 titlecolor"}/>
                        </div>);
                    }
                }
                alist.push(<div key="add" className="dib hoverhighlight pa1" onClick={this.onPickNewToken.bind(this,m)}>
                    <div className="flex items-center hoverhighlight ba titleborder" style={{height:"100px",width:"100px"}}>
                        <div className="tc f1 fas fa-plus titlecolor w-100"/>
                    </div>
                </div>);
                list.push(<div key={m} className="stdcontent">
                    <h2>{mon.displayName}</h2>
                    <div className="flex flex-wrap items-start">{alist}</div>
                </div>)
            }
        }

        return <Dialog
            open
            maxWidth="md"
            fullWidth
        >
            <DialogTitle onClose={this.onClose.bind(this)}>Select Tokens</DialogTitle>
            <DialogContent>
                <div className="hk-well">
                    Select tokens to use for each monster.  If multiple tokens are selected then random tokens from the selected list will be assigned.
                </div>
                {list}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.saveSelectedTokens.bind(this)} color="primary">
                    Save
                </Button>
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <PickArtMenu open={this.state.showAddMenu} onClose={this.closeMenu.bind(this)} onAddArtwork={this.addArtwork.bind(this)} anchorPos={this.state.anchorPos}  defaultType="Monster Token" defaultSearch={this.state.defaultSearch}/>
        </Dialog>;
    }

    onPickNewToken(m, event) {
        const mon = campaign.getMonster(m);
        this.setState({showAddMenu:true,selectedMonster:m, defaultSearch:mon&&mon.displayName, anchorPos:getAnchorPos(event) })
    }

    closeMenu() {
        this.setState({showAddMenu:false});
    }

    addArtwork(name) {
        this.setMonsterToken(this.state.selectedMonster, name, true);
    }

    setMonsterToken(m,a,val) {
        const monsterTokens = Object.assign({}, this.state.monsterTokens);
        const tokens = (monsterTokens[m]||[]).concat([]);
        if (!tokens.length) {
            const mon = campaign.getMonster(m);
            if (mon && mon.tokenArt) {
                tokens.push(mon.tokenArt);
            }
        }
        const i = tokens.indexOf(a)
        if (val) {
            if (i<0) {
                tokens.push(a);
            }
        } else if (i>=0) {
            tokens.splice(i,1);
        }
        monsterTokens[m]=tokens;
        this.setState({monsterTokens});
    }

    saveSelectedTokens() {
        this.props.onClose(this.state.monsterTokens)
    }

    onClose() {
        this.props.onClose();
    }
}

function addRowToCombat(row) {
    let encounter = campaign.getAdventure();
    const mapName = campaign.getPrefs().selectedMap;
    const mapInfo = campaign.getMapInfo(mapName);
    let mapPos;
    if (mapInfo) {
        const mapextra = campaign.getMapExtraInfo(mapName);
        mapPos = mapextra?mapextra.mapPos:mapInfo.mapPos;
    }

    if (!encounter) {
        encounter={name:'default'};
    }
    const combatants = updateCombatants(row, (encounter.combatants||[]).concat([]), mapPos, mapName, row.noSharedTreasure);

    if (combatants) {
        encounter.combatants=combatants;
        campaign.updateCampaignContent("adventure", encounter);
        displayMessage("Successfully added to encounter");
    }
}

function updateCombatants(row, combatants, mapPos, mapName, noSharedTreasure) {
    const monsters = row.monsters||{};
    let hasMonster = (Object.keys(monsters).length > 0);
    const items = row.items;
    const itemCounts = row.itemCounts||{};
    const custom = row.custom;
    const customCounts = row.customCounts;
    const equipment = {};
    let foundEquipment=Object.keys(row.coins||{}).length > 0;

    addMonsters(combatants, monsters, mapPos, mapPos&&mapName, false);

    for (let i in custom) {
        const ct = custom[i];
        const it = campaign.getCustom(ct.type, ct.id);
        if (it){
            const c = customCounts[i];
            let aoMap = mapPos&&mapName;
            let aoPos = mapPos;
            const ao = {
                ctype:"object",
                otype:"rectangle", fill:"#fcdc00", width:5, height:5,
                name:it.displayName||"Custom",
                custom:ct.type,
                customId:ct.id
            };

            const art = campaign.getArtInfo(it.defaultToken);
            if (art) {
                ao.tokenArt=art.name;
                if (art.mapWidth) {
                    ao.artWidth = art.mapWidth;
                } else if (!ao.artWidth) {
                    ao.artWidth=ao.width||10;
                }
                ao.otype="image";
            } else {
                aoMap = null;
                aoPos = null;
            }

            for (let x = 0; x<c; x++) {
                hasMonster=true;
                addObject(combatants, Object.assign({},ao), aoPos, aoMap, false);
            }
        }
    }

    for (let i in items) {
        const it = items[i];
        const item = Object.assign({}, it.item);
        item.count = itemCounts[i]||1;
        equipment[i]=item;
        foundEquipment=true
    }
    if (foundEquipment) {
        const elist = mergeItemList(equipment);
        const treasure={items:elist, coins:row.coins||{}};
        if (hasMonster||noSharedTreasure) {
            const ao = {
                ctype:"object",
                otype:"rectangle", fill:"#fcdc00", width:5, height:5,
                name:"Treasure",
                treasure:treasure
            };

            addObject(combatants, ao, mapPos, mapPos&&mapName, false);
        } else {
            var sharedTreasure = Object.assign({}, campaign.getSharedTreasure().treasure);
            const coins = treasure.coins;

            for (let e in elist){
                sharedTreasure[campaign.newUid()] = elist[e];
            }

            for (let c in coins) {
                let count = coins[c];
                for (let x in sharedTreasure) {
                    const st = sharedTreasure[x];
                    if (st.coin && (st.coinType==c)) {
                        count = count+(st.quantity||0);
                        delete sharedTreasure[x];
                    }
                }
                sharedTreasure[campaign.newUid()] = {quantity:count, coin:true, coinType:c, displayName:coinNames[c]};
            }

            campaign.updateCampaignContent("adventure", {name:"sharedtreasure", treasure:sharedTreasure});
            displayMessage("Successfully added to shared treasure");
        }
    }

    if ((noSharedTreasure && foundEquipment) || hasMonster) {
        return combatants;
    }
    return null;
}

function addMonsters(emon, selList, center, mapName, defaultShown, monsterTokens) {
    const hideName = !!campaign.isPlayerMode() || !campaign.getPrefs().showMonsterNames;
    for (let monster in selList) {
        let count = selList[monster];
        const mon = campaign.getMonster(monster);
        if (!mon){
            console.log("could not add monster.  couldn't find monster info", monster);
            count=0;
        }
        const tokenList = monsterTokens && monsterTokens[monster];

        while (count > 0) {
            const id = campaign.newUid();
            const newMon = {id:id, ctype:"monster", hideName, type:mon.name, name:mon.displayName, hidden:!defaultShown};
            const index = mon.unique?emon.findIndex(function (m) {return m.type==newMon.type}):-1;

            // make sure unique monster not already in list
            if (index < 0) {
                emon.push(newMon);
                if (mapName) {
                    newMon.tokenMap=mapName;
                    findFreeSpot(mapName, emon, center.x, center.y, newMon, emon.length-1);
                }
                if (!mon.unique && tokenList && tokenList.length) {
                    newMon.tokenArt = tokenList[campaign.random(tokenList.length)];
                }
            }        
            count--;
        }
    }
    return emon;
}

function addObject(emon, obj, center, mapName, hidden) {
    let nameMod = 1;

    for (let i in emon) {
        const m = emon[i];

        if (m.name.startsWith(obj.name)) {
            let num = Number(m.name.substr(obj.name.length));

            if (!isNaN(num) && (num >= nameMod))
                nameMod=num+1;
        }
    }

    const id = campaign.newUid();
    obj.name = obj.name+" "+nameMod;
    obj.id = id;
    obj.hidden = hidden;
    if (mapName) {
        obj.tokenMap=mapName;
        obj.tokenX=center.x;
        obj.tokenY=center.y;
    }
    emon.push(obj);
    return emon;
}



function findFreeSpot(imageName, combatants, nextX, nextY, c, ci) {
    let side = 1;
    let loop = 0;
    let maxloop=0;
    let baseInfo = getTokenInfo(combatants[ci]);        
    let baseSize = (sizeScaleMap[baseInfo.tokenSize]||1)*5/2;
    const tokenCheckSize = 5;

    if (!isCollision(nextX, nextY, baseSize, ci) || (combatants[ci].ctype=="object" && !combatants[ci].includeInCombat)) {
        c.tokenX = nextX;
        c.tokenY = nextY;
        return;
    }

    while (true) {
        side = side+2;
        maxloop = (side-1)*4;
        loop = 0;
        nextY = nextY-tokenCheckSize;
        while (loop < maxloop) {
            if (!isCollision(nextX, nextY, baseSize, ci)) {
                c.tokenX = nextX;
                c.tokenY = nextY;
                return;
            }
            switch (Math.trunc((loop+Math.trunc(side/2))/(side-1)) %4) {
                case 3:
                    nextY = nextY-tokenCheckSize;
                    break;
                case 2:
                    nextX = nextX-tokenCheckSize;
                    break;
                case 1:
                    nextY=nextY+tokenCheckSize;
                    break;
                case 0:
                    nextX=nextX+tokenCheckSize;
                    break;
                default:
                    console.log("unexpected value in look finding free spot");
                    break;
            }
            loop++;
        }
    }

    function isCollision(x,y,size, skipindex) {
        for (let i in combatants) {
            const c = combatants[i];
            if ((i == skipindex) || (c.tokenMap != imageName) || ((c.ctype=="object")&&!c.includeInCombat)) {
                continue;
            }
            const cInfo = getTokenInfo(c);
            const cs = (sizeScaleMap[cInfo.tokenSize]||1)*5/2 + size;
            const xd = c.tokenX-x;
            const yd = c.tokenY-y;

            if (Math.sqrt(xd*xd+yd*yd)*1.02 < cs) {
                return true;
            }
        }
        return false;
    }
}


function calcXPTotals(data, level, players) {
    let xpTotal=0;
    let cmon=0;
    const targetSum = {easy:0, medium:0, hard:0, deadly:0};
    let playersSum = 0;
    if (!data)
        data=[];

    for (let i in data){
        const d = data[i];
        if ((!d.ctype || d.ctype=="monster") && !d.friendly && ["active", "dead"].includes(d.state||"active")) {
            xpTotal += getXP(d);
            cmon++;
        }
    }

    let significantMonsters = 0;
    const avgXP = data.length?(xpTotal/cmon):0;

    for (let i in data){
        const d = data[i];
        if ((!d.ctype || d.ctype=="monster") && ["active", "dead"].includes(d.state||"active")) {
            if (!d.friendly) {
                const xp = getXP(d);

                if ( xp >= (avgXP/2))
                    significantMonsters ++;
                else if (xp >= (avgXP/4)) 
                    significantMonsters += 0.25;
                else if (xp >= (avgXP/8)) 
                    significantMonsters += 0.05;
            } else {
                const l = monToLevel(d);
                if (l) {
                    const t = levelXPTarget[l]
                    if (t) {
                        playersSum ++;
                        targetSum.easy += t.easy;
                        targetSum.medium += t.medium;
                        targetSum.hard += t.hard;
                        targetSum.deadly += t.deadly;
                    }
                }
            }
        } else if (d.ctype == "pc") {
            const p = campaign.getPlayerInfo(d.name);
            const l = (p && p.level) || d.level || 1;
            const t = levelXPTarget[l]
            if (t) {
                playersSum ++;
                targetSum.easy += t.easy;
                targetSum.medium += t.medium;
                targetSum.hard += t.hard;
                targetSum.deadly += t.deadly;
            }
        }
    }

    let target;
    if (level && players) {
        target = Object.assign({}, levelXPTarget[level]);
        target.easy *= players;
        target.medium *= players;
        target.hard *= players;
        target.deadly *= players;
    } else if (playersSum) {
        target = targetSum;
        players = playersSum;
    } else {
        target = Object.assign({}, levelXPTarget[1]);
        players = 4;
        target.easy *= players;
        target.medium *= players;
        target.hard *= players;
        target.deadly *= players;
    }

    const multiplier = getXPMultiplier(significantMonsters, players);
    const ret = {
        xpTotal:Math.trunc(xpTotal),
        multiplier,
        xpAdjusted:Math.trunc(multiplier*xpTotal),
        target
    };

    return ret;
}


function getXPMultiplier(sig, players) {
    let mult=1;
    if (sig <= 1) {
        mult= 1;
    } else if (sig <= 2) {
        mult= 1.5;
    } else if (sig <= 6) {
        mult= 2;
    } else if (sig <= 10) {
        mult= 2.5;
    } else if (sig <= 14) {
        mult= 3;
    } else {
        mult= 4;
    }
    if (players < 3) {
        mult = mult+0.5;
    } else if (players > 5) {
        mult = mult-0.5;
    }

    return mult;
}

function getXPColor(adjXP, target) {
    if (adjXP < target.easy) {
        return "tooeasyEncounter";
    } else if (adjXP < target.medium){
        return "easyEncounter";
    } else if (adjXP < target.hard){
        return "mediumEncounter";
    } else if (adjXP < target.deadly){
        return "hardEncounter";
    } else {
        return "deadlyEncounter";
    }
}

function getTokenInfo(c, forceRecalc) {
    return (new Combatant(c)).cInfo;
}

class Combatant {
    constructor(crow) {
        crow=crow||{};
        this.crow = crow;

        if (crow.ctype=="pc") {
            this.player = campaign.getPlayerInfo(crow.name);
            if (this.player) {
                this.characterObj = new Character(this.player);
            }
        } else if (crow.ctype=="cmonster") {
            this.character = campaign.getPlayerInfo(crow.name);
            if (this.character) {
                const char = new Character(this.character);
                this.companion = (char.companions||{})[crow.companionId];
                if (this.companion) {
                    this.characterObj = char;
                    this.monObj = new MonObj(this.companion, crow, true, onChangeCompanionMonster);

                    function onChangeCompanionMonster(newMon) {
                        const companions = Object.assign({}, char.companions);
                        companions[crow.companionId] =newMon
                        char.companions =companions;
                    }
                }
            }
        } else if (crow.type) {
            this.mon = campaign.getMonster(crow.type);
            if (this.mon) {
                if (this.mon.npc) {
                    this.characterObj = new Character(this.mon,"monsters");
                } else {
                    this.monObj = new MonObj(this.mon, crow, true, this.mon.unique?null:"no update");
                }
            }
        }
    }

    get d20Bonuses() {
        const cObj = this.monObj || this.characterObj;
        if (cObj) {
            return cObj.d20Bonuses;
        }
        return {};
    }

    get ac() {
        const cObj = this.monObj || this.characterObj;
        if (cObj) {
            return cObj.ac;
        }
        if (this.crow.ac) {
            return this.crow.ac;
        }
        return "";
    }

    get hp() {
        const cObj = this.monObj || this.characterObj;
        if (cObj) {
            return cObj.hp;
        }

        if (this.crow.hp || this.crow.hp===0) {
            return this.crow.hp
        }
        return "";
    }


    getSave(saveType) {
        const cObj = this.monObj || this.characterObj;
        if (cObj) {
            return cObj.abilities[saveType].spellSave||0;
        }
        return 0;
    }

    get friendly() {
        return this.crow.friendly;
    }

    get showDead() {
        return this.mon && (this.hp==0);
    }

    get temphp() {
        const cObj = this.monObj || this.characterObj;
        if (cObj) {
            return cObj.temphp;
        }

        return this.crow.temphp||0;
    }

    get size() {
        const cObj = this.monObj || this.characterObj;
        if (cObj) {
            return cObj.size;
        }

        return this.crow.size||0;
    }

    get usages() {
        if (this.monObj) {
            return this.monObj.usages;
        }

        return this.crow.usages;
    }

    get hpMax() {
        const cObj = this.monObj || this.characterObj;
        if (cObj) {
            return cObj.maxhp;
        }
        return 1000;
    }

    get cr() {
        if (this.mon) {
            return this.mon.cr;
        }
        return "";
    }

    get hidden () {
        return this.crow.hidden || false;
    }

    get treasure() {
        return this.crow.treasure||null;
    }

    get passive() {
        const cObj = this.monObj || this.characterObj;
        if (cObj) {
            return cObj.passive;
        }
        return "";
    }

    get initiative() {
        if (this.companion) {
            return this.crow.initiative || this.character.initiative;
        } else if (this.player) {
            return this.player.initiative;
        } else if (this.mon && this.mon.npc) {
            return this.mon.initiative;
        }
        return this.crow.initiative;
    }

    get xp() {
        if (this.player || this.companion || !this.mon) {
            return 0;
        }
        const cr=this.mon.cr; 
    
        return (cr&&(cr!="0"))?Parser.crToXp(cr):10; 
    }

    get displayName() {
        const cObj = this.monObj || this.characterObj;
        if (cObj) {
            return cObj.displayName;
        }

        return this.crow.name;
    }

    get conditions() {
        const cObj = this.monObj || this.characterObj;
        if (cObj) {
            return cObj.conditions;
        }

        return this.crow.conditions;
    }

    get currentTurn() {
        return this.crow.currentTurn;
    }

    get editInline() {
        return (this.crow.ctype=="object") || (this.mon && !(this.mon.unique||this.mon.npc));
    }
    get isPlayer() {
        return this.crow.ctype == "pc";
    }

    adjustValue(prop, value, adjust) {
        if (prop=="hp") {
            if (this.monObj) {
                this.monObj.damageHeal(adjust);
                if (this.monObj.updatedCrow) {
                    //console.log("change crow val", prop,value, this.monObj.crow);
                    this.crow = this.monObj.crow; 
                    return this.crow;
                }
                return;
            }
            if (this.characterObj) {
                this.characterObj.damageHeal(adjust);
                return;
            }
            this.crow = Object.assign({}, this.crow);
            this.crow.hp = this.hp + adjust;
            this.crow.hp = Math.max(0, Math.min(this.crow.hp,this.hpMax));
            return this.crow;
        }
        return this.changeValue(prop,value);
    }

    changeValue(prop, value) {
        if (!["hidden","friendly", "treasure","currentTurn", "ctype","hideName","tokenMap","state"].includes(prop)) {
            if (this.monObj) {
                this.monObj.setProperty(prop,value);
                if (this.monObj.updatedCrow) {
                    this.crow = this.monObj.crow; 
                    return this.crow;
                }
                return;
            }
            if (this.characterObj) {
                this.characterObj.setProperty(prop, value);
                return;
            }
        }
        this.crow = Object.assign({}, this.crow);
        this.crow[prop]=value;
        return this.crow;
    }

    advanceTurn() {
        const cObj = this.monObj || this.characterObj;
        if (cObj) {
            return cObj.advanceTurn();
        }

        return null;
    }

    get tokenArt() {
        const cObj = this.monObj || this.characterObj;
        if (cObj) {
            return cObj.tokenArt;
        }
        return this.crow.tokenArt;
    
    }

    get cInfo() {
        const c = this.crow;
    
        let tokenArt = this.tokenArt;
        const art = campaign.getArtInfo(tokenArt);

        if (c.ctype == "object") {
            if (art) {
                return {
                    tokenImageURL:art.url||null,
                    width:c.artWidth || art.mapWidth||10, 
                    height:(c.artWidth || art.mapWidth||10)*art.imgHeight/art.imgWidth, 
                    opacity:c.artOpacity || art.opacity||1,
                    group:c.includeInCombat?"monster":"object",
                    tokenType:c.otype
                };
            }
            return {
                tokenImageURL:c.url||null,
                width:c.width||0, 
                height:c.height||0, 
                opacity:c.opacity||1,
                group:c.includeInCombat?"monster":"object",
                tokenType:c.otype
            }
        }

        let hpMax=this.hpMax||1;
        let hp=this.hp||0;
        let conditions=this.conditions||null;
        let size = this.size||"M";
        let group="monster";
        let mon;
    
        if ((c.ctype == "pc") || (c.ctype=="cmonster") || c.friendly){
            group = "pc";
        }

        if (this.companion) {
            mon=this.companion;
        } else if (this.player || (this.mon && this.mon.npc)) {
            const player = this.player || this.mon;
            let showDead=false;
            if (player) {
                if (!hp) {
                    if (player.deathSaves==0) {
                        // stable but still at 0 hp
                        // will look mostly dead
                        hp=0.1;
                    } else if (player.deathFails==0){
                        showDead=true;
                    }
                }
            }
            const {url,art} = getImageUrlInfoFromCharacter(player);
            const ret= {
                tokenImageURL:url || "/blankplayer.png",
                tokenSize:size,
                hpMax,
                hp,
                conditions,
                group,
                showDead,
                displayName:this.displayName,
                tokenType:"nametoken"
            };

            if (["Map Token", "Treasure Token", "Spell Token"].includes(art?.type)) {
                ret.width = art.mapWidth||10
                ret.height = ret.width*art.imgHeight/art.imgWidth;
                ret.opacity = art.opacity||1;
                ret.tokenType = "imagetoken";
                //console.log("found art for character",ret);
            }
            return ret;
        } else if (c.type) {
            mon=this.mon;
        }

        if (mon) {
            const ret = {
                tokenImageURL:"/blankmonster.png",
                tokenSize:size,
                hpMax,
                hp,
                conditions:mon.uinque?mon.conditions:conditions,
                group,
                displayName:this.displayName,
                tokenType:"nametoken"
            };

            if (art) {
                ret.tokenImageURL = art.url;
                if (["Map Token", "Treasure Token", "Spell Token"].includes(art.type)) {
                    ret.width = art.mapWidth||10
                    ret.height = ret.width*art.imgHeight/art.imgWidth;
                    ret.opacity = art.opacity||1;
                    ret.tokenType = "imagetoken";
                    //console.log("art info", ret);
                }
            } else if (mon.imageURL) {
                ret.tokenImageURL = mon.imageURL;
            }
    
            return ret;
        }
    
        return {
            tokenImageURL:"/blankplayer.png",
            tokenSize:"M",
            hpMax,
            hp,
            conditions,
            group:group,
            displayName:this.displayName,
            tokenType:"nametoken"
        };
    
    }
}

function fixupSelection(combatants, selected, softSelected) {
    const newSelected = {};
    let found, newSoftSelected;

    for (let i in combatants) {
        const c=combatants[i];
        if (selected && selected[c.id]){
            newSelected[c.id]=true;
            found = true;
        }
        if (c.id==softSelected) {
            newSoftSelected = c.id;
        } else if (!newSoftSelected || ((newSoftSelected != softSelected)&&c.currentTurn)) {
            newSoftSelected = c.id;
        }
    }

    return {
        selected:found?newSelected:null,
        softSelected:newSoftSelected
    };

}

function fixupCombatants(combatants) {
    if (!combatants) {
        return false;
    }
    let didChange = false;

    for (let i in combatants) {
        const c = combatants[i];
        if (c) {
            delete c.cInfo;
            if (c.state=="dead") {
                c.state="active";
                didChange=true;
            }
        }
    }

    const prevOrder = combatants.concat([]);
    sortInitiative(combatants);
    if (!areSameDeep(combatants, prevOrder)) {
        didChange=true;
    }
    
    return didChange;
}

function toggleSelected(selected, softSelected, id) {
    if (selected && selected[id]) {
        selected = Object.assign({}, selected);
        delete selected[id];
        if (!Object.keys(selected).length) {
            selected = null;
            softSelected = id;
        }
    } else {
        selected = Object.assign({}, selected);
        selected[id]=true;
    }
    return {selected, softSelected};
}

function sortInitiative(newEM) {
    const ic = {};
    const {differentMonsterInitiatives} = campaign.getGameState();

    for (let i in newEM) {
        let e = newEM[i];

        if (e.initiative == null) {
            e = Object.assign({}, e);
            if (e.type) {
                if ((ic[e.type] != null) && !differentMonsterInitiatives) {
                    e.initiative = ic[e.type]||0;
                } else {
                    const mon = campaign.getMonster(e.type);
                    if (mon) {
                        if (mon.fixedInitiative) {
                            e.initiative = Number(mon.fixedInitiative);
                        } else {
                            const bonus = Math.trunc((mon.dex||10)/2)-5;

                            e.initiative = Math.trunc(dicerandom(20))+bonus;
                            if (e.initiative < 0){
                                e.initiative = 0;
                            }
                        }
                        ic[e.type] = e.initiative||0;
                    }
                }
            } else if (e.ctype == "cmonster") {
                for (let x in newEM) {
                    const pe = newEM[x];
                    if ((pe.ctype=="pc") && (pe.name == e.name)) {
                        const cObj = new Combatant(pe);
                        if (cObj.initiative != null) {
                            e.initiative = cObj.initiative;
                            break;
                        }
                    }
                }
            } else if (e.includeInCombat){
                e.initiative = Math.trunc(dicerandom(20));
            }
            newEM[i]=e;
        }
    }

    newEM.sort(function (a,b) {
        const ai = (new Combatant(a)).initiative;
        const bi = (new Combatant(b)).initiative;

        const comp = (bi||0)-(ai||0);
        // make sure companions come after player if same initiative value
        if (!comp) {
            return (a.companionId||"").localeCompare(b.companionId||"");
        }
        return comp;
    });
}

export {
    EncounterMonsterList, 
    calcXPTotals, 
    getXPColor, 
    getXP,
    getTokenInfo,
    addMonsters,
    addRowToCombat,
    addObject,
    fixupCombatants,
    fixupSelection,
    toggleSelected,
    findFreeSpot,
    Combatant,
}